import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import RootRouter from './router/RootRouter';
import apolloClient from './graphql/apollo';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './styles/index.sass';
import { AppContextProvider } from 'contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { registerReactDatepickerLocale, setYupLocale } from 'utils/locale';

const App: React.FunctionComponent = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    setYupLocale(i18n.language);
    registerReactDatepickerLocale(i18n.language);
  }, [i18n.language]);

  return (
    <ApolloProvider client={apolloClient}>
      <AppContextProvider>
        <RootRouter />
      </AppContextProvider>
      <ToastContainer />
    </ApolloProvider>
  );
};

export default App;
