import Empty from 'components/common/Empty';
import LoadingOverlay from 'components/common/loading/LoadingOverlay';
import CardPlaceholder from 'components/common/Placeholder/CardPlaceholder';
import React from 'react';
import { Col } from 'react-bootstrap';

const Container = ({ children }: { children: React.ReactNode }) => (
  <Col className="p-3" xs={12} lg={6} xxl={4}>
    {children}
  </Col>
);

const LoadingWrapper = ({
  loading,
  isData,
  children,
}: {
  loading: boolean;
  isData: boolean;
  children: undefined | JSX.Element[];
}) => {
  if (!isData && loading)
    return (
      <>
        {[...new Array(3)].map((e, index) => (
          <Container key={index}>
            <CardPlaceholder />
          </Container>
        ))}
      </>
    );

  if (!isData) return <Empty />;

  return (
    <>
      {children}
      {loading ? <LoadingOverlay /> : null}
    </>
  );
};

export default LoadingWrapper;
