import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type ProductForAppointmentFragment = {
  __typename?: 'Product';
  id: string;
  name: string;
  meetingPointName?: string | null;
  bookingkitSyncEnabled: boolean;
};

export type ProductForAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const ProductForAppointmentFragmentDoc = gql`
  fragment ProductForAppointment on Product {
    id
    name
    meetingPointName
    bookingkitSyncEnabled
  }
`;
