import React from 'react';
import TourTopics from './TourTopics';
import s from './index.module.sass';
import { useTranslation } from 'react-i18next';
import Products from './Products';
import { useToursConfigQuery } from 'graphql/queries/config/generated/ToursConfig';
import AlertGraphQLError from 'components/common/AlertGraphQLError';

const Tours = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useToursConfigQuery();

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <div className={s.options}>
      <h5>{t('systemConfig.tours')}</h5>
      <TourTopics loading={loading} data={data?.tourTopics} />
      <Products loading={loading} data={data?.products} />
    </div>
  );
};

export default Tours;
