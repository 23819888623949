import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { ProductConfigFragmentDoc } from '../../../fragments/product/generated/productConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductsForUpdatingQueryVariables = SchemaTypes.Exact<{
  appointmentType?: SchemaTypes.InputMaybe<SchemaTypes.AppointmentType>;
}>;

export type ProductsForUpdatingQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    bookingkitEventId?: string | null;
    bookingkitPriceId?: string | null;
    bookingkitSyncEnabled: boolean;
    name: string;
    language?: SchemaTypes.TourLanguage | null;
    type: SchemaTypes.ProductType;
    meetingPointName?: string | null;
    startPointAddress?: string | null;
    endPointAddress?: string | null;
    tourTopic?: {
      __typename?: 'TourTopic';
      id: string;
      name: { __typename?: 'Localized'; en: string; de: string };
    } | null;
  }>;
};

export const ProductsForUpdatingDocument = gql`
  query ProductsForUpdating($appointmentType: AppointmentType) {
    products(appointmentType: $appointmentType) {
      ...ProductConfig
    }
  }
  ${ProductConfigFragmentDoc}
`;

/**
 * __useProductsForUpdatingQuery__
 *
 * To run a query within a React component, call `useProductsForUpdatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForUpdatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForUpdatingQuery({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *   },
 * });
 */
export function useProductsForUpdatingQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsForUpdatingQuery, ProductsForUpdatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsForUpdatingQuery, ProductsForUpdatingQueryVariables>(
    ProductsForUpdatingDocument,
    options,
  );
}
export function useProductsForUpdatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductsForUpdatingQuery, ProductsForUpdatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsForUpdatingQuery, ProductsForUpdatingQueryVariables>(
    ProductsForUpdatingDocument,
    options,
  );
}
export type ProductsForUpdatingQueryHookResult = ReturnType<typeof useProductsForUpdatingQuery>;
export type ProductsForUpdatingLazyQueryHookResult = ReturnType<typeof useProductsForUpdatingLazyQuery>;
export type ProductsForUpdatingQueryResult = Apollo.QueryResult<
  ProductsForUpdatingQuery,
  ProductsForUpdatingQueryVariables
>;
export function refetchProductsForUpdatingQuery(variables?: ProductsForUpdatingQueryVariables) {
  return { query: ProductsForUpdatingDocument, variables: variables };
}
