import React from 'react';
import { FastField, Form, FormikHelpers, FormikProps } from 'formik';
import Button from 'components/common/Button';
import TextInput from 'components/inputs/TextInput/TextInput';
import Checkbox from 'components/inputs/Checkbox';
import * as yup from 'yup';
import s from './LoginForm.module.sass';
import { useTranslation } from 'react-i18next';
import { iLoginFormValues } from 'pages/auth/LoginPage';
import ScrollToError from 'components/common/ScrollToError';
import i18n from 'i18n';
import Formik from 'components/common/Formik';

const validationSchema = yup.object().shape({
  email: yup.string().required().label(i18n.t('login.fields.email.label')),
  password: yup.string().required().min(8).label(i18n.t('login.fields.password.label')),
});

interface iLoginFormProps {
  initialValues: iLoginFormValues;
  onSubmit: (values: iLoginFormValues, helpers: FormikHelpers<iLoginFormValues>) => void;
}

const LoginForm: FC<iLoginFormProps> = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {({ isSubmitting }: FormikProps<iLoginFormValues>) => (
        <Form>
          <ScrollToError />
          <FastField
            name="email"
            component={TextInput}
            label="login.fields.email.label"
            placeholder="login.fields.email.placeholder"
          />
          <FastField
            name="password"
            type="password"
            component={TextInput}
            label="login.fields.password.label"
            placeholder="login.fields.password.placeholder"
          />
          <FastField name="rememberMe" type="checkbox" component={Checkbox} label="login.fields.rememberMe.label" />
          <div className={s.submitBtnWrapper}>
            <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
              {t('send')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
