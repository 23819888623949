import React from 'react';
import * as yup from 'yup';
import { Navigate } from 'react-router-dom';
import { Form, FastField } from 'formik';
import Button from 'components/common/Button';
import useResetPassword from 'hooks/auth/useResetPassword';
import PageTitle from 'components/common/PageTitle';
import i18n from 'i18n';
import Formik from 'components/common/Formik';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import routePaths from 'router/route-paths';
import ScrollToError from 'components/common/ScrollToError';
import useGetSearchParams from 'hooks/useGetSearchParams';

interface IResetPasswordPage {
  token: string;
}

const resetPasswordShape = {
  password: yup.string().required(i18n.t('errors.requiredField')).min(8, i18n.t('errors.passwordIsTooShort')),
  repeatedPassword: yup
    .string()
    .required(i18n.t('errors.requiredField'))
    .oneOf([yup.ref('password'), null], i18n.t('errors.passwordsMustMatch')),
};

const validationSchema = yup.object().shape(resetPasswordShape);
const initialValues = { password: '', repeatedPassword: '' };

const ResetPasswordPage: FC<IResetPasswordPage> = ({ token }) => {
  const { t } = useTranslation();
  const onSubmit = useResetPassword(token);

  return (
    <Row className="h-100">
      <Col md={9} lg={6} xl={5} className="m-auto">
        <PageTitle title={t('pageTitles.resetPassword')} className="justify-content-center" />
        <Formik {...{ initialValues, onSubmit, validationSchema }}>
          {({ isSubmitting }) => (
            <>
              <Form>
                <ScrollToError />
                <FastField label="auth.newPassword" type="password" name="password" component={TextInput} />
                <FastField
                  label="auth.repeatNewPassword"
                  type="password"
                  name="repeatedPassword"
                  component={TextInput}
                />
                <div className="text-center">
                  <Button disabled={isSubmitting} loading={isSubmitting} type="submit" className="mt-3">
                    {t('auth.resetPasswordBtn')}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

const ResetPasswordContainer = () => {
  const {
    searchParams: { token },
  } = useGetSearchParams('token');

  if (!token) return <Navigate to={routePaths.login} />;

  return <ResetPasswordPage {...{ token }} />;
};

export default ResetPasswordContainer;
