import React, { useState } from 'react';
import s from './BookingRequestAlert.module.sass';
import { Trans } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import routePaths from 'router/route-paths';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useMatch } from 'react-router-dom';

const BookingRequestAlert = () => {
  const [isHideAlert, setIsHideAlert] = useState(false);
  const match = useMatch(routePaths.bookingRequestForm);
  if (isHideAlert || match) return null;
  return (
    <Alert
      variant="info"
      className={cn(s.alert, 'd-flex align-items-center gap-2')}
      onClose={() => setIsHideAlert(true)}
      dismissible
    >
      <InfoCircleFill />
      <span>
        <Trans
          i18nKey="appointments.bookingRequestAlert"
          components={{
            a: <Link target="_blank" to={routePaths.bookingRequestForm} className="alert-link" />,
          }}
        />
      </span>
    </Alert>
  );
};

export default BookingRequestAlert;
