import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  ContactPersonFormFields,
  contactPersonSchema,
  OrganizationFormFields,
  organizationSchema,
} from 'components/bookingRequest/ContactInformation';
import { TargetGroupSelect } from 'components/bookingRequest/inputs';
import { FormConfig } from 'components/bookingRequest/utils';
import { targetGroupSchema } from 'components/bookingRequest/TargetGroupForm';
import { useTranslation } from 'react-i18next';
import {
  ContactInformationInitialValuesProps,
  TargetGroupValuesProps,
} from 'pages/BookingRequestPage/BookingRequestPage.type';

export type DefaultUpdateCustomerInitialValues = Omit<ContactInformationInitialValuesProps, 'howDidYouHear'> &
  TargetGroupValuesProps;

export const defaultUpdateCustomerInitialValues: DefaultUpdateCustomerInitialValues = {
  targetGroupId: null,
  salutation: null,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '43',
  name: '',
  address: '',
  buildingNumber: '',
  zip: '',
  city: '',
  uidNumber: '',
};

export const schema = ({ formConfig }: { formConfig: FormConfig }) => ({
  ...targetGroupSchema(),
  ...organizationSchema({ formConfig }),
  ...contactPersonSchema(),
});

export type CustomerFormProps = { formConfig: FormConfig };

const CustomerForm = ({ formConfig }: CustomerFormProps) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col lg={6}>
        <h5>{t('bookingRequestView.updateCustomerModal.organization')}</h5>
        <TargetGroupSelect />
        <OrganizationFormFields formConfig={formConfig} />
      </Col>
      <Col>
        <h5>{t('bookingRequestView.updateCustomerModal.contactPerson')}</h5>
        <ContactPersonFormFields formConfig={formConfig} />
      </Col>
    </Row>
  );
};

export default CustomerForm;
