import { pickBy } from 'lodash';
import { Descendant } from 'slate';
import { jsx } from 'slate-hyperscript';
import { BLOCK_ELEMENT } from './constants';
import { getFontSizeFromStyle } from './PlaceholderSelect';
import { NodeAttributes } from './RichEditor.type';

const produceImageProps = (el: HTMLElement) =>
  pickBy(
    {
      width: parseFloat(el.style.width),
      height: parseFloat(el.style.height),
    },
    Number.isFinite,
  );

export const deserialize = (
  el: HTMLElement,
  markAttributes: NodeAttributes = {},
  options: { isDiv: boolean } = { isDiv: false },
) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes: NodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'I':
      nodeAttributes.italic = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
  }
  if (el.style.fontSize) {
    const fontSizeName = getFontSizeFromStyle(el.style.fontSize);
    if (fontSizeName) nodeAttributes['font-size'] = fontSizeName;
  }
  if (el.style.color) nodeAttributes.color = el.style.color;

  const newOptions = { ...options, isDiv: options.isDiv || el.nodeName === 'DIV' };

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node as HTMLElement, nodeAttributes, newOptions))
    .flat()
    .filter((node) => node && !('text' in node && !newOptions.isDiv)) as Descendant[];

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  const currentAttributes: NodeAttributes = {};
  if (el.style.textAlign) currentAttributes['text-align'] = el.style.textAlign;

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'STYLE':
      return [];
    case 'DIV':
      if (!options.isDiv) return children;
      return jsx('element', { type: BLOCK_ELEMENT.DIV, ...currentAttributes }, children);
    case 'BLOCKQUOTE':
      return jsx('element', { type: BLOCK_ELEMENT.BLOCK_QUOTE, ...currentAttributes }, children);
    case 'A':
      return jsx('element', { type: BLOCK_ELEMENT.LINK, url: el.getAttribute('href') }, children);
    case 'OL':
      return jsx('element', { type: BLOCK_ELEMENT.NUMBERED_LIST, ...currentAttributes }, children);
    case 'UL':
      return jsx('element', { type: BLOCK_ELEMENT.BULLETED_LIST, ...currentAttributes }, children);
    case 'LI':
      return jsx('element', { type: BLOCK_ELEMENT.LIST_ITEM }, children);
    case 'IMG':
      return jsx(
        'element',
        {
          type: BLOCK_ELEMENT.IMAGE,
          url: el.getAttribute('src'),
          ...produceImageProps(el),
          ...currentAttributes,
        },
        children,
      );
    default:
      return children;
  }
};

export const htmlStringToSlateString = (htmlString: string) => {
  try {
    const html = new DOMParser().parseFromString(htmlString, 'text/html');
    return JSON.stringify(deserialize(html.body));
  } catch {
    return '';
  }
};
