import { BookingkitConnectionForAppointmentFragment } from 'graphql/fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import { AppointmentType } from 'graphql/types.generated';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { Color, Variant } from 'react-bootstrap/esm/types';
import { useTranslation } from 'react-i18next';
import { BookingkitSyncStatus, getSyncStatus } from './utils';

interface SyncStatusBadgeProps {
  bookingkitConnection?: BookingkitConnectionForAppointmentFragment | null;
  appointmentType: AppointmentType;
}

const colors: Record<BookingkitSyncStatus, { bg: Variant; text: Color }> = {
  [BookingkitSyncStatus.PUSHED]: { bg: 'white', text: 'dark' },
  [BookingkitSyncStatus.NOT_PUSHED]: { bg: 'warning', text: 'light' },
  [BookingkitSyncStatus.ERROR]: { bg: 'danger', text: 'light' },
  [BookingkitSyncStatus.IGNORED]: { bg: 'white', text: 'dark' },
  [BookingkitSyncStatus.UNKNOWN]: { bg: 'warning', text: 'light' },
};
const SyncStatusBadge: FC<SyncStatusBadgeProps> = (props) => {
  const { t } = useTranslation();
  const { bookingkitConnection, appointmentType } = props;

  const syncStatus = getSyncStatus({ bookingkitConnection, appointmentType });

  const colorConfig = colors[syncStatus];

  return <Badge {...colorConfig}>{t(`appointments.bookingkitCell.status.${syncStatus}`)}</Badge>;
};

export default SyncStatusBadge;
