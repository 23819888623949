import { useAppContext } from 'contexts/AppContext';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import routePaths from 'router/route-paths';
import s from './Sidebar.module.sass';
import { NavLink, useLocation } from 'react-router-dom';
import { screenSizeConfig } from 'components/common/Visibility';
import { Gear, People, Envelope, Calendar2Heart, ChevronLeft, BookmarkHeart } from 'react-bootstrap-icons';
import { isMobile } from 'react-device-detect';
import { Button, Nav, Navbar } from 'react-bootstrap';
import useUser from 'hooks/user/useUser';
import { Roles, User } from 'graphql/types.generated';
import Footer from './Footer';
import DropdownMenu from './DropdownMenu';
import NavbarBrand from './NavbarBrand';
import useBreakpoint from 'use-breakpoint';
import TotalBookingRequestBadge from 'components/app/bookingRequest/TotalBookingRequestBadge';

const sidebarItems = [
  {
    path: routePaths.appointments,
    accessRole: Roles.ADMIN,
    title: 'pageTitles.appointments',
    icon: <Calendar2Heart />,
  },
  {
    path: routePaths.bookingRequests,
    accessRole: Roles.ADMIN,
    title: 'pageTitles.bookingRequests',
    icon: <BookmarkHeart />,
    extra: TotalBookingRequestBadge,
  },
  {
    path: routePaths.emailTemplates,
    accessRole: Roles.ADMIN,
    title: 'pageTitles.emailTemplates',
    icon: <Envelope />,
  },
  {
    path: routePaths.userManagement,
    accessRole: Roles.ADMIN,
    title: 'pageTitles.userManagement',
    icon: <People />,
  },
  {
    path: routePaths.systemConfig,
    accessRole: Roles.ADMIN,
    title: 'pageTitles.systemConfig',
    icon: <Gear />,
  },
].map((item, i) => ({ id: i, ...item }));

interface iSidebarMenuItem {
  path: string;
  title: string;
  icon?: React.ReactElement;
  onClick: () => void;
  isActive: boolean;
  extra?: FC<{ className?: string }>;
}

const SidebarMenuItem: FC<iSidebarMenuItem> = ({ path, title, onClick, icon, isActive, extra: Extra }) => {
  return (
    <li>
      <NavLink to={path} className={s.sidebarMenuItem} onClick={onClick}>
        {icon ? (
          <span className={cn(s.sidebarMenuItemIcon, isActive ? 'text-primary' : 'text-muted')}>{icon}</span>
        ) : null}
        <span className={cn(s.sidebarMenuItemLabel, isActive ? 'text-primary' : 'text-muted', 'text-truncate')}>
          {title}
        </span>
        {Extra ? <Extra className="ms-1" /> : null}
      </NavLink>
    </li>
  );
};

const isWider = (breakpoint: keyof typeof screenSizeConfig | undefined) =>
  !!breakpoint && screenSizeConfig[breakpoint] > screenSizeConfig.md;

const Sidebar = () => {
  const { t } = useTranslation();
  const { sidebarOpen, toggleSidebar } = useAppContext();
  const { me, loading } = useUser();
  const { role } = me as User;
  const { breakpoint } = useBreakpoint(screenSizeConfig);
  const widthRef: React.MutableRefObject<'narrow' | 'wide' | null> = useRef(null);
  const { pathname } = useLocation();

  const onSideBarItemClick = useCallback(() => {
    if (isMobile) toggleSidebar(false);
  }, [toggleSidebar]);

  useLayoutEffect(() => {
    if (isWider(breakpoint) && widthRef.current !== 'wide') {
      widthRef.current = 'wide';
      toggleSidebar(true);
    } else if (!isWider(breakpoint) && widthRef.current !== 'narrow') {
      widthRef.current = 'narrow';
      toggleSidebar(false);
    }
  }, [breakpoint, toggleSidebar]);
  if (loading) return null;
  return (
    <div className={cn(s.sidebar, { [s.sidebarOpen]: sidebarOpen })}>
      <Navbar bg="white" expand={false} className={cn('px-3 py-0 flex-nowrap', s.sidebarContent)}>
        <NavbarBrand />
        <ul className={cn(s.sidebarMenu, s.mainMenu, 'flex-1')}>
          {sidebarItems.map(({ id, path, title, accessRole, icon, extra }) => {
            if (accessRole && role !== accessRole) return null;
            return (
              <SidebarMenuItem
                key={id}
                onClick={onSideBarItemClick}
                path={path}
                title={t(title)}
                icon={icon}
                isActive={pathname === path}
                extra={extra}
              />
            );
          })}
        </ul>
        <Nav className="w-100">
          <DropdownMenu />
        </Nav>
        {sidebarOpen ? <Footer /> : null}
      </Navbar>
      <Button
        type="button"
        variant="light"
        className={cn(s.toggleSidebarBtn, !sidebarOpen ? 'rotate-180' : '', 'custom-border rounded-circle')}
        onClick={() => {
          toggleSidebar(!sidebarOpen);
        }}
      >
        <ChevronLeft className="text-primary" />
      </Button>
    </div>
  );
};

export default Sidebar;
