import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Card } from 'react-bootstrap';
import RichEditorFieldWithRef, { getDefaultRichEditorString } from 'components/inputs/RichEditor';
import { formatDate } from 'utils/helpers';
import { Clock, Trash } from 'react-bootstrap-icons';
import { iInputModalConfirm, InputModalConfirm } from 'components/common/Modal/InputModal';
import i18n from 'i18n';
import * as yup from 'yup';
import s from './EmailTemplateCard.module.sass';
import { merge, pick, pickBy } from 'lodash';
import { EmailTemplateInput, TourLanguage } from 'graphql/types.generated';
import cn from 'classnames';
import { screenSizeConfig } from 'components/common/Visibility';
import { emailTemplateSchema, BodyInput, NameInput, SubjectInput } from 'components/app/emailTemplate';
import { LanguageSelect } from 'components/bookingRequest/inputs';
import { useUpdateEmailTemplateMutation } from 'graphql/mutations/emailTemplate/generated/UpdateEmailTemplate';
import { EmailTemplateSettingFragment } from 'graphql/fragments/emailTemplate/generated/emailTemplateSettingFragment';
import { FlagEmojis } from '../UserProfilePage/UserProfilePage';
import { toast } from 'react-toastify';

const emailTemplateValidationSchema = () =>
  yup.object().shape({
    ...emailTemplateSchema(),
  });

type EmailTemplateProps = {
  name: string;
  language: TourLanguage | null;
  subject: string;
  body: string;
};

const EmailTemplateFormikContent: FC<{ isTargetGroupTemplate?: boolean }> = ({ isTargetGroupTemplate }) => (
  <>
    <NameInput />
    <LanguageSelect disabled={isTargetGroupTemplate} />
    <SubjectInput />
    <BodyInput />
  </>
);

const defaultInitialValues: EmailTemplateProps = {
  name: '',
  subject: '',
  language: null,
  body: getDefaultRichEditorString(),
};

export const emailTemplateModal = ({
  emailTemplate,
  onSubmit,
}: {
  emailTemplate?: EmailTemplateSettingFragment;
  onSubmit: (values: EmailTemplateInput) => void;
}) =>
  InputModalConfirm({
    initialValues: merge(
      {},
      defaultInitialValues,
      pick(pickBy(emailTemplate, Boolean), Object.keys(defaultInitialValues)),
    ),
    formikContent: () => <EmailTemplateFormikContent isTargetGroupTemplate={!!emailTemplate?.targetGroupId} />,
    onSubmit: async (values: EmailTemplateInput) => {
      await onSubmit(values);
    },
    headerText: i18n.t('emailTemplates.edit.modalTitle'),
    validationSchema: emailTemplateValidationSchema,
    width: screenSizeConfig.xl,
  } as iInputModalConfirm<EmailTemplateProps>);

const EmailTemplateCard = ({
  emailTemplate,
  onDelete,
}: {
  emailTemplate: EmailTemplateSettingFragment;
  onDelete: (emailTemplateId: string) => void;
}) => {
  const { t } = useTranslation();
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation();
  return (
    <Card className={s.card}>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-baseline gap-2">
          <Card.Title>
            {FlagEmojis[emailTemplate.language as keyof typeof FlagEmojis]} {emailTemplate.name}{' '}
            {emailTemplate.targetGroupId ? <Badge>{t('emailTemplates.isTargetGroupTemplate')}</Badge> : null}
          </Card.Title>
          {emailTemplate.targetGroupId ? null : (
            <Trash
              className="cursor-pointer min-height-fit-content"
              onClick={() => {
                onDelete(emailTemplate.id);
              }}
            />
          )}
        </div>
        <h6 className={cn(s.subject, 'text-muted')}>{emailTemplate.subject}</h6>
        <RichEditorFieldWithRef
          initialValue={emailTemplate.body}
          className={cn(s.body, 'rich-editor-read-only')}
          readOnly
        />
        <Button
          className="px-0 my-2"
          variant="link"
          onClick={() => {
            emailTemplateModal({
              emailTemplate,
              onSubmit: async (values: EmailTemplateInput) => {
                await updateEmailTemplate({ variables: { modifier: values, emailTemplateId: emailTemplate.id } });
                toast.success(i18n.t('emailTemplates.edit.successfullyUpdated'));
              },
            });
          }}
        >
          {t('emailTemplates.edit.button')}
        </Button>
      </Card.Body>
      <Card.Footer className="text-muted d-flex align-items-center gap-2">
        <Clock />
        {formatDate(emailTemplate.createdAt)}
      </Card.Footer>
    </Card>
  );
};
export default EmailTemplateCard;
