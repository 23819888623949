import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldInputProps, FormikProps } from 'formik';
import { get, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { iRequired, Required } from 'components/common/Required';

interface iTextArea extends iRequired {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  type?: string;
  label: string;
  disabled?: boolean;
  rows?: number;
  className?: string;
}

const TextArea: FC<iTextArea> = ({ field, form, label, disabled = false, rows = 5, className, isRequired }) => {
  const { name, onBlur, value } = field;
  const { errors, touched, isSubmitting } = form;
  const { t } = useTranslation();

  const isDisabled = disabled || isSubmitting;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.target.value);
    },
    [form, name],
  );

  useEffect(() => {
    if (isNull(value)) form.setFieldValue(name, '');
  }, [form, name, value]);

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <Form.Control
        {...{ name, onBlur, disabled, rows }}
        value={value ?? ''}
        onChange={handleChange}
        disabled={isDisabled}
        readOnly={isDisabled}
        className={cn(className, isDisabled ? 'disabled-input' : '')}
        as="textarea"
      />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextArea;
