import client from 'graphql/apollo';
import {
  CreateEmailTemplateMutation,
  CreateEmailTemplateMutationVariables,
  CreateEmailTemplateDocument,
} from 'graphql/mutations/emailTemplate/generated/CreateEmailTemplate';
import {
  EmailTemplatesDocument,
  EmailTemplatesQueryVariables,
} from 'graphql/queries/emailTemplate/generated/EmailTemplates';
import constants from 'constants/constants';
import i18n from 'i18n';
import ConfirmModal, { iConfirmModalProps } from 'components/common/Modal/ConfirmModal';
import { DeleteEmailTemplateDocument } from 'graphql/mutations/emailTemplate/generated/DeleteEmailTemplate';
import { toast } from 'react-toastify';
import { EmailTemplateInput } from 'graphql/types.generated';

const getRefetchQueries = (skip: number) => [
  {
    query: EmailTemplatesDocument,
    variables: { limit: constants.emailTemplateCountTableRowsPerPage, skip } as EmailTemplatesQueryVariables,
  },
];

export const createEmailTemplate = async (values: EmailTemplateInput, skip: number) => {
  await client.mutate<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>({
    mutation: CreateEmailTemplateDocument,
    variables: { modifier: values },
    refetchQueries: getRefetchQueries(skip),
  });
  toast.success(i18n.t('emailTemplates.createEmailTemplateSuccess'));
};

export const deleteEmailTemplate = async ({ emailTemplateId, skip }: { emailTemplateId: string; skip: number }) =>
  ConfirmModal({
    headerText: i18n.t('emailTemplates.deleteConfirmation.title'),
    description: i18n.t('emailTemplates.deleteConfirmation.description'),
    onOk: async () => {
      await client.mutate({
        mutation: DeleteEmailTemplateDocument,
        variables: {
          emailTemplateId,
        },
        refetchQueries: getRefetchQueries(skip),
      });
      toast.success(i18n.t('emailTemplates.deleteConfirmation.success'));
    },
  } as iConfirmModalProps);
