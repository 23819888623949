import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TourTopicConfigFragmentDoc } from '../../../fragments/tourTopic/generated/tourTopicConfigFragment';
import { ProductConfigFragmentDoc } from '../../../fragments/product/generated/productConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ToursConfigQueryVariables = SchemaTypes.Exact<{
  appointmentType?: SchemaTypes.InputMaybe<SchemaTypes.AppointmentType>;
}>;

export type ToursConfigQuery = {
  __typename?: 'Query';
  tourTopics: Array<{
    __typename?: 'TourTopic';
    id: string;
    code: string;
    name: { __typename?: 'Localized'; en: string; de: string };
  }>;
  products: Array<{
    __typename?: 'Product';
    id: string;
    bookingkitEventId?: string | null;
    bookingkitPriceId?: string | null;
    bookingkitSyncEnabled: boolean;
    name: string;
    language?: SchemaTypes.TourLanguage | null;
    type: SchemaTypes.ProductType;
    meetingPointName?: string | null;
    startPointAddress?: string | null;
    endPointAddress?: string | null;
    tourTopic?: {
      __typename?: 'TourTopic';
      id: string;
      name: { __typename?: 'Localized'; en: string; de: string };
    } | null;
  }>;
};

export const ToursConfigDocument = gql`
  query ToursConfig($appointmentType: AppointmentType) {
    tourTopics {
      ...TourTopicConfig
    }
    products(appointmentType: $appointmentType) {
      ...ProductConfig
    }
  }
  ${TourTopicConfigFragmentDoc}
  ${ProductConfigFragmentDoc}
`;

/**
 * __useToursConfigQuery__
 *
 * To run a query within a React component, call `useToursConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useToursConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToursConfigQuery({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *   },
 * });
 */
export function useToursConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<ToursConfigQuery, ToursConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ToursConfigQuery, ToursConfigQueryVariables>(ToursConfigDocument, options);
}
export function useToursConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ToursConfigQuery, ToursConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ToursConfigQuery, ToursConfigQueryVariables>(ToursConfigDocument, options);
}
export type ToursConfigQueryHookResult = ReturnType<typeof useToursConfigQuery>;
export type ToursConfigLazyQueryHookResult = ReturnType<typeof useToursConfigLazyQuery>;
export type ToursConfigQueryResult = Apollo.QueryResult<ToursConfigQuery, ToursConfigQueryVariables>;
export function refetchToursConfigQuery(variables?: ToursConfigQueryVariables) {
  return { query: ToursConfigDocument, variables: variables };
}
