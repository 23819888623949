import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type UpdateEmailTemplateFragment = {
  __typename?: 'EmailTemplate';
  id: string;
  name: string;
  language: SchemaTypes.TourLanguage;
  subject: string;
  body: string;
};

export type UpdateEmailTemplateFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const UpdateEmailTemplateFragmentDoc = gql`
  fragment UpdateEmailTemplate on EmailTemplate {
    id
    name
    language
    subject
    body
  }
`;
