import { LanguageSelect, TourTopicSelect } from 'components/bookingRequest/inputs';
import FastField from 'components/common/FastField';
import Select from 'components/inputs/Select';
import Switch from 'components/inputs/Switch';
import TextInput from 'components/inputs/TextInput';
import { Field, useFormikContext } from 'formik';
import { useBookingkitEventQuery } from 'graphql/queries/bookingkit/generated/BookingkitEvent';
import { useBookingkitEventsQuery } from 'graphql/queries/bookingkit/generated/BookingkitEvents';
import { ProductType } from 'graphql/types.generated';
import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Values } from './productModal';

export const isIncludeBookingPriceField = ({
  type,
  bookingkitSyncEnabled,
}: Pick<Values, 'bookingkitSyncEnabled' | 'type'>) =>
  bookingkitSyncEnabled && type && [ProductType.EVENT, ProductType.PRIVATE_TOUR].includes(type);

export const ProductTypeSelect: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const productTypeOptions = useMemo(() => {
    return Object.keys(ProductType).map((type) => ({
      value: type,
      label: t(`productType.${type}`),
    }));
  }, [t]);

  return (
    <FastField
      isRequired
      name="type"
      label="systemConfig.fields.type.label"
      component={Select}
      options={productTypeOptions}
      disabled={disabled}
    />
  );
};

const TourTopicAndLanguageSection = () => {
  const {
    values: { type },
  } = useFormikContext<Values>();
  const issRequired = useMemo(() => !(!type || type === ProductType.EVENT), [type]);

  return (
    <>
      <LanguageSelect isRequired={issRequired} />
      <TourTopicSelect isRequired={issRequired} />
    </>
  );
};

const BookingkitEventInput = () => {
  const {
    values: { bookingkitSyncEnabled },
  } = useFormikContext<Values>();

  const { data, loading } = useBookingkitEventsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !bookingkitSyncEnabled,
  });

  const bookingkitEventOptions = useMemo(() => {
    if (!data) return [];
    return data.bookingkitEvents?.map(({ id, title }) => ({
      value: id,
      label: title,
    }));
  }, [data]);

  return (
    <Field
      isRequired
      disabled={!data && loading}
      loading={loading}
      label="systemConfig.fields.bookingkitEventId.label"
      name="bookingkitEventId"
      component={Select}
      options={bookingkitEventOptions}
    />
  );
};

const BookingkitPriceIdInput = () => {
  const {
    values: { type, bookingkitEventId, bookingkitSyncEnabled },
    setFieldValue,
  } = useFormikContext<Values>();
  const isShow = useMemo(
    () => isIncludeBookingPriceField({ type, bookingkitSyncEnabled }),
    [bookingkitSyncEnabled, type],
  );

  const { data, loading } = useBookingkitEventQuery({
    variables: { bookingkitEventId },
    fetchPolicy: 'cache-and-network',
    skip: !bookingkitEventId || !isShow,
  });

  const priceOptions = useMemo(() => {
    if (!data?.bookingkitEvent) return [];
    return data.bookingkitEvent.prices.map(({ id, title, value, currency }) => ({
      value: id,
      label: `${title} – ${value} ${currency}`,
    }));
  }, [data]);

  useEffect(() => {
    if (data?.bookingkitEvent?.prices.length === 1)
      setFieldValue('bookingkitPriceId', data.bookingkitEvent.prices[0].id, true);
  }, [data, setFieldValue]);

  if (!isShow) return null;
  return (
    <Field
      isRequired
      disabled={!data && loading}
      loading={loading}
      label="systemConfig.fields.bookingkitPriceId.label"
      name="bookingkitPriceId"
      component={Select}
      options={priceOptions}
    />
  );
};

const BookingkitSection = () => {
  const {
    values: { bookingkitSyncEnabled },
  } = useFormikContext<Values>();
  return (
    <>
      <Field
        label="systemConfig.fields.bookingkitSyncEnabled.label"
        name="bookingkitSyncEnabled"
        component={Switch}
        isRequired
      />
      {bookingkitSyncEnabled ? (
        <>
          <BookingkitEventInput />
          <BookingkitPriceIdInput />
        </>
      ) : null}
    </>
  );
};

const NameInput = () => {
  const {
    values: { bookingkitSyncEnabled },
  } = useFormikContext<Values>();
  return bookingkitSyncEnabled ? null : (
    <FastField isRequired name="name" component={TextInput} label="systemConfig.fields.name.label" />
  );
};

const ProductForm = ({ isEdit }: { isEdit: boolean }) => {
  return (
    <>
      {isEdit ? null : <ProductTypeSelect />}
      <BookingkitSection />
      <NameInput />
      <FastField name="meetingPointName" component={TextInput} label="systemConfig.fields.meetingPointName.label" />
      <Row>
        <Col md={6}>
          <FastField
            name="startPointAddress"
            component={TextInput}
            label="systemConfig.fields.startPointAddress.label"
          />
        </Col>
        <Col>
          <FastField name="endPointAddress" component={TextInput} label="systemConfig.fields.endPointAddress.label" />
        </Col>
      </Row>
      {isEdit ? null : <TourTopicAndLanguageSection />}
    </>
  );
};
export default ProductForm;
