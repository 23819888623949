import { AppointmentType as AT } from 'graphql/types.generated';

export type AppointmentType = keyof typeof AT | 'RECURRING_BOOKING_SLOT';
export const AppointmentTypes = Object.fromEntries([
  ...Object.entries(AT),
  ['RECURRING_BOOKING_SLOT', 'RECURRING_BOOKING_SLOT'],
]) as Record<AppointmentType, AppointmentType>;

export const DEFAULT_APPOINTMENT_DURATION_HOURS = 2; // hours;
export const PARTICIPANTS_MAX_LIMIT = 200;
