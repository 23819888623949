import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BookingRequestViewMainInfoFragment = {
  __typename?: 'BookingRequest';
  id: string;
  date: string | Date;
  altDate: string | Date;
  createdAt: string | Date;
  totalParticipants: number;
  totalAccompanyingPersons?: number | null;
  ageOfParticipants?: string | null;
  code?: string | null;
  language: SchemaTypes.TourLanguage;
  howDidYouHear?: string | null;
  status: SchemaTypes.BookingRequestStatus;
  automationDetails?: Array<{
    __typename?: 'BookingRequestAutomationDetails';
    name: SchemaTypes.CriteriaType;
    details?: string | null;
    passed: boolean;
  }> | null;
};

export type BookingRequestViewMainInfoFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingRequestViewMainInfoFragmentDoc = gql`
  fragment BookingRequestViewMainInfo on BookingRequest {
    id
    date
    altDate
    createdAt
    totalParticipants
    totalAccompanyingPersons
    ageOfParticipants
    code
    language
    howDidYouHear
    status
    automationDetails {
      name
      details
      passed
    }
  }
`;
