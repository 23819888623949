import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { ProductConfigFragmentDoc } from '../../../fragments/product/generated/productConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProductMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.UpdateProductInput;
  id: SchemaTypes.Scalars['ID'];
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  updateProduct: {
    __typename?: 'Product';
    id: string;
    bookingkitEventId?: string | null;
    bookingkitPriceId?: string | null;
    bookingkitSyncEnabled: boolean;
    name: string;
    language?: SchemaTypes.TourLanguage | null;
    type: SchemaTypes.ProductType;
    meetingPointName?: string | null;
    startPointAddress?: string | null;
    endPointAddress?: string | null;
    tourTopic?: {
      __typename?: 'TourTopic';
      id: string;
      name: { __typename?: 'Localized'; en: string; de: string };
    } | null;
  };
};

export const UpdateProductDocument = gql`
  mutation UpdateProduct($data: UpdateProductInput!, $id: ID!) {
    updateProduct(data: $data, id: $id) {
      ...ProductConfig
    }
  }
  ${ProductConfigFragmentDoc}
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
