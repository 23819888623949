import React from 'react';
import s from './PageTitle.module.sass';
import cn from 'classnames';

export interface iPageTitleProps {
  logo?: FC | string;
  subtitle?: FC | string;
  title?: FC | string;
  extra?: React.ReactNode;
  className?: string;
}

const PageTitle: FC<iPageTitleProps> = ({ subtitle, title, logo, extra, className }) => {
  if (!subtitle && !title && !logo) return null;
  return (
    <div className={cn(className ?? 'justify-content-between', 'd-flex')}>
      <div className="text-center">
        {logo ? (
          <>
            {typeof logo === 'string' ? <img className="logo" src={logo} /> : logo}
            <br />
            <br />
          </>
        ) : null}
        <h1 className={s.title}>{title}</h1>
        <h6 className={s.subtitle}>{subtitle}</h6>
      </div>
      {extra ? <div>{extra}</div> : null}
    </div>
  );
};

export default PageTitle;
