import React from 'react';
import { InputModalConfirm } from 'components/common/Modal/InputModal';
import i18n from 'i18n';
import Button from 'components/common/Button';
import { Save } from 'react-bootstrap-icons';
import { AppointmentSchemaProps } from 'components/app/appointment/AppointmentForm';
import { FormConfigs } from 'components/bookingRequest/utils';
import { toast } from 'react-toastify';
import BlockedDateForm, { blockedDateValidationSchema } from './BlockedDateForm';
import client from 'graphql/apollo';
import {
  CreateBlockedDateDocument,
  CreateBlockedDateMutation,
  CreateBlockedDateMutationVariables,
} from 'graphql/mutations/blockedDate/generated/CreateBlockedDate';
import { Scalars, BlockedDateInput } from 'graphql/types.generated';
import { disabledCodesToJSON, getRefetchQueries } from './utils';
import {
  UpdateBlockedDateDocument,
  UpdateBlockedDateMutation,
  UpdateBlockedDateMutationVariables,
} from 'graphql/mutations/blockedDate/generated/UpdateBlockedDate';
import { BlockedDateConfigFragment } from 'graphql/fragments/blockedDates/generated/blockedDateConfigFragment';

export type iAppointmentValidationSchema = Omit<AppointmentSchemaProps, 'formConfig'> & {
  formConfigs: FormConfigs;
};

export type Values = {
  startDate?: Scalars['DateTime'];
  endDate?: Scalars['DateTime'];
  disabledCodes: string[];
  isAllCodes: boolean;
};

const produceBlockedDate = ({ disabledCodes, endDate, startDate, isAllCodes }: Values): BlockedDateInput => ({
  isAllCodes,
  endDate: new Date(endDate as Scalars['DateTime']),
  startDate: new Date(startDate as Scalars['DateTime']),
  disabledCodes: disabledCodes.map((disabledCode) => JSON.parse(disabledCode)),
});

const blockedDateModal = async (blockedDate?: BlockedDateConfigFragment) => {
  const initialValues: Values = {
    startDate: blockedDate?.startDate ? new Date(blockedDate?.startDate) : undefined,
    endDate: blockedDate?.endDate ? new Date(blockedDate?.endDate) : undefined,
    disabledCodes:
      blockedDate?.disabledCodes?.map(({ tourTopic, targetGroup }) =>
        disabledCodesToJSON({ tourTopic, targetGroup }),
      ) ?? [],
    isAllCodes: blockedDate?.isAllCodes ?? false,
  };
  InputModalConfirm({
    validationSchema: blockedDateValidationSchema,
    initialValues,
    onSubmit: async (values) => {
      const data = produceBlockedDate(values);
      if (blockedDate) {
        await client.mutate<UpdateBlockedDateMutation, UpdateBlockedDateMutationVariables>({
          mutation: UpdateBlockedDateDocument,
          variables: {
            id: blockedDate.id,
            data,
          },
          refetchQueries: getRefetchQueries(),
        });
        toast.success(i18n.t('systemConfig.updateBlockedDateSuccess'));
      } else {
        await client.mutate<CreateBlockedDateMutation, CreateBlockedDateMutationVariables>({
          mutation: CreateBlockedDateDocument,
          variables: {
            data,
          },
          refetchQueries: getRefetchQueries(),
        });
        toast.success(i18n.t('systemConfig.createBlockedDateSuccess'));
      }
    },
    formikContent: () => <BlockedDateForm />,
    customFooter: ({ onClose, disabled }) => (
      <>
        <Button onClick={onClose} variant="outline-primary">
          {i18n.t('cancel')}
        </Button>
        <Button endIcon={<Save />} disabled={disabled} type="submit">
          {i18n.t('save')}
        </Button>
      </>
    ),
  });
};

export default blockedDateModal;
