import React from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Empty = () => {
  const { t } = useTranslation();
  return (
    <Alert className="text-center" variant="info">
      {t('empty')}
    </Alert>
  );
};

export default Empty;
