import { useFormikContext } from 'formik';
import client from 'graphql/apollo';
import { TargetGroupFieldConfigFragment } from 'graphql/fragments/targetGroup/generated/targetGroupFieldConfigFragment';
import {
  TargetGroupNameFragment,
  TargetGroupNameFragmentDoc,
} from 'graphql/fragments/targetGroup/generated/targetGroupNameFragment';
import {
  TargetGroupOfferFragment,
  TargetGroupOfferFragmentDoc,
} from 'graphql/fragments/targetGroup/generated/targetGroupOfferFragment';
import { useTargetGroupFieldConfigsQuery } from 'graphql/queries/targetGroup/generated/TargetGroupFieldConfigs';
import { TargetGroupFieldConfig } from 'graphql/types.generated';
import { pick, reduce } from 'lodash';
import { useMemo } from 'react';

export type FormConfig = Record<string, Omit<TargetGroupFieldConfig, 'name' | '__typename'>>;
export type FormConfigs = Record<string, FormConfig>;

export const useTargetGroupNameFromCache = (targetGroupId?: string): TargetGroupNameFragment | null => {
  return useMemo(
    () =>
      client.readFragment({
        id: `TargetGroup:${targetGroupId}`,
        fragment: TargetGroupNameFragmentDoc,
        fragmentName: 'TargetGroupName',
      }),
    [targetGroupId],
  );
};

export const useTargetGroupOfferFromCache = (targetGroupId?: string): TargetGroupOfferFragment | null => {
  return useMemo(
    () =>
      client.readFragment({
        id: `TargetGroup:${targetGroupId}`,
        fragment: TargetGroupOfferFragmentDoc,
        fragmentName: 'TargetGroupOffer',
      }),
    [targetGroupId],
  );
};

export const fieldConfigArrayToObject = (fieldConfig: TargetGroupFieldConfig[] = []): FormConfig =>
  reduce(
    fieldConfig,
    (acc, field) => ({
      ...acc,
      [field.name]: pick(field, ['visible', 'required']),
    }),
    {},
  );

export const useTargetGroupFormConfigs = (targetGroup?: TargetGroupFieldConfigFragment) => {
  if (targetGroup?.bookingFormConfig?.fieldConfig)
    [[{ [targetGroup.id]: fieldConfigArrayToObject(targetGroup.bookingFormConfig.fieldConfig) }], [targetGroup]];
  const { data, loading, error } = useTargetGroupFieldConfigsQuery();
  const formConfigs = useMemo(
    () =>
      (data?.targetGroups ?? []).reduce((acc: FormConfigs, { id, bookingFormConfig }) => {
        if (!bookingFormConfig) return acc;
        return {
          ...acc,
          [id]: fieldConfigArrayToObject(bookingFormConfig.fieldConfig),
        };
      }, {}),
    [data?.targetGroups],
  );
  return { formConfigs, targetGroups: data?.targetGroups, loading, error };
};

export const useFormConfig = (formConfigs: FormConfigs) => {
  const {
    values: { targetGroupId },
  } = useFormikContext<{ targetGroupId: string }>();

  return useMemo(() => formConfigs[targetGroupId], [formConfigs, targetGroupId]);
};
