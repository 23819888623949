import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FastField from 'components/common/FastField';
import { Field } from 'formik';
import Select from 'components/inputs/Select';
import { FlagEmojis } from 'pages/app/UserProfilePage/UserProfilePage';
import TextArea from 'components/inputs/TextArea';
import i18n from 'i18n';
import { useTargetGroupsForSelectQuery } from 'graphql/queries/targetGroup/generated/TargetGroupsForSelect';
import { TourLanguage } from 'graphql/types.generated';
import { useTourTopicsForSelectQuery } from 'graphql/queries/tourTopic/generated/TourTopicsForSelect';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { iRequired } from 'components/common/Required';

export const LanguageSelect: FC<{ disabled?: boolean } & iRequired> = ({ isRequired = true, ...props }) => {
  const { t } = useTranslation();
  const languageOptions = useMemo(() => {
    return Object.keys(TourLanguage).map((language) => ({
      value: language,
      label: `${FlagEmojis[language as keyof typeof FlagEmojis]} ${t(`locale.${language}`)}`,
    }));
  }, [t]);

  return (
    <FastField
      isRequired={isRequired}
      label="bookingRequestView.fields.language.label"
      name="language"
      component={Select}
      options={languageOptions}
      {...props}
    />
  );
};

export const TourTopicSelect: FC<iRequired> = ({ isRequired = true }) => {
  const { data, loading, error } = useTourTopicsForSelectQuery();

  const tourTopicOptions = useMemo(() => {
    if (!data) return [];
    return data.tourTopics.map(({ id, name }) => ({
      value: id,
      label: name[i18n.language as keyof typeof name],
    }));
  }, [data]);

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Field
      isRequired={isRequired}
      disabled={!data && loading}
      loading={loading}
      label="bookingRequestView.fields.tourTopicId.label"
      name="tourTopicId"
      component={Select}
      options={tourTopicOptions}
    />
  );
};

export const AgeOfParticipantsTextareaInput = () => (
  <FastField
    name="ageOfParticipants"
    component={TextArea}
    rows={3}
    label="bookingRequestView.fields.ageOfParticipants.label"
  />
);

export const TargetGroupSelect = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useTargetGroupsForSelectQuery();
  const targetGroupOptions = useMemo(() => {
    if (!data) return [];
    return data.targetGroups.map(({ id, name }) => ({
      value: id,
      label: name[i18n.language as keyof typeof name],
    }));
  }, [data, i18n.language]);

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Field
      isRequired
      disabled={!data && loading}
      loading={loading}
      label="bookingRequestView.fields.targetGroupId.label"
      name="targetGroupId"
      component={Select}
      options={targetGroupOptions}
    />
  );
};
