import Table from 'components/common/Table';
import { FlagEmojis } from 'pages/app/UserProfilePage/UserProfilePage';
import React, { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { TourTopicsProps, TourTopic } from './index.types';

const columns = ({ t }: { t: TFunction }) => [
  {
    key: 'nameDE',
    title: `${FlagEmojis.de}  ${t('systemConfig.columns.name')}`,
    render: (value: TourTopic) => value.name.de,
    width: '40%',
  },
  {
    key: 'nameEN',
    title: `${FlagEmojis.en}  ${t('systemConfig.columns.name')}`,
    render: (value: TourTopic) => value.name.en,
    width: '40%',
  },
  {
    key: 'code',
    title: t('systemConfig.columns.abbreviation'),
    render: (value: TourTopic) => value.code,
  },
];

const TourTopics: TourTopicsProps = ({ data, loading }) => {
  const { t } = useTranslation();
  const tourTopicColumns = useMemo(() => columns({ t }), [t]);

  return (
    <Layout title="systemConfig.tourTopics">
      <Table className="table-header-border border" columns={tourTopicColumns} dataSource={data} loading={loading} />
    </Layout>
  );
};

export default TourTopics;
