import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import LoginForm from 'components/auth/LoginForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useLogin from 'hooks/auth/useLogin';
import { toast } from 'react-toastify';
import PageTitle from 'components/common/PageTitle';
import logoSrc from 'assets/icons/logo.png';

export interface iLoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const onSubmitLogin = useLogin();
  const initialValues: iLoginFormValues = { email: '', password: '', rememberMe: true };

  const onSubmit = useCallback(
    async (values: iLoginFormValues) => {
      try {
        await onSubmitLogin({ variables: values });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error.message as string);
      }
    },
    [onSubmitLogin],
  );

  return (
    <Row className="h-100">
      <Col md={6} lg={5} xxl={4} className="m-auto">
        <div className="bg-white p-4">
          <PageTitle
            logo={logoSrc}
            subtitle={t('login.subtitle')}
            title={t('login.title')}
            className="justify-content-center"
          />
          <br />
          <LoginForm {...{ initialValues, onSubmit }} />
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;
