import { FieldProps } from 'formik';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Clock } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import FormDescription from '../FormDescription';
import TimePickerBase, { TimePickerBaseProps } from './TimePickerBase';
import c from './TimePicker.module.sass';
import { iRequired, Required } from 'components/common/Required';

type TimePickerProps = FieldProps<Date> &
  TimePickerBaseProps &
  iRequired & {
    label: string;
    placeholder?: string;
    icon?: React.ReactNode;
    description?: string;
  };

const TimePicker: FC<TimePickerProps> = ({ field, form, label, description, placeholder, isRequired, ...props }) => {
  const { name, onBlur, value } = field;
  const { touched, errors } = form;
  const { icon } = props;
  const { t } = useTranslation();
  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const handleChange = React.useCallback<TimePickerBaseProps['onChange']>(
    (date) => {
      form.setFieldValue(name, date);
    },
    [form, name],
  );
  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <div className={c.inputWrapper}>
        <TimePickerBase
          {...props}
          placeholder={placeholder ? t(placeholder) : undefined}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
        />
        <div className={c.icon}>{icon ? icon : <Clock />}</div>
      </div>

      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {typeof error === 'string' ? t(error) : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TimePicker;
