import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { GuideFragmentDoc } from '../../../fragments/user/generated/guideFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidesQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type GuidesQuery = {
  __typename?: 'Query';
  guides: Array<{ __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } }>;
};

export const GuidesDocument = gql`
  query Guides {
    guides {
      ...Guide
    }
  }
  ${GuideFragmentDoc}
`;

/**
 * __useGuidesQuery__
 *
 * To run a query within a React component, call `useGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuidesQuery(baseOptions?: Apollo.QueryHookOptions<GuidesQuery, GuidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GuidesQuery, GuidesQueryVariables>(GuidesDocument, options);
}
export function useGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidesQuery, GuidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GuidesQuery, GuidesQueryVariables>(GuidesDocument, options);
}
export type GuidesQueryHookResult = ReturnType<typeof useGuidesQuery>;
export type GuidesLazyQueryHookResult = ReturnType<typeof useGuidesLazyQuery>;
export type GuidesQueryResult = Apollo.QueryResult<GuidesQuery, GuidesQueryVariables>;
export function refetchGuidesQuery(variables?: GuidesQueryVariables) {
  return { query: GuidesDocument, variables: variables };
}
