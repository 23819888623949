import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { CurrentUserFragmentDoc } from '../../../fragments/user/generated/currentUserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleEmailNotificationsMutationVariables = SchemaTypes.Exact<{
  emailNotificationsEnabled: SchemaTypes.Scalars['Boolean'];
}>;

export type ToggleEmailNotificationsMutation = {
  __typename?: 'Mutation';
  toggleEmailNotifications: {
    __typename?: 'User';
    id: string;
    role: SchemaTypes.Roles;
    emailNotificationsEnabled: boolean;
    createdAt: string | Date;
    email: string;
    profile: { __typename?: 'UserProfile'; fullName: string };
  };
};

export const ToggleEmailNotificationsDocument = gql`
  mutation ToggleEmailNotifications($emailNotificationsEnabled: Boolean!) {
    toggleEmailNotifications(emailNotificationsEnabled: $emailNotificationsEnabled) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type ToggleEmailNotificationsMutationFn = Apollo.MutationFunction<
  ToggleEmailNotificationsMutation,
  ToggleEmailNotificationsMutationVariables
>;

/**
 * __useToggleEmailNotificationsMutation__
 *
 * To run a mutation, you first call `useToggleEmailNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEmailNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEmailNotificationsMutation, { data, loading, error }] = useToggleEmailNotificationsMutation({
 *   variables: {
 *      emailNotificationsEnabled: // value for 'emailNotificationsEnabled'
 *   },
 * });
 */
export function useToggleEmailNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleEmailNotificationsMutation, ToggleEmailNotificationsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleEmailNotificationsMutation, ToggleEmailNotificationsMutationVariables>(
    ToggleEmailNotificationsDocument,
    options,
  );
}
export type ToggleEmailNotificationsMutationHookResult = ReturnType<typeof useToggleEmailNotificationsMutation>;
export type ToggleEmailNotificationsMutationResult = Apollo.MutationResult<ToggleEmailNotificationsMutation>;
export type ToggleEmailNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ToggleEmailNotificationsMutation,
  ToggleEmailNotificationsMutationVariables
>;
