import React, { useCallback, useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import useUser from 'hooks/user/useUser';
import { Clock, EnvelopeFill, ShieldLockFill } from 'react-bootstrap-icons';
import { formatDate } from 'utils/helpers';
import avatarSrc from 'assets/icons/avatar.svg';
import ChangeLocale, { ChangeLocaleComponentProps, Locale } from 'components/common/ChangeLocale';
import Select from 'react-select';
import ChangePassword from './ChangePassword';
import CardPlaceholder from 'components/common/Placeholder/CardPlaceholder';
import { FULL_DATE_FORMAT } from 'constants/date';
import { selectStyles } from 'components/inputs/Select/Select';
import EmailNotificationsToggle from './EmailNotificationsToggle';

export const FlagEmojis: Record<Locale, string> = {
  en: '🇬🇧',
  de: '🇩🇪',
};

const ChangeLanguageDropdown: FC<ChangeLocaleComponentProps> = ({ locales, onChangeLanguage }) => {
  const { i18n, t } = useTranslation();
  const localeOptions = useMemo(
    () =>
      locales.map((locale) => ({
        value: locale,
        label: `${FlagEmojis[locale]} ${t(`locale.${locale}`)}`,
      })),
    [locales, t],
  );
  const onInputChange = useCallback(
    ({ value }) => {
      onChangeLanguage(value as Locale);
    },
    [onChangeLanguage],
  );
  const optionValue = useMemo(() => {
    const option = localeOptions.find((o) => o.value === i18n.language);
    return option || null;
  }, [localeOptions, i18n.language]);

  return (
    <>
      <label>{t('userProfile.changeLocaleSelectLabel')}</label>
      <Select
        options={localeOptions}
        styles={selectStyles}
        value={optionValue}
        isSearchable={false}
        isClearable={false}
        onChange={onInputChange}
        components={{ IndicatorSeparator: () => null }}
      />
    </>
  );
};

const UserProfileCard = () => {
  const { me } = useUser();
  const { t } = useTranslation();

  if (!me) return <CardPlaceholder />;
  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex align-items-center gap-2">
          <img src={avatarSrc} />
          {me.profile.fullName}
        </Card.Title>
        <Card.Text className="text-muted d-flex align-items-center gap-2 mb-1 font-weight-normal">
          <ShieldLockFill /> {t(`role.${me.role}`)}
        </Card.Text>
        <Card.Text className="d-flex align-items-center gap-2 mb-1">
          <EnvelopeFill /> {me.email}
        </Card.Text>
        <ChangePassword />
        <EmailNotificationsToggle />
      </Card.Body>
      <Card.Footer className="text-muted d-flex align-items-center gap-2">
        <Clock />
        {t('userProfile.registered')} {formatDate(me.createdAt, FULL_DATE_FORMAT)}
      </Card.Footer>
    </Card>
  );
};

const UserProfilePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t('userProfile.title')} />
      <Row>
        <Col xs={12} sm={6}>
          <UserProfileCard />
          <br />
          <ChangeLocale render={ChangeLanguageDropdown} />
        </Col>
      </Row>
    </>
  );
};

export default UserProfilePage;
