import React, { ChangeEvent, Fragment, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { iRequired, Required } from 'components/common/Required';

interface iRadioOption {
  value: string | number;
  label: string;
}

export interface iRadioOptionRender extends iRadioOption {
  value: string | number;
  label: string;
  formikValue: string | number;
  name: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  buttonClassName?: string;
}

type iRadiosInputContainerProps = {
  children: React.ReactNode;
};

export interface iRadiosInputProps extends iRequired {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  options: iRadioOption[];
  container?: FC<iRadiosInputContainerProps>;
  renderOption?: FC<iRadioOptionRender>;
}

const CheckOption: FC<iRadioOptionRender> = ({ formikValue, label, name, value, handleChange }) => (
  <Form.Check key={label}>
    <Form.Check.Input
      id={`${name}-${value}`}
      name={name}
      type="radio"
      value={value}
      onChange={handleChange}
      checked={formikValue === value}
    />
    <Form.Check.Label htmlFor={`${name}-${value}`}>{label}</Form.Check.Label>
  </Form.Check>
);

const RadioGroup: FC<iRadiosInputProps> = ({
  field,
  form,
  label,
  options = [],
  container,
  renderOption,
  isRequired,
}) => {
  const { name, value, onBlur } = field;
  const { touched, errors } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.target.value);
      onBlur(name);
    },
    [form, name, onBlur],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);
  const { Option, Container } = useMemo(
    () => ({
      Option: renderOption || CheckOption,
      Container: container || Fragment,
    }),
    [container, renderOption],
  );
  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <Container>
        {options.map((props) => (
          <Option key={props.value} formikValue={value} name={name} handleChange={handleChange} {...props} />
        ))}
      </Container>

      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default RadioGroup;
