import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Save } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const Controls = () => {
  const { t } = useTranslation();
  const { isSubmitting, errors, setFieldValue } = useFormikContext();
  const disabled = useMemo(() => isSubmitting || !isEmpty(errors), [errors, isSubmitting]);
  return (
    <div className="d-flex justify-content-end">
      <ButtonGroup aria-label="Basic example">
        <Button
          variant="outline-primary"
          disabled={isSubmitting}
          type="submit"
          onClick={() => setFieldValue('isReset', true, false)}
        >
          {t('systemConfig.revert')}
        </Button>
        <Button disabled={disabled} type="submit">
          {t('systemConfig.save')} <Save />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Controls;
