import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { EmailTemplatesSendEmailFragmentDoc } from '../../../fragments/emailTemplate/generated/emailTemplatesSendEmailFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailTemplatesSendEmailQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int'];
  skip: SchemaTypes.Scalars['Int'];
}>;

export type EmailTemplatesSendEmailQuery = {
  __typename?: 'Query';
  emailTemplates: {
    __typename?: 'PaginatedEmailTemplateResponse';
    items: Array<{
      __typename?: 'EmailTemplate';
      id: string;
      name: string;
      language: SchemaTypes.TourLanguage;
      subject: string;
      body: string;
    }>;
  };
};

export const EmailTemplatesSendEmailDocument = gql`
  query EmailTemplatesSendEmail($limit: Int!, $skip: Int!) {
    emailTemplates(limit: $limit, skip: $skip) {
      items {
        ...EmailTemplatesSendEmail
      }
    }
  }
  ${EmailTemplatesSendEmailFragmentDoc}
`;

/**
 * __useEmailTemplatesSendEmailQuery__
 *
 * To run a query within a React component, call `useEmailTemplatesSendEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplatesSendEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplatesSendEmailQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEmailTemplatesSendEmailQuery(
  baseOptions: Apollo.QueryHookOptions<EmailTemplatesSendEmailQuery, EmailTemplatesSendEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailTemplatesSendEmailQuery, EmailTemplatesSendEmailQueryVariables>(
    EmailTemplatesSendEmailDocument,
    options,
  );
}
export function useEmailTemplatesSendEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailTemplatesSendEmailQuery, EmailTemplatesSendEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailTemplatesSendEmailQuery, EmailTemplatesSendEmailQueryVariables>(
    EmailTemplatesSendEmailDocument,
    options,
  );
}
export type EmailTemplatesSendEmailQueryHookResult = ReturnType<typeof useEmailTemplatesSendEmailQuery>;
export type EmailTemplatesSendEmailLazyQueryHookResult = ReturnType<typeof useEmailTemplatesSendEmailLazyQuery>;
export type EmailTemplatesSendEmailQueryResult = Apollo.QueryResult<
  EmailTemplatesSendEmailQuery,
  EmailTemplatesSendEmailQueryVariables
>;
export function refetchEmailTemplatesSendEmailQuery(variables: EmailTemplatesSendEmailQueryVariables) {
  return { query: EmailTemplatesSendEmailDocument, variables: variables };
}
