import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmailTemplateMutationVariables = SchemaTypes.Exact<{
  modifier: SchemaTypes.EmailTemplateInput;
}>;

export type CreateEmailTemplateMutation = { __typename?: 'Mutation'; createEmailTemplate: boolean };

export const CreateEmailTemplateDocument = gql`
  mutation CreateEmailTemplate($modifier: EmailTemplateInput!) {
    createEmailTemplate(modifier: $modifier)
  }
`;
export type CreateEmailTemplateMutationFn = Apollo.MutationFunction<
  CreateEmailTemplateMutation,
  CreateEmailTemplateMutationVariables
>;

/**
 * __useCreateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useCreateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailTemplateMutation, { data, loading, error }] = useCreateEmailTemplateMutation({
 *   variables: {
 *      modifier: // value for 'modifier'
 *   },
 * });
 */
export function useCreateEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>(
    CreateEmailTemplateDocument,
    options,
  );
}
export type CreateEmailTemplateMutationHookResult = ReturnType<typeof useCreateEmailTemplateMutation>;
export type CreateEmailTemplateMutationResult = Apollo.MutationResult<CreateEmailTemplateMutation>;
export type CreateEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailTemplateMutation,
  CreateEmailTemplateMutationVariables
>;
