import classNames from 'classnames';
import React from 'react';
import { Pencil } from 'react-bootstrap-icons';
import c from './EditableCell.module.sass';
import Loading from './loading/Loading';

type EditableCellProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: () => any;
  value?: string | boolean | null;
  displayValue?: string | null | JSX.Element;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  valueWrapperClassName?: string;
};
const EditableCell: FC<EditableCellProps> = ({
  value,
  displayValue,
  onClick,
  disabled,
  loading,
  className,
  valueWrapperClassName,
}) => {
  return (
    <div className={classNames(c.cell, className)} onClick={disabled ? undefined : onClick}>
      {value ? <span className={classNames('text-break', valueWrapperClassName)}>{displayValue || value}</span> : null}
      <div className={classNames(c.button, 'text-primary')}>{!loading ? <Pencil size={14} /> : <Loading />}</div>
    </div>
  );
};

export default EditableCell;
