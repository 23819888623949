import { blockedDateModal } from 'components/app/blockedDate';
import deleteBlockedDateModal from 'components/app/blockedDate/deleteBlockedDateModal';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import Table from 'components/common/Table';
import { DATE_FORMAT } from 'constants/date';
import { BlockedDateConfigFragment } from 'graphql/fragments/blockedDates/generated/blockedDateConfigFragment';
import { useBlockedDatesConfigQuery } from 'graphql/queries/blockedDate/generated/BlockedDatesConfig';
import React, { useMemo } from 'react';
import { Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { TFunction, useTranslation } from 'react-i18next';
import { formatDate } from 'utils/helpers';
import Layout from '../../components/Layout';

const columns = ({ t }: { t: TFunction }) => [
  {
    key: 'startDate',
    title: t('systemConfig.columns.startDate'),
    render: (value: BlockedDateConfigFragment) => formatDate(value.startDate, DATE_FORMAT),
    width: '25%',
  },
  {
    key: 'endDate',
    title: t('systemConfig.columns.endDate'),
    render: (value: BlockedDateConfigFragment) => formatDate(value.endDate, DATE_FORMAT),
    width: '25%',
  },
  {
    key: 'disabledCodes',
    title: t('systemConfig.columns.disabledCodes'),
    render: (value: BlockedDateConfigFragment) => {
      if (value.isAllCodes) return t('systemConfig.all');
      if (!value.disabledCodes) return '';
      return value.disabledCodes
        .map((disabledCode) => [disabledCode.tourTopic.code, disabledCode.targetGroup.code].join(''))
        .join(', ');
    },
    width: '25%',
  },
  {
    key: 'place',
    title: t('systemConfig.columns.blockedBy'),
    render: (value: BlockedDateConfigFragment) => value.blockedBy.profile.fullName,
    width: '25%',
  },
  {
    key: 'actions',
    title: '',
    render: (value: BlockedDateConfigFragment) => (
      <div className="d-flex justify-content-end gap-3 text-muted">
        <Pencil className="cursor-pointer" onClick={() => blockedDateModal(value)} />
        <Trash className="cursor-pointer" onClick={() => deleteBlockedDateModal(value.id)} />
      </div>
    ),
  },
];

const BlockDates = () => {
  const { t } = useTranslation();
  const blockedDateColumns = useMemo(() => columns({ t }), [t]);
  const { data, loading, error } = useBlockedDatesConfigQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Layout
      title={
        <>
          {t('systemConfig.blockDates')}{' '}
          <Plus className="text-primary cursor-pointer" onClick={() => blockedDateModal()} />
        </>
      }
    >
      <Table
        className="table-header-border border"
        columns={blockedDateColumns}
        dataSource={data?.blockedDates}
        loading={loading}
      />
    </Layout>
  );
};

export default BlockDates;
