import React from 'react';
import { Editor, Element as SlateElement } from 'slate';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import { useMentionContext } from '../context';
import s from './withMentions.module.sass';
import cn from 'classnames';
import { MentionElement } from '../RichEditor.type';
import { getStyles } from '../utils';
import { BLOCK_ELEMENT } from '../constants';

export const isMentionActive = (editor: Editor) => {
  const [mention] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === BLOCK_ELEMENT.MENTION,
  });
  return mention;
};

export const withMentions = (editor: Editor) => {
  const { isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => {
    return element.type === BLOCK_ELEMENT.MENTION ? true : isVoid(element);
  };

  return editor;
};

export const Mention: FC<RenderElementProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const mentionContext = useMentionContext();
  if (!mentionContext) {
    console.error('no mention context');
    return null;
  }
  const style = getStyles(element as MentionElement);

  const { renderMention } = mentionContext;
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={style}
      className={cn(s.mention, selected && focused ? s.activeMention : '')}
    >
      {renderMention({ element })}
      {children}
    </span>
  );
};
