import React from 'react';
import i18n from 'i18n';
import * as yup from 'yup';
import { merge } from 'lodash';
import { InputModalConfirm } from 'components/common/Modal/InputModal';
import { GuideFeedback, GuideFeedbackInput } from 'graphql/types.generated';
import client from 'graphql/apollo';
import { toast } from 'react-toastify';
import GuideFeedbackForm from './GuideFeedbackForm';
import { totalParticipantsSchema } from 'components/bookingRequest/PricingLevelForm';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import {
  ProvideGuideFeedbackDocument,
  ProvideGuideFeedbackMutation,
  ProvideGuideFeedbackMutationVariables,
} from 'graphql/mutations/appointment/generated/ProvideGuideFeedback';

const guideFeedbackValidationSchema = () => yup.object().shape(totalParticipantsSchema());

type DefaultInitialValues = {
  comment: string;
  totalParticipants?: number;
  totalAccompanyingPersons?: number;
};
const defaultInitialValues: DefaultInitialValues = {
  comment: '',
  totalParticipants: undefined,
  totalAccompanyingPersons: undefined,
};
const guideFeedbackModal = async ({
  appointmentId,
  guideFeedback,
  refetchQueries,
}: {
  appointmentId: string;
  guideFeedback?: Pick<GuideFeedback, 'comment' | 'totalParticipants' | 'totalAccompanyingPersons'>;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) => {
  InputModalConfirm({
    validationSchema: () => guideFeedbackValidationSchema(),
    headerText: i18n.t('appointments.provideGuideFeedbackModal'),
    initialValues: merge({}, defaultInitialValues, {
      comment: guideFeedback?.comment,
      totalParticipants: guideFeedback?.totalParticipants,
      totalAccompanyingPersons: guideFeedback?.totalAccompanyingPersons,
    }),
    onSubmit: async (value) => {
      await client.mutate<ProvideGuideFeedbackMutation, ProvideGuideFeedbackMutationVariables>({
        mutation: ProvideGuideFeedbackDocument,
        variables: { appointmentId, data: value as GuideFeedbackInput },
        refetchQueries,
      });
      toast.success(i18n.t('appointments.provideGuideFeedbackSuccess'));
    },
    formikContent: () => <GuideFeedbackForm />,
  });
};

export default guideFeedbackModal;
