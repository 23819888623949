import { MutableRefObject, useMemo, useRef, useState } from 'react';
import constants from 'constants/constants';
import Apollo from '@apollo/client';

const usePagination = <T, V extends object>({
  fetchPolicy = 'cache-and-network',
  pageSize = constants.countTableRowsPerPage,
  params = {} as Partial<V>,
  query,
}: {
  fetchPolicy?: Apollo.WatchQueryFetchPolicy;
  pageSize?: number;
  params?: Partial<V>;
  query: (baseOptions: Apollo.QueryHookOptions<T, V>) => Apollo.QueryResult<T, V>;
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const valuesRef: MutableRefObject<T | null> = useRef(null);
  const variables = useMemo<V>(
    () =>
      ({
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
        ...params,
      } as V),
    [currentPage, pageSize, params],
  );
  const { data, loading, error } = query({
    fetchPolicy,
    variables,
  });

  const newData = useMemo(() => {
    if (data) valuesRef.current = data;
    return valuesRef.current;
  }, [data]);

  return { data: newData, variables, loading, error, setCurrentPage, currentPage };
};

export default usePagination;
