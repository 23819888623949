import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RenderBookingRequestEmailTemplateQueryVariables = SchemaTypes.Exact<{
  bookingRequestId: SchemaTypes.Scalars['ID'];
  emailTemplateId: SchemaTypes.Scalars['ID'];
}>;

export type RenderBookingRequestEmailTemplateQuery = {
  __typename?: 'Query';
  renderBookingRequestEmailTemplate: string;
};

export const RenderBookingRequestEmailTemplateDocument = gql`
  query RenderBookingRequestEmailTemplate($bookingRequestId: ID!, $emailTemplateId: ID!) {
    renderBookingRequestEmailTemplate(bookingRequestId: $bookingRequestId, emailTemplateId: $emailTemplateId)
  }
`;

/**
 * __useRenderBookingRequestEmailTemplateQuery__
 *
 * To run a query within a React component, call `useRenderBookingRequestEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderBookingRequestEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderBookingRequestEmailTemplateQuery({
 *   variables: {
 *      bookingRequestId: // value for 'bookingRequestId'
 *      emailTemplateId: // value for 'emailTemplateId'
 *   },
 * });
 */
export function useRenderBookingRequestEmailTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    RenderBookingRequestEmailTemplateQuery,
    RenderBookingRequestEmailTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RenderBookingRequestEmailTemplateQuery, RenderBookingRequestEmailTemplateQueryVariables>(
    RenderBookingRequestEmailTemplateDocument,
    options,
  );
}
export function useRenderBookingRequestEmailTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RenderBookingRequestEmailTemplateQuery,
    RenderBookingRequestEmailTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RenderBookingRequestEmailTemplateQuery, RenderBookingRequestEmailTemplateQueryVariables>(
    RenderBookingRequestEmailTemplateDocument,
    options,
  );
}
export type RenderBookingRequestEmailTemplateQueryHookResult = ReturnType<
  typeof useRenderBookingRequestEmailTemplateQuery
>;
export type RenderBookingRequestEmailTemplateLazyQueryHookResult = ReturnType<
  typeof useRenderBookingRequestEmailTemplateLazyQuery
>;
export type RenderBookingRequestEmailTemplateQueryResult = Apollo.QueryResult<
  RenderBookingRequestEmailTemplateQuery,
  RenderBookingRequestEmailTemplateQueryVariables
>;
export function refetchRenderBookingRequestEmailTemplateQuery(
  variables: RenderBookingRequestEmailTemplateQueryVariables,
) {
  return { query: RenderBookingRequestEmailTemplateDocument, variables: variables };
}
