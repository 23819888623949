import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup, { RadioButtonGroupProps } from 'components/bookingRequest/AppointmentForm/RadioButtonGroup';
import s from './TargetGroupForm.module.sass';
import cn from 'classnames';
import * as yup from 'yup';
import i18n from 'i18n';
import { RadioButtonPlaceholder } from 'components/bookingRequest/placeholders';
import { useTargetGroupsForBookingRequestFormQuery } from 'graphql/queries/targetGroup/generated/TargetGroupsForBookingRequestForm';
import AlertGraphQLError from 'components/common/AlertGraphQLError';

export const schema = () => ({
  targetGroupId: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.targetGroupId.name')),
});

const TargetGroupSelect = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useTargetGroupsForBookingRequestFormQuery();

  const targetGroupOptions = useMemo(() => {
    if (!data) return [];
    return data.targetGroups
      .map(({ id, name, bookingFormConfig }) => {
        if (!bookingFormConfig) return null;
        const { description, iconUrl } = bookingFormConfig;
        return {
          value: id,
          label: (
            <div className="d-flex flex-column align-items-center gap-2">
              <img src={iconUrl} className="icon-4" />
              <span className="font-weight-normal">{name[i18n.language as keyof typeof name]}</span>
              {description ? (
                <span className={cn(s.description, 'text-muted font-weight-normal')}>
                  {description[i18n.language as keyof typeof description]}
                </span>
              ) : null}
            </div>
          ) as unknown as string,
        };
      })
      .filter(Boolean) as RadioButtonGroupProps['options'];
  }, [data, i18n.language]);

  if (error) return <AlertGraphQLError error={error} />;
  if (!data && loading) return <RadioButtonPlaceholder isDescription />;
  return (
    <RadioButtonGroup
      isRequired
      label="bookingRequest.fields.targetGroupId.label"
      name="targetGroupId"
      buttonClassName={s.button}
      options={targetGroupOptions}
    />
  );
};

const TargetGroupForm = () => <TargetGroupSelect />;

export default TargetGroupForm;
