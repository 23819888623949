import React from 'react';

interface iLoadingProps {
  color?: string;
  fontSize?: string;
  position?: 'start' | 'center';
  size?: number;
  style?: React.CSSProperties;
}

const Loading = ({ color, fontSize = '1.3em', position = 'start', size, style }: iLoadingProps) => {
  return (
    <div className={`d-flex justify-content-${position}`} style={style}>
      <div className="spinner-border spinner-border-sm" style={{ fontSize, color, width: size, height: size }} />
    </div>
  );
};

export default Loading;
