import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomContainerProps, InputModalConfirm } from 'components/common/Modal/InputModal';
import { merge, omit } from 'lodash';
import * as yup from 'yup';
import { screenSizeConfig } from 'components/common/Visibility';
import { ApolloError, MutationOptions } from '@apollo/client';
import { FormConfigs, useTargetGroupFormConfigs, useFormConfig } from 'components/bookingRequest/utils';
import { CustomerForm, customerSchema } from 'components/app/bookingRequest';
import client from 'graphql/apollo';
import { UpdateCustomerDocument } from 'graphql/mutations/customer/generated/UpdateCustomer';
import { produceCustomerInput } from './utils';
import { useCustomerForUpdatingQuery } from 'graphql/queries/customer/generated/CustomerForUpdating';
import { toast } from 'react-toastify';
import {
  defaultUpdateCustomerInitialValues,
  DefaultUpdateCustomerInitialValues,
} from 'components/app/bookingRequest/CustomerForm';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import Loading from 'components/common/loading/Loading';

export const CustomerFormikContent = ({ formConfigs }: { formConfigs: FormConfigs }) => {
  const formConfig = useFormConfig(formConfigs);
  return <CustomerForm formConfig={formConfig} />;
};

const editCustomerValidationSchema = ({ formConfigs }: { formConfigs: FormConfigs }) => {
  return yup.lazy((values: DefaultUpdateCustomerInitialValues) => {
    const formConfig = formConfigs[`${values.targetGroupId}`];
    return yup.object().shape({
      ...customerSchema({ formConfig }),
    });
  });
};

const CustomContainer: (props: {
  customerId: string;
  refetchQueries?: MutationOptions['refetchQueries'];
}) => CustomContainerProps<DefaultUpdateCustomerInitialValues> = (props) =>
  function CustomerContainer({ Component, Modal }) {
    const { t } = useTranslation();
    const { customerId, refetchQueries } = props;
    const {
      formConfigs,
      targetGroups,
      loading: targetGroupLoading,
      error: targetGroupError,
    } = useTargetGroupFormConfigs();
    const {
      data,
      loading: customerLoading,
      error: customerError,
    } = useCustomerForUpdatingQuery({
      variables: { id: customerId },
    });
    const modalProps = useMemo(
      () => ({
        headerText: t('bookingRequestView.updateCustomerModal.title'),
        width: screenSizeConfig.xl,
      }),
      [t],
    );
    if (targetGroupError || customerError || targetGroupLoading || customerLoading || !data || !targetGroups)
      return (
        <Modal {...modalProps}>
          {[
            (targetGroupError || customerError) && (
              <AlertGraphQLError error={(targetGroupError || customerError) as ApolloError} />
            ),
            (targetGroupLoading || customerLoading) && <Loading position="center" size={60} />,
            !data && !customerLoading && <span>{t('errors.customerNotFound')}</span>,
            !targetGroups && !targetGroupLoading && <span>{t('errors.targetGroupsTypeNotFound')}</span>,
          ].filter(Boolean)}
        </Modal>
      );
    return (
      <Component
        {...modalProps}
        initialValues={merge(
          {},
          defaultUpdateCustomerInitialValues,
          omit(
            {
              ...data.customer,
              ...data.customer.organization,
              targetGroupId: data.customer.organization.targetGroup.id,
            },
            ['__typename', 'organization', 'targetGroup'],
          ),
        )}
        formikContent={() => <CustomerFormikContent formConfigs={formConfigs} />}
        onSubmit={async (values: DefaultUpdateCustomerInitialValues) => {
          await client.mutate({
            mutation: UpdateCustomerDocument,
            variables: {
              id: customerId,
              data: produceCustomerInput(
                values,
                targetGroups.find((targetGroup) => targetGroup.id === values.targetGroupId)?.bookingFormConfig
                  ?.fieldConfig,
              ),
            },
            refetchQueries,
          });
          toast.success(t('bookingRequestView.updateCustomerSuccess'));
        }}
        validationSchema={() => editCustomerValidationSchema({ formConfigs })}
      />
    );
  };

const updateCustomerModal = async (props: {
  customerId: string;
  refetchQueries?: MutationOptions['refetchQueries'];
}) => {
  InputModalConfirm({
    CustomContainer: CustomContainer(props),
  });
};

export default updateCustomerModal;
