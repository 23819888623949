import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TotalBookingRequestsQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TotalBookingRequestsQuery = { __typename?: 'Query'; totalBookingRequests: number };

export const TotalBookingRequestsDocument = gql`
  query TotalBookingRequests {
    totalBookingRequests
  }
`;

/**
 * __useTotalBookingRequestsQuery__
 *
 * To run a query within a React component, call `useTotalBookingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalBookingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalBookingRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalBookingRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<TotalBookingRequestsQuery, TotalBookingRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalBookingRequestsQuery, TotalBookingRequestsQueryVariables>(
    TotalBookingRequestsDocument,
    options,
  );
}
export function useTotalBookingRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalBookingRequestsQuery, TotalBookingRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalBookingRequestsQuery, TotalBookingRequestsQueryVariables>(
    TotalBookingRequestsDocument,
    options,
  );
}
export type TotalBookingRequestsQueryHookResult = ReturnType<typeof useTotalBookingRequestsQuery>;
export type TotalBookingRequestsLazyQueryHookResult = ReturnType<typeof useTotalBookingRequestsLazyQuery>;
export type TotalBookingRequestsQueryResult = Apollo.QueryResult<
  TotalBookingRequestsQuery,
  TotalBookingRequestsQueryVariables
>;
export function refetchTotalBookingRequestsQuery(variables?: TotalBookingRequestsQueryVariables) {
  return { query: TotalBookingRequestsDocument, variables: variables };
}
