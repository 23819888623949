import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TargetGroupForSelectFragment = {
  __typename?: 'TargetGroup';
  id: string;
  name: { __typename?: 'Localized'; en: string; de: string };
};

export type TargetGroupForSelectFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TargetGroupForSelectFragmentDoc = gql`
  fragment TargetGroupForSelect on TargetGroup {
    id
    name {
      ...Localized
    }
  }
  ${LocalizedFragmentDoc}
`;
