import React from 'react';
import s from './placeholders.module.sass';
import cn from 'classnames';
import { Button, Placeholder } from 'react-bootstrap';

export const RadioButtonPlaceholder = ({
  isDescription,
  count = 5,
  titleCount = 1,
  className,
}: {
  count?: number;
  titleCount?: number;
  isDescription?: boolean;
  className?: string;
}) => (
  <div className={cn(className, 'd-flex gap-3 flex-wrap')}>
    {[...new Array(count)].map((e, i) => (
      <Button key={i} variant="light" className={cn(s.button, 'border')}>
        <Placeholder as="h4" animation="glow">
          <Placeholder bg="secondary" className="icon-4" />
        </Placeholder>
        <Placeholder as="h4" animation="glow">
          {[...new Array(titleCount)].map((e, i) => (
            <Placeholder key={i} bg="secondary" xs={12} />
          ))}
        </Placeholder>
        {isDescription ? (
          <Placeholder animation="glow">
            <Placeholder bg="secondary" xs={12} />
            <Placeholder bg="secondary" xs={12} />
          </Placeholder>
        ) : null}
      </Button>
    ))}
  </div>
);
