import React, { TdHTMLAttributes } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { formatDate, formatPhoneNumber } from 'utils/helpers';
import { ColumnCellProps } from 'components/common/Table/Table';
import AppointmentTableActions from 'components/app/appointment/components/AppointmentTableActions';
import { FULL_DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import AppointmentStatus from 'components/appointments/AppointmentStatus';
import { AppointmentType, AppointmentStatus as AppointmentStatusEnum } from 'graphql/types.generated';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import { generatePath, Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { AppointmentTable } from 'pages/app/AppointmentsPage/utils';
import updateCustomerModal from '../bookingRequestAndAppointment/modals/updateCustomerModal';
import { AppointmentFragment } from 'graphql/fragments/appointments/generated/appointmentFragment';
import { BookingRequestViewDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestView';
import appointmentInfoForOfficeModal from '../bookingRequestAndAppointment/modals/appointmentInfoForOfficeModal';
import EditableCell from 'components/common/EditableCell';
import { guideFeedbackModal } from '../guideFeedback';
import appointmentGuideModal from '../bookingRequestAndAppointment/modals/appointmentGuideModal';
import AppointmentInfoForOffice from 'components/appointments/AppointmentInfoForOffice';
import BookingkitCell from './components/BookingkitCell';

const CustomerCell = ({
  value: { customer, bookingRequest },
  refetchQueries,
}: {
  value: AppointmentFragment;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) => {
  const { t } = useTranslation();
  if (!customer) return null;
  return (
    <small>
      <EditableCell
        onClick={() => {
          updateCustomerModal({
            customerId: customer.id,
            refetchQueries: [
              ...(bookingRequest ? [{ query: BookingRequestViewDocument, variables: { id: bookingRequest.id } }] : []),
              ...(refetchQueries ?? []),
            ],
          });
        }}
        value={customer.id}
        valueWrapperClassName="flex-1"
        displayValue={
          <div style={{ width: '100%' }}>
            {customer.organization.name}
            <div className="text-nowrap">
              {[t(`salutations.${customer.salutation}`), customer.firstName, customer.lastName].join(' ')}
            </div>
            {customer.phoneNumber ? <div className="text-nowrap">{formatPhoneNumber(customer.phoneNumber)}</div> : null}
            {customer.email}
          </div>
        }
      />
    </small>
  );
};

const GuideFeedback = ({ guideFeedback }: { guideFeedback: AppointmentFragment['guideFeedback'] }) => {
  const { t } = useTranslation();

  return guideFeedback ? (
    <>
      <strong>{t('appointments.fields.guideFeedback.label')}</strong>
      <br />
      {formatDate(guideFeedback.createdAt)}
      <br />
      {[guideFeedback.totalParticipants, guideFeedback.totalAccompanyingPersons].filter(Boolean).join(' + ')}
      <br />
      {guideFeedback.comment}
    </>
  ) : null;
};

const GuideFeedbackCell = ({
  value: { id, status, guideFeedback },
  refetchQueries,
}: {
  value: AppointmentFragment;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) => {
  if (
    [AppointmentStatusEnum.SCHEDULED, AppointmentStatusEnum.AWAITS_FEEDBACK, AppointmentStatusEnum.COMPLETED].includes(
      status,
    )
  )
    return (
      <small>
        <EditableCell
          onClick={async () => {
            guideFeedbackModal({
              appointmentId: id,
              guideFeedback: guideFeedback ?? undefined,
              refetchQueries,
            });
          }}
          value={!!guideFeedback}
          displayValue={<GuideFeedback guideFeedback={guideFeedback} />}
        />
      </small>
    );
  return <GuideFeedback guideFeedback={guideFeedback} />;
};

const appointmentColumns = ({
  t,
  refetchQueries,
}: {
  t: TFunction;
  refetchQueries: InternalRefetchQueryDescriptor[];
}) => [
  {
    key: 'date',
    title: t('appointments.columns.date'),
    render: (value: AppointmentTable) =>
      value instanceof Date || (
        <span className="text-nowrap">{formatDate(new Date(value.startDate), FULL_DATE_FORMAT)}</span>
      ),
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
  },
  {
    key: 'time',
    title: t('appointments.columns.time'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <span className="text-nowrap">
          {[value.startDate, value.endDate].map((date) => formatDate(date, TIME_FORMAT)).join(' - ')}
        </span>
      ),
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    width: '100px',
  },
  {
    key: 'contact',
    title: t('appointments.columns.contact'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && <CustomerCell value={value} refetchQueries={refetchQueries} />,
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    cellProps: () => ({ className: 'text-start' }),
    width: '300px',
  },
  {
    key: 'code',
    title: t('appointments.columns.code'),
    render: (value: AppointmentTable) => !(value instanceof Date) && <div className="fw-bold">{value.code}</div>,
    cellProps: ({ value }: ColumnCellProps<AppointmentTable>) =>
      !(value instanceof Date) && value.tourTopic
        ? { style: { backgroundColor: value.tourTopic.backgroundColor } }
        : undefined,
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    width: '50px',
  },
  {
    key: 'totalParticipants',
    title: t('bookingRequestView.columns.totalParticipants'),
    render: (value: AppointmentTable) => {
      if (value instanceof Date) return null;
      if (value.type === AppointmentType.BOOKING_SLOT) {
        return `${value.totalSlots}`;
      }
      if (value.type === AppointmentType.PRIVATE_TOUR) {
        return typeof value.totalAccompanyingPersons === 'number'
          ? `${value.totalParticipants}+${value.totalAccompanyingPersons}`
          : value.totalParticipants;
      }
      return value.totalParticipants;
    },
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    width: '60px',
  },
  {
    key: 'type',
    title: t('appointments.columns.type'),
    render: (value: AppointmentTable) => (
      <small>
        {!(value instanceof Date) && (
          <>
            {[AppointmentType.BOOKING_SLOT, AppointmentType.PRIVATE_TOUR].includes(value.type)
              ? t('appointments.tour')
              : t(`productType.${value.type}`)}
            {value.bookingRequest?.id ? (
              <Link className="d-block" to={generatePath(routePaths.bookingRequest, { id: value.bookingRequest?.id })}>
                {t('appointments.view')}
              </Link>
            ) : null}
          </>
        )}
      </small>
    ),
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    width: '90px',
  },
  {
    key: 'meetingPlace',
    title: <div className="text-nowrap">{t('appointments.columns.meetingPlace')}</div>,
    render: (value: AppointmentTable) => !(value instanceof Date) && value.product?.meetingPointName,
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    width: '130px',
  },
  {
    key: 'status',
    title: t('appointments.columns.status'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <div className="text-center">
          <small>
            <AppointmentStatus status={value.status} />
          </small>
        </div>
      ),
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    width: '100px',
  },
  {
    key: 'infoForOffice',
    title: t('appointments.columns.infoForOffice'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <small>
          <EditableCell
            value={value.infoForOffice}
            displayValue={<AppointmentInfoForOffice infoForOffice={value.infoForOffice} />}
            onClick={() => {
              appointmentInfoForOfficeModal({ id: value.id, infoForOffice: value.infoForOffice });
            }}
          />
        </small>
      ),
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    cellProps: ({ value }: ColumnCellProps<AppointmentTable>) => {
      if (value instanceof Date) return undefined;
      // setting height 1 to make the cell content fill 100% of the cell height
      const props: TdHTMLAttributes<unknown> = { height: 1 };
      return props;
    },
    width: '120px',
  },
  {
    key: 'guide',
    title: t('appointments.columns.guide'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <EditableCell
          value={value.guide?.profile.fullName}
          onClick={() => {
            appointmentGuideModal({ id: value.id, guideId: value.guide?.id });
          }}
        />
      ),
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    cellProps: ({ value }: ColumnCellProps<AppointmentTable>) => {
      if (value instanceof Date) return undefined;
      // setting height 1 to make the cell content fill 100% of the cell height
      const props: TdHTMLAttributes<unknown> = { height: 1 };
      return props;
    },
    width: '100px',
  },
  {
    key: 'detailedInfoForGuides',
    title: t('appointments.columns.infoForGuide'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <small>
          {value.totalGuides ? (
            <div>
              {value.totalGuides} {t('appointments.guides', { count: value.totalGuides })}
            </div>
          ) : null}
          {value.ageOfParticipants ? (
            <div>
              {value.ageOfParticipants} {t('bookingRequest.fields.ageOfParticipants.addon')}
            </div>
          ) : null}
          {value.infoForGuides}
        </small>
      ),
    width: '200px',
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
  },
  {
    key: 'guideFeedback',
    title: t('appointments.columns.guideFeedback'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && <GuideFeedbackCell value={value} refetchQueries={refetchQueries} />,
    width: '130px',
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    cellProps: ({ value }: ColumnCellProps<AppointmentTable>) => {
      if (value instanceof Date) return undefined;
      // setting height 1 to make the cell content fill 100% of the cell height
      const props: TdHTMLAttributes<unknown> = { height: 1 };
      return props;
    },
  },
  {
    key: 'bookingkit',
    title: t('appointments.columns.bookingkit'),
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <small>
          <BookingkitCell
            bookingRequestId={value.bookingRequest?.id}
            appointmentId={value.id}
            appointmentType={value.type}
            bookingkitConnection={value.bookingkitConnection}
            productBookingkitSyncEnabled={!!value.product?.bookingkitSyncEnabled}
            productName={value.product?.name}
          />
        </small>
      ),
    width: '130px',
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
    cellProps: ({ value }: ColumnCellProps<AppointmentTable>) => {
      if (value instanceof Date) return undefined;
      // setting height 1 to make the cell content fill 100% of the cell height
      const props: TdHTMLAttributes<unknown> = { height: 1 };
      return props;
    },
  },
  {
    key: 'actions',
    title: '',
    render: (value: AppointmentTable) =>
      !(value instanceof Date) && (
        <AppointmentTableActions
          type={value.type}
          status={value.status}
          refetchQueries={refetchQueries}
          appointmentId={value.id}
          bookingkitDateId={value.bookingkitConnection?.dateId || undefined}
        />
      ),
    width: '100px',
    shouldRender: (value: AppointmentTable) => !(value instanceof Date),
  },
];

export default appointmentColumns;
