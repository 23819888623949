import React, { useMemo } from 'react';
import { CustomContainerProps, InputModalConfirm } from 'components/common/Modal/InputModal';
import i18n from 'i18n';
import { merge } from 'lodash';
import Button from 'components/common/Button';
import { Save } from 'react-bootstrap-icons';
import * as yup from 'yup';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import { FormConfigs, useTargetGroupFormConfigs, useFormConfig } from 'components/bookingRequest/utils';
import {
  customerSchema,
  requestSchema,
  CustomerForm,
  BookingRequestForm,
  defaultInitialValues,
  BookingRequestDataTransformer,
} from 'components/app/bookingRequest';
import { screenSizeConfig } from 'components/common/Visibility';
import {
  CreateBookingRequestDocument,
  CreateBookingRequestMutation,
  CreateBookingRequestMutationVariables,
} from 'graphql/mutations/bookingRequest/generated/CreateBookingRequest';
import client from 'graphql/apollo';
import {
  BookingRequestsDocument,
  BookingRequestsQueryVariables,
} from 'graphql/queries/bookingRequest/generated/BookingRequests';
import { toast } from 'react-toastify';
import { TotalBookingRequestsDocument } from 'graphql/queries/bookingRequest/generated/TotalBookingRequests';
import { generatePath, NavigateFunction } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { BookingInitialValuesProps } from 'pages/BookingRequestPage/BookingRequestPage.type';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import Loading from 'components/common/loading/Loading';
import { useTranslation } from 'react-i18next';

export type iAppointmentValidationSchema = {
  formConfigs: FormConfigs;
};

const appointmentValidationSchema = ({ formConfigs }: iAppointmentValidationSchema) =>
  yup.lazy(({ targetGroupId }: { targetGroupId: string }) => {
    const formConfig = formConfigs[targetGroupId];
    return yup.object().shape({
      ...requestSchema({ formConfig }),
      ...customerSchema({ formConfig }),
    });
  });

export interface iAppointmentForm {
  formConfigs: FormConfigs;
}

const AppointmentFormikContent: FC<iAppointmentForm> = ({ formConfigs }) => {
  const formConfig = useFormConfig(formConfigs);
  return (
    <>
      <CustomerForm formConfig={formConfig} />
      <BookingRequestForm formConfig={formConfig} />
    </>
  );
};

type InitialValues = BookingInitialValuesProps & { dateTime?: Date; altDateTime?: Date; skipAutomaticEmail: boolean };

const CustomContainer: (props: {
  variables?: BookingRequestsQueryVariables;
  navigate: NavigateFunction;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) => CustomContainerProps<InitialValues> = (props) =>
  function CreateBookingRequestContainer({ Component, Modal }) {
    const { variables, navigate, refetchQueries } = props;
    const { t } = useTranslation();
    const { formConfigs, targetGroups, loading, error } = useTargetGroupFormConfigs();
    const modalProps = useMemo(
      () => ({
        headerText: t('appointments.createBookingRequestModal'),
        width: screenSizeConfig.xl,
      }),
      [t],
    );
    if (error || loading || !targetGroups)
      return (
        <Modal {...modalProps}>
          {[
            error && <AlertGraphQLError error={error} />,
            loading && <Loading position="center" size={60} />,
            !targetGroups && !loading && <span>{t('errors.targetGroupsTypeNotFound')}</span>,
          ].filter(Boolean)}
        </Modal>
      );
    return (
      <Component
        validationSchema={() => appointmentValidationSchema({ formConfigs })}
        {...modalProps}
        initialValues={merge(
          {
            dateTime: undefined,
            altDateTime: undefined,
            skipAutomaticEmail: false,
          },
          defaultInitialValues,
        )}
        onSubmit={async ({ skipAutomaticEmail, ...values }) => {
          const { data } = await client.mutate<CreateBookingRequestMutation, CreateBookingRequestMutationVariables>({
            mutation: CreateBookingRequestDocument,
            variables: {
              skipAutomaticEmail,
              data: BookingRequestDataTransformer.transformFormValuesToGraphQLParams(
                values,
                targetGroups.find((targetGroup) => targetGroup.id === values.targetGroupId)?.bookingFormConfig
                  ?.fieldConfig,
              ),
            },
            refetchQueries: [
              ...(variables ? [{ query: BookingRequestsDocument, variables }] : []),
              { query: TotalBookingRequestsDocument },
              ...(refetchQueries ?? []),
            ],
          });
          toast.success(i18n.t('appointments.createBookingRequestSuccess'));
          navigate(generatePath(routePaths.bookingRequest, { id: data?.createBookingRequest.id }));
        }}
        formikContent={() => <AppointmentFormikContent formConfigs={formConfigs} />}
        customFooter={({ onClose, disabled }) => (
          <>
            <Button disabled={disabled} onClick={onClose} variant="outline-primary">
              {i18n.t('cancel')}
            </Button>
            <Button endIcon={<Save />} disabled={disabled} type="submit">
              {i18n.t('save')}
            </Button>
          </>
        )}
      />
    );
  };

const createBookingRequestModal = (props: {
  variables?: BookingRequestsQueryVariables;
  navigate: NavigateFunction;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) => {
  InputModalConfirm({
    CustomContainer: CustomContainer(props),
  });
};

export default createBookingRequestModal;
