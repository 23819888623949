import React, { HTMLAttributes, useMemo } from 'react';
import { Table as RTable, TableProps } from 'react-bootstrap';
import s from './Table.module.sass';
import cn from 'classnames';
import LoadingOverlay from '../loading/LoadingOverlay';
import { get } from 'lodash';

export type ColumnCellProps<T> = {
  value: T;
  columnCount: number;
};

type Column<T> = {
  key: string;
  title?: React.ReactNode;
  width?: string;
  render: (value: T) => React.ReactNode;
  cellProps?: (props: ColumnCellProps<T>) => HTMLAttributes<HTMLTableCellElement> | undefined;
  shouldRender?: (value: T) => boolean;
  isRenderHeader?: boolean;
};

export interface iTableProps<T> extends TableProps, React.RefAttributes<HTMLTableElement> {
  columns: Column<T>[];
  dataSource?: T[];
  rowKey?: (value: T) => string;
  customRow?: (value: T) => React.ReactNode;
  columnKey?: (value?: string) => string;
  loading?: boolean;
  classNameContainer?: string;
  onClickRow?: (data: T) => void;
  trClassName?: string;
}

const Table = <T,>({
  columns,
  dataSource = [],
  customRow,
  rowKey = (e) => get(e, 'id'),
  loading,
  className,
  classNameContainer,
  onClickRow,
  trClassName,
  ...props
}: iTableProps<T>): React.ReactElement => {
  const isHeader = useMemo(() => !!columns.find(({ title }) => title), [columns]);
  const columnCount = columns.length;
  return (
    <div className={cn(classNameContainer, 'overflow-auto position-relative')}>
      <RTable size="sm" className={cn(s.table, className)} {...props}>
        {isHeader ? (
          <thead>
            <tr>
              {columns.map(
                ({ key, title, isRenderHeader = true }) => isRenderHeader && <th key={`${key ?? title}`}>{title}</th>,
              )}
            </tr>
          </thead>
        ) : null}
        <colgroup>
          {columns.map(
            ({ key, title, width, isRenderHeader = true }) =>
              isRenderHeader && <col key={`${key ?? title}`} style={width ? { width } : {}} />,
          )}
        </colgroup>
        <tbody>
          {dataSource?.length ? (
            dataSource.map((data) =>
              typeof customRow === 'function' ? (
                customRow(data)
              ) : (
                <tr
                  key={rowKey(data)}
                  onClick={typeof onClickRow === 'function' ? () => onClickRow(data) : undefined}
                  className={trClassName}
                >
                  {columns.map(
                    ({ render, key, shouldRender = () => true, cellProps }) =>
                      shouldRender(data) && (
                        <td key={key} {...(cellProps && cellProps({ value: data, columnCount }))}>
                          {render(data)}
                        </td>
                      ),
                  )}
                </tr>
              ),
            )
          ) : (
            <tr>
              <td colSpan={columnCount} className="text-center">
                <div className="text-muted">There is no data</div>
              </td>
            </tr>
          )}
        </tbody>
      </RTable>
      {loading ? <LoadingOverlay /> : null}
    </div>
  );
};

export default Table;
