import React from 'react';
import {
  ContactPersonForm,
  OrganizationForm,
  contactPersonSchema,
  organizationSchema,
  howDidYouHearSchema,
} from 'components/bookingRequest/ContactInformation';
import * as yup from 'yup';
import StepLayout, { ValidationSchemaParams } from '../components/StepLayout';
import { useBookingContext } from '../context';

const contactInformationValidationSchema = (props: ValidationSchemaParams) =>
  yup.object().shape({
    ...howDidYouHearSchema(props),
    ...contactPersonSchema(),
    ...organizationSchema(props),
  });

const ContactInformation = () => {
  const {
    values: { targetGroupId },
    formConfig,
  } = useBookingContext();
  return (
    <StepLayout validationSchema={contactInformationValidationSchema}>
      <OrganizationForm targetGroupId={targetGroupId ?? undefined} formConfig={formConfig} />
      <br />
      <ContactPersonForm formConfig={formConfig} />
    </StepLayout>
  );
};

export default ContactInformation;
