import React from 'react';
import { Descendant, Editor, Text } from 'slate';
import { FontSizeToStyle } from './components/FontSizeSelect';
import { BLOCK_ELEMENT } from './constants';
import { MentionElement, RootElement } from './RichEditor.type';

declare global {
  interface Window {
    showRichTextRaw: boolean;
    showRichTextForI18n: boolean;
  }
}

export const getValueForI18n = (value: Descendant[]) => {
  if (!window.showRichTextForI18n) return null;
  const value1 = `'${JSON.stringify(value)}'`;
  return (
    <div
      role="presentation"
      onClick={() => {
        navigator.clipboard.writeText(value1);
      }}
      style={{ whiteSpace: 'pre-line', fontSize: 13, lineHeight: '12px', fontFamily: 'monospace' }}
    >
      {value1}
    </div>
  );
};

export const getValueForDebug = (value: Descendant[]) => {
  if (!window.showRichTextRaw) return null;
  const value1 = JSON.stringify(value, null, 2);
  return (
    <div
      role="presentation"
      onClick={() => {
        navigator.clipboard.writeText(value1);
      }}
      style={{ whiteSpace: 'pre-wrap', fontSize: 13, lineHeight: '12px', fontFamily: 'monospace' }}
    >
      {value1}
    </div>
  );
};

export const getSelectionRootBlocks = (editor: Editor) => {
  const arr = [editor?.selection?.anchor?.path?.[0] || 0, editor?.selection?.focus?.path?.[0] || 0];
  const min = Math.min(...arr);
  const max = Math.max(...arr);
  return {
    anchor: { offset: 0, path: [min, 0] },
    focus: { offset: 9999, path: [max, 9999] },
  };
};

export const getDefaultRichEditorValue = (rootElement: RootElement = BLOCK_ELEMENT.DIV): Descendant[] => [
  { type: rootElement, children: [{ text: '' }] },
];

export const getDefaultRichEditorString = (rootElement?: RootElement): string => {
  try {
    return JSON.stringify(getDefaultRichEditorValue(rootElement));
  } catch (err) {
    console.debug(err);
  }
  return '';
};

export const tryGetInitialValue = (
  initialValueJSON?: string,
  { rootElement }: { rootElement?: RootElement } = {},
): Descendant[] => {
  let value: Descendant[] = getDefaultRichEditorValue(rootElement);
  if (!initialValueJSON) return value;
  try {
    value = JSON.parse(initialValueJSON);
  } catch (e) {
    // console.debug(e);
  }
  return value;
};

export const getStyles = (text: Text | MentionElement) => {
  const style: React.CSSProperties = {};

  if (text.bold) {
    style.fontWeight = 'bold';
  }
  if (text.italic) {
    style.fontStyle = 'italic';
  }
  if (text.underline) {
    style.textDecoration = 'underline';
  }
  if (text['font-size']) {
    style.fontSize = FontSizeToStyle[text['font-size']];
  }
  if (text.color) {
    style.color = text.color;
  }
  return style;
};
