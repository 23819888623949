import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ScrollToTop from 'components/common/ScrollToTop';
import routePaths from './route-paths';
import BookingRequestPage from 'pages/BookingRequestPage';
import BookingLayout from 'components/layouts/BookingLayout';

const routes = [
  {
    path: routePaths.bookingRequestForm,
    component: () => <BookingRequestPage />,
  },
];

const PublicRouter = () => {
  return (
    <BookingLayout>
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, component: C }) => {
          return <Route key={path} path={path} element={<C />} />;
        })}
        <Route path="*" element={<Navigate to={routePaths.bookingRequestForm} />} />;
      </Routes>
    </BookingLayout>
  );
};

export default PublicRouter;
