import React from 'react';
import { MenuListProps } from 'react-select';
import { FixedSizeList as List } from 'react-window';

const MenuList = (props: MenuListProps) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const height = 40;
  const initialOffset = options.indexOf(value) * height;
  const elements = children as React.ReactElement[];

  return (
    <List height={maxHeight} itemCount={elements.length} width="100%" itemSize={40} initialScrollOffset={initialOffset}>
      {(item) => <div style={item.style}>{elements[item.index]}</div>}
    </List>
  );
};

export default MenuList;
