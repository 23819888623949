import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';

export type CustomFooterParams = { onClose: () => void; disabled: boolean };

export interface iInputModalFooter {
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  onClose: () => void;
  customFooter?: FC<CustomFooterParams>;
}

const InputModalFooter: FC<iInputModalFooter> = ({ onClose, okText, cancelText, customFooter: CustomFooter }) => {
  const { t } = useTranslation();
  const { errors, isSubmitting, submitForm, submitCount } = useFormikContext();

  const disabled = useMemo(
    () => !!submitCount && (!isEmpty(errors) || isSubmitting),
    [errors, isSubmitting, submitCount],
  );
  return (
    <div className="d-flex justify-content-end gap-2">
      {CustomFooter ? (
        <CustomFooter disabled={disabled} onClose={onClose} />
      ) : (
        <>
          {cancelText ? (
            <Button variant="secondary" onClick={onClose} disabled={disabled}>
              {cancelText}
            </Button>
          ) : null}
          <Button onClick={() => disabled || submitForm()} disabled={disabled}>
            {okText ?? t('save')}
          </Button>
        </>
      )}
    </div>
  );
};

export default InputModalFooter;
