import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitEventTitleFragmentDoc } from '../../../fragments/bookingkit/generated/bookingkitEventTitleFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingkitEventsQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type BookingkitEventsQuery = {
  __typename?: 'Query';
  bookingkitEvents?: Array<{ __typename?: 'BookingkitEvent'; id: string; title: string }> | null;
};

export const BookingkitEventsDocument = gql`
  query BookingkitEvents {
    bookingkitEvents {
      ...BookingkitEventTitle
    }
  }
  ${BookingkitEventTitleFragmentDoc}
`;

/**
 * __useBookingkitEventsQuery__
 *
 * To run a query within a React component, call `useBookingkitEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingkitEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingkitEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingkitEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<BookingkitEventsQuery, BookingkitEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingkitEventsQuery, BookingkitEventsQueryVariables>(BookingkitEventsDocument, options);
}
export function useBookingkitEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingkitEventsQuery, BookingkitEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingkitEventsQuery, BookingkitEventsQueryVariables>(BookingkitEventsDocument, options);
}
export type BookingkitEventsQueryHookResult = ReturnType<typeof useBookingkitEventsQuery>;
export type BookingkitEventsLazyQueryHookResult = ReturnType<typeof useBookingkitEventsLazyQuery>;
export type BookingkitEventsQueryResult = Apollo.QueryResult<BookingkitEventsQuery, BookingkitEventsQueryVariables>;
export function refetchBookingkitEventsQuery(variables?: BookingkitEventsQueryVariables) {
  return { query: BookingkitEventsDocument, variables: variables };
}
