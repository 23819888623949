import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { AutomationConfigFragmentDoc } from '../../../fragments/automationConfig/generated/automationConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAutomationConfigMutationVariables = SchemaTypes.Exact<{
  data?: SchemaTypes.InputMaybe<SchemaTypes.AutomationConfigInput>;
}>;

export type UpdateAutomationConfigMutation = {
  __typename?: 'Mutation';
  updateAutomationConfig: {
    __typename?: 'AutomationConfig';
    id: string;
    isMaxToursDuringDayByTourTopic: boolean;
    maxParticipants: number;
    maxParticipantsInGroup: number;
    maxToursDuringDay?: number | null;
    participantsManualReviewRangeEnd: number;
    participantsManualReviewRangeStart: number;
  };
};

export const UpdateAutomationConfigDocument = gql`
  mutation UpdateAutomationConfig($data: AutomationConfigInput) {
    updateAutomationConfig(data: $data) {
      ...AutomationConfig
    }
  }
  ${AutomationConfigFragmentDoc}
`;
export type UpdateAutomationConfigMutationFn = Apollo.MutationFunction<
  UpdateAutomationConfigMutation,
  UpdateAutomationConfigMutationVariables
>;

/**
 * __useUpdateAutomationConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAutomationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutomationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutomationConfigMutation, { data, loading, error }] = useUpdateAutomationConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAutomationConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAutomationConfigMutation, UpdateAutomationConfigMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAutomationConfigMutation, UpdateAutomationConfigMutationVariables>(
    UpdateAutomationConfigDocument,
    options,
  );
}
export type UpdateAutomationConfigMutationHookResult = ReturnType<typeof useUpdateAutomationConfigMutation>;
export type UpdateAutomationConfigMutationResult = Apollo.MutationResult<UpdateAutomationConfigMutation>;
export type UpdateAutomationConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateAutomationConfigMutation,
  UpdateAutomationConfigMutationVariables
>;
