import React from 'react';
import cn from 'classnames';
import s from './Footer.module.sass';
import config from 'config/config';

const Footer: FC = () => {
  return (
    <footer className={cn('align-self-start text-muted py-1', s.footer)}>
      <div className="text-truncate">
        {config.appVersion} • {new Date().getFullYear()} © Shared Tours
      </div>
    </footer>
  );
};

export default Footer;
