import Loading from 'components/common/loading/Loading';
import { useToggleEmailNotificationsMutation } from 'graphql/mutations/user/generated/ToggleEmailNotifications';
import { useMeQuery } from 'graphql/queries/user/generated/Me';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastErrorMessage } from 'utils/helpers';

const EmailNotificationsToggle: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useMeQuery();
  const [toggleEmailNotificationsMutation, { loading: mutating }] = useToggleEmailNotificationsMutation({
    onError: (error) => toastErrorMessage(error),
  });
  if (loading)
    return (
      <Form.Control>
        <label>{t('userProfile.emailNotificationsToggle')}</label>
        <Loading />
      </Form.Control>
    );
  return (
    <div>
      <Form.Check
        type="switch"
        id="emailNotificationsToggle"
        checked={!!data?.me.emailNotificationsEnabled}
        onChange={(event) => {
          toggleEmailNotificationsMutation({ variables: { emailNotificationsEnabled: event.target.checked } });
        }}
        disabled={mutating}
        label={t('userProfile.emailNotificationsToggle')}
      />
    </div>
  );
};

export default EmailNotificationsToggle;
