import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BlockedDateConfigFragmentDoc } from '../../../fragments/blockedDates/generated/blockedDateConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockedDatesConfigQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type BlockedDatesConfigQuery = {
  __typename?: 'Query';
  blockedDates: Array<{
    __typename?: 'BlockedDate';
    id: string;
    endDate: string | Date;
    startDate: string | Date;
    isAllCodes?: boolean | null;
    disabledCodes?: Array<{
      __typename?: 'DisabledCode';
      tourTopic: { __typename?: 'TourTopic'; id: string; code: string };
      targetGroup: { __typename?: 'TargetGroup'; id: string; code: string };
    }> | null;
    blockedBy: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } };
  }>;
};

export const BlockedDatesConfigDocument = gql`
  query BlockedDatesConfig {
    blockedDates {
      ...BlockedDateConfig
    }
  }
  ${BlockedDateConfigFragmentDoc}
`;

/**
 * __useBlockedDatesConfigQuery__
 *
 * To run a query within a React component, call `useBlockedDatesConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockedDatesConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockedDatesConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockedDatesConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<BlockedDatesConfigQuery, BlockedDatesConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlockedDatesConfigQuery, BlockedDatesConfigQueryVariables>(
    BlockedDatesConfigDocument,
    options,
  );
}
export function useBlockedDatesConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlockedDatesConfigQuery, BlockedDatesConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlockedDatesConfigQuery, BlockedDatesConfigQueryVariables>(
    BlockedDatesConfigDocument,
    options,
  );
}
export type BlockedDatesConfigQueryHookResult = ReturnType<typeof useBlockedDatesConfigQuery>;
export type BlockedDatesConfigLazyQueryHookResult = ReturnType<typeof useBlockedDatesConfigLazyQuery>;
export type BlockedDatesConfigQueryResult = Apollo.QueryResult<
  BlockedDatesConfigQuery,
  BlockedDatesConfigQueryVariables
>;
export function refetchBlockedDatesConfigQuery(variables?: BlockedDatesConfigQueryVariables) {
  return { query: BlockedDatesConfigDocument, variables: variables };
}
