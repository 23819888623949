import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BlockedDateFragmentDoc } from '../../../fragments/blockedDates/generated/blockedDateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockedDatesQueryVariables = SchemaTypes.Exact<{
  onlyActive?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Boolean']>;
  disabledCode?: SchemaTypes.InputMaybe<SchemaTypes.DisabledCodeInput>;
}>;

export type BlockedDatesQuery = {
  __typename?: 'Query';
  blockedDates: Array<{ __typename?: 'BlockedDate'; id: string; endDate: string | Date; startDate: string | Date }>;
};

export const BlockedDatesDocument = gql`
  query BlockedDates($onlyActive: Boolean, $disabledCode: DisabledCodeInput) {
    blockedDates(onlyActive: $onlyActive, disabledCode: $disabledCode) {
      ...BlockedDate
    }
  }
  ${BlockedDateFragmentDoc}
`;

/**
 * __useBlockedDatesQuery__
 *
 * To run a query within a React component, call `useBlockedDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockedDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockedDatesQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *      disabledCode: // value for 'disabledCode'
 *   },
 * });
 */
export function useBlockedDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<BlockedDatesQuery, BlockedDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlockedDatesQuery, BlockedDatesQueryVariables>(BlockedDatesDocument, options);
}
export function useBlockedDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlockedDatesQuery, BlockedDatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlockedDatesQuery, BlockedDatesQueryVariables>(BlockedDatesDocument, options);
}
export type BlockedDatesQueryHookResult = ReturnType<typeof useBlockedDatesQuery>;
export type BlockedDatesLazyQueryHookResult = ReturnType<typeof useBlockedDatesLazyQuery>;
export type BlockedDatesQueryResult = Apollo.QueryResult<BlockedDatesQuery, BlockedDatesQueryVariables>;
export function refetchBlockedDatesQuery(variables?: BlockedDatesQueryVariables) {
  return { query: BlockedDatesDocument, variables: variables };
}
