import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { CustomerTargetGroupForUpdatingAppointmentFragmentDoc } from '../../customer/generated/customerTargetGroupForUpdatingAppointmentFragment';
export type BookingRequestForUpdatingAppointmentFragment = {
  __typename?: 'BookingRequest';
  id: string;
  date: string | Date;
  altDate: string | Date;
  defaultProduct: { __typename?: 'Product'; id: string };
  customer: {
    __typename?: 'Customer';
    id: string;
    organization: {
      __typename?: 'CustomerOrganization';
      id: string;
      targetGroup: {
        __typename?: 'TargetGroup';
        code: string;
        id: string;
        name: { __typename?: 'Localized'; en: string; de: string };
        bookingFormConfig?: {
          __typename?: 'TargetGroupBookingFormConfig';
          fieldConfig: Array<{
            __typename?: 'TargetGroupFieldConfig';
            visible: boolean;
            required: boolean;
            name: string;
          }>;
        } | null;
      };
    };
  };
};

export type BookingRequestForUpdatingAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingRequestForUpdatingAppointmentFragmentDoc = gql`
  fragment BookingRequestForUpdatingAppointment on BookingRequest {
    id
    defaultProduct {
      id
    }
    date
    altDate
    customer {
      ...CustomerTargetGroupForUpdatingAppointment
    }
  }
  ${CustomerTargetGroupForUpdatingAppointmentFragmentDoc}
`;
