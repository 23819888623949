import React from 'react';
import PricingLevelForm, { pricingLevelValidationSchema } from 'components/bookingRequest/PricingLevelForm';
import StepLayout from '../components/StepLayout';

const PricingLevel = () => {
  return (
    <StepLayout validationSchema={pricingLevelValidationSchema}>
      <PricingLevelForm />
    </StepLayout>
  );
};

export default PricingLevel;
