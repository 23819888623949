import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TargetGroupOfferFragment = {
  __typename?: 'TargetGroup';
  bookingFormConfig?: {
    __typename?: 'TargetGroupBookingFormConfig';
    offerDescription: { __typename?: 'Localized'; en: string; de: string };
  } | null;
};

export type TargetGroupOfferFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TargetGroupOfferFragmentDoc = gql`
  fragment TargetGroupOffer on TargetGroup {
    bookingFormConfig {
      offerDescription {
        ...Localized
      }
    }
  }
  ${LocalizedFragmentDoc}
`;
