import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type CustomerForBookingRequestFragment = {
  __typename?: 'Customer';
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  salutation: SchemaTypes.Salutation;
  organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
};

export type CustomerForBookingRequestFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CustomerForBookingRequestFragmentDoc = gql`
  fragment CustomerForBookingRequest on Customer {
    id
    email
    firstName
    lastName
    salutation
    organization {
      id
      name
    }
  }
`;
