import { useEffect } from 'react';
import { connect } from 'formik';

// TODO: replace any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScrollToError = ({ formik, inModal }: { formik?: any; inModal?: boolean }) => {
  useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[id="${keys[0]}"], [name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        if (inModal) {
          errorElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        } else {
          const coordinates = errorElement.getBoundingClientRect();
          window.scrollTo({ top: coordinates.top + window.pageYOffset - 300, left: 0, behavior: 'smooth' });
        }
      }
    }
  }, [formik, inModal]);
  return null;
};

export default connect(ScrollToError);
