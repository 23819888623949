import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { GuideFeedbackForAppointmentFragmentDoc } from '../../../fragments/guideFeedback/generated/guideFeedbackForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProvideGuideFeedbackMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.GuideFeedbackInput;
  appointmentId: SchemaTypes.Scalars['ID'];
}>;

export type ProvideGuideFeedbackMutation = {
  __typename?: 'Mutation';
  provideGuideFeedback: {
    __typename?: 'Appointment';
    id: string;
    guideFeedback?: {
      __typename?: 'GuideFeedback';
      totalParticipants: number;
      totalAccompanyingPersons?: number | null;
      comment: string;
      createdAt: string | Date;
    } | null;
  };
};

export const ProvideGuideFeedbackDocument = gql`
  mutation ProvideGuideFeedback($data: GuideFeedbackInput!, $appointmentId: ID!) {
    provideGuideFeedback(data: $data, appointmentId: $appointmentId) {
      id
      guideFeedback {
        ...GuideFeedbackForAppointment
      }
    }
  }
  ${GuideFeedbackForAppointmentFragmentDoc}
`;
export type ProvideGuideFeedbackMutationFn = Apollo.MutationFunction<
  ProvideGuideFeedbackMutation,
  ProvideGuideFeedbackMutationVariables
>;

/**
 * __useProvideGuideFeedbackMutation__
 *
 * To run a mutation, you first call `useProvideGuideFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideGuideFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideGuideFeedbackMutation, { data, loading, error }] = useProvideGuideFeedbackMutation({
 *   variables: {
 *      data: // value for 'data'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useProvideGuideFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<ProvideGuideFeedbackMutation, ProvideGuideFeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProvideGuideFeedbackMutation, ProvideGuideFeedbackMutationVariables>(
    ProvideGuideFeedbackDocument,
    options,
  );
}
export type ProvideGuideFeedbackMutationHookResult = ReturnType<typeof useProvideGuideFeedbackMutation>;
export type ProvideGuideFeedbackMutationResult = Apollo.MutationResult<ProvideGuideFeedbackMutation>;
export type ProvideGuideFeedbackMutationOptions = Apollo.BaseMutationOptions<
  ProvideGuideFeedbackMutation,
  ProvideGuideFeedbackMutationVariables
>;
