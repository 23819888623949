import Table from 'components/common/Table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appointmentsToTableFormat, AppointmentTable } from './utils';
import CreateAppointmentDropdown from './components/CreateAppointmentDropdown';
import {
  AppointmentsQueryVariables,
  useAppointmentsQuery,
  AppointmentsDocument,
} from 'graphql/queries/appointment/generated/Appointments';
import usePagination from 'hooks/usePagination';
import constants from 'constants/constants';
import Pagination from 'components/common/Pagination';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import columns from 'components/app/appointment/appointmentColumns';
import { ColumnCellProps } from 'components/common/Table/Table';
import { FULL_DATE_FORMAT } from 'constants/date';
import { formatDate } from 'utils/helpers';
import AppointmentControls, { initialValues } from './AppointmentControls';

const AppointmentsTab = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(initialValues);
  const params = useMemo(() => {
    const params: Partial<AppointmentsQueryVariables> = {};
    if (state.startDate) params.startDate = state.startDate;
    return params;
  }, [state.startDate]);

  const { data, loading, error, variables, currentPage, setCurrentPage } = usePagination({
    pageSize: constants.appointmentCountTableRowsPerPage,
    query: useAppointmentsQuery,
    params,
  });

  // TODO: move to the Pagination component
  const prevStartDateRef = useRef<Date | undefined>(state.startDate);

  useEffect(() => {
    if (state.startDate !== prevStartDateRef.current) {
      setCurrentPage(1);
    }
    prevStartDateRef.current = state.startDate;
  }, [state.startDate, variables.startDate, setCurrentPage]);

  const appointmentColumns = useMemo(
    () =>
      columns({ t, refetchQueries: [{ query: AppointmentsDocument, variables }] }).map((column) => ({
        ...column,
        ...(column.key === 'date' && {
          render: (value: AppointmentTable) =>
            value instanceof Date && <span className="text-nowrap">{formatDate(value, FULL_DATE_FORMAT)}</span>,
          isRenderHeader: false,
          shouldRender: (value: AppointmentTable) => value instanceof Date,
          cellProps: ({ value, columnCount }: ColumnCellProps<AppointmentTable>) =>
            value instanceof Date
              ? { className: 'bg-secondary text-white text-start', colSpan: columnCount }
              : undefined,
        }),
      })),
    [t, variables],
  );
  const dataSource = useMemo(() => appointmentsToTableFormat(data?.appointments.items), [data?.appointments.items]);

  return (
    <>
      <AppointmentControls
        state={state}
        setState={setState}
        extra={<CreateAppointmentDropdown variables={variables} />}
      />
      {error ? (
        <AlertGraphQLError error={error} />
      ) : (
        <>
          <Table
            bordered
            loading={loading}
            columns={appointmentColumns}
            dataSource={dataSource}
            className="border bg-white m-0 table-text-center table-smaller-text"
            rowKey={(value: AppointmentTable) => (!(value instanceof Date) ? value.id : value.toString())}
          />
          <Pagination
            total={data?.appointments.total}
            currentPage={currentPage}
            changePage={setCurrentPage}
            pageSize={constants.appointmentCountTableRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default AppointmentsTab;
