import ConfirmModal from 'components/common/Modal/ConfirmModal';
import client from 'graphql/apollo';
import {
  DeleteBlockedDateDocument,
  DeleteBlockedDateMutation,
  DeleteBlockedDateMutationVariables,
} from 'graphql/mutations/blockedDate/generated/DeleteBlockedDate';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getRefetchQueries } from './utils';

const deleteBlockedDateModal = (blockedDateId: string) => {
  ConfirmModal({
    headerText: i18n.t('systemConfig.deleteBlockedDateConfirmation.title'),
    description: i18n.t('systemConfig.deleteBlockedDateConfirmation.description'),
    onOk: async () => {
      await client.mutate<DeleteBlockedDateMutation, DeleteBlockedDateMutationVariables>({
        mutation: DeleteBlockedDateDocument,
        variables: { id: blockedDateId },
        refetchQueries: getRefetchQueries(),
      });
      toast.success(i18n.t('systemConfig.deleteBlockedDateConfirmation.success'));
    },
  });
};

export default deleteBlockedDateModal;
