import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TourTopicForBookingRequestFormFragment = {
  __typename?: 'TourTopic';
  id: string;
  iconUrl: string;
  name: { __typename?: 'Localized'; en: string; de: string };
};

export type TourTopicForBookingRequestFormFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TourTopicForBookingRequestFormFragmentDoc = gql`
  fragment TourTopicForBookingRequestForm on TourTopic {
    id
    name {
      ...Localized
    }
    iconUrl
  }
  ${LocalizedFragmentDoc}
`;
