import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { GuideForAppointmentFragmentDoc } from '../../../fragments/user/generated/guideForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppointmentGuideMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  guideId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']>;
}>;

export type UpdateAppointmentGuideMutation = {
  __typename?: 'Mutation';
  updateAppointmentGuide: {
    __typename?: 'Appointment';
    id: string;
    guide?: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } } | null;
  };
};

export const UpdateAppointmentGuideDocument = gql`
  mutation UpdateAppointmentGuide($id: String!, $guideId: ID) {
    updateAppointmentGuide(id: $id, guideId: $guideId) {
      id
      guide {
        ...GuideForAppointment
      }
    }
  }
  ${GuideForAppointmentFragmentDoc}
`;
export type UpdateAppointmentGuideMutationFn = Apollo.MutationFunction<
  UpdateAppointmentGuideMutation,
  UpdateAppointmentGuideMutationVariables
>;

/**
 * __useUpdateAppointmentGuideMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentGuideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentGuideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentGuideMutation, { data, loading, error }] = useUpdateAppointmentGuideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      guideId: // value for 'guideId'
 *   },
 * });
 */
export function useUpdateAppointmentGuideMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentGuideMutation, UpdateAppointmentGuideMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppointmentGuideMutation, UpdateAppointmentGuideMutationVariables>(
    UpdateAppointmentGuideDocument,
    options,
  );
}
export type UpdateAppointmentGuideMutationHookResult = ReturnType<typeof useUpdateAppointmentGuideMutation>;
export type UpdateAppointmentGuideMutationResult = Apollo.MutationResult<UpdateAppointmentGuideMutation>;
export type UpdateAppointmentGuideMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentGuideMutation,
  UpdateAppointmentGuideMutationVariables
>;
