import React, { useCallback, useMemo } from 'react';
import Pagination from 'components/common/Pagination';
import Table from 'components/common/Table';
import { useUsersQuery } from 'graphql/queries/user/generated/Users';
import { Roles, User, UserAccountActivationStatus } from 'graphql/types.generated';
import { TFunction, useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Save } from 'react-bootstrap-icons';
import usePagination from 'hooks/usePagination';
import { formatDate, formatPhoneNumber, toastErrorMessage } from 'utils/helpers';
import useChangeUserActivationStatus from 'hooks/user/useChangeUserActivationStatus';
import { iInputModalConfirm, InputModalConfirm } from 'components/common/Modal/InputModal';
import { defaultInitialValues, UserModalProps } from './UserManagementPage';
import { UserForm, userFormValidationSchema } from 'components/app/user/UserForm';
import { deleteUser, updateUser } from './utils';
import useUser from 'hooks/user/useUser';
import { UserManagementFragment } from 'graphql/fragments/user/generated/userManagementFragment';
import errors from 'constants/errors';

const columns = ({
  t,
  onEdit,
  onDelete,
  onChangeActive,
  meId,
}: {
  t: TFunction;
  onEdit: (values: UserManagementFragment) => void;
  onDelete: (_id: string) => void;
  onChangeActive: (_id: string, isActive: boolean) => void;
  meId?: string;
}) => [
  {
    key: 'fullName',
    title: t('userManagement.columns.fullName'),
    render: (value: UserManagementFragment) => value.profile.fullName,
    width: '30%',
  },
  {
    key: 'email',
    title: t('userManagement.columns.email'),
    render: (value: UserManagementFragment) => value.email,
    width: '25%',
  },
  {
    key: 'phoneNumber',
    title: t('userManagement.columns.phoneNumber'),
    render: (value: UserManagementFragment) => formatPhoneNumber(value.profile.phoneNumber ?? ''),
    width: '25%',
  },
  {
    key: 'lastActivityDate',
    title: t('userManagement.columns.lastActivityDate'),
    render: (value: UserManagementFragment) => (value.lastActivityDate ? formatDate(value.lastActivityDate) : null),
    width: '20%',
  },
  {
    key: 'active',
    title: t('userManagement.columns.active'),
    render: (value: UserManagementFragment) => {
      if (meId === value.id) return null;
      return (
        <Form.Check
          type="switch"
          checked={!value.deactivated}
          onChange={() => onChangeActive(value.id, !value.deactivated)}
        />
      );
    },
  },
  {
    key: 'actions',
    title: t('userManagement.columns.actions'),
    render: (value: UserManagementFragment) => (
      <>
        <span className="text-primary user-select-none pe-auto" role="button" onClick={() => onEdit(value)}>
          {t('edit')}
        </span>
        {meId === value.id ? null : (
          <>
            {' '}
            <span className="text-primary user-select-none pe-auto" role="button" onClick={() => onDelete(value.id)}>
              {t('delete')}
            </span>
          </>
        )}
      </>
    ),
  },
];

const UserTab = ({ role = 'ADMIN' as Roles }: { role?: Roles }) => {
  const { t } = useTranslation();
  const { me } = useUser();
  const meId = useMemo(() => me?.id, [me]);
  const [changeUserAccountActivationStatus] = useChangeUserActivationStatus();
  const {
    data,
    loading,
    currentPage,
    variables: { skip },
    setCurrentPage,
  } = usePagination({
    params: { filter: { role } },
    query: useUsersQuery,
  });
  const { users, total } = useMemo(() => {
    if (!data) return {};
    const { items, total } = data.users;
    return { users: items as User[], total };
  }, [data]);

  const onChangeActive = useCallback(
    async (_id: string, isActive: boolean) =>
      changeUserAccountActivationStatus({
        variables: {
          userId: _id,
          activationStatus: (isActive ? 'deactivated' : 'activated') as UserAccountActivationStatus,
        },
        onError: toastErrorMessage,
      }),
    [changeUserAccountActivationStatus],
  );
  const onEdit = useCallback(
    (user: UserManagementFragment) => {
      // TODO: refactor: move to the user modal file
      InputModalConfirm({
        initialValues: {
          fullName: user.profile.fullName ?? defaultInitialValues.fullName,
          email: user.email ?? defaultInitialValues.email,
          role: user.role ?? defaultInitialValues.role,
          phoneNumber: user.profile.phoneNumber ?? defaultInitialValues.phoneNumber,
        },
        onSubmit: async (values) => {
          await updateUser({ values, skip, user });
        },
        okText: (
          <>
            {t('save')} <Save />
          </>
        ),
        headerText: t('userManagement.update'),
        formikContent: (props) => <UserForm isMe={user.id === meId} {...props} />,
        validationSchema: userFormValidationSchema,
        errorResolver: (e) =>
          e.message === errors.duplicated ? t('userManagement.duplicatedErrorMessage') : undefined,
      } as iInputModalConfirm<UserModalProps>);
    },
    [skip, meId, t],
  );
  const onDelete = useCallback(
    async (_id: string) => {
      await deleteUser({ _id, role, skip });
    },
    [skip, role],
  );
  const userColumns = useMemo(
    () => columns({ t, onEdit, onDelete, onChangeActive, meId }),
    [t, onEdit, onDelete, onChangeActive, meId],
  );
  return (
    <>
      <Table
        classNameContainer="mb-3"
        className="table-header-border border bg-white"
        dataSource={users}
        loading={loading}
        columns={userColumns}
      />
      <Pagination total={total} currentPage={currentPage} changePage={setCurrentPage} />
    </>
  );
};

export default UserTab;
