import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TourTopicForConfigFragmentDoc } from '../../../fragments/tourTopic/generated/tourTopicForConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TourTopicsForConfigQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TourTopicsForConfigQuery = {
  __typename?: 'Query';
  tourTopics: Array<{
    __typename?: 'TourTopic';
    id: string;
    maxToursDuringDay?: number | null;
    name: { __typename?: 'Localized'; en: string; de: string };
  }>;
};

export const TourTopicsForConfigDocument = gql`
  query TourTopicsForConfig {
    tourTopics {
      ...TourTopicForConfig
    }
  }
  ${TourTopicForConfigFragmentDoc}
`;

/**
 * __useTourTopicsForConfigQuery__
 *
 * To run a query within a React component, call `useTourTopicsForConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourTopicsForConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourTopicsForConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useTourTopicsForConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<TourTopicsForConfigQuery, TourTopicsForConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TourTopicsForConfigQuery, TourTopicsForConfigQueryVariables>(
    TourTopicsForConfigDocument,
    options,
  );
}
export function useTourTopicsForConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TourTopicsForConfigQuery, TourTopicsForConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TourTopicsForConfigQuery, TourTopicsForConfigQueryVariables>(
    TourTopicsForConfigDocument,
    options,
  );
}
export type TourTopicsForConfigQueryHookResult = ReturnType<typeof useTourTopicsForConfigQuery>;
export type TourTopicsForConfigLazyQueryHookResult = ReturnType<typeof useTourTopicsForConfigLazyQuery>;
export type TourTopicsForConfigQueryResult = Apollo.QueryResult<
  TourTopicsForConfigQuery,
  TourTopicsForConfigQueryVariables
>;
export function refetchTourTopicsForConfigQuery(variables?: TourTopicsForConfigQueryVariables) {
  return { query: TourTopicsForConfigDocument, variables: variables };
}
