import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BookingRequestForUpdatingResultFragment = {
  __typename?: 'BookingRequest';
  id: string;
  date: string | Date;
  altDate: string | Date;
  totalParticipants: number;
  totalAccompanyingPersons?: number | null;
  ageOfParticipants?: string | null;
  tourTopicId: string;
  language: SchemaTypes.TourLanguage;
  howDidYouHear?: string | null;
};

export type BookingRequestForUpdatingResultFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingRequestForUpdatingResultFragmentDoc = gql`
  fragment BookingRequestForUpdatingResult on BookingRequest {
    id
    date
    altDate
    totalParticipants
    totalAccompanyingPersons
    ageOfParticipants
    tourTopicId
    language
    howDidYouHear
  }
`;
