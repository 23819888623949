import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TourTopicForSelectFragment = {
  __typename?: 'TourTopic';
  id: string;
  name: { __typename?: 'Localized'; en: string; de: string };
};

export type TourTopicForSelectFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TourTopicForSelectFragmentDoc = gql`
  fragment TourTopicForSelect on TourTopic {
    id
    name {
      ...Localized
    }
  }
  ${LocalizedFragmentDoc}
`;
