import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type LocalizedFragment = { __typename?: 'Localized'; en: string; de: string };

export type LocalizedFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const LocalizedFragmentDoc = gql`
  fragment Localized on Localized {
    en
    de
  }
`;
