import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { UpdateAppointmentFragmentDoc } from '../../../fragments/appointments/generated/updateAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppointmentQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type UpdateAppointmentQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'Appointment';
    id: string;
    ageOfParticipants?: string | null;
    code?: string | null;
    endDate: string | Date;
    infoForGuides?: string | null;
    infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
    language?: SchemaTypes.TourLanguage | null;
    startDate: string | Date;
    totalAccompanyingPersons?: number | null;
    totalParticipants?: number | null;
    totalSlots: number;
    totalGuides?: number | null;
    type: SchemaTypes.AppointmentType;
    bookingkitConnection?: { __typename?: 'AppointmentBookingkitConnection'; dateId?: string | null } | null;
    guide?: { __typename?: 'User'; id: string } | null;
    bookingRequest?: { __typename?: 'BookingRequest'; id: string } | null;
    product?: {
      __typename?: 'Product';
      id: string;
      name: string;
      language?: SchemaTypes.TourLanguage | null;
      meetingPointName?: string | null;
      tourTopic?: {
        __typename?: 'TourTopic';
        id: string;
        code: string;
        name: { __typename?: 'Localized'; en: string; de: string };
      } | null;
    } | null;
    customer?: {
      __typename?: 'Customer';
      id: string;
      salutation: SchemaTypes.Salutation;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
      organization: {
        __typename?: 'CustomerOrganization';
        id: string;
        name?: string | null;
        address?: string | null;
        buildingNumber?: string | null;
        zip?: string | null;
        city?: string | null;
        uidNumber?: string | null;
      };
    } | null;
    targetGroup?: {
      __typename?: 'TargetGroup';
      code: string;
      id: string;
      name: { __typename?: 'Localized'; en: string; de: string };
      bookingFormConfig?: {
        __typename?: 'TargetGroupBookingFormConfig';
        fieldConfig: Array<{
          __typename?: 'TargetGroupFieldConfig';
          visible: boolean;
          required: boolean;
          name: string;
        }>;
      } | null;
    } | null;
  };
};

export const UpdateAppointmentDocument = gql`
  query UpdateAppointment($id: String!) {
    appointment(id: $id) {
      ...UpdateAppointment
    }
  }
  ${UpdateAppointmentFragmentDoc}
`;

/**
 * __useUpdateAppointmentQuery__
 *
 * To run a query within a React component, call `useUpdateAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<UpdateAppointmentQuery, UpdateAppointmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdateAppointmentQuery, UpdateAppointmentQueryVariables>(UpdateAppointmentDocument, options);
}
export function useUpdateAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UpdateAppointmentQuery, UpdateAppointmentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpdateAppointmentQuery, UpdateAppointmentQueryVariables>(
    UpdateAppointmentDocument,
    options,
  );
}
export type UpdateAppointmentQueryHookResult = ReturnType<typeof useUpdateAppointmentQuery>;
export type UpdateAppointmentLazyQueryHookResult = ReturnType<typeof useUpdateAppointmentLazyQuery>;
export type UpdateAppointmentQueryResult = Apollo.QueryResult<UpdateAppointmentQuery, UpdateAppointmentQueryVariables>;
export function refetchUpdateAppointmentQuery(variables: UpdateAppointmentQueryVariables) {
  return { query: UpdateAppointmentDocument, variables: variables };
}
