import { useAppContext } from 'contexts/AppContext';
import React from 'react';
import routePaths from 'router/route-paths';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import s from './NavbarBrand.module.sass';
import cn from 'classnames';

const NavbarBrand: FC = () => {
  const { sidebarOpen } = useAppContext();
  return (
    <Link className={cn('py-4', sidebarOpen ? 'overflow-hidden' : 'max-width-unset')} to={routePaths.appointments}>
      <Navbar.Brand className="p-0 m-0">
        <img src={sidebarOpen ? '/static/logo.png' : '/static/android-chrome-256x256.png'} className={s.logo} />
      </Navbar.Brand>
    </Link>
  );
};

export default NavbarBrand;
