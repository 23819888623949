import { useCallback } from 'react';
import gql from 'graphql-tag';
import { toastErrorMessage } from 'utils/helpers';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { useResetPasswordMutation } from 'graphql/mutations/auth/generated/ResetPassword';

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`;

const useResetPassword = (token: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPasswordMutation] = useResetPasswordMutation();

  const resetPassword = useCallback(
    async ({ password }: { password: string }, helpers) => {
      try {
        await resetPasswordMutation({ variables: { token, password } });
        toast.success(t('auth.passwordChangedSuccessfully'));
        navigate(routePaths.login);
      } catch (error) {
        toastErrorMessage(error as ApolloError);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [navigate, resetPasswordMutation, t, token],
  );

  return resetPassword;
};

export default useResetPassword;
