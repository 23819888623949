import { TourLanguage } from 'graphql/types.generated';
import { FlagEmojis } from 'pages/app/UserProfilePage/UserProfilePage';

export type EmailTemplate = { id: string; language: TourLanguage; name: string };

export const emailTemplatesToOptions = (emailTemplates: EmailTemplate[]) =>
  emailTemplates.map((emailTemplate) => ({
    value: emailTemplate.id,
    label: [FlagEmojis[emailTemplate.language as keyof typeof FlagEmojis], emailTemplate.name].join(' '),
  }));
