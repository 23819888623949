import FastField from 'components/common/FastField';
import NumberInput from 'components/inputs/NumberInput';
import { Field, Form, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Controls from '../components/Controls';
import * as yup from 'yup';
import i18n from 'i18n';
import { omit } from 'lodash';
import { ApolloError } from '@apollo/client';
import { toastErrorMessage } from 'utils/helpers';
import InputPlaceholders from '../components/InputPlaceholders';
import { useAutomationConfigQuery } from 'graphql/queries/config/generated/AutomationConfig';
import { useUpdateAutomationConfigMutation } from 'graphql/mutations/config/generated/UpdateAutomationConfig';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Formik from 'components/common/Formik';
import Switch from 'components/inputs/Switch';
import { AutomationConfigInput } from 'graphql/types.generated';
import {
  TourTopicsForConfigDocument,
  useTourTopicsForConfigQuery,
} from 'graphql/queries/tourTopic/generated/TourTopicsForConfig';
import { TourTopicForConfigFragment } from 'graphql/fragments/tourTopic/generated/tourTopicForConfigFragment';

type AutomationInitialValues = Record<string, unknown> & AutomationConfigInput & { isReset: boolean };

const validationSchema = (tourTopics?: TourTopicForConfigFragment[]) =>
  yup.lazy(({ isMaxToursDuringDayByTourTopic }: AutomationInitialValues) =>
    yup.object().shape({
      ...(isMaxToursDuringDayByTourTopic
        ? tourTopics?.reduce(
            (acc, tourTopic) => ({
              ...acc,
              [tourTopic.id]: yup
                .number()
                .nullable()
                .required()
                .label(
                  i18n.t('systemConfig.fields.maxToursDuringDay.tourTopicLabel', {
                    name: tourTopic.name[i18n.language as keyof typeof tourTopic.name],
                  }),
                ),
            }),
            {},
          )
        : {
            maxToursDuringDay: yup
              .number()
              .nullable()
              .required()
              .label(i18n.t('systemConfig.fields.maxToursDuringDay.label')),
          }),
      maxParticipants: yup.number().nullable().required().label(i18n.t('systemConfig.fields.maxParticipants.label')),
      maxParticipantsInGroup: yup
        .number()
        .nullable()
        .required()
        .label(i18n.t('systemConfig.fields.maxParticipantsInGroup.label')),
      participantsManualReviewRangeStart: yup
        .number()
        .nullable()
        .when('participantsManualReviewRangeEnd', {
          is: (e?: number) => e,
          then: (schema) => schema.max(yup.ref('participantsManualReviewRangeEnd')),
        })
        .required()
        .label(i18n.t('systemConfig.fields.participantsManualReviewRangeStart.label')),
      participantsManualReviewRangeEnd: yup
        .number()
        .nullable()
        .required()
        .label(i18n.t('systemConfig.fields.participantsManualReviewRangeEnd.label')),
    }),
  );

const MaxToursDuringDaySection = ({ tourTopics }: { tourTopics?: TourTopicForConfigFragment[] }) => {
  const { values } = useFormikContext<AutomationInitialValues>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <>
      <Field
        label="systemConfig.fields.isMaxToursDuringDayByTourTopic.label"
        name="isMaxToursDuringDayByTourTopic"
        component={Switch}
        isRequired
      />
      {values.isMaxToursDuringDayByTourTopic ? (
        tourTopics?.map((tourTopic) => (
          <Field
            key={tourTopic.id}
            name={tourTopic.id}
            component={NumberInput}
            isInteger
            description={t('systemConfig.fields.maxToursDuringDay.description')}
            label={t('systemConfig.fields.maxToursDuringDay.tourTopicLabel', {
              name: tourTopic.name[language as keyof typeof tourTopic.name],
            })}
            isRequired
          />
        ))
      ) : (
        <FastField
          isRequired
          name="maxToursDuringDay"
          component={NumberInput}
          isInteger
          description={t('systemConfig.fields.maxToursDuringDay.description')}
          label="systemConfig.fields.maxToursDuringDay.label"
        />
      )}
    </>
  );
};

const useAutomationConfig = () => {
  const automationConfigResult = useAutomationConfigQuery();
  const tourTopicResult = useTourTopicsForConfigQuery();

  return {
    tourTopics: tourTopicResult.data?.tourTopics,
    automationConfig: automationConfigResult.data?.automationConfig,
    loading: automationConfigResult.loading || tourTopicResult.loading,
    error: automationConfigResult.error || tourTopicResult.error,
  };
};

const produceInput = (
  values: AutomationInitialValues,
  tourTopics?: TourTopicForConfigFragment[],
): AutomationConfigInput => ({
  isMaxToursDuringDayByTourTopic: values.isMaxToursDuringDayByTourTopic,
  ...(values.isMaxToursDuringDayByTourTopic
    ? {
        maxToursDuringDayByTourTopics: tourTopics
          ?.map((tourTopic) => ({
            id: tourTopic.id,
            maxToursDuringDay: values[tourTopic.id] as number,
          }))
          .filter((e) => e.maxToursDuringDay),
      }
    : {
        maxToursDuringDay: values.maxToursDuringDay,
      }),
  maxParticipants: values.maxParticipants,
  maxParticipantsInGroup: values.maxParticipantsInGroup,
  participantsManualReviewRangeEnd: values.participantsManualReviewRangeEnd,
  participantsManualReviewRangeStart: values.participantsManualReviewRangeStart,
});

const AutomationForm = () => {
  const { t } = useTranslation();
  const [updateAutomationConfig] = useUpdateAutomationConfigMutation();
  const { automationConfig, tourTopics, loading, error } = useAutomationConfig();

  const onSubmit = useCallback(
    async (values: AutomationInitialValues) => {
      try {
        await updateAutomationConfig({
          variables: values.isReset ? undefined : { data: produceInput(values, tourTopics) },
          onCompleted: () => {
            toast.success(
              t(`systemConfig.${values.isReset ? 'resetAutomationConfigSuccess' : 'updateAutomationConfigSuccess'}`),
            );
          },
          refetchQueries: [{ query: TourTopicsForConfigDocument }],
        });
      } catch (error) {
        toastErrorMessage(error as ApolloError);
      }
    },
    [t, tourTopics, updateAutomationConfig],
  );

  const initialValues = useMemo<AutomationInitialValues>(
    () => ({
      isReset: false,
      ...omit(automationConfig, ['__typename', 'id']),
      ...tourTopics?.reduce(
        (acc, tourTopic) => ({ ...acc, [tourTopic.id]: tourTopic.maxToursDuringDay ?? undefined }),
        {},
      ),
    }),
    [automationConfig, tourTopics],
  );

  if (error) return <AlertGraphQLError error={error} />;
  if ((!tourTopics || !automationConfig) && loading) return <InputPlaceholders />;
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(tourTopics)}
    >
      <Form>
        <MaxToursDuringDaySection tourTopics={tourTopics} />
        <FastField
          isRequired
          name="maxParticipants"
          component={NumberInput}
          isInteger
          description={t('systemConfig.fields.maxParticipants.description')}
          label="systemConfig.fields.maxParticipants.label"
        />
        <Row>
          <Col sm={6}>
            <FastField
              isRequired
              name="participantsManualReviewRangeStart"
              component={NumberInput}
              isInteger
              description={t('systemConfig.fields.participantsManualReviewRangeStart.description')}
              label="systemConfig.fields.participantsManualReviewRangeStart.label"
            />
          </Col>
          <Col sm={6}>
            <FastField
              isRequired
              name="participantsManualReviewRangeEnd"
              component={NumberInput}
              isInteger
              label="systemConfig.fields.participantsManualReviewRangeEnd.label"
            />
          </Col>
        </Row>
        <FastField
          isRequired
          name="maxParticipantsInGroup"
          component={NumberInput}
          isInteger
          description={t('systemConfig.fields.maxParticipantsInGroup.description')}
          label="systemConfig.fields.maxParticipantsInGroup.label"
        />
        <Controls />
      </Form>
    </Formik>
  );
};

const Automation = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-3">
      <h5>{t('systemConfig.automation')}</h5>
      <AutomationForm />
    </div>
  );
};

export default Automation;
