import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingOverlay from 'components/common/loading/LoadingOverlay';
import PublicRouter from './PublicRouter';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import useUser from 'hooks/user/useUser';
import routePaths from './route-paths';
import { ConfirmAlertContainer } from '@uplab/react-confirm-alert';
import { withSentryReactRouterV6Routing } from '@sentry/react';

const AdminRouters = () => {
  const { me, loading } = useUser();

  if (loading && !me) {
    return <LoadingOverlay />;
  }

  return me ? <AppRouter /> : <AuthRouter />;
};

const routes = [
  {
    path: routePaths.auth,
    component: () => <AdminRouters />,
  },
  {
    path: routePaths.secure,
    component: () => <AdminRouters />,
  },
  {
    path: '*',
    component: () => <PublicRouter />,
  },
];

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const RootRouter = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <SentryRoutes>
      {routes.map(({ path, component: C }) => {
        return <Route key={path} path={path} element={<C />} />;
      })}
    </SentryRoutes>
    <ConfirmAlertContainer />
  </Router>
);

export default RootRouter;
