import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BookingkitConnectionForAppointmentFragment = {
  __typename?: 'AppointmentBookingkitConnection';
  dateId?: string | null;
  dateSyncedAt?: string | Date | null;
  dateSyncSuccess?: boolean | null;
  orderSuccess?: boolean | null;
  orderSyncedAt?: string | Date | null;
  errorName?: string | null;
  errorMessage?: string | null;
  ignoreError?: boolean | null;
};

export type BookingkitConnectionForAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingkitConnectionForAppointmentFragmentDoc = gql`
  fragment BookingkitConnectionForAppointment on AppointmentBookingkitConnection {
    dateId
    dateSyncedAt
    dateSyncSuccess
    orderSuccess
    orderSyncedAt
    errorName
    errorMessage
    ignoreError
  }
`;
