import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddAppointmentToBookingRequestMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.BookingRequestAppointmentInput;
  id: SchemaTypes.Scalars['ID'];
}>;

export type AddAppointmentToBookingRequestMutation = {
  __typename?: 'Mutation';
  addAppointmentToBookingRequest: { __typename?: 'BookingRequest'; id: string };
};

export const AddAppointmentToBookingRequestDocument = gql`
  mutation AddAppointmentToBookingRequest($data: BookingRequestAppointmentInput!, $id: ID!) {
    addAppointmentToBookingRequest(data: $data, id: $id) {
      id
    }
  }
`;
export type AddAppointmentToBookingRequestMutationFn = Apollo.MutationFunction<
  AddAppointmentToBookingRequestMutation,
  AddAppointmentToBookingRequestMutationVariables
>;

/**
 * __useAddAppointmentToBookingRequestMutation__
 *
 * To run a mutation, you first call `useAddAppointmentToBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppointmentToBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppointmentToBookingRequestMutation, { data, loading, error }] = useAddAppointmentToBookingRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddAppointmentToBookingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAppointmentToBookingRequestMutation,
    AddAppointmentToBookingRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAppointmentToBookingRequestMutation, AddAppointmentToBookingRequestMutationVariables>(
    AddAppointmentToBookingRequestDocument,
    options,
  );
}
export type AddAppointmentToBookingRequestMutationHookResult = ReturnType<
  typeof useAddAppointmentToBookingRequestMutation
>;
export type AddAppointmentToBookingRequestMutationResult =
  Apollo.MutationResult<AddAppointmentToBookingRequestMutation>;
export type AddAppointmentToBookingRequestMutationOptions = Apollo.BaseMutationOptions<
  AddAppointmentToBookingRequestMutation,
  AddAppointmentToBookingRequestMutationVariables
>;
