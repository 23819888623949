import Table from 'components/common/Table';
import { FlagEmojis } from 'pages/app/UserProfilePage/UserProfilePage';
import React, { useMemo, useState } from 'react';
import { ChevronRight, Plus } from 'react-bootstrap-icons';
import { TFunction, useTranslation } from 'react-i18next';
import { generatePath, useNavigate, Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { formatDate } from 'utils/helpers';
import { BookingRequestStatus as EBookingRequestStatus } from 'graphql/types.generated';
import BookingRequestAlert from './components/BookingRequestAlert';
import Button from 'components/common/Button';
import { useBookingRequestsQuery } from 'graphql/queries/bookingRequest/generated/BookingRequests';
import usePagination from 'hooks/usePagination';
import constants from 'constants/constants';
import { BookingRequestFragment } from 'graphql/fragments/bookingRequest/generated/bookingRequestFragment';
import { add, format } from 'date-fns';
import { DEFAULT_APPOINTMENT_DURATION_HOURS } from 'constants/appointment';
import Pagination from 'components/common/Pagination';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { DATE_TIME_FORMAT, FULL_DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import BookingRequestStatus from 'components/bookingRequest/BookingRequestStatus';
import createBookingRequestModal from 'components/app/bookingRequestAndAppointment/modals/createBookingRequestModal';
import { ColumnCellProps } from 'components/common/Table/Table';
import BookingRequestControls, { initialValues } from './BookingRequestControls';

const columns = ({ t }: { t: TFunction }) => [
  {
    key: 'date',
    title: t('appointments.columns.date'),
    render: (value: BookingRequestFragment) => (
      <span className="text-nowrap">{formatDate(value.date, FULL_DATE_FORMAT)}</span>
    ),
    width: '250px',
  },
  {
    key: 'time',
    title: t('appointments.columns.time'),
    render: (value: BookingRequestFragment) => (
      <span className="text-nowrap">
        {formatDate(value.date, TIME_FORMAT)} -{' '}
        {formatDate(add(new Date(value.date), { hours: DEFAULT_APPOINTMENT_DURATION_HOURS }), TIME_FORMAT)}
      </span>
    ),
    width: '120px',
  },
  {
    key: 'code',
    title: t('appointments.columns.code'),
    render: (value: BookingRequestFragment) => <div className="fw-bold text-center">{value.code}</div>,
    cellProps: ({ value }: ColumnCellProps<BookingRequestFragment>) =>
      value.tourTopic ? { style: { backgroundColor: value.tourTopic.backgroundColor } } : undefined,
    width: '60px',
  },
  {
    key: 'organization',
    title: t('appointments.columns.organization'),
    render: ({ customer }: BookingRequestFragment) => (
      <small>
        {customer.organization.name}
        <div className="text-nowrap">
          {[t(`salutations.${customer.salutation}`), customer.firstName, customer.lastName].join(' ')}
        </div>
      </small>
    ),
    width: '250px',
  },
  {
    key: 'totalParticipants',
    title: t('appointments.columns.totalParticipants'),
    render: (value: BookingRequestFragment) => value.totalParticipants,
    width: '40px',
  },
  {
    key: 'totalAccompanyingPersons',
    title: t('appointments.columns.totalAccompanyingPersons'),
    render: (value: BookingRequestFragment) => value.totalAccompanyingPersons,
    width: '40px',
  },
  {
    key: 'status',
    title: t('appointments.columns.status'),
    render: (value: BookingRequestFragment) => (
      <small>
        <BookingRequestStatus status={value.status} />
      </small>
    ),
    width: '120px',
  },
  {
    key: 'language',
    title: t('appointments.columns.language'),
    render: (value: BookingRequestFragment) => FlagEmojis[value.language],
    width: '40px',
  },
  {
    key: 'guides',
    title: t('appointments.columns.guides'),
    render: (value: BookingRequestFragment) => value.totalAppointments,
    width: '80px',
  },
  {
    key: 'emails',
    title: t('appointments.columns.lastEmailDate'),
    render: (value: BookingRequestFragment) =>
      value.lastEmail?.createdAt ? format(new Date(value.lastEmail?.createdAt), DATE_TIME_FORMAT) : null,
  },
  {
    key: 'actions',
    title: '',
    render: (value: BookingRequestFragment) => (
      <div className="float-end">
        <Link className="color-inherit" to={generatePath(routePaths.bookingRequest, { id: value.id })}>
          <ChevronRight />
        </Link>
      </div>
    ),
    width: '10px',
  },
];

const Requests = () => {
  const { t } = useTranslation();
  const bookingRequestColumns = useMemo(() => columns({ t }), [t]);
  const [state, setState] = useState(initialValues);
  const navigate = useNavigate();
  const { data, loading, error, currentPage, setCurrentPage, variables } = usePagination({
    pageSize: constants.bookingRequestCountTableRowsPerPage,
    query: useBookingRequestsQuery,
    params: {
      limit: constants.bookingRequestCountTableRowsPerPage,
      sort: state.sort,
      statuses: [EBookingRequestStatus.NEW, EBookingRequestStatus.EMAIL_SENT],
    },
  });
  return (
    <>
      <BookingRequestAlert />
      <BookingRequestControls
        state={state}
        setState={setState}
        extra={
          <Button endIcon={<Plus size="1.5em" />} onClick={() => createBookingRequestModal({ variables, navigate })}>
            {t('create')}
          </Button>
        }
      />
      {error ? (
        <AlertGraphQLError error={error} />
      ) : (
        <>
          <Table
            hover
            bordered
            onClickRow={(row) => navigate(generatePath(routePaths.bookingRequest, { id: row.id }))}
            loading={loading}
            columns={bookingRequestColumns}
            dataSource={data?.bookingRequests.items}
            className="table-header-border border bg-white table-smaller-text"
            trClassName="cursor-pointer"
          />
          <Pagination
            total={data?.bookingRequests.total}
            currentPage={currentPage}
            changePage={setCurrentPage}
            pageSize={constants.bookingRequestCountTableRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default Requests;
