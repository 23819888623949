import React from 'react';
import { useTranslation } from 'react-i18next';

interface iLayout {
  title: string | React.ReactNode;
  subtitle?: string;
}

const Layout: FC<iLayout> = ({ title, subtitle, children }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-3">
      <h5>{typeof title === 'string' ? t(title) : title}</h5>
      {subtitle ? (
        <>
          <div className="text-muted">{t(subtitle)}</div>
          <br />
        </>
      ) : null}
      {children}
    </div>
  );
};

export default Layout;
