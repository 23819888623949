import { omit } from 'lodash';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { BLOCK_ELEMENT } from './constants';
import { isMentionActive } from './plugins/withMentions';
import { FontSize, MentionElement } from './RichEditor.type';

export const FontSizeToStyle: Record<FontSize, string> = {
  small: '0.8em',
  normal: '1em',
  large: '2em',
  huge: '4em',
};

export const getFontSizeFromStyle = (fontSize: string) => {
  const [fontSizeName] = Object.entries(omit(FontSizeToStyle, 'normal')).find(([, value]) => fontSize === value) ?? [];
  return fontSizeName;
};

export const useInsertRichEditor = () => {
  const editor = useSlate();

  const insertMention = ({ _id }: { _id: string }) => {
    if (isMentionActive(editor)) return;
    const mention: MentionElement = {
      type: BLOCK_ELEMENT.MENTION,
      children: [{ text: '' }],
      _id,
    };
    Transforms.insertNodes(editor, mention, {});
    Transforms.move(editor);
    ReactEditor.focus(editor);
  };
  return { insertMention };
};

const PlaceholderSelect = () => {
  const { insertMention } = useInsertRichEditor();
  const { t } = useTranslation();
  return (
    <Dropdown
      className="d-inline-block dropdown-remove-toggle"
      onSelect={(v) => {
        insertMention({ _id: v as string });
      }}
    >
      <Dropdown.Toggle className="dropdown-toggle bg-transparent border-0 text-primary">
        {t('Placeholder.title')} <Plus />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {['contact'].map((name) => (
          <Dropdown.Item key={name} eventKey={name}>
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PlaceholderSelect;
