import { BookingRequest, BookingRequestStatus as EBookingRequestStatus } from 'graphql/types.generated';
import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type BookingRequestStatusProps = {
  status: EBookingRequestStatus;
};

const badgeColorByStatus: Record<BookingRequest['status'], BadgeProps['color']> = {
  [EBookingRequestStatus.ACCEPTED]: 'success',
  [EBookingRequestStatus.CANCELED]: 'secondary',
  [EBookingRequestStatus.EMAIL_SENT]: 'primary',
  [EBookingRequestStatus.NEW]: 'danger',
};
const BookingRequestStatus: FC<BookingRequestStatusProps> = (props) => {
  const { t } = useTranslation();
  return <Badge bg={badgeColorByStatus[props.status]}>{t(`bookingRequestStatus.${props.status}`)}</Badge>;
};

export default BookingRequestStatus;
