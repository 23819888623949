import React from 'react';
import { InputModalConfirm } from 'components/common/Modal/InputModal';
import i18n from 'i18n';
import * as yup from 'yup';
import {
  AppointmentInfoForOfficeInput,
  AppointmentSchemaProps,
  infoForOfficeSchema,
} from 'components/app/appointment/AppointmentForm';
import { FormConfigs } from 'components/bookingRequest/utils';
import { screenSizeConfig } from 'components/common/Visibility';
import { toast } from 'react-toastify';
import client from 'graphql/apollo';
import {
  UpdateAppointmentInfoForOfficeDocument,
  UpdateAppointmentInfoForOfficeMutation,
  UpdateAppointmentInfoForOfficeMutationVariables,
} from 'graphql/mutations/appointment/generated/UpdateAppointmentInfoForOffice';
import { Appointment } from 'graphql/types.generated';

export type iAppointmentValidationSchema = Omit<AppointmentSchemaProps, 'formConfig'> & {
  formConfigs: FormConfigs;
};

const appointmentInfoForOfficeValidationSchema = () => yup.object().shape(infoForOfficeSchema());

const appointmentInfoForOfficeModal = async ({ id, infoForOffice }: Pick<Appointment, 'id' | 'infoForOffice'>) => {
  InputModalConfirm({
    validationSchema: () => appointmentInfoForOfficeValidationSchema(),
    headerText: i18n.t('appointments.appointmentInfoForOfficeModal'),
    initialValues: { infoForOffice },
    onSubmit: async ({ infoForOffice }) => {
      await client.mutate<UpdateAppointmentInfoForOfficeMutation, UpdateAppointmentInfoForOfficeMutationVariables>({
        mutation: UpdateAppointmentInfoForOfficeDocument,
        variables: { id, infoForOffice },
      });
      toast.success(i18n.t('appointments.updateAppointmentInfoForOfficeSuccess'));
    },
    formikContent: () => <AppointmentInfoForOfficeInput />,
    width: screenSizeConfig.md,
  });
};
export default appointmentInfoForOfficeModal;
