import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import { formatDate, toastErrorMessage } from 'utils/helpers';
import { FlagEmojis } from '../../../UserProfilePage/UserProfilePage';
import { Pencil, Check, X, ArrowClockwise, CalendarCheck, BoxArrowUpRight } from 'react-bootstrap-icons';
import CardField from '../../components/CardField';
import updateBookingRequestModal from './updateBookingRequestModal';
import { BookingRequestViewMainInfoFragment } from 'graphql/fragments/bookingRequest/generated/bookingRequestViewMainInfoFragment';
import { useRunBookingRequestAutomationCriteriasMutation } from 'graphql/mutations/bookingRequest/generated/RunBookingRequestAutomationCriterias';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import { ApolloError } from '@apollo/client';
import MultilineText from 'components/common/MultilineText';
import BookingRequestStatus from 'components/bookingRequest/BookingRequestStatus';
import { createSearchParams, Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { Scalars } from 'graphql/types.generated';
import applyDateModal from './applyDateModal';

const ApplyDate = ({ bookingRequestId, date }: { bookingRequestId: string; date: Scalars['DateTime'] }) => {
  return <IconButton onClick={() => applyDateModal(bookingRequestId, date)} icon={<CalendarCheck />} />;
};

const ViewDay = ({ date }: { date: Scalars['DateTime'] }) => (
  <Link
    className="color-inherit"
    to={
      routePaths.appointments +
      '?' +
      createSearchParams({ initialStartDate: new Date(date).toISOString().substring(0, 10) })
    }
  >
    <IconButton icon={<BoxArrowUpRight />} />
  </Link>
);

const BookingRequestCard = ({ bookingRequest }: { bookingRequest?: BookingRequestViewMainInfoFragment }) => {
  const { t } = useTranslation();
  if (!bookingRequest) return null;
  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <span className="flex-1">{t('bookingRequestView.request')}</span>
        <Pencil
          className="cursor-pointer"
          size="1em"
          onClick={() => {
            updateBookingRequestModal({ bookingRequestId: bookingRequest.id });
          }}
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={6}>
            <CardField label="bookingRequestView.fields.internalId.label">{bookingRequest.id}</CardField>
            <CardField label="bookingRequestView.fields.createdAt.label">
              {formatDate(bookingRequest.createdAt)}
            </CardField>
            <CardField label="bookingRequestView.fields.dateTime.label">
              <div className="d-flex align-items-end">
                {formatDate(bookingRequest.date)} <ViewDay date={bookingRequest.date} />{' '}
                <ApplyDate date={bookingRequest.date} bookingRequestId={bookingRequest.id} />
              </div>
            </CardField>
            <CardField label="bookingRequestView.fields.altDateTime.label">
              <div className="d-flex align-items-end">
                {formatDate(bookingRequest.altDate)} <ViewDay date={bookingRequest.altDate} />{' '}
                <ApplyDate date={bookingRequest.altDate} bookingRequestId={bookingRequest.id} />
              </div>
            </CardField>
            <CardField label="bookingRequestView.fields.totalParticipants.label">
              {bookingRequest.totalParticipants}
            </CardField>
            <CardField
              fieldConfigName="totalAccompanyingPersons"
              label="bookingRequestView.fields.totalAccompanyingPersons.label"
            >
              {bookingRequest.totalAccompanyingPersons}
            </CardField>
            <CardField fieldConfigName="ageOfParticipants" label="bookingRequestView.fields.ageOfParticipants.label">
              {bookingRequest.ageOfParticipants}
            </CardField>
          </Col>
          <Col>
            <CardField label="bookingRequestView.fields.code.label">{bookingRequest.code}</CardField>
            <CardField label="bookingRequestView.fields.language.label">
              {FlagEmojis[bookingRequest.language as keyof typeof FlagEmojis]} {t(`locale.${bookingRequest.language}`)}
            </CardField>
            <CardField label="bookingRequestView.fields.howDidYouHear.label">{bookingRequest.howDidYouHear}</CardField>
            <CardField label="bookingRequestView.fields.status.label">
              <BookingRequestStatus status={bookingRequest.status} />
            </CardField>
            <AutomationCard bookingRequest={bookingRequest} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const AutomationCard = ({ bookingRequest }: { bookingRequest: BookingRequestViewMainInfoFragment }) => {
  const { t } = useTranslation();

  const [runBookingRequestAutomationCriteriasMutation, { loading }] = useRunBookingRequestAutomationCriteriasMutation({
    variables: { id: bookingRequest.id },
    onError: (error: ApolloError) => {
      toastErrorMessage(error);
    },
    onCompleted: () => {
      toast.success(t('bookingRequestView.fields.automationDetails.refreshAutomationDetailsSuccess'));
    },
  });
  return (
    <CardField
      label="bookingRequestView.fields.automationDetails.label"
      right={
        <IconButton
          onClick={() => {
            runBookingRequestAutomationCriteriasMutation();
          }}
          disabled={loading}
          icon={<ArrowClockwise />}
        ></IconButton>
      }
    >
      {!bookingRequest.automationDetails ? 'N/A' : null}
      {bookingRequest.automationDetails?.map((row) => (
        <div key={row.name}>
          {row.passed ? <Check className="text-success" /> : <X className="text-danger" />}{' '}
          <small>
            <MultilineText text={row.details} />
          </small>
        </div>
      ))}
    </CardField>
  );
};

export default BookingRequestCard;
