/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Locales } from 'i18n';

type AppConfig = {
  appVersion: string;
  defaultLocale: Locales;
  graphqlServerUrl: string;
  isDevelopment: boolean;
  isProduction: boolean;
  shadesToursLanding: string;
  shadesToursPrivacyPolicy: string;
  sentryDsn?: string;
};

const config: AppConfig = {
  appVersion: process.env.REACT_APP_VERSION ?? '1.0.0',
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE ? (process.env.REACT_APP_DEFAULT_LOCALE! as Locales) : Locales.en,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL || '/graphql',
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  shadesToursLanding: process.env.REACT_APP_SHADES_TOURS_LANDING ?? '',
  shadesToursPrivacyPolicy: process.env.REACT_APP_SHADES_TOURS_PRIVACY_POLICY ?? '',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
};

export default config;
