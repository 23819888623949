import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeUserAccountActivationStatusMutationVariables = SchemaTypes.Exact<{
  activationStatus: SchemaTypes.UserAccountActivationStatus;
  userId: SchemaTypes.Scalars['ID'];
}>;

export type ChangeUserAccountActivationStatusMutation = {
  __typename?: 'Mutation';
  changeUserAccountActivationStatus: boolean;
};

export const ChangeUserAccountActivationStatusDocument = gql`
  mutation ChangeUserAccountActivationStatus($activationStatus: UserAccountActivationStatus!, $userId: ID!) {
    changeUserAccountActivationStatus(activationStatus: $activationStatus, userId: $userId)
  }
`;
export type ChangeUserAccountActivationStatusMutationFn = Apollo.MutationFunction<
  ChangeUserAccountActivationStatusMutation,
  ChangeUserAccountActivationStatusMutationVariables
>;

/**
 * __useChangeUserAccountActivationStatusMutation__
 *
 * To run a mutation, you first call `useChangeUserAccountActivationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserAccountActivationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserAccountActivationStatusMutation, { data, loading, error }] = useChangeUserAccountActivationStatusMutation({
 *   variables: {
 *      activationStatus: // value for 'activationStatus'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeUserAccountActivationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserAccountActivationStatusMutation,
    ChangeUserAccountActivationStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserAccountActivationStatusMutation,
    ChangeUserAccountActivationStatusMutationVariables
  >(ChangeUserAccountActivationStatusDocument, options);
}
export type ChangeUserAccountActivationStatusMutationHookResult = ReturnType<
  typeof useChangeUserAccountActivationStatusMutation
>;
export type ChangeUserAccountActivationStatusMutationResult =
  Apollo.MutationResult<ChangeUserAccountActivationStatusMutation>;
export type ChangeUserAccountActivationStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserAccountActivationStatusMutation,
  ChangeUserAccountActivationStatusMutationVariables
>;
