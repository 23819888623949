import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'react-bootstrap-icons';
import Table from 'components/common/Table';
import {
  BookingRequestAppointmentsDocument,
  useBookingRequestAppointmentsQuery,
} from 'graphql/queries/bookingRequest/generated/BookingRequestAppointments';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import appointmentModal from 'components/app/bookingRequestAndAppointment/modals/appointmentModal';
import { AppointmentType, BookingRequestStatus } from 'graphql/types.generated';
import columns from 'components/app/appointment/appointmentColumns';

const AppointmentTable = ({ bookingRequestId }: { bookingRequestId: string }) => {
  const { t } = useTranslation();
  const appointmentColumns = useMemo(
    () =>
      columns({
        t,
        refetchQueries: [{ query: BookingRequestAppointmentsDocument, variables: { id: bookingRequestId } }],
      }),
    [t, bookingRequestId],
  );

  const { data, loading, error } = useBookingRequestAppointmentsQuery({ variables: { id: bookingRequestId } });
  return (
    <div className="mb-3">
      <h5>
        {t('bookingRequestView.appointments')}{' '}
        {data?.bookingRequest.status !== BookingRequestStatus.CANCELED ? (
          <Plus
            className="text-primary cursor-pointer"
            onClick={() =>
              appointmentModal(
                { type: AppointmentType.PRIVATE_TOUR, bookingRequestId: data?.bookingRequest.id, isEdit: false },
                [{ query: BookingRequestAppointmentsDocument, variables: { id: bookingRequestId } }],
              )
            }
          />
        ) : null}
      </h5>
      {error ? (
        <AlertGraphQLError error={error} />
      ) : (
        <Table
          className="border bg-white m-0 table-text-center table-smaller-text"
          bordered
          columns={appointmentColumns}
          dataSource={data?.bookingRequest.appointments}
          loading={loading}
        />
      )}
    </div>
  );
};

export default AppointmentTable;
