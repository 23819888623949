import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingRequestStatusQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type BookingRequestStatusQuery = {
  __typename?: 'Query';
  bookingRequest: { __typename?: 'BookingRequest'; id: string; status: SchemaTypes.BookingRequestStatus };
};

export const BookingRequestStatusDocument = gql`
  query BookingRequestStatus($id: String!) {
    bookingRequest(id: $id) {
      id
      status
    }
  }
`;

/**
 * __useBookingRequestStatusQuery__
 *
 * To run a query within a React component, call `useBookingRequestStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestStatusQuery(
  baseOptions: Apollo.QueryHookOptions<BookingRequestStatusQuery, BookingRequestStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingRequestStatusQuery, BookingRequestStatusQueryVariables>(
    BookingRequestStatusDocument,
    options,
  );
}
export function useBookingRequestStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestStatusQuery, BookingRequestStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingRequestStatusQuery, BookingRequestStatusQueryVariables>(
    BookingRequestStatusDocument,
    options,
  );
}
export type BookingRequestStatusQueryHookResult = ReturnType<typeof useBookingRequestStatusQuery>;
export type BookingRequestStatusLazyQueryHookResult = ReturnType<typeof useBookingRequestStatusLazyQuery>;
export type BookingRequestStatusQueryResult = Apollo.QueryResult<
  BookingRequestStatusQuery,
  BookingRequestStatusQueryVariables
>;
export function refetchBookingRequestStatusQuery(variables: BookingRequestStatusQueryVariables) {
  return { query: BookingRequestStatusDocument, variables: variables };
}
