import { throttle } from 'lodash';
import React, { memo, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Type, ChevronDown } from 'react-bootstrap-icons';
import { ChromePicker, ColorResult } from 'react-color';
import { FontSize, iComponentProps } from '../RichEditor.type';
import equal from 'fast-deep-equal/es6/react';

export const FontSizeToStyle: Record<FontSize, string> = {
  small: '0.8em',
  normal: '1em',
  large: '2em',
  huge: '4em',
};

const ColorSelectMemo: FC<iComponentProps> = memo(function ColorSelect({ getValue, onChange }) {
  const onChangeThrottled = useMemo(() => throttle(onChange, 100), [onChange]);
  const color = useMemo(() => getValue(), [getValue]);

  return (
    <Dropdown
      className="d-inline-block dropdown-remove-toggle"
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="text-muted bg-transparent border-0 p-1">
        <Type size="1.5em" /> <ChevronDown size="0.5em" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0">
        <ChromePicker
          disableAlpha
          color={color as string}
          onChange={(colors: ColorResult, e: React.ChangeEvent) => {
            onChangeThrottled(e, colors.hex, true);
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}, equal);

export default ColorSelectMemo;
