import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LanguageSelect, TourTopicSelect } from 'components/bookingRequest/inputs';
import { FormConfig } from 'components/bookingRequest/utils';
import {
  AgeOfParticipantsInput,
  TotalAccompanyingPersonsFormConfigInput,
  TotalParticipantsInput,
} from 'components/bookingRequest/PricingLevelForm/PricingLevelForm';
import {
  HowDidYouHearInput,
  howDidYouHearSchema,
} from 'components/bookingRequest/ContactInformation/ContactPersonForm';
import i18n from 'i18n';
import { tourTopicAndLanguageSchema as getTourTopicAndLanguageSchema } from 'components/bookingRequest/AppointmentForm';
import { pricingLevelSchema as getPricingLevelSchema } from 'components/bookingRequest/PricingLevelForm';
import { pick } from 'lodash';
import FastField from 'components/common/FastField';
import Checkbox from 'components/inputs/Checkbox';
import { iRequired } from 'components/common/Required';
import { filterPassedTime } from 'constants/date';
import { DatePickerFormik } from 'components/inputs/DatePicker';

export const schema = ({ formConfig, isEdit }: { formConfig: FormConfig; isEdit?: boolean }) => {
  const pricingLevelSchema = getPricingLevelSchema({ formConfig });
  const tourTopicAndLanguageSchema = getTourTopicAndLanguageSchema();

  return {
    ...howDidYouHearSchema({ formConfig }),
    ...tourTopicAndLanguageSchema,
    ...(tourTopicAndLanguageSchema.tourTopicId && {
      tourTopicId: tourTopicAndLanguageSchema.tourTopicId.label(i18n.t('bookingRequestView.fields.tourTopicId.label')),
    }),
    ...pick(pricingLevelSchema, [
      'totalParticipants',
      'totalAccompanyingPersons',
      ...(isEdit ? [] : ['ageOfParticipants']),
    ]),
    ...(pricingLevelSchema.date && {
      dateTime: pricingLevelSchema.date,
    }),
    ...(pricingLevelSchema.altDate && {
      altDateTime: pricingLevelSchema.altDate.label(i18n.t('bookingRequestView.fields.altDateTime.label')),
    }),
  };
};

const DateTimePicker = (props: { label: string; name: string } & iRequired) => (
  <FastField
    {...props}
    component={DatePickerFormik}
    showTimeSelect
    filterTime={filterPassedTime()}
    timeClassName={() => 'hide-disabled-times'}
    dateFormat="dd/MM/yyyy HH:mm"
  />
);

export type BookingRequestFormProps = { formConfig: FormConfig; isEdit?: boolean };

const BookingRequestForm = ({ formConfig, isEdit = false }: BookingRequestFormProps) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <DateTimePicker isRequired name="dateTime" label="bookingRequestView.fields.dateTime.label" />
        </Col>
        <Col md={6}>
          <DateTimePicker isRequired name="altDateTime" label="bookingRequestView.fields.altDateTime.label" />
        </Col>
      </Row>
      <Row>
        <Col>
          <TotalParticipantsInput />
        </Col>
        <TotalAccompanyingPersonsFormConfigInput formConfig={formConfig} />
      </Row>
      <Row>
        {!isEdit ? (
          <>
            <Col md={6}>
              <TourTopicSelect />
            </Col>
            <Col md={6}>
              <LanguageSelect />
            </Col>
          </>
        ) : null}
        <Row className="p-0 m-0">
          <HowDidYouHearInput formConfig={formConfig} />
          {isEdit ? null : <AgeOfParticipantsInput formConfig={formConfig} colProps={{ md: 6 }} />}
        </Row>
        {isEdit ? null : (
          <FastField
            name="skipAutomaticEmail"
            type="checkbox"
            component={Checkbox}
            label="bookingRequestView.fields.skipAutomaticEmail.label"
          />
        )}
      </Row>
    </>
  );
};

export default BookingRequestForm;
