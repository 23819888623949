import React, { useState } from 'react';
import routePaths from 'router/route-paths';
import s from './DropdownMenu.module.sass';
import NavDropdown from 'react-bootstrap/NavDropdown';
import avatarSrc from 'assets/icons/avatar.svg';
import { ChevronUp } from 'react-bootstrap-icons';
import cn from 'classnames';
import useUser from 'hooks/user/useUser';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
interface iDropdownMenuTitle {
  fullName?: string;
  isOpen: boolean;
}
const DropdownMenuTitle: FC<iDropdownMenuTitle> = ({ fullName, isOpen }) => {
  return (
    <div className={s.dropdownTitle}>
      <img src={avatarSrc} />
      <span className="flex-1 text-truncate">{fullName}</span>
      <ChevronUp className={isOpen ? 'rotate-180' : ''} />
    </div>
  );
};
const DropdownMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { me } = useUser({ fetchPolicy: 'cache-only' });
  const { t } = useTranslation();
  return (
    <NavDropdown
      title={<DropdownMenuTitle fullName={me?.profile.fullName} isOpen={isOpen} />}
      active={isOpen}
      onToggle={setIsOpen}
      id="profile-nav-dropdowd"
      className={cn('dropdown-remove-toggle d-flex flex-column-reverse py-1', s.dropdown)}
      drop="up"
    >
      <NavDropdown.Item className="text-muted" as={Link} to={routePaths.profile}>
        {t('pageTitles.myProfile')}
      </NavDropdown.Item>
      <NavDropdown.Item className="text-muted" as={Link} to={routePaths.logout}>
        {t('pageTitles.logout')}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default DropdownMenu;
