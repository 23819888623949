import React, { useMemo } from 'react';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import RCCheckbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import s from './Checkbox.module.sass';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import FormDescription from '../FormDescription';
import { iFormDescription } from '../FormDescription/FormDescription';
import { iRequired, Required } from 'components/common/Required';

interface iCheckboxProps extends iFormDescription, iRequired {
  field: FieldInputProps<boolean>;
  form: FormikProps<string>;
  label: string | React.ReactElement;
}

const Checkbox: FC<iCheckboxProps> = ({ field, form, label, description, isRequired }) => {
  const { name, value, onBlur, onChange } = field;
  const { touched, errors, isSubmitting } = form;
  const { t } = useTranslation();

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group>
      <div className="form-check">
        <RCCheckbox
          id={name}
          type="checkbox"
          checked={value}
          readOnly={isSubmitting}
          disabled={isSubmitting}
          {...{ value, name, onBlur, onChange }}
          className={cn(s.checkbox, isSubmitting ? 'disabled-input' : '')}
        />
        <Form.Label className={isSubmitting ? 'disabled-input' : ''} htmlFor={name} style={{ marginBottom: 0 }}>
          <Required visible={isRequired} /> {typeof label === 'string' ? t(label) : label}
        </Form.Label>
      </div>
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Checkbox;
