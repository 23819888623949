import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransitionBookingRequestToStatusMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
  status: SchemaTypes.BookingRequestStatus;
}>;

export type TransitionBookingRequestToStatusMutation = {
  __typename?: 'Mutation';
  transitionBookingRequestToStatus: {
    __typename?: 'BookingRequest';
    id: string;
    status: SchemaTypes.BookingRequestStatus;
  };
};

export const TransitionBookingRequestToStatusDocument = gql`
  mutation TransitionBookingRequestToStatus($id: ID!, $status: BookingRequestStatus!) {
    transitionBookingRequestToStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
export type TransitionBookingRequestToStatusMutationFn = Apollo.MutationFunction<
  TransitionBookingRequestToStatusMutation,
  TransitionBookingRequestToStatusMutationVariables
>;

/**
 * __useTransitionBookingRequestToStatusMutation__
 *
 * To run a mutation, you first call `useTransitionBookingRequestToStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionBookingRequestToStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionBookingRequestToStatusMutation, { data, loading, error }] = useTransitionBookingRequestToStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTransitionBookingRequestToStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransitionBookingRequestToStatusMutation,
    TransitionBookingRequestToStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransitionBookingRequestToStatusMutation,
    TransitionBookingRequestToStatusMutationVariables
  >(TransitionBookingRequestToStatusDocument, options);
}
export type TransitionBookingRequestToStatusMutationHookResult = ReturnType<
  typeof useTransitionBookingRequestToStatusMutation
>;
export type TransitionBookingRequestToStatusMutationResult =
  Apollo.MutationResult<TransitionBookingRequestToStatusMutation>;
export type TransitionBookingRequestToStatusMutationOptions = Apollo.BaseMutationOptions<
  TransitionBookingRequestToStatusMutation,
  TransitionBookingRequestToStatusMutationVariables
>;
