import { cloneDeep } from 'lodash';
import { registerLocale } from 'react-datepicker';
import { enGB as dateFnsEN, de as dateFnsDE } from 'date-fns/locale';
import en from 'yup/es/locale.js';
import { de as deYupLocale } from 'yup-locales';
import { setLocale } from 'yup';
import { Locales } from 'i18n';

const enYupLocale = cloneDeep(en);

export const registerReactDatepickerLocale = (locale: string) => {
  registerLocale(locale, locale === Locales.en ? dateFnsEN : dateFnsDE);
};

export const setYupLocale = (locale: string) => {
  setLocale(locale === Locales.en ? enYupLocale : deYupLocale);
};
