import React, { useCallback, useEffect, useState } from 'react';
import { InputModalConfirm } from 'components/common/Modal/InputModal';
import {
  emailTemplateIdSchema,
  emailTemplateSchema,
  EmailTemplateSelect,
  SubjectInput,
} from 'components/app/emailTemplate';
import i18n from 'i18n';
import { useFormikContext } from 'formik';
import { SelectedEmailTemplate } from 'components/app/emailTemplate/index.type';
import { merge, pick } from 'lodash';
import Button from 'components/common/Button';
import { Envelope } from 'react-bootstrap-icons';
import * as yup from 'yup';
import { useRenderBookingRequestEmailTemplateQuery } from 'graphql/queries/emailTemplate/generated/RenderBookingRequestEmailTemplate';
import { EmailMessageFragment } from 'graphql/fragments/emailMessage/generated/EmailMessage';
import {
  SendBookingRequestEmailTemplateDocument,
  SendBookingRequestEmailTemplateMutation,
  SendBookingRequestEmailTemplateMutationVariables,
} from 'graphql/queries/emailTemplate/generated/SendBookingRequestEmailTemplate';
import client from 'graphql/apollo';
import { toast } from 'react-toastify';
import { CustomFooterParams } from 'components/common/Modal/InputModal/Footer';
import htmr from 'htmr';
import Loading from 'components/common/loading/Loading';
import { MutationOptions } from '@apollo/client';
import RichEditor, { getDefaultRichEditorString } from 'components/inputs/RichEditor';
import { htmlStringToSlateString } from 'components/inputs/RichEditor/deserialize';
import { TourLanguage } from 'graphql/types.generated';
import { toastErrorMessage } from 'utils/helpers';

type Values = {
  emailTemplateId: string | null;
  language: TourLanguage | null;
  subject: string;
  body: string;
  isTest: boolean;
};
const defaultInitialValues: Values = {
  emailTemplateId: null,
  language: null,
  subject: '',
  body: getDefaultRichEditorString(),
  isTest: false,
};

interface iSendEmailFormikContent {
  isPreview?: boolean;
  sentEmail?: EmailMessageFragment;
  bookingRequestId?: string;
}

export const EmailTemplateContainer = ({ children }: { children: React.ReactNode }) => {
  const [key, setKey] = useState(0);
  const {
    values: { emailTemplateId },
  } = useFormikContext<{ emailTemplateId: string }>();
  useEffect(() => setKey((s) => s + 1), [emailTemplateId]);
  return <React.Fragment key={key}>{children}</React.Fragment>;
};

const RenderBody = ({ bookingRequestId }: Pick<iSendEmailFormikContent, 'bookingRequestId'>) => {
  const {
    values: { emailTemplateId },
    setFieldValue,
  } = useFormikContext<typeof defaultInitialValues>();

  const { data, loading } = useRenderBookingRequestEmailTemplateQuery({
    variables: { bookingRequestId: bookingRequestId || '', emailTemplateId: emailTemplateId || '' },
    fetchPolicy: 'network-only',
    skip: !emailTemplateId || !bookingRequestId,
    onError: toastErrorMessage,
    onCompleted: (data) => {
      setFieldValue(
        'body',
        data.renderBookingRequestEmailTemplate ? htmlStringToSlateString(data.renderBookingRequestEmailTemplate) : '',
      );
    },
  });
  if (!data && loading) return <Loading size={60} position="center" />;
  return <RichEditor name="body" label="emailTemplates.fields.body.label" hidePlaceholdersButton />;
};

const SendEmailFormikContent: FC<iSendEmailFormikContent> = ({ isPreview = !true, bookingRequestId }) => {
  const {
    setFieldValue,
    values: { body },
  } = useFormikContext<Values>();
  const onChange = useCallback(
    (emailTemplate: SelectedEmailTemplate) => {
      setFieldValue('language', emailTemplate.language, false);
      setFieldValue('subject', emailTemplate.subject, true);
    },
    [setFieldValue],
  );

  return (
    <>
      <EmailTemplateSelect
        isRequired={!isPreview}
        disabled={isPreview}
        name="emailTemplateId"
        label="emailTemplates.fields.emailTemplateId.label"
        onChange={onChange}
      />
      {/* <ToInput name="to" label="bookingRequestView.fields.to.label" /> */}
      <SubjectInput disabled={isPreview} />
      {isPreview ? htmr(body ?? '') : <RenderBody bookingRequestId={bookingRequestId} />}
    </>
  );
};

interface iSendEmailModal extends iSendEmailFormikContent {
  to?: string;
  refetchQueries?: MutationOptions['refetchQueries'];
}

const Footer = ({ onClose, disabled, isPreview }: CustomFooterParams & Pick<iSendEmailFormikContent, 'isPreview'>) => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Button disabled={disabled} onClick={onClose} variant="outline-primary">
        {i18n.t('close')}
      </Button>
      {isPreview ? null : (
        <>
          <Button
            endIcon={<Envelope />}
            disabled={disabled}
            onClick={() => setFieldValue('isTest', true, false)}
            type="submit"
          >
            {i18n.t('bookingRequestView.newEmailModal.sendTestEmailButton')}
          </Button>
          <Button
            endIcon={<Envelope />}
            disabled={disabled}
            onClick={() => setFieldValue('isTest', false, false)}
            type="submit"
          >
            {i18n.t('send')}
          </Button>
        </>
      )}
    </>
  );
};

const sendEmailModal = ({ to, isPreview, sentEmail, bookingRequestId, refetchQueries }: iSendEmailModal = {}) => {
  InputModalConfirm({
    validationSchema: isPreview
      ? undefined
      : () =>
          yup.object().shape({
            ...pick(emailTemplateSchema(), ['body', 'subject']),
            ...emailTemplateIdSchema(),
          }),
    headerText: i18n.t('bookingRequestView.newEmailModalTitle'),
    initialValues: merge({}, defaultInitialValues, {
      to: to ?? sentEmail?.to,
      emailTemplateId: sentEmail?.id,
      subject: sentEmail?.subject,
      body: sentEmail?.body,
    }),
    onSubmit: async ({ isTest, ...values }: Values) => {
      if (!bookingRequestId) {
        toast.error('No bookingRequestId found. Please contact developer');
        return;
      }
      if (!values.emailTemplateId) {
        toast.error('Please select email template');
        return;
      }
      await client.mutate<SendBookingRequestEmailTemplateMutation, SendBookingRequestEmailTemplateMutationVariables>({
        mutation: SendBookingRequestEmailTemplateDocument,
        variables: {
          test: isTest,
          bookingRequestId,
          emailTemplate: {
            id: values.emailTemplateId as string,
            language: values.language as TourLanguage,
            subject: values.subject,
            body: values.body,
          },
        },
        refetchQueries,
      });
      toast.success(i18n.t('bookingRequestView.newEmailModal.emailSent'));
      return isTest;
    },
    formikContent: () => (
      <SendEmailFormikContent isPreview={isPreview} sentEmail={sentEmail} bookingRequestId={bookingRequestId} />
    ),
    customFooter: Footer,
  });
};
export default sendEmailModal;
