import React from 'react';
import { MultiCheckbox } from 'components/inputs/Checkbox';
import FastField from 'components/common/FastField';
import { format, setDay } from 'date-fns';

const weekDayOptions = [...new Array(7)].map((e, i) => ({
  value: (i + 1) % 7,
  label: format(setDay(new Date(), i + 1), 'iii'),
}));

const WeekDaysCheckbox = () => {
  return (
    <FastField
      isRequired
      name="weekDays"
      component={MultiCheckbox}
      label="appointments.fields.weekDays.label"
      options={weekDayOptions}
    />
  );
};

export default WeekDaysCheckbox;
