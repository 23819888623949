import React, { useCallback, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { Roles } from 'graphql/types.generated';
import UserTab from './UserTab';
import { useTranslation } from 'react-i18next';
import { iInputModalConfirm, InputModalConfirm } from 'components/common/Modal/InputModal';
import { UserForm, userFormValidationSchema } from 'components/app/user/UserForm';
import { createUser } from './utils';
import errors from 'constants/errors';

export type UserModalProps = {
  fullName: string;
  email: string;
  role: Roles | undefined;
  password?: string;
  phoneNumber: string;
};

export const defaultInitialValues: UserModalProps = {
  fullName: '',
  email: '',
  role: undefined,
  password: '',
  phoneNumber: '43',
};

const UserManagementPage = () => {
  const [key, setKey] = useState<Roles>(Roles.ADMIN);
  const { t } = useTranslation();
  const onCreate = useCallback(() => {
    // TODO: refactor: move to the user modal file
    InputModalConfirm({
      initialValues: defaultInitialValues,
      onSubmit: async (values) => {
        await createUser({ values });
      },
      okText: (
        <>
          {t('create')} <Plus />
        </>
      ),
      headerText: t('userManagement.create'),
      formikContent: (props) => <UserForm {...props} isCreating />,
      validationSchema: () => userFormValidationSchema({ isCreating: true }),
      errorResolver: (e) => (e.message === errors.duplicated ? t('userManagement.duplicatedErrorMessage') : undefined),
    } as iInputModalConfirm<UserModalProps>);
  }, [t]);

  return (
    <>
      <PageTitle
        title={t('userManagement.title')}
        extra={
          <Button onClick={onCreate}>
            <span className="align-middle">{t('create')}</span> <Plus size="1.5em" />
          </Button>
        }
      />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k as Roles)}
        className="mb-3 w-max-content"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {Object.keys(Roles).map((tab) => (
          <Tab key={tab} eventKey={tab} title={t(`role.${tab}`)}>
            <UserTab role={tab as Roles} />
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default UserManagementPage;
