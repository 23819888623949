import { parse, format } from 'date-fns';
import { AppointmentFragment } from 'graphql/fragments/appointments/generated/appointmentFragment';
import { groupBy, sortBy } from 'lodash';

// export interface IRow {
//   type: string;
//   value: any;
//   label: string;
// }
export type AppointmentTable = AppointmentFragment | Date;

const INDEXING_FORMAT = 'yyyy-MM-dd';

export const appointmentsToTableFormat = (appointments: AppointmentFragment[] = []): AppointmentTable[] =>
  sortBy(
    Object.entries(groupBy(appointments, (appointment) => format(new Date(appointment.startDate), INDEXING_FORMAT))),
    ([date]) => parse(date, INDEXING_FORMAT, new Date()),
  )
    .flat()
    .flat()
    .map((e) => {
      if (typeof e === 'string') {
        // return { type: 'date', value: e, label: format(new Date(e), FULL_DATE_FORMAT) };
        return new Date(e);
      }
      return e;
    });
