import React, { Fragment, memo, useLayoutEffect, useMemo, useState } from 'react';
import s from './Image.module.sass';
import cn from 'classnames';
import { Editor, Path, Transforms } from 'slate';
import { Trash } from 'react-bootstrap-icons';
import { ReactEditor, RenderElementProps, useFocused, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import { ImageElement } from '../RichEditor.type';
import equal from 'fast-deep-equal/es6/react';
import { Resizable, ResizableProps } from 're-resizable';
import { merge } from 'lodash';

export const resizeImageFn =
  ({ width, height, path }: { width: number; height: number; path: Path }) =>
  (editor: Editor) => {
    Transforms.setNodes(editor, { width, height }, { at: path });
  };

const Image = ({ attributes, children, element }: Omit<RenderElementProps, 'element'> & { element: ImageElement }) => {
  const readOnly = useReadOnly();
  const [size, setSize] = useState<{ width: number; height: number } | undefined>();
  const sizePX = useMemo(
    () =>
      size && {
        width: size.width + 'px',
        height: size.height + 'px',
      },
    [size],
  );

  useLayoutEffect(
    () => setSize(element.width && element.height ? { width: element.width, height: element.height } : undefined),
    [element],
  );
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  const C = useMemo<FC<ResizableProps>>(
    () =>
      readOnly
        ? ({ children }) => <>{children}</>
        : ({ children, ...props }) => <Resizable {...props}>{children}</Resizable>,
    [readOnly],
  );
  return (
    <div contentEditable={false} {...(merge({ style: { userSelect: 'none' } }, attributes) as typeof attributes)}>
      <C
        size={size}
        onResize={(e, direction, ref) => {
          setSize({ width: ref.clientWidth, height: ref.clientHeight });
        }}
        onResizeStop={(e, direction, ref) => {
          Transforms.setNodes(editor, { width: ref.clientWidth, height: ref.clientHeight }, { at: path });
        }}
        className="d-inline-block"
        lockAspectRatio
      >
        <span className="d-inline-block position-relative">
          <img src={element.url} className={cn(s.image, selected && focused ? s.activeImage : '')} style={sizePX} />
          <div
            className={cn(s.remove, selected && focused ? '' : 'd-none', 'btn btn-primary')}
            onClick={() => {
              Transforms.removeNodes(editor, { at: path });
            }}
          >
            <Trash />
          </div>
          <div className="text-nowrap">{children}</div>
        </span>
      </C>
    </div>
  );
};

export default memo(Image, equal);
