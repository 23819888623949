import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type ModifyUserFragment = {
  __typename?: 'User';
  id: string;
  role: SchemaTypes.Roles;
  email: string;
  profile: { __typename?: 'UserProfile'; fullName: string };
};

export type ModifyUserFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const ModifyUserFragmentDoc = gql`
  fragment ModifyUserFragment on User {
    id
    role
    profile {
      fullName
    }
    email
  }
`;
