import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBookingSlotAppointmentMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.BookingSlotAppointmentInput;
}>;

export type CreateBookingSlotAppointmentMutation = {
  __typename?: 'Mutation';
  createBookingSlotAppointment: { __typename?: 'Appointment'; id: string };
};

export const CreateBookingSlotAppointmentDocument = gql`
  mutation CreateBookingSlotAppointment($data: BookingSlotAppointmentInput!) {
    createBookingSlotAppointment(data: $data) {
      id
    }
  }
`;
export type CreateBookingSlotAppointmentMutationFn = Apollo.MutationFunction<
  CreateBookingSlotAppointmentMutation,
  CreateBookingSlotAppointmentMutationVariables
>;

/**
 * __useCreateBookingSlotAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateBookingSlotAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingSlotAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingSlotAppointmentMutation, { data, loading, error }] = useCreateBookingSlotAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBookingSlotAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookingSlotAppointmentMutation,
    CreateBookingSlotAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBookingSlotAppointmentMutation, CreateBookingSlotAppointmentMutationVariables>(
    CreateBookingSlotAppointmentDocument,
    options,
  );
}
export type CreateBookingSlotAppointmentMutationHookResult = ReturnType<typeof useCreateBookingSlotAppointmentMutation>;
export type CreateBookingSlotAppointmentMutationResult = Apollo.MutationResult<CreateBookingSlotAppointmentMutation>;
export type CreateBookingSlotAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateBookingSlotAppointmentMutation,
  CreateBookingSlotAppointmentMutationVariables
>;
