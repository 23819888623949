import React from 'react';
import i18n from 'i18n';
import * as yup from 'yup';
import { merge } from 'lodash';
import { InputModalConfirm } from 'components/common/Modal/InputModal';
import { ProductType, TourLanguage, UpdateProductInput, CreateProductInput } from 'graphql/types.generated';
import client from 'graphql/apollo';
import { toast } from 'react-toastify';
import ProductForm, { isIncludeBookingPriceField } from './ProductForm';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import {
  CreateProductDocument,
  CreateProductMutation,
  CreateProductMutationVariables,
} from 'graphql/mutations/product/generated/CreateProduct';
import {
  UpdateProductDocument,
  UpdateProductMutation,
  UpdateProductMutationVariables,
} from 'graphql/mutations/product/generated/UpdateProduct';
import { ProductConfigFragment } from 'graphql/fragments/product/generated/productConfigFragment';
import { getRefetchQueries } from './utils';
import { tourTopicAndLanguageSchema } from 'components/bookingRequest/AppointmentForm';
import { BookingkitEventTitleFragmentDoc } from 'graphql/fragments/bookingkit/generated/bookingkitEventTitleFragment';

const productValidationSchema = () =>
  yup.lazy(({ type, bookingkitSyncEnabled }: Values) =>
    yup.object().shape({
      type: yup.string().nullable().required().label(i18n.t('systemConfig.fields.type.label')),
      ...(bookingkitSyncEnabled
        ? {
            bookingkitEventId: yup
              .string()
              .nullable()
              .required()
              .label(i18n.t('systemConfig.fields.bookingkitEventId.label')),
          }
        : { name: yup.string().nullable().required().label(i18n.t('systemConfig.fields.name.label')) }),
      ...(!type || type === ProductType.EVENT ? {} : tourTopicAndLanguageSchema()),
      ...(isIncludeBookingPriceField({ type, bookingkitSyncEnabled })
        ? {
            bookingkitPriceId: yup
              .string()
              .nullable()
              .required()
              .label(i18n.t('systemConfig.fields.bookingkitPriceId.label')),
          }
        : {}),
    }),
  );

export type Values = {
  bookingkitSyncEnabled: boolean;
  bookingkitEventId: string;
  bookingkitPriceId: string;
  name: string;
  type: ProductType;
  meetingPointName: string;
  startPointAddress: string;
  endPointAddress: string;
  language: TourLanguage | null;
  tourTopicId: string;
};

const defaultInitialValues: Values = {
  bookingkitSyncEnabled: false,
  bookingkitEventId: '',
  bookingkitPriceId: '',
  name: '',
  type: ProductType.PRIVATE_TOUR,
  meetingPointName: '',
  startPointAddress: '',
  endPointAddress: '',
  language: null,
  tourTopicId: '',
};

const getBookingkitEventTitle = (bookingkitEventId: string): string | null =>
  client.readFragment({
    id: `BookingkitEvent:${bookingkitEventId}`,
    fragment: BookingkitEventTitleFragmentDoc,
    fragmentName: 'BookingkitEventTitle',
  })?.title;

const produceUpdateProductInput = (values: Values): UpdateProductInput => ({
  bookingkitSyncEnabled: values.bookingkitSyncEnabled,
  bookingkitEventId: values.bookingkitEventId,
  ...(isIncludeBookingPriceField(values) ? { bookingkitPriceId: values.bookingkitPriceId } : {}),
  name: values.bookingkitSyncEnabled ? getBookingkitEventTitle(values.bookingkitEventId) ?? '' : values.name,
  meetingPointName: values.meetingPointName,
  startPointAddress: values.startPointAddress,
  endPointAddress: values.endPointAddress,
});

const produceCreateProductInput = (values: Values): CreateProductInput => ({
  ...produceUpdateProductInput(values),
  type: values.type as ProductType,
  language: values.language,
  tourTopicId: values.tourTopicId,
});

const productModal = async ({
  product,
}: {
  product?: ProductConfigFragment;
  refetchQueries?: InternalRefetchQueryDescriptor[];
} = {}) => {
  InputModalConfirm({
    validationSchema: productValidationSchema,
    headerText: i18n.t(`systemConfig.${product ? 'update' : 'create'}ProductModal`),
    initialValues: merge({}, defaultInitialValues, {
      bookingkitSyncEnabled: product?.bookingkitSyncEnabled,
      bookingkitEventId: product?.bookingkitEventId,
      bookingkitPriceId: product?.bookingkitPriceId,
      name: product?.name,
      type: product?.type,
      meetingPointName: product?.meetingPointName,
      startPointAddress: product?.startPointAddress,
      endPointAddress: product?.endPointAddress,
      language: product?.language,
      tourTopicId: product?.tourTopic?.id,
    }),
    onSubmit: async (values: Values) => {
      const selectedType = values.type as ProductType;
      if (product) {
        await client.mutate<UpdateProductMutation, UpdateProductMutationVariables>({
          mutation: UpdateProductDocument,
          variables: { id: product.id, data: produceUpdateProductInput(values) },
        });
        toast.success(i18n.t('systemConfig.updateProductSuccess'));
      } else {
        await client.mutate<CreateProductMutation, CreateProductMutationVariables>({
          mutation: CreateProductDocument,
          variables: { data: produceCreateProductInput(values) },
          refetchQueries: getRefetchQueries(selectedType),
        });
        toast.success(i18n.t('systemConfig.createProductSuccess'));
      }
    },
    formikContent: () => <ProductForm isEdit={!!product} />,
  });
};

export default productModal;
