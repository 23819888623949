import { Appointment, AppointmentInfoForOffice as EAppointmentInfoForOffice } from 'graphql/types.generated';
import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type AppointmentInfoForOfficeProps = {
  infoForOffice: Appointment['infoForOffice'];
};

const badgeColorByStatus: Record<EAppointmentInfoForOffice, { bg: BadgeProps['bg']; text: BadgeProps['text'] }> = {
  [EAppointmentInfoForOffice.INVOICE_NOT_SENT]: { bg: 'warning', text: 'dark' },
  [EAppointmentInfoForOffice.PARTIAL_INVOICE]: { bg: 'warning', text: 'dark' },
  [EAppointmentInfoForOffice.TICKET_NOT_SENT]: { bg: 'warning', text: 'dark' },
};
const AppointmentInfoForOffice: FC<AppointmentInfoForOfficeProps> = ({ infoForOffice }) => {
  const { t } = useTranslation();
  if (!infoForOffice) return null;
  return (
    <Badge bg={badgeColorByStatus[infoForOffice].bg} text={badgeColorByStatus[infoForOffice].text}>
      {t(`infoForOffice.${infoForOffice}`)}
    </Badge>
  );
};

export default AppointmentInfoForOffice;
