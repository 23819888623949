import React from 'react';
import ChangeLocale, { ChangeLocaleComponentProps, Locale } from 'components/common/ChangeLocale';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useBookingContext } from '../context';
import s from './BookingHeader.module.sass';

const ChangeLanguageDropdown: FC<ChangeLocaleComponentProps> = ({ locales, onChangeLanguage }) => {
  const { i18n } = useTranslation();
  return (
    <Dropdown
      onSelect={(locale) => {
        onChangeLanguage((locale ?? 'de') as Locale);
      }}
    >
      <Dropdown.Toggle className="dropdown-toggle bg-transparent">
        <img className={s.flag} src={`/static/${i18n.language}.svg`} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="gap-4">
        {locales.map((name) => (
          <Dropdown.Item key={name} eventKey={name} className="d-inline-block w-auto">
            <img src={`/static/${name}.svg`} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const BookingHeader = () => {
  const { t } = useTranslation();
  const { currentIndex, stepNumber } = useBookingContext();
  return (
    <div className="bg-primary text-white p-3 d-flex justify-content-between">
      <span className="text-white text-uppercase align-self-center">
        {t('bookingRequest.header', { currentIndex: currentIndex + 1, stepNumber })}
      </span>
      <ChangeLocale render={ChangeLanguageDropdown} />
    </div>
  );
};

export default BookingHeader;
