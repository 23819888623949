import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TargetGroupNameFragment = {
  __typename?: 'TargetGroup';
  name: { __typename?: 'Localized'; en: string; de: string };
};

export type TargetGroupNameFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TargetGroupNameFragmentDoc = gql`
  fragment TargetGroupName on TargetGroup {
    name {
      ...Localized
    }
  }
  ${LocalizedFragmentDoc}
`;
