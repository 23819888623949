import React from 'react';
import { Col, Row } from 'react-bootstrap';

export interface iControlsLayout {
  extra?: React.ReactNode;
}

export interface iControlsStates<T> {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
}

const ControlsLayout: FC<iControlsLayout> = ({ children, extra }) => {
  return (
    <Row>
      <Col>{children}</Col>
      <Col className="d-flex justify-content-end flex-wrap mb-3 gap-2">{extra}</Col>
    </Row>
  );
};

export default ControlsLayout;
