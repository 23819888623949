import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type TourTopicBackgroundColorFragment = { __typename?: 'TourTopic'; id: string; backgroundColor: string };

export type TourTopicBackgroundColorFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TourTopicBackgroundColorFragmentDoc = gql`
  fragment TourTopicBackgroundColor on TourTopic {
    id
    backgroundColor
  }
`;
