import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { AutomationConfigFragmentDoc } from '../../../fragments/automationConfig/generated/automationConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AutomationConfigQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type AutomationConfigQuery = {
  __typename?: 'Query';
  automationConfig: {
    __typename?: 'AutomationConfig';
    id: string;
    isMaxToursDuringDayByTourTopic: boolean;
    maxParticipants: number;
    maxParticipantsInGroup: number;
    maxToursDuringDay?: number | null;
    participantsManualReviewRangeEnd: number;
    participantsManualReviewRangeStart: number;
  };
};

export const AutomationConfigDocument = gql`
  query AutomationConfig {
    automationConfig {
      ...AutomationConfig
    }
  }
  ${AutomationConfigFragmentDoc}
`;

/**
 * __useAutomationConfigQuery__
 *
 * To run a query within a React component, call `useAutomationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutomationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutomationConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAutomationConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<AutomationConfigQuery, AutomationConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutomationConfigQuery, AutomationConfigQueryVariables>(AutomationConfigDocument, options);
}
export function useAutomationConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AutomationConfigQuery, AutomationConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutomationConfigQuery, AutomationConfigQueryVariables>(AutomationConfigDocument, options);
}
export type AutomationConfigQueryHookResult = ReturnType<typeof useAutomationConfigQuery>;
export type AutomationConfigLazyQueryHookResult = ReturnType<typeof useAutomationConfigLazyQuery>;
export type AutomationConfigQueryResult = Apollo.QueryResult<AutomationConfigQuery, AutomationConfigQueryVariables>;
export function refetchAutomationConfigQuery(variables?: AutomationConfigQueryVariables) {
  return { query: AutomationConfigDocument, variables: variables };
}
