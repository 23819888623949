export enum BLOCK_ELEMENT {
  DIV = 'div',
  NUMBERED_LIST = 'numbered-list',
  BULLETED_LIST = 'bulleted-list',
  LIST_ITEM = 'list-item',
  BLOCK_QUOTE = 'block-quote',
  MENTION = 'mention',
  LINK = 'link',
  IMAGE = 'image',
}

export const BLOCK_ELEMENT_ARRAY = Object.values(BLOCK_ELEMENT);

export const LIST_TYPES = [BLOCK_ELEMENT.NUMBERED_LIST, BLOCK_ELEMENT.BULLETED_LIST];
