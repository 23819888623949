import React, { ChangeEvent, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import FormDescription from '../FormDescription';
import { iFormDescription } from '../FormDescription/FormDescription';
import { iRequired, Required } from 'components/common/Required';

interface iSwitchProps extends iFormDescription, iRequired {
  field: FieldInputProps<boolean>;
  form: FormikProps<string>;
  label: string | React.ReactElement;
}

const Switch: FC<iSwitchProps> = ({ field, form, label, description, isRequired }) => {
  const { name, value, onBlur } = field;
  const { touched, errors, isSubmitting } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.target.checked);
    },
    [form, name],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {typeof label === 'string' ? t(label) : label}
      </Form.Label>
      <Form.Check
        id={name}
        onBlur={onBlur}
        type="switch"
        checked={!!value}
        onChange={handleChange}
        disabled={isSubmitting}
      />
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Switch;
