import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import RCCheckbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import s from './Checkbox.module.sass';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import FormDescription from '../FormDescription';
import { iFormDescription } from '../FormDescription/FormDescription';
import { concat, filter, includes } from 'lodash';
import { iRequired, Required } from 'components/common/Required';

interface iCheckboxOption extends iFormDescription {
  value: string;
  label: string;
}

interface iCheckboxProps extends iFormDescription, iRequired {
  field: FieldInputProps<[]>;
  form: FormikProps<string>;
  label: string | React.ReactElement;
  options: iCheckboxOption[];
}

const MultiCheckbox: FC<iCheckboxProps> = ({ field, form, label, description, options, isRequired }) => {
  const { name, value, onBlur } = field;
  const { touched, errors, isSubmitting, setFieldValue } = form;
  const { t } = useTranslation();

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const handleChange = useCallback(
    (_id: iCheckboxOption['value']) => {
      return (event: Event) => {
        const newValue = get(event, 'target.checked') ? concat(value, _id) : filter(value, (e) => e !== _id);
        setFieldValue(name, newValue, true);
      };
    },
    [name, setFieldValue, value],
  );

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {typeof label === 'string' ? t(label) : label}
      </Form.Label>
      <div className="d-flex flex-wrap gap-2">
        {options.map(({ value: name, label }) => {
          return (
            <div key={name} className="form-check d-inline-block">
              <RCCheckbox
                id={name}
                type="checkbox"
                checked={includes(value, name)}
                readOnly={isSubmitting}
                disabled={isSubmitting}
                onChange={handleChange(name)}
                {...{ value, name, onBlur }}
                className={cn(s.checkbox, isSubmitting ? 'disabled-input' : '')}
              />
              <Form.Label className={isSubmitting ? 'disabled-input' : ''} htmlFor={name} style={{ marginBottom: 0 }}>
                {typeof label === 'string' ? t(label) : label}
              </Form.Label>
            </div>
          );
        })}
      </div>
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default MultiCheckbox;
