import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { CustomerForUpdatingFragmentDoc } from '../../../fragments/customer/generated/customerForUpdatingFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.CustomerInput;
  id: SchemaTypes.Scalars['ID'];
}>;

export type UpdateCustomerMutation = {
  __typename?: 'Mutation';
  updateCustomer: {
    __typename?: 'Customer';
    id: string;
    salutation: SchemaTypes.Salutation;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | null;
    organization: {
      __typename?: 'CustomerOrganization';
      id: string;
      name?: string | null;
      address?: string | null;
      buildingNumber?: string | null;
      zip?: string | null;
      city?: string | null;
      uidNumber?: string | null;
      targetGroup: { __typename?: 'TargetGroup'; id: string };
    };
  };
};

export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($data: CustomerInput!, $id: ID!) {
    updateCustomer(data: $data, id: $id) {
      ...CustomerForUpdating
    }
  }
  ${CustomerForUpdatingFragmentDoc}
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
