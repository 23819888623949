import { FormConfig, fieldConfigArrayToObject } from 'components/bookingRequest/utils';
import { TargetGroupFieldConfigFragment } from 'graphql/fragments/targetGroup/generated/targetGroupFieldConfigFragment';
import React, { useContext, useMemo } from 'react';

export type FormConfigContextResult = FormConfig | undefined;

type FormConfigContextProps = {
  children: React.ReactNode;
  targetGroup?: TargetGroupFieldConfigFragment;
};

const BookingContext = React.createContext<FormConfigContextResult>(undefined);

export const FormConfigProvider: FC<FormConfigContextProps> = ({ children, targetGroup }) => {
  const formConfig = useMemo(
    () => fieldConfigArrayToObject(targetGroup?.bookingFormConfig?.fieldConfig),
    [targetGroup?.bookingFormConfig?.fieldConfig],
  );
  return <BookingContext.Provider value={formConfig}>{children}</BookingContext.Provider>;
};

export const useFormConfigContext = () => useContext(BookingContext);
