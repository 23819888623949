import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import i18n from 'i18n';

const RefreshI18nErrorsFormik = () => {
  const { touched, setTouched } = useFormikContext();

  const refreshErrors = useCallback(
    () => window.setTimeout(() => setTouched({ ...touched }), 0),
    [setTouched, touched],
  );

  useEffect(() => {
    i18n.on('languageChanged', refreshErrors);
    return () => {
      i18n.off('languageChanged', refreshErrors);
    };
  }, [refreshErrors]);
  return null;
};

export default RefreshI18nErrorsFormik;
