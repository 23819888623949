import { FormConfigs, useTargetGroupFormConfigs } from 'components/bookingRequest/utils';
import { AppointmentType, AppointmentTypes, DEFAULT_APPOINTMENT_DURATION_HOURS } from 'constants/appointment';
import { UpdateAppointmentFragment } from 'graphql/fragments/appointments/generated/updateAppointmentFragment';
import {
  UpdateAppointmentDocument,
  useUpdateAppointmentQuery,
} from 'graphql/queries/appointment/generated/UpdateAppointment';
import { usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery } from 'graphql/queries/targetGroup/generated/PublicTourDefaultTargetGroupForUpdatingAppointment';
import { TargetGroupForUpdatingAppointmentFragment } from 'graphql/fragments/targetGroup/generated/targetGroupForUpdatingAppointmentFragment';
import { useBookingRequestForUpdatingAppointmentQuery } from 'graphql/queries/bookingRequest/generated/BookingRequestForUpdatingAppointment';
import { BookingRequestForUpdatingAppointmentFragment } from 'graphql/fragments/bookingRequest/generated/bookingRequestForUpdatingAppointmentFragment';
import { merge, omit } from 'lodash';
import {
  BookingSlotAppointmentInput,
  BookingRequestAppointmentInput,
  EventAppointmentInput,
  RecurringEventInput,
  TargetGroupFieldConfig,
  BookingRequestInput,
  AppointmentInfoForOffice,
} from 'graphql/types.generated';
import { add, format, getHours, getMinutes, set } from 'date-fns';
import {
  DefaultUpdateCustomerInitialValues,
  defaultUpdateCustomerInitialValues,
} from 'components/app/bookingRequest/CustomerForm';
import { TIME_FORMAT } from 'constants/date';
import { unsetBookingRequestValues } from 'components/app/bookingRequest';
import { ProductForUpdatingAppointmentFragment } from 'graphql/fragments/product/generated/productForUpdatingAppointmentFragment';
import { ApolloError } from '@apollo/client';

type InitialValuesBase = {
  weekDays?: number[];
  startDate?: Date | null;
  endDate?: Date | null;
  startTime?: Date | null;
  endTime?: Date | null;
  guideId?: string | null;
  targetGroupId: string | null;
  totalSlots?: number | null;
  totalParticipants: number | null;
  totalAccompanyingPersons?: number | null;
  productId: string | null;
  ageOfParticipants?: string;
  infoForGuides: string;
  infoForOffice?: AppointmentInfoForOffice | null;
  totalGuides?: number;
};

export type InitialValues<S extends boolean = false> = S extends true
  ? InitialValuesBase & DefaultUpdateCustomerInitialValues
  : InitialValuesBase;

export const defaultInitialValues: InitialValues = {
  weekDays: [],
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  guideId: null,
  targetGroupId: null,
  totalSlots: 15,
  totalParticipants: null,
  totalAccompanyingPersons: null,
  productId: null,
  ageOfParticipants: '',
  infoForGuides: '',
};

export const defaultInitialValuesWithCustomer: InitialValues<true> = {
  ...defaultInitialValues,
  ...defaultUpdateCustomerInitialValues,
};

export type AppointmentModalProps = {
  bookingRequestId?: string;
  isEdit: boolean;
  appointmentId?: string;
  type?: AppointmentType;
};

const useDefaultTargetGroup = ({ skip }: { skip: boolean }) => {
  const { data, loading, error } = usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery({
    skip,
  });
  return { data: data?.publicTourDefaultTargetGroup, loading, error };
};

const getInitialValue = ({
  appointment,
  bookingRequest,
  targetGroup,
  type,
}: {
  appointment?: Partial<UpdateAppointmentFragment>;
  bookingRequest?: BookingRequestForUpdatingAppointmentFragment;
  targetGroup?: TargetGroupForUpdatingAppointmentFragment;
  type?: AppointmentType;
}): InitialValues => {
  let startDate = appointment?.startDate ? new Date(appointment?.startDate) : undefined;
  let endDate = appointment?.endDate ? new Date(appointment?.endDate) : undefined;
  let startTime = appointment?.startDate ? new Date(appointment?.startDate) : undefined;
  let endTime = appointment?.endDate ? new Date(appointment?.endDate) : undefined;

  if (bookingRequest) {
    if (!startDate) startDate = new Date(bookingRequest.date);
    if (!endDate) endDate = add(new Date(bookingRequest.date), { hours: DEFAULT_APPOINTMENT_DURATION_HOURS });
    if (!startTime) startTime = new Date(bookingRequest.date);
    if (!endTime) endTime = add(new Date(bookingRequest.date), { hours: DEFAULT_APPOINTMENT_DURATION_HOURS });
  }

  return merge(
    {},
    type === AppointmentTypes.EVENT ? defaultInitialValuesWithCustomer : defaultInitialValues,
    {
      startDate,
      endDate,
      startTime,
      endTime,
      guideId: appointment?.guide?.id,
      totalGuides: appointment?.totalGuides,
      infoForOffice: appointment?.infoForOffice,
      targetGroupId: appointment?.targetGroup?.id ?? targetGroup?.id,
      totalParticipants: appointment?.totalParticipants,
      totalAccompanyingPersons: appointment?.totalAccompanyingPersons,
      totalSlots: appointment?.totalSlots,
      productId: appointment?.product?.id ?? bookingRequest?.defaultProduct.id,
      ageOfParticipants: appointment?.ageOfParticipants,
      infoForGuides: appointment?.infoForGuides,
    },
    type === AppointmentTypes.EVENT
      ? omit({ ...appointment?.customer, ...appointment?.customer?.organization }, ['organization'])
      : {},
  );
};

const useAppointment = (appointmentId?: string) => {
  const { data, loading, error } = useUpdateAppointmentQuery({
    query: UpdateAppointmentDocument,
    variables: { id: appointmentId as string },
    fetchPolicy: 'network-only',
    skip: !appointmentId,
  });
  return { data: data?.appointment, loading, error };
};

const useBookingRequest = ({ bookingRequestId, skip }: { bookingRequestId?: string; skip: boolean }) => {
  const { data, loading, error } = useBookingRequestForUpdatingAppointmentQuery({
    variables: { id: bookingRequestId as string },
    skip: skip || !bookingRequestId,
  });

  return { data: data?.bookingRequest, loading, error };
};

type PrepareDataResult = {
  initialValues: InitialValues;
  isPushed: boolean;
  type?: AppointmentType;
  formConfigs: FormConfigs;
  targetGroup?: TargetGroupForUpdatingAppointmentFragment;
  bookingRequestId?: string;
  customerId?: string;
  product?: ProductForUpdatingAppointmentFragment;
  error?: ApolloError;
  loading?: boolean;
};

export const useProduceData = (props: AppointmentModalProps): PrepareDataResult => {
  const {
    data: appointment,
    loading: appointmentLoading,
    error: appointmentError,
  } = useAppointment(props.appointmentId);
  const {
    data: bookingRequest,
    loading: bookingRequestLoading,
    error: bookingRequestError,
  } = useBookingRequest({ bookingRequestId: props.bookingRequestId, skip: !!props.appointmentId });

  const type = props.type || appointment?.type;

  const {
    data: defaultTargetGroup,
    loading: defaultTargetGroupLoading,
    error: defaultTargetGroupError,
  } = useDefaultTargetGroup({
    skip: !!props.appointmentId || !!props.bookingRequestId || type !== AppointmentTypes.BOOKING_SLOT,
  });

  const targetGroup =
    appointment?.targetGroup ?? bookingRequest?.customer.organization.targetGroup ?? defaultTargetGroup;
  const { formConfigs, loading: targetGroupLoading, error: targetGroupError } = useTargetGroupFormConfigs(targetGroup);
  const initialValues = getInitialValue({ appointment, bookingRequest, targetGroup, type });

  return {
    initialValues,
    isPushed: !!appointment?.bookingkitConnection?.dateId,
    type,
    formConfigs,
    targetGroup: targetGroup ?? undefined,
    bookingRequestId: props.bookingRequestId ?? appointment?.bookingRequest?.id,
    customerId: appointment?.customer?.id,
    product: appointment?.product ?? undefined,
    loading: appointmentLoading || bookingRequestLoading || targetGroupLoading || defaultTargetGroupLoading,
    error: appointmentError || bookingRequestError || targetGroupError || defaultTargetGroupError,
  };
};

const getDateTimeFromInitialValues = (date: InitialValues['startDate'], time: InitialValues['startTime']) => {
  const _date = date ?? new Date();
  const _time = time ?? new Date();
  return set(new Date(_date), { hours: getHours(_time), minutes: getMinutes(_time) });
};

export const produceBookingSlotInput = (values: InitialValues): BookingSlotAppointmentInput => {
  return {
    startDate: getDateTimeFromInitialValues(values?.startDate, values?.startTime),
    endDate: getDateTimeFromInitialValues(values?.endDate, values?.endTime),
    productId: values.productId as string,
    targetGroupId: values.targetGroupId as string,
    totalSlots: values.totalSlots as number,
    guideId: values.guideId,
    ageOfParticipants: values.ageOfParticipants,
    infoForOffice: values?.infoForOffice,
    infoForGuides: values?.infoForGuides,
  };
};

export const produceEventInput = (values: InitialValues<true>, fieldConfigs?: TargetGroupFieldConfig[]) => {
  const unsetValues = unsetBookingRequestValues(
    {
      totalParticipants: values.totalParticipants,
      totalAccompanyingPersons: values.totalAccompanyingPersons,
      ageOfParticipants: values.ageOfParticipants,
      customer: {
        salutation: values.salutation,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        organization: {
          name: values.name,
          targetGroupId: values.targetGroupId,
          address: values.address,
          buildingNumber: values.buildingNumber,
          city: values.city,
          uidNumber: values.uidNumber,
          zip: values.zip,
        },
      },
    } as BookingRequestInput,
    fieldConfigs,
  );

  return {
    startDate: getDateTimeFromInitialValues(values?.startDate, values?.startTime),
    endDate: getDateTimeFromInitialValues(values?.endDate, values?.endTime),
    productId: values.productId,
    guideId: values.guideId,
    targetGroupId: values.targetGroupId,
    infoForOffice: values?.infoForOffice,
    infoForGuides: values?.infoForGuides,
    ...unsetValues,
  } as EventAppointmentInput;
};

export const producePrivateTourInput = (values: InitialValues, fieldConfigs?: TargetGroupFieldConfig[]) => {
  const unsetValues = unsetBookingRequestValues(
    {
      totalParticipants: values.totalParticipants as number,
      totalAccompanyingPersons: values.totalAccompanyingPersons,
      ageOfParticipants: values.ageOfParticipants,
    } as BookingRequestInput,
    fieldConfigs,
  );
  return {
    startDate: getDateTimeFromInitialValues(values.startDate, values.startTime),
    endDate: getDateTimeFromInitialValues(values.endDate, values.endTime),
    infoForOffice: values.infoForOffice,
    guideId: values.guideId,
    productId: values.productId,
    infoForGuides: values?.infoForGuides,
    totalGuides: values?.totalGuides,
    ...unsetValues,
    // FIXME: Currently we want to always keep age of participants field
    ageOfParticipants: values.ageOfParticipants,
  } as BookingRequestAppointmentInput;
};

export const produceRecurringEventInput = (values: InitialValues) => {
  return {
    endDate: values?.endDate,
    endTimeString: values?.endTime ? format(values.endTime, TIME_FORMAT) : '',
    productId: values.productId,
    startDate: values.startDate,
    startTimeString: values?.startTime ? format(values.startTime, TIME_FORMAT) : '',
    totalSlots: values.totalSlots,
    weekDays: values.weekDays,
    infoForOffice: values?.infoForOffice,
    infoForGuides: values?.infoForGuides,
  } as RecurringEventInput;
};

export const produceCustomerInput = (
  values: DefaultUpdateCustomerInitialValues,
  formConfig: TargetGroupFieldConfig[] | undefined,
) => {
  const customerInput = unsetBookingRequestValues(
    {
      customer: {
        email: values.email,
        salutation: values.salutation,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        organization: {
          name: values.name,
          targetGroupId: values.targetGroupId,
          address: values.address,
          buildingNumber: values.buildingNumber,
          city: values.city,
          uidNumber: values.uidNumber,
          zip: values.zip,
        },
      },
    } as BookingRequestInput,
    formConfig,
  ).customer;
  return customerInput;
};
