import React from 'react';
import deleteAppointmentModal from 'pages/app/AppointmentsPage/deleteAppointment';
import appointmentModal from 'components/app/bookingRequestAndAppointment/modals/appointmentModal';
import { Pencil, Stickies, Trash, XCircle } from 'react-bootstrap-icons';
import IconButton from 'components/common/IconButton';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import { AppointmentStatus, AppointmentType } from 'graphql/types.generated';

const AppointmentTableActions = ({
  appointmentId,
  refetchQueries,
  bookingRequestId,
  type,
  status,
  bookingkitDateId,
}: {
  type: AppointmentType;
  appointmentId: string;
  refetchQueries: InternalRefetchQueryDescriptor[];
  bookingRequestId?: string;
  bookingkitDateId?: string;
  status: AppointmentStatus;
}) => {
  let shouldDisplayDuplicateButton = true;
  let shouldDisplayDeleteButton = true;
  if (type === AppointmentType.PRIVATE_TOUR) {
    if (status === AppointmentStatus.CANCELED) {
      shouldDisplayDuplicateButton = false;
    }
    if (status === AppointmentStatus.COMPLETED) {
      shouldDisplayDuplicateButton = false;
      shouldDisplayDeleteButton = false;
    }
  }
  const cancelInsteadOfDelete = status !== AppointmentStatus.CANCELED && status !== AppointmentStatus.NEW;
  const DeleteAppointmentIcon = cancelInsteadOfDelete ? XCircle : Trash;
  return (
    <div className="d-flex justify-content-end gap-1">
      {shouldDisplayDuplicateButton ? (
        <IconButton
          icon={<Stickies />}
          onClick={() => {
            appointmentModal({ appointmentId, bookingRequestId, isEdit: false }, refetchQueries);
          }}
        />
      ) : null}
      <IconButton
        icon={<Pencil />}
        onClick={() => {
          appointmentModal(
            { appointmentId, isEdit: true },
            type === AppointmentType.PRIVATE_TOUR ? refetchQueries : undefined,
          );
        }}
      />
      {shouldDisplayDeleteButton ? (
        <IconButton
          icon={<DeleteAppointmentIcon />}
          onClick={() =>
            deleteAppointmentModal({ appointmentId, refetchQueries, isCancel: cancelInsteadOfDelete, bookingkitDateId })
          }
        />
      ) : null}
    </div>
  );
};

export default AppointmentTableActions;
