import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import CardField from '../../components/CardField';
import { CustomerForBookingRequestViewFragment } from 'graphql/fragments/customer/generated/customerForBookingRequestViewFragment';
import { formatPhoneNumber } from 'utils/helpers';
import updateCustomerModal from 'components/app/bookingRequestAndAppointment/modals/updateCustomerModal';
import { BookingRequestViewDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestView';
import { BookingRequestAppointmentsDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestAppointments';

const CustomerCard = ({
  customer,
  bookingRequestId,
}: {
  customer?: CustomerForBookingRequestViewFragment;
  bookingRequestId: string;
}) => {
  const { t } = useTranslation();
  if (!customer) return null;
  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <span className="flex-1">{t('bookingRequestView.customer')}</span>
        <Pencil
          className="cursor-pointer"
          size="1em"
          onClick={() => {
            updateCustomerModal({
              customerId: customer.id,
              refetchQueries: [
                { query: BookingRequestViewDocument, variables: { id: bookingRequestId } },
                { query: BookingRequestAppointmentsDocument, variables: { id: bookingRequestId } },
              ],
            });
          }}
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={6}>
            <CardField label="bookingRequestView.fields.name.label">
              {[t('salutations.' + customer.salutation), customer.firstName, customer.lastName].join(' ')}
            </CardField>
            <CardField label="bookingRequestView.fields.email.label">{customer.email}</CardField>
            <CardField label="bookingRequestView.fields.phoneNumber.label">
              {customer.phoneNumber && formatPhoneNumber(customer.phoneNumber)}
            </CardField>
          </Col>
          <Col>
            <CardField
              fieldConfigName="customer.organization.name"
              label="bookingRequestView.fields.organizationName.label"
            >
              {customer.organization?.name}
            </CardField>
            <CardField fieldConfigName="customer.organization.address" label="bookingRequestView.fields.street.label">
              {customer.organization?.address}
            </CardField>
            <CardField
              fieldConfigName="customer.organization.buildingNumber"
              label="bookingRequestView.fields.buildingNumber.label"
            >
              {customer.organization?.buildingNumber}
            </CardField>
            <CardField fieldConfigName="customer.organization.zip" label="bookingRequestView.fields.zip.label">
              {customer.organization?.zip}
            </CardField>
            <CardField fieldConfigName="customer.organization.city" label="bookingRequestView.fields.city.label">
              {customer.organization?.city}
            </CardField>
            <CardField
              fieldConfigName="customer.organization.uidNumber"
              label="bookingRequestView.fields.uidNumber.label"
            >
              {customer.organization?.uidNumber}
            </CardField>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CustomerCard;
