import { AppointmentType, ProductType } from 'graphql/types.generated';
import {
  ProductsForUpdatingDocument,
  ProductsForUpdatingQueryVariables,
} from 'graphql/queries/product/generated/ProductsForUpdatingCache';

const getAppointmentTypeFromProductType = (type: ProductType) => {
  if (type === ProductType.BOOKING_SLOT) return AppointmentType.BOOKING_SLOT;
  if (type === ProductType.EVENT) return AppointmentType.EVENT;
  return AppointmentType.PRIVATE_TOUR;
};
export const getRefetchQueries = (type: ProductType) =>
  [type, undefined].map((type) => {
    const variables: ProductsForUpdatingQueryVariables | undefined = type
      ? { appointmentType: getAppointmentTypeFromProductType(type) }
      : undefined;
    return {
      query: ProductsForUpdatingDocument,
      variables,
    };
  });
