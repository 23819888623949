import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
import { TargetGroupFieldConfigFragmentDoc } from './targetGroupFieldConfigFragment';
export type TargetGroupForUpdatingAppointmentFragment = {
  __typename?: 'TargetGroup';
  code: string;
  id: string;
  name: { __typename?: 'Localized'; en: string; de: string };
  bookingFormConfig?: {
    __typename?: 'TargetGroupBookingFormConfig';
    fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
  } | null;
};

export type TargetGroupForUpdatingAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TargetGroupForUpdatingAppointmentFragmentDoc = gql`
  fragment TargetGroupForUpdatingAppointment on TargetGroup {
    code
    name {
      ...Localized
    }
    ...TargetGroupFieldConfig
  }
  ${LocalizedFragmentDoc}
  ${TargetGroupFieldConfigFragmentDoc}
`;
