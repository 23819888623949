import { useFormikContext } from 'formik';
import i18n from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTargetGroupOfferFromCache } from '../utils';
import RichEditorFieldWithRef from 'components/inputs/RichEditor';

const Offer = () => {
  const { t } = useTranslation();
  const {
    values: { targetGroupId },
  } = useFormikContext();

  const targetGroup = useTargetGroupOfferFromCache(targetGroupId);
  return (
    <div className="bg-white p-3 mb-3">
      <div>
        <strong>{t('bookingRequest.offerTitle')}</strong>
      </div>
      <RichEditorFieldWithRef
        initialValue={
          targetGroup?.bookingFormConfig?.offerDescription[
            i18n.language as keyof typeof targetGroup.bookingFormConfig.offerDescription
          ]
        }
        className="rich-editor-read-only"
        readOnly
      />
    </div>
  );
};

export default Offer;
