import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { ModifyUserFragmentDoc } from '../../../fragments/user/generated/modifyUserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditUserInfoMutationVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID'];
  input: SchemaTypes.EditUserInfoInput;
}>;

export type EditUserInfoMutation = {
  __typename?: 'Mutation';
  editUserInfo: {
    __typename?: 'User';
    id: string;
    role: SchemaTypes.Roles;
    email: string;
    profile: { __typename?: 'UserProfile'; fullName: string };
  };
};

export const EditUserInfoDocument = gql`
  mutation EditUserInfo($userId: ID!, $input: EditUserInfoInput!) {
    editUserInfo(userId: $userId, input: $input) {
      ...ModifyUserFragment
    }
  }
  ${ModifyUserFragmentDoc}
`;
export type EditUserInfoMutationFn = Apollo.MutationFunction<EditUserInfoMutation, EditUserInfoMutationVariables>;

/**
 * __useEditUserInfoMutation__
 *
 * To run a mutation, you first call `useEditUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserInfoMutation, { data, loading, error }] = useEditUserInfoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<EditUserInfoMutation, EditUserInfoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserInfoMutation, EditUserInfoMutationVariables>(EditUserInfoDocument, options);
}
export type EditUserInfoMutationHookResult = ReturnType<typeof useEditUserInfoMutation>;
export type EditUserInfoMutationResult = Apollo.MutationResult<EditUserInfoMutation>;
export type EditUserInfoMutationOptions = Apollo.BaseMutationOptions<
  EditUserInfoMutation,
  EditUserInfoMutationVariables
>;
