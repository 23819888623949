import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s from './Layout.module.sass';

type iAuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: FC<iAuthLayoutProps> = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col className={s.content}>{children}</Col>
      </Row>
    </Container>
  );
};

export default AuthLayout;
