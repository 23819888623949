import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import RichTextWithRef, { iRichEditorProps } from './RichEditor';
import { Form } from 'react-bootstrap';
import { Field, FieldProps } from 'formik';
import { Editor } from 'slate';
import { BLOCK_ELEMENT } from './constants';

export interface iRichEditorFormikProps extends iRichEditorProps {
  name?: string;
  label?: string;
  onChange?: (value: string) => void;
}

const RichEditorFormikWithRef = forwardRef<Editor, iRichEditorFormikProps>(function RichEditorFormik(
  { name, label, onChange, rootElement = BLOCK_ELEMENT.DIV, ...props },
  ref,
) {
  const { t } = useTranslation();
  if (name)
    return (
      <Form.Group>
        <Form.Label htmlFor={name}>{label ? t(label) : undefined}</Form.Label>
        <Field name={name}>
          {({ field, form }: FieldProps) => {
            return (
              <>
                <RichTextWithRef
                  ref={ref}
                  onChange={(contentState: string) => {
                    form.setFieldValue(name, contentState, true);
                  }}
                  initialValue={field.value}
                  rootElement={rootElement}
                  {...props}
                />
                <Form.Control.Feedback type="invalid" className="d-block min-height-3">
                  {/* {t(field.error)} */}
                </Form.Control.Feedback>
              </>
            );
          }}
        </Field>
      </Form.Group>
    );
  return <RichTextWithRef ref={ref} onChange={onChange} rootElement={rootElement} {...props} />;
});
export default RichEditorFormikWithRef;
