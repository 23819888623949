import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { CustomerForUpdatingFragmentDoc } from '../../../fragments/customer/generated/customerForUpdatingFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerForUpdatingQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type CustomerForUpdatingQuery = {
  __typename?: 'Query';
  customer: {
    __typename?: 'Customer';
    id: string;
    salutation: SchemaTypes.Salutation;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | null;
    organization: {
      __typename?: 'CustomerOrganization';
      id: string;
      name?: string | null;
      address?: string | null;
      buildingNumber?: string | null;
      zip?: string | null;
      city?: string | null;
      uidNumber?: string | null;
      targetGroup: { __typename?: 'TargetGroup'; id: string };
    };
  };
};

export const CustomerForUpdatingDocument = gql`
  query CustomerForUpdating($id: String!) {
    customer(id: $id) {
      ...CustomerForUpdating
    }
  }
  ${CustomerForUpdatingFragmentDoc}
`;

/**
 * __useCustomerForUpdatingQuery__
 *
 * To run a query within a React component, call `useCustomerForUpdatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerForUpdatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerForUpdatingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerForUpdatingQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerForUpdatingQuery, CustomerForUpdatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerForUpdatingQuery, CustomerForUpdatingQueryVariables>(
    CustomerForUpdatingDocument,
    options,
  );
}
export function useCustomerForUpdatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerForUpdatingQuery, CustomerForUpdatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerForUpdatingQuery, CustomerForUpdatingQueryVariables>(
    CustomerForUpdatingDocument,
    options,
  );
}
export type CustomerForUpdatingQueryHookResult = ReturnType<typeof useCustomerForUpdatingQuery>;
export type CustomerForUpdatingLazyQueryHookResult = ReturnType<typeof useCustomerForUpdatingLazyQuery>;
export type CustomerForUpdatingQueryResult = Apollo.QueryResult<
  CustomerForUpdatingQuery,
  CustomerForUpdatingQueryVariables
>;
export function refetchCustomerForUpdatingQuery(variables: CustomerForUpdatingQueryVariables) {
  return { query: CustomerForUpdatingDocument, variables: variables };
}
