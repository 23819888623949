import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomContainerProps, InputModalConfirm } from 'components/common/Modal/InputModal';
import * as yup from 'yup';
import { screenSizeConfig } from 'components/common/Visibility';
import client from 'graphql/apollo';
import { toastErrorMessage } from 'utils/helpers';
import { FormConfig, fieldConfigArrayToObject } from 'components/bookingRequest/utils';
import { BookingRequestForm, requestSchema } from 'components/app/bookingRequest';
import { useBookingRequestForUpdatingQuery } from 'graphql/queries/bookingRequest/generated/BookingRequestForUpdating';
import { merge } from 'lodash';
import { BookingRequestFormValues, defaultInitialValues, formatInput } from './utils';
import {
  UpdateBookingRequestDocument,
  UpdateBookingRequestMutation,
  UpdateBookingRequestMutationVariables,
} from 'graphql/mutations/bookingRequest/generated/UpdateBookingRequest';
import { toast } from 'react-toastify';
import { BookingRequestViewDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestView';
import { BookingRequestInput } from 'graphql/types.generated';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import Loading from 'components/common/loading/Loading';

const editRequestValidationSchema = (props: { formConfig: FormConfig; isEdit?: boolean }) =>
  yup.object().shape({
    ...requestSchema(props),
  });

const CustomContainer: (props: { bookingRequestId: string }) => CustomContainerProps<BookingRequestFormValues> = ({
  bookingRequestId,
}) =>
  function BookingRequestContainer({ Component, Modal }) {
    const { t } = useTranslation();
    const {
      data: { bookingRequest } = {},
      loading,
      error,
    } = useBookingRequestForUpdatingQuery({
      variables: { id: bookingRequestId },
      onError: toastErrorMessage,
    });

    const { fieldConfig, formConfig } = useMemo(() => {
      const fieldConfig = bookingRequest?.customer.organization.targetGroup?.bookingFormConfig?.fieldConfig;
      return { fieldConfig, formConfig: fieldConfigArrayToObject(fieldConfig) };
    }, [bookingRequest]);
    const modalProps = useMemo(
      () => ({
        headerText: t('bookingRequestView.updateBookingRequestModal'),
        width: screenSizeConfig.xl,
      }),
      [t],
    );
    if (error || (!bookingRequest && loading) || !bookingRequest)
      return (
        <Modal {...modalProps}>
          {[
            error && <AlertGraphQLError error={error} />,
            !bookingRequest && loading && <Loading position="center" size={60} />,
            !bookingRequest && !loading && <span>{t('errors.bookingRequestNotFound')}</span>,
          ].filter(Boolean)}
        </Modal>
      );
    return (
      <Component
        {...modalProps}
        initialValues={merge({}, defaultInitialValues, {
          tourTopicId: bookingRequest.tourTopicId,
          dateTime: bookingRequest.date,
          altDateTime: bookingRequest.altDate,
          totalParticipants: bookingRequest.totalParticipants,
          totalAccompanyingPersons: bookingRequest.totalAccompanyingPersons,
          ageOfParticipants: bookingRequest.ageOfParticipants,
          language: bookingRequest.language,
          howDidYouHear: bookingRequest.howDidYouHear,
        })}
        formikContent={() => <BookingRequestForm formConfig={formConfig} isEdit />}
        onSubmit={async (values) => {
          await client.mutate<UpdateBookingRequestMutation, UpdateBookingRequestMutationVariables>({
            mutation: UpdateBookingRequestDocument,
            variables: {
              id: bookingRequestId,
              data: formatInput(values, fieldConfig) as BookingRequestInput,
            },
            refetchQueries: [{ query: BookingRequestViewDocument, variables: { id: bookingRequestId } }],
          });
          toast.success(t('bookingRequestView.updateBookingRequestSuccess'));
        }}
        validationSchema={() => editRequestValidationSchema({ formConfig, isEdit: true })}
      />
    );
  };

const updateBookingRequestModal = ({ bookingRequestId }: { bookingRequestId: string }) => {
  InputModalConfirm<BookingRequestFormValues>({
    CustomContainer: CustomContainer({ bookingRequestId }),
  });
};

export default updateBookingRequestModal;
