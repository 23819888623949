import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldInputProps, FormikProps } from 'formik';
import { get, isNull } from 'lodash';
import InputGroup from 'react-bootstrap/InputGroup';
import cn from 'classnames';
import s from './TextInput.module.sass';
import { useTranslation } from 'react-i18next';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { iRequired, Required } from 'components/common/Required';
import FormDescription, { iFormDescription } from '../FormDescription/FormDescription';

export interface iTextInput {
  label?: string;
  startIcon?: React.ReactElement;
  disabled?: boolean;
  placeholder?: string;
  addon?: React.ReactElement;
  inputClassName?: string;
  name?: string;
}

interface iTextFormikInput extends iTextInput, iRequired, iFormDescription {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  type?: string;
}

const TextInput: FC<iTextFormikInput> = ({
  field,
  form,
  type = 'text',
  label,
  startIcon,
  placeholder,
  addon,
  inputClassName,
  disabled,
  isRequired,
  description,
}) => {
  const { name, onBlur, value } = field;
  const { errors, touched, isSubmitting } = form;
  const { t } = useTranslation();

  const isDisabled = disabled || isSubmitting;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(name, e.target.value);
    },
    [form, name],
  );

  useEffect(() => {
    if (isNull(value)) form.setFieldValue(name, '');
  }, [form, name, value]);

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group className="w-100">
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {label && t(label)}
      </Form.Label>
      <div className="d-flex position-relative">
        {startIcon ? (
          <InputGroup.Text id={name} className={s.startBoxRightAngles}>
            {startIcon}
          </InputGroup.Text>
        ) : null}
        <InputGroup>
          <Form.Control
            {...{ type, name, onBlur }}
            disabled={isDisabled}
            value={value ?? ''}
            placeholder={placeholder && t(placeholder)}
            readOnly={isDisabled}
            onChange={handleChange}
            className={cn(
              inputClassName ? inputClassName : '',
              startIcon ? s.inputLeftAngles : '',
              isDisabled ? 'disabled-input' : '',
            )}
          />
          {addon ? addon : null}
        </InputGroup>
      </div>
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const PasswordInput: FC<iTextFormikInput> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isSubmitting } = props.form;
  const onToggle = useCallback(() => {
    isSubmitting || setIsVisible(!isVisible);
  }, [isSubmitting, isVisible]);

  const Icon = isVisible ? Eye : EyeSlash;

  return (
    <div>
      <TextInput
        {...props}
        type={isVisible ? 'text' : 'password'}
        inputClassName={s.passwordInput}
        addon={<Icon onClick={onToggle} className={cn(s.eye, isSubmitting ? 'disabled-input' : '')} />}
      />
    </div>
  );
};

const TextInputContainer: FC<iTextFormikInput> = (props) => {
  const Input = props.type === 'password' ? PasswordInput : TextInput;
  return <Input {...props} />;
};

export default TextInputContainer;
