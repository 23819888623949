import React, { useCallback, useMemo } from 'react';
import RDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';
import { COMPARE_DATE_FORMAT, COMPARE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import { format, set } from 'date-fns';
import { useTranslation } from 'react-i18next';

const setDateToDate = (targetDate: Date, sourceDate: Date) =>
  set(targetDate, {
    year: sourceDate.getFullYear(),
    month: sourceDate.getMonth(),
    date: sourceDate.getDate(),
  });

const setTimeToDate = (targetDate: Date, sourceDate: Date) =>
  set(targetDate, {
    hours: sourceDate.getHours(),
    minutes: sourceDate.getMinutes(),
    seconds: sourceDate.getSeconds(),
    milliseconds: sourceDate.getMilliseconds(),
  });

const DatePickerBase = (
  {
    dateFormat = DATE_FORMAT,
    timeFormat = TIME_FORMAT,
    timeIntervals = 15,
    calendarStartDay = 1,
    autoComplete = 'off',
    className,
    onChange,
    ...props
  }: ReactDatePickerProps,
  ref: React.LegacyRef<RDatePicker> | null,
) => {
  const { minDate, minTime, maxDate, maxTime } = props;
  const { i18n } = useTranslation();
  const [minDateFormat, minTimeFormat, maxDateFormat, maxTimeFormat] = useMemo(
    () => [
      minDate && format(minDate, COMPARE_DATE_FORMAT),
      minTime && format(minTime, COMPARE_TIME_FORMAT),
      maxDate && format(maxDate, COMPARE_DATE_FORMAT),
      maxTime && format(maxTime, COMPARE_TIME_FORMAT),
    ],
    [maxDate, maxTime, minDate, minTime],
  );

  const handleChange = useCallback(
    (date: Date | null, event: React.SyntheticEvent | undefined) => {
      let newDate = date;
      if (newDate) {
        const dateFormat = format(newDate, COMPARE_DATE_FORMAT);
        const timeFormat = format(newDate, COMPARE_TIME_FORMAT);
        if (minDate && minDateFormat && dateFormat < minDateFormat) newDate = setDateToDate(newDate, minDate);
        if (minTime && minTimeFormat && timeFormat < minTimeFormat) newDate = setTimeToDate(newDate, minTime);
        if (maxDate && maxDateFormat && dateFormat > maxDateFormat) newDate = setDateToDate(newDate, maxDate);
        if (maxTime && maxTimeFormat && timeFormat > maxTimeFormat) newDate = setTimeToDate(newDate, maxTime);
      }
      onChange(newDate, event);
    },
    [maxDate, maxDateFormat, maxTime, maxTimeFormat, minDate, minDateFormat, minTime, minTimeFormat, onChange],
  );

  return (
    <RDatePicker
      ref={ref}
      {...{ dateFormat, timeFormat, calendarStartDay, autoComplete, timeIntervals, locale: i18n.language, ...props }}
      onChange={handleChange}
      className={cn(className, 'form-control')}
    />
  );
};

export default React.forwardRef(DatePickerBase);
