import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingRequestFragmentDoc } from '../../../fragments/bookingRequest/generated/bookingRequestFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingRequestsQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int'];
  skip: SchemaTypes.Scalars['Int'];
  sort?: SchemaTypes.InputMaybe<SchemaTypes.BookingRequestSort>;
  statuses?: SchemaTypes.InputMaybe<Array<SchemaTypes.BookingRequestStatus>>;
}>;

export type BookingRequestsQuery = {
  __typename?: 'Query';
  bookingRequests: {
    __typename?: 'PaginatedBookingRequestResponse';
    total: number;
    items: Array<{
      __typename?: 'BookingRequest';
      id: string;
      code?: string | null;
      date: string | Date;
      language: SchemaTypes.TourLanguage;
      status: SchemaTypes.BookingRequestStatus;
      totalAccompanyingPersons?: number | null;
      totalParticipants: number;
      totalAppointments: number;
      totalEmailMessages: number;
      lastEmail?: { __typename?: 'EmailMessage'; id: string; createdAt: string | Date } | null;
      customer: {
        __typename?: 'Customer';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        salutation: SchemaTypes.Salutation;
        organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
      };
      tourTopic: { __typename?: 'TourTopic'; id: string; backgroundColor: string };
    }>;
  };
};

export const BookingRequestsDocument = gql`
  query BookingRequests($limit: Int!, $skip: Int!, $sort: BookingRequestSort, $statuses: [BookingRequestStatus!]) {
    bookingRequests(limit: $limit, skip: $skip, sort: $sort, statuses: $statuses) {
      items {
        ...BookingRequest
      }
      total
    }
  }
  ${BookingRequestFragmentDoc}
`;

/**
 * __useBookingRequestsQuery__
 *
 * To run a query within a React component, call `useBookingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useBookingRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingRequestsQuery, BookingRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingRequestsQuery, BookingRequestsQueryVariables>(BookingRequestsDocument, options);
}
export function useBookingRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestsQuery, BookingRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingRequestsQuery, BookingRequestsQueryVariables>(BookingRequestsDocument, options);
}
export type BookingRequestsQueryHookResult = ReturnType<typeof useBookingRequestsQuery>;
export type BookingRequestsLazyQueryHookResult = ReturnType<typeof useBookingRequestsLazyQuery>;
export type BookingRequestsQueryResult = Apollo.QueryResult<BookingRequestsQuery, BookingRequestsQueryVariables>;
export function refetchBookingRequestsQuery(variables: BookingRequestsQueryVariables) {
  return { query: BookingRequestsDocument, variables: variables };
}
