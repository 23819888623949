import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from 'components/layouts/AppLayout';
import ScrollToTop from 'components/common/ScrollToTop';
import routePaths from './route-paths';
import Logout from 'pages/app/Logout';
import LoadingOverlay from 'components/common/loading/LoadingOverlay';
import useUser from 'hooks/user/useUser';
import { Roles } from 'graphql/types.generated';
import AppointmentsPage from 'pages/app/AppointmentsPage';
import UserManagementPage from 'pages/app/UserManagementPage';
import UserProfilePage from 'pages/app/UserProfilePage';
import EmailTemplatesPage from 'pages/app/EmailTemplatesPage';
import SystemConfigPage from 'pages/app/SystemConfigPage';
import BookingRequestViewPage from 'pages/app/BookingRequestViewPage';
import BookingRequestsPage from 'pages/app/BookingRequestsPage';

const routes = [
  {
    path: routePaths.profile,
    component: () => <UserProfilePage />,
  },
  {
    path: routePaths.appointments,
    component: () => <AppointmentsPage />,
    accessRole: Roles.ADMIN,
  },
  {
    path: routePaths.bookingRequests,
    component: () => <BookingRequestsPage />,
    accessRole: Roles.ADMIN,
  },
  {
    path: routePaths.bookingRequest,
    component: () => <BookingRequestViewPage />,
    accessRole: Roles.ADMIN,
  },
  {
    path: routePaths.emailTemplates,
    component: () => <EmailTemplatesPage />,
    accessRole: Roles.ADMIN,
  },
  {
    path: routePaths.userManagement,
    component: () => <UserManagementPage />,
    accessRole: Roles.ADMIN,
  },
  {
    path: routePaths.systemConfig,
    component: () => <SystemConfigPage />,
    accessRole: Roles.ADMIN,
  },
  {
    path: routePaths.logout,
    component: Logout,
  },
];

const AppRouter = () => {
  const { me, loading } = useUser();

  if (loading) return <LoadingOverlay />;

  return (
    <AppLayout>
      <ScrollToTop />

      <Routes>
        {routes.map(({ path, component: C, accessRole }) => {
          if (accessRole && me?.role !== accessRole) return null;
          return <Route key={path} path={path} element={<C />} />;
        })}

        <Route
          path="*"
          element={<Navigate to={me?.role === Roles.ADMIN ? routePaths.appointments : routePaths.profile} />}
        />
      </Routes>
    </AppLayout>
  );
};

export default AppRouter;
