import Button from 'components/common/Button';
import { usePushAppointmentToBookingkitMutation } from 'graphql/mutations/appointment/generated/PushAppointmentToBookingkit';
import React from 'react';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';

const PushAppointmentButton: FC<{ appointmentId: string }> = ({ appointmentId }) => {
  const { t } = useTranslation();

  const [pushAppointmentToBookingkitMutation, { loading }] = usePushAppointmentToBookingkitMutation({
    variables: { appointmentId },
    onCompleted: () => {
      toast.success(t('appointments.bookingkitCell.pushedSuccessfullyNotification'));
    },
    onError: (error) => {
      toast.error(formatGraphqlErrors(error).message);
    },
  });

  return (
    <Button
      size="sm"
      variant="link"
      loading={loading}
      disabled={loading}
      onClick={() => pushAppointmentToBookingkitMutation()}
    >
      <small>
        {t('appointments.bookingkitCell.manualPushButton')} <ArrowRepeat />
      </small>
    </Button>
  );
};

export default PushAppointmentButton;
