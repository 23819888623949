import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TourTopicForBookingRequestFormFragmentDoc } from '../../../fragments/tourTopic/generated/tourTopicForBookingRequestFormFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TourTopicsForBookingRequestFormQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TourTopicsForBookingRequestFormQuery = {
  __typename?: 'Query';
  tourTopics: Array<{
    __typename?: 'TourTopic';
    id: string;
    iconUrl: string;
    name: { __typename?: 'Localized'; en: string; de: string };
  }>;
};

export const TourTopicsForBookingRequestFormDocument = gql`
  query TourTopicsForBookingRequestForm {
    tourTopics {
      ...TourTopicForBookingRequestForm
    }
  }
  ${TourTopicForBookingRequestFormFragmentDoc}
`;

/**
 * __useTourTopicsForBookingRequestFormQuery__
 *
 * To run a query within a React component, call `useTourTopicsForBookingRequestFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourTopicsForBookingRequestFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourTopicsForBookingRequestFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useTourTopicsForBookingRequestFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TourTopicsForBookingRequestFormQuery,
    TourTopicsForBookingRequestFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TourTopicsForBookingRequestFormQuery, TourTopicsForBookingRequestFormQueryVariables>(
    TourTopicsForBookingRequestFormDocument,
    options,
  );
}
export function useTourTopicsForBookingRequestFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TourTopicsForBookingRequestFormQuery,
    TourTopicsForBookingRequestFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TourTopicsForBookingRequestFormQuery, TourTopicsForBookingRequestFormQueryVariables>(
    TourTopicsForBookingRequestFormDocument,
    options,
  );
}
export type TourTopicsForBookingRequestFormQueryHookResult = ReturnType<typeof useTourTopicsForBookingRequestFormQuery>;
export type TourTopicsForBookingRequestFormLazyQueryHookResult = ReturnType<
  typeof useTourTopicsForBookingRequestFormLazyQuery
>;
export type TourTopicsForBookingRequestFormQueryResult = Apollo.QueryResult<
  TourTopicsForBookingRequestFormQuery,
  TourTopicsForBookingRequestFormQueryVariables
>;
export function refetchTourTopicsForBookingRequestFormQuery(variables?: TourTopicsForBookingRequestFormQueryVariables) {
  return { query: TourTopicsForBookingRequestFormDocument, variables: variables };
}
