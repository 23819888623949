import React from 'react';
import { InputModalConfirm } from 'components/common/Modal/InputModal';
import i18n from 'i18n';
import { GuideSelect } from 'components/app/appointment/AppointmentForm';
import { screenSizeConfig } from 'components/common/Visibility';
import { toast } from 'react-toastify';
import client from 'graphql/apollo';
import {
  UpdateAppointmentGuideDocument,
  UpdateAppointmentGuideMutation,
  UpdateAppointmentGuideMutationVariables,
} from 'graphql/mutations/appointment/generated/UpdateAppointmentGuide';

const appointmentGuideModal = async ({ id, guideId }: { id: string; guideId?: string }) => {
  InputModalConfirm({
    headerText: i18n.t('appointments.appointmentGuideModal'),
    initialValues: { guideId },
    onSubmit: async ({ guideId }) => {
      await client.mutate<UpdateAppointmentGuideMutation, UpdateAppointmentGuideMutationVariables>({
        mutation: UpdateAppointmentGuideDocument,
        variables: { id, guideId },
      });
      toast.success(i18n.t('appointments.updateAppointmentGuideSuccess'));
    },
    formikContent: () => <GuideSelect />,
    width: screenSizeConfig.md,
  });
};
export default appointmentGuideModal;
