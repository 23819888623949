import ConfirmModal, { iConfirmModalProps } from 'components/common/Modal/ConfirmModal';
import constants from 'constants/constants';
import client from 'graphql/apollo';
import { CreateUserDocument } from 'graphql/mutations/user/generated/CreateUser';
import { DeleteUserDocument } from 'graphql/mutations/user/generated/DeleteUser';
import { EditUserInfoDocument } from 'graphql/mutations/user/generated/EditUserInfo';
import { UsersDocument, UsersQueryVariables } from 'graphql/queries/user/generated/Users';
import { Roles } from 'graphql/types.generated';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { UserModalProps } from './UserManagementPage';

const getRefetchQueries = (roles: Roles[], skip = 0) =>
  roles.map((role) => ({
    query: UsersDocument,
    variables: {
      limit: constants.countTableRowsPerPage,
      skip: skip,
      filter: { role },
    } as UsersQueryVariables,
  }));

export const updateUser = async ({
  user,
  values,
  skip,
}: {
  user: { id: string; role: Roles };
  values: UserModalProps;
  skip: number;
}) => {
  await client.mutate({
    mutation: EditUserInfoDocument,
    variables: {
      userId: user.id,
      input: values,
    },
    refetchQueries: user.role === values.role ? [] : getRefetchQueries([user.role, values.role as Roles], skip),
  });
  toast.success(i18n.t('userManagement.updateUserSuccess'));
};

export const createUser = async ({ values }: { values: UserModalProps }) => {
  await client.mutate({
    mutation: CreateUserDocument,
    variables: {
      input: values,
    },
    refetchQueries: getRefetchQueries([values.role as Roles]),
  });
  toast.success(i18n.t('userManagement.createUserSuccess'));
};

export const deleteUser = async ({ _id, role, skip }: { _id: string; role: Roles; skip?: number }) =>
  ConfirmModal({
    headerText: i18n.t('userManagement.deleteConfirmation.title'),
    description: i18n.t('userManagement.deleteConfirmation.description'),
    onOk: async () => {
      await client.mutate({
        mutation: DeleteUserDocument,
        variables: {
          userId: _id,
        },
        refetchQueries: getRefetchQueries([role], skip),
      });
      toast.success(i18n.t('userManagement.deleteConfirmation.success'));
    },
  } as iConfirmModalProps);
