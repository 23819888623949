import RadioGroup from 'components/inputs/RadioGroup';
import React from 'react';
import { ToggleButton } from 'react-bootstrap';
import { iRadiosInputProps, iRadioOptionRender } from 'components/inputs/RadioGroup/RadioGroup';
import s from './RadioButtonGroup.module.sass';
import cn from 'classnames';
import checkedMark from 'assets/icons/checked-mark.svg';
import FastField from 'components/common/FastField';

const renderOption: FC<iRadioOptionRender> = ({ formikValue, label, name, value, handleChange, buttonClassName }) => {
  const checked = formikValue === value;
  return (
    <ToggleButton
      variant="light"
      className={cn(s.button, buttonClassName, checked ? s.activeButton : '', 'p-3 bg-white')}
      id={`${name}-${value}`}
      name={name}
      type="radio"
      value={value}
      onChange={handleChange}
      checked={formikValue === value}
    >
      {checked ? <img className={s.mark} src={checkedMark} /> : null}
      {label}
    </ToggleButton>
  );
};

export interface RadioButtonGroupProps extends Omit<iRadiosInputProps, 'field' | 'form'> {
  name: string;
  buttonClassName?: string;
}

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({ buttonClassName, ...props }) => {
  return (
    <FastField
      component={(props: iRadiosInputProps) => (
        <RadioGroup
          {...props}
          container={({ children }) => <div className={s.container}>{children}</div>}
          renderOption={(props) => renderOption({ ...props, buttonClassName })}
        />
      )}
      {...props}
    />
  );
};

export default RadioButtonGroup;
