import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { ProductForUpdatingAppointmentFragmentDoc } from '../../product/generated/productForUpdatingAppointmentFragment';
import { CustomerForUpdatingAppointmentFragmentDoc } from '../../customer/generated/customerForUpdatingAppointmentFragment';
import { TargetGroupForUpdatingAppointmentFragmentDoc } from '../../targetGroup/generated/targetGroupForUpdatingAppointmentFragment';
export type UpdateAppointmentFragment = {
  __typename?: 'Appointment';
  id: string;
  ageOfParticipants?: string | null;
  code?: string | null;
  endDate: string | Date;
  infoForGuides?: string | null;
  infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
  language?: SchemaTypes.TourLanguage | null;
  startDate: string | Date;
  totalAccompanyingPersons?: number | null;
  totalParticipants?: number | null;
  totalSlots: number;
  totalGuides?: number | null;
  type: SchemaTypes.AppointmentType;
  bookingkitConnection?: { __typename?: 'AppointmentBookingkitConnection'; dateId?: string | null } | null;
  guide?: { __typename?: 'User'; id: string } | null;
  bookingRequest?: { __typename?: 'BookingRequest'; id: string } | null;
  product?: {
    __typename?: 'Product';
    id: string;
    name: string;
    language?: SchemaTypes.TourLanguage | null;
    meetingPointName?: string | null;
    tourTopic?: {
      __typename?: 'TourTopic';
      id: string;
      code: string;
      name: { __typename?: 'Localized'; en: string; de: string };
    } | null;
  } | null;
  customer?: {
    __typename?: 'Customer';
    id: string;
    salutation: SchemaTypes.Salutation;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | null;
    organization: {
      __typename?: 'CustomerOrganization';
      id: string;
      name?: string | null;
      address?: string | null;
      buildingNumber?: string | null;
      zip?: string | null;
      city?: string | null;
      uidNumber?: string | null;
    };
  } | null;
  targetGroup?: {
    __typename?: 'TargetGroup';
    code: string;
    id: string;
    name: { __typename?: 'Localized'; en: string; de: string };
    bookingFormConfig?: {
      __typename?: 'TargetGroupBookingFormConfig';
      fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
    } | null;
  } | null;
};

export type UpdateAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const UpdateAppointmentFragmentDoc = gql`
  fragment UpdateAppointment on Appointment {
    id
    ageOfParticipants
    code
    endDate
    infoForGuides
    infoForOffice
    language
    startDate
    totalAccompanyingPersons
    totalParticipants
    totalSlots
    totalGuides
    type
    bookingkitConnection {
      dateId
    }
    guide {
      id
    }
    bookingRequest {
      id
    }
    product {
      ...ProductForUpdatingAppointment
    }
    customer {
      ...CustomerForUpdatingAppointment
    }
    targetGroup {
      ...TargetGroupForUpdatingAppointment
    }
  }
  ${ProductForUpdatingAppointmentFragmentDoc}
  ${CustomerForUpdatingAppointmentFragmentDoc}
  ${TargetGroupForUpdatingAppointmentFragmentDoc}
`;
