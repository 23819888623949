import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupForUpdatingAppointmentFragmentDoc } from '../../../fragments/targetGroup/generated/targetGroupForUpdatingAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables = SchemaTypes.Exact<{
  [key: string]: never;
}>;

export type PublicTourDefaultTargetGroupForUpdatingAppointmentQuery = {
  __typename?: 'Query';
  publicTourDefaultTargetGroup: {
    __typename?: 'TargetGroup';
    code: string;
    id: string;
    name: { __typename?: 'Localized'; en: string; de: string };
    bookingFormConfig?: {
      __typename?: 'TargetGroupBookingFormConfig';
      fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
    } | null;
  };
};

export const PublicTourDefaultTargetGroupForUpdatingAppointmentDocument = gql`
  query PublicTourDefaultTargetGroupForUpdatingAppointment {
    publicTourDefaultTargetGroup {
      ...TargetGroupForUpdatingAppointment
    }
  }
  ${TargetGroupForUpdatingAppointmentFragmentDoc}
`;

/**
 * __usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery__
 *
 * To run a query within a React component, call `usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublicTourDefaultTargetGroupForUpdatingAppointmentQuery,
    PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicTourDefaultTargetGroupForUpdatingAppointmentQuery,
    PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables
  >(PublicTourDefaultTargetGroupForUpdatingAppointmentDocument, options);
}
export function usePublicTourDefaultTargetGroupForUpdatingAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicTourDefaultTargetGroupForUpdatingAppointmentQuery,
    PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicTourDefaultTargetGroupForUpdatingAppointmentQuery,
    PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables
  >(PublicTourDefaultTargetGroupForUpdatingAppointmentDocument, options);
}
export type PublicTourDefaultTargetGroupForUpdatingAppointmentQueryHookResult = ReturnType<
  typeof usePublicTourDefaultTargetGroupForUpdatingAppointmentQuery
>;
export type PublicTourDefaultTargetGroupForUpdatingAppointmentLazyQueryHookResult = ReturnType<
  typeof usePublicTourDefaultTargetGroupForUpdatingAppointmentLazyQuery
>;
export type PublicTourDefaultTargetGroupForUpdatingAppointmentQueryResult = Apollo.QueryResult<
  PublicTourDefaultTargetGroupForUpdatingAppointmentQuery,
  PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables
>;
export function refetchPublicTourDefaultTargetGroupForUpdatingAppointmentQuery(
  variables?: PublicTourDefaultTargetGroupForUpdatingAppointmentQueryVariables,
) {
  return { query: PublicTourDefaultTargetGroupForUpdatingAppointmentDocument, variables: variables };
}
