import React, { useMemo } from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';
import Select from 'components/inputs/Select';
import { DateTimeRangePicker } from 'components/inputs/DatePicker';
import i18n from 'i18n';
import { useCodesQuery } from 'graphql/queries/common/generated/Codes';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { formatDate } from 'utils/helpers';
import { DATE_FORMAT } from 'constants/date';
import locale from 'yup/es/locale';
import { MessageParams } from 'yup/es/types';
import Switch from 'components/inputs/Switch';
import { Values } from './blockedDateModal';
import { disabledCodesToJSON } from './utils';

export const blockedDateValidationSchema = () =>
  yup.object().shape({
    startDate: yup
      .date()
      .when(['endDate'], {
        is: (e: Values['endDate']) => e,
        then: (schema) =>
          schema.max(yup.ref('endDate'), ({ max, ...props }: { max: string | Date } & MessageParams) =>
            yup.ValidationError.formatError(locale.date.max, { max: formatDate(max, DATE_FORMAT), ...props }),
          ),
      })
      .nullable()
      .required()
      .label(i18n.t('systemConfig.fields.blockedDateRange.startName')),
    endDate: yup.date().nullable().required().label(i18n.t('systemConfig.fields.blockedDateRange.endName')),
    disabledCodes: yup
      .array()
      .of(yup.string())
      .nullable()
      .when(['isAllCodes'], {
        is: (e: Values['isAllCodes']) => !e,
        then: (schema) => schema.min(1),
      })
      .label(i18n.t('systemConfig.fields.disabledCodes.label')),
  });

const CodeSelect = () => {
  const {
    values: { isAllCodes },
  } = useFormikContext<Values>();
  const { data, loading, error } = useCodesQuery();
  const codeOptions = useMemo(() => {
    if (!data) return [];
    return data.tourTopics
      .map((tourTopic) =>
        data.targetGroups.map((targetGroup) => ({
          value: disabledCodesToJSON({ tourTopic, targetGroup }),
          label: [tourTopic.code, targetGroup.code].join(''),
        })),
      )
      .flat();
  }, [data]);

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Field
      disabled={(!data && loading) || isAllCodes}
      loading={loading}
      label="systemConfig.fields.disabledCodes.label"
      placeholder="systemConfig.fields.disabledCodes.placeholder"
      name="disabledCodes"
      component={Select}
      options={codeOptions}
      isClearable
      isRequired
      isMulti
    />
  );
};

const BlockedDateForm = () => {
  return (
    <>
      <DateTimeRangePicker
        isRequired
        label="systemConfig.fields.blockedDateRange.label"
        startPlaceholder="systemConfig.fields.blockedDateRange.startPlaceholder"
        endPlaceholder="systemConfig.fields.blockedDateRange.endPlaceholder"
        endName="endDate"
        startName="startDate"
      />
      <Field label="systemConfig.fields.isAllCodes.label" name="isAllCodes" component={Switch} isRequired />
      <CodeSelect />
    </>
  );
};

export default BlockedDateForm;
