import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type CustomerForUpdatingFragment = {
  __typename?: 'Customer';
  id: string;
  salutation: SchemaTypes.Salutation;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  organization: {
    __typename?: 'CustomerOrganization';
    id: string;
    name?: string | null;
    address?: string | null;
    buildingNumber?: string | null;
    zip?: string | null;
    city?: string | null;
    uidNumber?: string | null;
    targetGroup: { __typename?: 'TargetGroup'; id: string };
  };
};

export type CustomerForUpdatingFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CustomerForUpdatingFragmentDoc = gql`
  fragment CustomerForUpdating on Customer {
    id
    salutation
    firstName
    lastName
    email
    phoneNumber
    organization {
      id
      targetGroup {
        id
      }
      name
      address
      buildingNumber
      zip
      city
      uidNumber
    }
  }
`;
