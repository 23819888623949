import { useChangeUserAccountActivationStatusMutation } from 'graphql/mutations/user/generated/ChangeUserAccountActivationStatus';
import client from 'graphql/apollo';
import { UserManagementFragmentDoc } from 'graphql/fragments/user/generated/userManagementFragment';

const useChangeUserActivationStatus = () => {
  const data = useChangeUserAccountActivationStatusMutation({
    update: (cache, { data }, { variables: { userId } = {} }) => {
      if (data?.changeUserAccountActivationStatus)
        client.cache.updateFragment(
          {
            id: 'User:' + userId,
            fragment: UserManagementFragmentDoc,
          },
          (data) => ({ ...data, deactivated: !data.deactivated }),
        );
    },
  });
  return data;
};

export default useChangeUserActivationStatus;
