import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendBookingRequestEmailTemplateMutationVariables = SchemaTypes.Exact<{
  test: SchemaTypes.Scalars['Boolean'];
  bookingRequestId: SchemaTypes.Scalars['ID'];
  emailTemplate: SchemaTypes.SendEmailTemplateInput;
}>;

export type SendBookingRequestEmailTemplateMutation = {
  __typename?: 'Mutation';
  sendBookingRequestEmailTemplate?: boolean | null;
};

export const SendBookingRequestEmailTemplateDocument = gql`
  mutation SendBookingRequestEmailTemplate(
    $test: Boolean!
    $bookingRequestId: ID!
    $emailTemplate: SendEmailTemplateInput!
  ) {
    sendBookingRequestEmailTemplate(test: $test, bookingRequestId: $bookingRequestId, emailTemplate: $emailTemplate)
  }
`;
export type SendBookingRequestEmailTemplateMutationFn = Apollo.MutationFunction<
  SendBookingRequestEmailTemplateMutation,
  SendBookingRequestEmailTemplateMutationVariables
>;

/**
 * __useSendBookingRequestEmailTemplateMutation__
 *
 * To run a mutation, you first call `useSendBookingRequestEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBookingRequestEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBookingRequestEmailTemplateMutation, { data, loading, error }] = useSendBookingRequestEmailTemplateMutation({
 *   variables: {
 *      test: // value for 'test'
 *      bookingRequestId: // value for 'bookingRequestId'
 *      emailTemplate: // value for 'emailTemplate'
 *   },
 * });
 */
export function useSendBookingRequestEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendBookingRequestEmailTemplateMutation,
    SendBookingRequestEmailTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendBookingRequestEmailTemplateMutation, SendBookingRequestEmailTemplateMutationVariables>(
    SendBookingRequestEmailTemplateDocument,
    options,
  );
}
export type SendBookingRequestEmailTemplateMutationHookResult = ReturnType<
  typeof useSendBookingRequestEmailTemplateMutation
>;
export type SendBookingRequestEmailTemplateMutationResult =
  Apollo.MutationResult<SendBookingRequestEmailTemplateMutation>;
export type SendBookingRequestEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  SendBookingRequestEmailTemplateMutation,
  SendBookingRequestEmailTemplateMutationVariables
>;
