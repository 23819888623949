import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupForSelectFragmentDoc } from '../../../fragments/targetGroup/generated/targetGroupForSelectFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TargetGroupsForSelectQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TargetGroupsForSelectQuery = {
  __typename?: 'Query';
  targetGroups: Array<{
    __typename?: 'TargetGroup';
    id: string;
    name: { __typename?: 'Localized'; en: string; de: string };
  }>;
};

export const TargetGroupsForSelectDocument = gql`
  query TargetGroupsForSelect {
    targetGroups {
      ...TargetGroupForSelect
    }
  }
  ${TargetGroupForSelectFragmentDoc}
`;

/**
 * __useTargetGroupsForSelectQuery__
 *
 * To run a query within a React component, call `useTargetGroupsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetGroupsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetGroupsForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useTargetGroupsForSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<TargetGroupsForSelectQuery, TargetGroupsForSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TargetGroupsForSelectQuery, TargetGroupsForSelectQueryVariables>(
    TargetGroupsForSelectDocument,
    options,
  );
}
export function useTargetGroupsForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TargetGroupsForSelectQuery, TargetGroupsForSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TargetGroupsForSelectQuery, TargetGroupsForSelectQueryVariables>(
    TargetGroupsForSelectDocument,
    options,
  );
}
export type TargetGroupsForSelectQueryHookResult = ReturnType<typeof useTargetGroupsForSelectQuery>;
export type TargetGroupsForSelectLazyQueryHookResult = ReturnType<typeof useTargetGroupsForSelectLazyQuery>;
export type TargetGroupsForSelectQueryResult = Apollo.QueryResult<
  TargetGroupsForSelectQuery,
  TargetGroupsForSelectQueryVariables
>;
export function refetchTargetGroupsForSelectQuery(variables?: TargetGroupsForSelectQueryVariables) {
  return { query: TargetGroupsForSelectDocument, variables: variables };
}
