import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type ProductForUpdatingAppointmentFragment = {
  __typename?: 'Product';
  id: string;
  name: string;
  language?: SchemaTypes.TourLanguage | null;
  meetingPointName?: string | null;
  tourTopic?: {
    __typename?: 'TourTopic';
    id: string;
    code: string;
    name: { __typename?: 'Localized'; en: string; de: string };
  } | null;
};

export type ProductForUpdatingAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const ProductForUpdatingAppointmentFragmentDoc = gql`
  fragment ProductForUpdatingAppointment on Product {
    id
    name
    language
    meetingPointName
    tourTopic {
      id
      code
      name {
        ...Localized
      }
    }
  }
  ${LocalizedFragmentDoc}
`;
