import { set } from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useGetSearchParams = (params?: string | string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!params) return { searchParams: Object.fromEntries([...searchParams]), setSearchParams };

  const selectedParams = useMemo(() => {
    if (typeof params === 'string') {
      return { [params]: searchParams.get(params) || undefined };
    }

    return params.reduce((acc, key) => {
      set(acc, key, searchParams.get(key) || undefined);
      return acc;
    }, {} as Record<string, string | undefined>);
  }, [params, searchParams]);

  return { searchParams: selectedParams, setSearchParams };
};

export default useGetSearchParams;
