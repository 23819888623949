import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BlockedDateFragment = {
  __typename?: 'BlockedDate';
  id: string;
  endDate: string | Date;
  startDate: string | Date;
};

export type BlockedDateFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BlockedDateFragmentDoc = gql`
  fragment BlockedDate on BlockedDate {
    id
    endDate
    startDate
  }
`;
