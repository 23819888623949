import React from 'react';
import CheckOrX from 'components/common/CheckOrX';
import { DATE_TIME_FORMAT } from 'constants/date';
import { format } from 'date-fns';
import { BookingkitConnectionForAppointmentFragment } from 'graphql/fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import { AppointmentType } from 'graphql/types.generated';

interface BookingkitSyncDebugInfoProps {
  bookingkitConnection: BookingkitConnectionForAppointmentFragment;
  appointmentType: AppointmentType;
}
const BookingkitSyncDebugInfo: FC<BookingkitSyncDebugInfoProps> = (props) => {
  const { bookingkitConnection, appointmentType } = props;

  return (
    <>
      <div>
        Date sync:
        <CheckOrX value={!!bookingkitConnection.dateSyncSuccess} />
      </div>
      <div>
        {bookingkitConnection.dateSyncedAt
          ? format(new Date(bookingkitConnection.dateSyncedAt), DATE_TIME_FORMAT)
          : null}
      </div>
      {appointmentType === AppointmentType.PRIVATE_TOUR || appointmentType === AppointmentType.EVENT ? (
        <>
          <div>
            Order sync:
            <CheckOrX value={!!bookingkitConnection?.orderSuccess} />
          </div>
          <div>
            {bookingkitConnection.orderSyncedAt
              ? format(new Date(bookingkitConnection.orderSyncedAt), DATE_TIME_FORMAT)
              : null}
          </div>
        </>
      ) : null}
      {bookingkitConnection.errorName || bookingkitConnection.errorMessage ? (
        <div>
          Error: {bookingkitConnection.errorName}: {bookingkitConnection.errorMessage}
        </div>
      ) : null}
    </>
  );
};

export default BookingkitSyncDebugInfo;
