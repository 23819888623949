import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { CustomerForBookingRequestFragmentDoc } from '../../customer/generated/customerForBookingRequestFragment';
import { TourTopicBackgroundColorFragmentDoc } from '../../tourTopic/generated/tourTopicBackgroundColorFragment';
export type BookingRequestFragment = {
  __typename?: 'BookingRequest';
  id: string;
  code?: string | null;
  date: string | Date;
  language: SchemaTypes.TourLanguage;
  status: SchemaTypes.BookingRequestStatus;
  totalAccompanyingPersons?: number | null;
  totalParticipants: number;
  totalAppointments: number;
  totalEmailMessages: number;
  lastEmail?: { __typename?: 'EmailMessage'; id: string; createdAt: string | Date } | null;
  customer: {
    __typename?: 'Customer';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    salutation: SchemaTypes.Salutation;
    organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
  };
  tourTopic: { __typename?: 'TourTopic'; id: string; backgroundColor: string };
};

export type BookingRequestFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingRequestFragmentDoc = gql`
  fragment BookingRequest on BookingRequest {
    id
    code
    date
    language
    status
    totalAccompanyingPersons
    totalParticipants
    totalAppointments
    totalEmailMessages
    lastEmail {
      id
      createdAt
    }
    customer {
      ...CustomerForBookingRequest
    }
    tourTopic {
      ...TourTopicBackgroundColor
    }
  }
  ${CustomerForBookingRequestFragmentDoc}
  ${TourTopicBackgroundColorFragmentDoc}
`;
