import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RunBookingRequestAutomationCriteriasMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;

export type RunBookingRequestAutomationCriteriasMutation = {
  __typename?: 'Mutation';
  runBookingRequestAutomationCriterias: {
    __typename?: 'BookingRequest';
    id: string;
    automationDetails?: Array<{
      __typename?: 'BookingRequestAutomationDetails';
      name: SchemaTypes.CriteriaType;
      details?: string | null;
      passed: boolean;
    }> | null;
  };
};

export const RunBookingRequestAutomationCriteriasDocument = gql`
  mutation RunBookingRequestAutomationCriterias($id: ID!) {
    runBookingRequestAutomationCriterias(id: $id) {
      id
      automationDetails {
        name
        details
        passed
      }
    }
  }
`;
export type RunBookingRequestAutomationCriteriasMutationFn = Apollo.MutationFunction<
  RunBookingRequestAutomationCriteriasMutation,
  RunBookingRequestAutomationCriteriasMutationVariables
>;

/**
 * __useRunBookingRequestAutomationCriteriasMutation__
 *
 * To run a mutation, you first call `useRunBookingRequestAutomationCriteriasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunBookingRequestAutomationCriteriasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runBookingRequestAutomationCriteriasMutation, { data, loading, error }] = useRunBookingRequestAutomationCriteriasMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunBookingRequestAutomationCriteriasMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunBookingRequestAutomationCriteriasMutation,
    RunBookingRequestAutomationCriteriasMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunBookingRequestAutomationCriteriasMutation,
    RunBookingRequestAutomationCriteriasMutationVariables
  >(RunBookingRequestAutomationCriteriasDocument, options);
}
export type RunBookingRequestAutomationCriteriasMutationHookResult = ReturnType<
  typeof useRunBookingRequestAutomationCriteriasMutation
>;
export type RunBookingRequestAutomationCriteriasMutationResult =
  Apollo.MutationResult<RunBookingRequestAutomationCriteriasMutation>;
export type RunBookingRequestAutomationCriteriasMutationOptions = Apollo.BaseMutationOptions<
  RunBookingRequestAutomationCriteriasMutation,
  RunBookingRequestAutomationCriteriasMutationVariables
>;
