import { defaultInitialValues } from 'components/app/bookingRequest';
import ConfirmModal, { iConfirmModalProps } from 'components/common/Modal/ConfirmModal';
import config from 'config/config';
import { useFormikContext } from 'formik';
import i18n from 'i18n';
import { isEmpty, isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, Send } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { BookingInitialValuesProps } from '../BookingRequestPage.type';
import { useBookingContext } from '../context';

type ControlsProps = {
  onNext?: () => void;
};

const goAway = () => {
  window.location.href = config.shadesToursLanding;
};

const Controls: FC<ControlsProps> = ({ onNext }) => {
  const { currentIndex, stepNumber, onBack } = useBookingContext();
  const { errors, isSubmitting, values, submitCount } = useFormikContext<BookingInitialValuesProps>();
  const isDisabled = useMemo(
    () => !!submitCount && (!isEmpty(errors) || isSubmitting),
    [errors, isSubmitting, submitCount],
  );
  const { t } = useTranslation();
  const isLast = useMemo(() => currentIndex === stepNumber - 1, [stepNumber, currentIndex]);
  return (
    <div className="d-flex justify-content-end gap-3 pt-3">
      <Button
        onClick={() =>
          currentIndex
            ? onBack(values)
            : isEqual(values, defaultInitialValues)
            ? goAway()
            : ConfirmModal({
                description: i18n.t('bookingRequest.goBackConfirmation'),
                onOk: goAway,
              } as iConfirmModalProps)
        }
        variant="outline-primary"
        disabled={isSubmitting}
      >
        <ArrowLeft />
      </Button>
      <Button type="submit" className="text-uppercase" onClick={onNext} disabled={isDisabled}>
        {t(isLast ? 'submit' : 'continue')} {isLast ? <Send /> : <ArrowRight />}
      </Button>
    </div>
  );
};

export default Controls;
