import { ApolloError } from '@apollo/client';
import { isKeyHotkey, KeyboardEventLike } from 'is-hotkey';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';

export const useOnSubmitClick = ({
  setSubmitting,
  isSubmitting,
  onClick,
}: {
  setSubmitting: (value: boolean) => void;
  isSubmitting: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...props: any) => void;
}) => {
  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (...innerProps: any) => {
      if (isSubmitting) return;
      setSubmitting(true);
      try {
        await onClick(...innerProps);
      } catch (error) {
        toast.error(formatGraphqlErrors(error as ApolloError).message);
      } finally {
        setSubmitting(false);
      }
    },
    [isSubmitting, onClick, setSubmitting],
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunction = () => {};

export const useEnterDownDocument = (onOk: () => void) => {
  useEffect(() => {
    const elements = document.getElementsByClassName('ReactModalPortal');
    const element = elements[elements.length - 1];
    function keyDownHandler(event: Event) {
      if (isKeyHotkey('enter', event as unknown as KeyboardEventLike)) {
        onOk();
      }
    }
    element.addEventListener('keypress', keyDownHandler);
    return () => element.removeEventListener('keypress', keyDownHandler);
  }, [onOk]);
};
