import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/add-golden-tz';
import { set, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DEFAULT_APPOINTMENT_DURATION_HOURS } from './appointment';

export const FULL_DATE_FORMAT = 'EEEE, dd LLLL yyyy';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const TZ = 'Europe/Vienna';
export const COMPARE_DATE_FORMAT = 'yyyy:MM:dd';
export const COMPARE_TIME_FORMAT = 'HH:mm:ss:SSS';
export const startRestrict = {
  minDate: new Date(),
  minTime: zonedTimeToUtc(set(startOfDay(new Date()), { hours: 9 }), TZ),
  maxTime: zonedTimeToUtc(set(startOfDay(new Date()), { hours: 18 }), TZ),
};
export const endRestrict = {
  minDate: new Date(),
  minTime: zonedTimeToUtc(set(startOfDay(new Date()), { hours: 11 }), TZ),
  maxTime: zonedTimeToUtc(set(startOfDay(new Date()), { hours: 20 }), TZ),
};

export const filterPassedTime =
  (isStart = true) =>
  /**
   * Filter local times. Filtered time will be displayed in the time select of the datepicker
   * @param {boolean} time - a time from an option of the time select
   * @returns {boolean} Indicating flag whether to display the time in the time select
   */
  (time: Date) => {
    const selectedDate = zonedTimeToUtc(new Date(time), TZ);
    const increment = isStart ? 0 : DEFAULT_APPOINTMENT_DURATION_HOURS;

    return !(
      selectedDate.getHours() < 9 + increment ||
      selectedDate.getHours() > 18 + increment ||
      (selectedDate.getHours() === 18 + increment && selectedDate.getMinutes())
    );
  };
