import LoadingOverlay from 'components/common/loading/LoadingOverlay';
import useLogout from 'hooks/auth/useLogout';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import routePaths from 'router/route-paths';

export default function Logout() {
  const logout = useLogout();
  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <>
      <LoadingOverlay />
      <Navigate to={routePaths.login} />
    </>
  );
}
