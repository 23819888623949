import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingRequestForUpdatingResultFragmentDoc } from '../../../fragments/bookingRequest/generated/bookingRequestForUpdatingResultFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBookingRequestMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.BookingRequestInput;
  id: SchemaTypes.Scalars['ID'];
}>;

export type UpdateBookingRequestMutation = {
  __typename?: 'Mutation';
  updateBookingRequest: {
    __typename?: 'BookingRequest';
    id: string;
    date: string | Date;
    altDate: string | Date;
    totalParticipants: number;
    totalAccompanyingPersons?: number | null;
    ageOfParticipants?: string | null;
    tourTopicId: string;
    language: SchemaTypes.TourLanguage;
    howDidYouHear?: string | null;
  };
};

export const UpdateBookingRequestDocument = gql`
  mutation UpdateBookingRequest($data: BookingRequestInput!, $id: ID!) {
    updateBookingRequest(data: $data, id: $id) {
      ...BookingRequestForUpdatingResult
    }
  }
  ${BookingRequestForUpdatingResultFragmentDoc}
`;
export type UpdateBookingRequestMutationFn = Apollo.MutationFunction<
  UpdateBookingRequestMutation,
  UpdateBookingRequestMutationVariables
>;

/**
 * __useUpdateBookingRequestMutation__
 *
 * To run a mutation, you first call `useUpdateBookingRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingRequestMutation, { data, loading, error }] = useUpdateBookingRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBookingRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBookingRequestMutation, UpdateBookingRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBookingRequestMutation, UpdateBookingRequestMutationVariables>(
    UpdateBookingRequestDocument,
    options,
  );
}
export type UpdateBookingRequestMutationHookResult = ReturnType<typeof useUpdateBookingRequestMutation>;
export type UpdateBookingRequestMutationResult = Apollo.MutationResult<UpdateBookingRequestMutation>;
export type UpdateBookingRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookingRequestMutation,
  UpdateBookingRequestMutationVariables
>;
