import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitEventPricesFragmentDoc } from '../../../fragments/bookingkit/generated/bookingkitEventPricesFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingkitEventQueryVariables = SchemaTypes.Exact<{
  bookingkitEventId: SchemaTypes.Scalars['String'];
}>;

export type BookingkitEventQuery = {
  __typename?: 'Query';
  bookingkitEvent?: {
    __typename?: 'BookingkitEvent';
    id: string;
    prices: Array<{ __typename?: 'BookingkitEventPrice'; id: string; title: string; value: number; currency: string }>;
  } | null;
};

export const BookingkitEventDocument = gql`
  query BookingkitEvent($bookingkitEventId: String!) {
    bookingkitEvent(bookingkitEventId: $bookingkitEventId) {
      ...BookingkitEventPrices
    }
  }
  ${BookingkitEventPricesFragmentDoc}
`;

/**
 * __useBookingkitEventQuery__
 *
 * To run a query within a React component, call `useBookingkitEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingkitEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingkitEventQuery({
 *   variables: {
 *      bookingkitEventId: // value for 'bookingkitEventId'
 *   },
 * });
 */
export function useBookingkitEventQuery(
  baseOptions: Apollo.QueryHookOptions<BookingkitEventQuery, BookingkitEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingkitEventQuery, BookingkitEventQueryVariables>(BookingkitEventDocument, options);
}
export function useBookingkitEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingkitEventQuery, BookingkitEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingkitEventQuery, BookingkitEventQueryVariables>(BookingkitEventDocument, options);
}
export type BookingkitEventQueryHookResult = ReturnType<typeof useBookingkitEventQuery>;
export type BookingkitEventLazyQueryHookResult = ReturnType<typeof useBookingkitEventLazyQuery>;
export type BookingkitEventQueryResult = Apollo.QueryResult<BookingkitEventQuery, BookingkitEventQueryVariables>;
export function refetchBookingkitEventQuery(variables: BookingkitEventQueryVariables) {
  return { query: BookingkitEventDocument, variables: variables };
}
