import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import PageTitle from 'components/common/PageTitle';
import { useTranslation } from 'react-i18next';
import EmailTemplateCard, { emailTemplateModal } from './EmailTemplateCard';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'components/common/Pagination';
import Button from 'components/common/Button';
import { Plus } from 'react-bootstrap-icons';
import { EmailTemplateInput } from 'graphql/types.generated';
import {
  EmailTemplatesQueryVariables,
  useEmailTemplatesQuery,
} from 'graphql/queries/emailTemplate/generated/EmailTemplates';
import usePagination from 'hooks/usePagination';
import constants from 'constants/constants';
import LoadingWrapper from './LoadingWrapper';
import { deleteEmailTemplate, createEmailTemplate } from './utils';
import { EmailTemplateSettingFragment } from 'graphql/fragments/emailTemplate/generated/emailTemplateSettingFragment';
import AlertGraphQLError from 'components/common/AlertGraphQLError';

const EmailTemplatesWithRef = forwardRef<EmailTemplatesQueryVariables>(function EmailTemplates(_, ref) {
  const { data, loading, error, variables, currentPage, setCurrentPage } = usePagination({
    pageSize: constants.emailTemplateCountTableRowsPerPage,
    query: useEmailTemplatesQuery,
  });

  useImperativeHandle(ref, () => variables);

  const { emailTemplates, total } = useMemo(() => {
    if (!data) return {};
    const { items, total } = data.emailTemplates;
    return { emailTemplates: items as EmailTemplateSettingFragment[], total };
  }, [data]);

  const onDelete = useCallback(
    (emailTemplateId: string) => {
      deleteEmailTemplate({ emailTemplateId, skip: variables.skip });
    },
    [variables.skip],
  );

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <>
      <Row className="position-relative p-3">
        <LoadingWrapper isData={!!emailTemplates?.length} loading={loading}>
          {emailTemplates?.map((emailTemplate) => (
            <Col className="pb-2" key={emailTemplate.name} xs={12} lg={6} xxl={4}>
              <EmailTemplateCard emailTemplate={emailTemplate} onDelete={onDelete} />
            </Col>
          ))}
        </LoadingWrapper>
      </Row>
      <Pagination
        total={total}
        currentPage={currentPage}
        changePage={setCurrentPage}
        pageSize={constants.emailTemplateCountTableRowsPerPage}
      />
    </>
  );
});

const EmailTemplatesPage = () => {
  const variablesRef = useRef<EmailTemplatesQueryVariables>(null);
  const { t } = useTranslation();
  return (
    <>
      <PageTitle
        title={t('emailTemplates.title')}
        extra={
          <Button
            onClick={() => {
              emailTemplateModal({
                onSubmit: async (values: EmailTemplateInput) => {
                  await createEmailTemplate(values, variablesRef.current?.skip as number);
                },
              });
            }}
            endIcon={<Plus size="1.5em" />}
          >
            <span className="align-middle">{t('create')}</span>
          </Button>
        }
      />
      <EmailTemplatesWithRef ref={variablesRef} />
    </>
  );
};

export default EmailTemplatesPage;
