import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { EmailMessageFragmentDoc } from '../../../fragments/emailMessage/generated/EmailMessage';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailMessagesQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int'];
  skip: SchemaTypes.Scalars['Int'];
  bookingRequestId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
}>;

export type EmailMessagesQuery = {
  __typename?: 'Query';
  emailMessages: {
    __typename?: 'PaginatedEmailMessageResponse';
    total: number;
    items: Array<{
      __typename?: 'EmailMessage';
      id: string;
      subject: string;
      body: string;
      delivered?: boolean | null;
      emailClientResponse?: string | null;
      to: string;
      from: string;
      createdAt: string | Date;
      webhookEvents?: Array<{
        __typename?: 'MailgunWebhookEvent';
        id: string;
        event: string;
        deliveryStatusCode?: number | null;
        deliveryStatusMessage?: string | null;
        deliveryStatusDescription?: string | null;
        reason?: string | null;
        severity?: string | null;
        date: string | Date;
      }> | null;
      emailTemplate?: { __typename?: 'EmailTemplate'; id: string; name: string } | null;
    }>;
  };
};

export const EmailMessagesDocument = gql`
  query EmailMessages($limit: Int!, $skip: Int!, $bookingRequestId: String) {
    emailMessages(limit: $limit, skip: $skip, bookingRequestId: $bookingRequestId) {
      items {
        ...EmailMessage
      }
      total
    }
  }
  ${EmailMessageFragmentDoc}
`;

/**
 * __useEmailMessagesQuery__
 *
 * To run a query within a React component, call `useEmailMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailMessagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      bookingRequestId: // value for 'bookingRequestId'
 *   },
 * });
 */
export function useEmailMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<EmailMessagesQuery, EmailMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailMessagesQuery, EmailMessagesQueryVariables>(EmailMessagesDocument, options);
}
export function useEmailMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailMessagesQuery, EmailMessagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailMessagesQuery, EmailMessagesQueryVariables>(EmailMessagesDocument, options);
}
export type EmailMessagesQueryHookResult = ReturnType<typeof useEmailMessagesQuery>;
export type EmailMessagesLazyQueryHookResult = ReturnType<typeof useEmailMessagesLazyQuery>;
export type EmailMessagesQueryResult = Apollo.QueryResult<EmailMessagesQuery, EmailMessagesQueryVariables>;
export function refetchEmailMessagesQuery(variables: EmailMessagesQueryVariables) {
  return { query: EmailMessagesDocument, variables: variables };
}
