import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from './RadioButtonGroup';
import i18n from 'i18n';
import * as yup from 'yup';
import { RadioButtonPlaceholder } from '../placeholders';
import { useTourTopicsForBookingRequestFormQuery } from 'graphql/queries/tourTopic/generated/TourTopicsForBookingRequestForm';
import AlertGraphQLError from 'components/common/AlertGraphQLError';

export const schema = () => {
  return {
    tourTopicId: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.tourTopicId.name')),
    language: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.language.label')),
  };
};

const Languages = [
  { value: 'de', src: '/static/de-rounded.svg' },
  { value: 'en', src: '/static/en-rounded.svg' },
];

const TourTopicRadioButtons = () => {
  const { i18n } = useTranslation();
  const { data, loading, error } = useTourTopicsForBookingRequestFormQuery();

  const tourTopicOptions = useMemo(() => {
    if (!data) return [];
    return data.tourTopics.map(({ id, iconUrl, name }) => ({
      value: id,
      label: (
        <div className="d-flex flex-column align-items-center gap-2">
          <img src={iconUrl} className="icon-4" />
          <span>{name[i18n.language as keyof typeof name]}</span>
        </div>
      ) as unknown as string,
    }));
  }, [data, i18n.language]);

  if (error) return <AlertGraphQLError error={error} />;
  if (!data && loading) return <RadioButtonPlaceholder count={2} titleCount={2} className="mb-3" />;
  return (
    <RadioButtonGroup
      isRequired
      label="bookingRequest.fields.tourTopicId.label"
      name="tourTopicId"
      options={tourTopicOptions}
    />
  );
};

const LanguageRadioButtons = () => {
  const { t } = useTranslation();

  const languageOptions = useMemo(
    () =>
      Languages.map(({ value, src }) => ({
        value,
        label: (
          <div className="d-flex flex-column align-items-center gap-2">
            <img src={src} className="icon-4" />
            <span>{t('locale.' + value)}</span>
          </div>
        ) as unknown as string,
      })),
    [t],
  );

  return (
    <RadioButtonGroup
      isRequired
      label="bookingRequest.fields.language.label"
      name="language"
      options={languageOptions}
    />
  );
};

const AppointmentForm = () => {
  return (
    <>
      <TourTopicRadioButtons />
      <LanguageRadioButtons />
    </>
  );
};

export default AppointmentForm;
