import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BlockedDateFragmentDoc } from '../../../fragments/blockedDates/generated/blockedDateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBlockedDateMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.BlockedDateInput;
}>;

export type CreateBlockedDateMutation = {
  __typename?: 'Mutation';
  createBlockedDate: { __typename?: 'BlockedDate'; id: string; endDate: string | Date; startDate: string | Date };
};

export const CreateBlockedDateDocument = gql`
  mutation CreateBlockedDate($data: BlockedDateInput!) {
    createBlockedDate(data: $data) {
      ...BlockedDate
    }
  }
  ${BlockedDateFragmentDoc}
`;
export type CreateBlockedDateMutationFn = Apollo.MutationFunction<
  CreateBlockedDateMutation,
  CreateBlockedDateMutationVariables
>;

/**
 * __useCreateBlockedDateMutation__
 *
 * To run a mutation, you first call `useCreateBlockedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockedDateMutation, { data, loading, error }] = useCreateBlockedDateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBlockedDateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBlockedDateMutation, CreateBlockedDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBlockedDateMutation, CreateBlockedDateMutationVariables>(
    CreateBlockedDateDocument,
    options,
  );
}
export type CreateBlockedDateMutationHookResult = ReturnType<typeof useCreateBlockedDateMutation>;
export type CreateBlockedDateMutationResult = Apollo.MutationResult<CreateBlockedDateMutation>;
export type CreateBlockedDateMutationOptions = Apollo.BaseMutationOptions<
  CreateBlockedDateMutation,
  CreateBlockedDateMutationVariables
>;
