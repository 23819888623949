import { Appointment, AppointmentStatus as EAppointmentStatus } from 'graphql/types.generated';
import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type AppointmentStatusProps = {
  status: EAppointmentStatus;
};

const badgeColorByStatus: Record<Appointment['status'], { bg: BadgeProps['bg']; text: BadgeProps['text'] }> = {
  [EAppointmentStatus.NEW]: { bg: 'danger', text: 'light' },
  [EAppointmentStatus.REQUESTED]: { bg: 'primary', text: 'light' },
  [EAppointmentStatus.SCHEDULED]: { bg: 'white', text: 'dark' },
  [EAppointmentStatus.AWAITS_FEEDBACK]: { bg: 'info', text: 'light' },
  [EAppointmentStatus.COMPLETED]: { bg: 'white', text: 'dark' },
  [EAppointmentStatus.CANCELED]: { bg: 'danger', text: 'light' },
};
const AppointmentStatus: FC<AppointmentStatusProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Badge bg={badgeColorByStatus[props.status].bg} text={badgeColorByStatus[props.status].text}>
      {t(`appointmentStatus.${props.status}`)}
    </Badge>
  );
};

export default AppointmentStatus;
