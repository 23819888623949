import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

const CardPlaceholder: FC = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Placeholder as="h1" animation="glow">
            <Placeholder xs={12} bg="secondary" />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={8} bg="secondary" />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={8} bg="secondary" />
          </Placeholder>
          <Placeholder as="h4" animation="glow">
            <Placeholder xs={4} bg="secondary" />
          </Placeholder>
        </Card.Body>
        <Card.Footer>
          <Placeholder animation="glow">
            <Placeholder xs={12} bg="secondary" />
          </Placeholder>
        </Card.Footer>
      </Card>
    </>
  );
};

export default CardPlaceholder;
