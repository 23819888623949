import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type GuideForAppointmentFragment = {
  __typename?: 'User';
  id: string;
  profile: { __typename?: 'UserProfile'; fullName: string };
};

export type GuideForAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const GuideForAppointmentFragmentDoc = gql`
  fragment GuideForAppointment on User {
    id
    profile {
      fullName
    }
  }
`;
