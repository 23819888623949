import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BookingkitEventTitleFragment = { __typename?: 'BookingkitEvent'; id: string; title: string };

export type BookingkitEventTitleFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingkitEventTitleFragmentDoc = gql`
  fragment BookingkitEventTitle on BookingkitEvent {
    id
    title
  }
`;
