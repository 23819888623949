import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';

const CardPlaceholder: FC<{ countFields?: number }> = ({ countFields = 2 }) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Placeholder animation="glow">
            <Placeholder xs={12} bg="secondary" />
          </Placeholder>
        </Card.Header>
        <Card.Body>
          {[...new Array(countFields)].map((e, i) => (
            <Placeholder key={i} as="h2" animation="glow">
              <Placeholder xs={12} bg="secondary" />
            </Placeholder>
          ))}
        </Card.Body>
      </Card>
    </>
  );
};

export default CardPlaceholder;
