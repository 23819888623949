import React from 'react';
import s from './FormDescription.module.sass';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export interface iFormDescription {
  description?: React.ReactNode | string;
}

const FormDescription: FC<iFormDescription> = ({ description }) => {
  const { t } = useTranslation();
  return description ? (
    <div className={cn(s.description, 'text-muted')}>
      {typeof description === 'string' ? t(description) : description}
    </div>
  ) : null;
};

export default FormDescription;
