import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEmailTemplateMutationVariables = SchemaTypes.Exact<{
  emailTemplateId: SchemaTypes.Scalars['ID'];
}>;

export type DeleteEmailTemplateMutation = { __typename?: 'Mutation'; deleteEmailTemplate: boolean };

export const DeleteEmailTemplateDocument = gql`
  mutation DeleteEmailTemplate($emailTemplateId: ID!) {
    deleteEmailTemplate(emailTemplateId: $emailTemplateId)
  }
`;
export type DeleteEmailTemplateMutationFn = Apollo.MutationFunction<
  DeleteEmailTemplateMutation,
  DeleteEmailTemplateMutationVariables
>;

/**
 * __useDeleteEmailTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailTemplateMutation, { data, loading, error }] = useDeleteEmailTemplateMutation({
 *   variables: {
 *      emailTemplateId: // value for 'emailTemplateId'
 *   },
 * });
 */
export function useDeleteEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables>(
    DeleteEmailTemplateDocument,
    options,
  );
}
export type DeleteEmailTemplateMutationHookResult = ReturnType<typeof useDeleteEmailTemplateMutation>;
export type DeleteEmailTemplateMutationResult = Apollo.MutationResult<DeleteEmailTemplateMutation>;
export type DeleteEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailTemplateMutation,
  DeleteEmailTemplateMutationVariables
>;
