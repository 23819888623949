import React, { useEffect } from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import { useTotalBookingRequestsQuery } from 'graphql/queries/bookingRequest/generated/TotalBookingRequests';
import { toastErrorMessage } from 'utils/helpers';
import { ApolloError } from '@apollo/client';
import { TOTAL_BOOKING_REQUESTS_POLL_INTERVAL } from 'constants/bookingRequest';
import s from './TotalBookingRequestBadge.module.sass';
import cn from 'classnames';
import { useAppContext } from 'contexts/AppContext';

const TotalBookingRequestBadge = ({ className }: { className?: string }) => {
  const { sidebarOpen } = useAppContext();

  const { data, loading, startPolling, stopPolling } = useTotalBookingRequestsQuery({
    onError: (error: ApolloError) => {
      toastErrorMessage(error);
    },
  });

  useEffect(() => {
    startPolling(TOTAL_BOOKING_REQUESTS_POLL_INTERVAL);
    return stopPolling;
  }, [startPolling, stopPolling]);

  return (
    <Badge pill className={cn(className, sidebarOpen ? '' : s.badge, sidebarOpen ? '' : 'text-truncate')}>
      {!data && loading ? <Spinner animation="border" size="sm" /> : data?.totalBookingRequests}
    </Badge>
  );
};

export default TotalBookingRequestBadge;
