import { BookingkitConnectionForAppointmentFragment } from 'graphql/fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import { AppointmentType } from 'graphql/types.generated';

export const checkIsSynced = ({
  bookingkitConnection,
  appointmentType,
}: {
  bookingkitConnection: BookingkitConnectionForAppointmentFragment;
  appointmentType: AppointmentType;
}) => {
  let isSynced = !!bookingkitConnection.dateSyncSuccess;
  if (AppointmentType.PRIVATE_TOUR || appointmentType === AppointmentType.EVENT) {
    isSynced = !!bookingkitConnection.dateSyncSuccess && !!bookingkitConnection.orderSuccess;
  }

  return isSynced;
};

export enum BookingkitSyncStatus {
  NOT_PUSHED = 'NOT_PUSHED',
  PUSHED = 'PUSHED',
  ERROR = 'ERROR',
  IGNORED = 'IGNORED',
  UNKNOWN = 'UNKNOWN',
}
export const getSyncStatus = ({
  bookingkitConnection,
  appointmentType,
}: {
  bookingkitConnection?: BookingkitConnectionForAppointmentFragment | null;
  appointmentType: AppointmentType;
}): BookingkitSyncStatus => {
  if (!bookingkitConnection) return BookingkitSyncStatus.NOT_PUSHED;
  let isPushed = !!bookingkitConnection.dateSyncSuccess;
  if (AppointmentType.PRIVATE_TOUR || appointmentType === AppointmentType.EVENT) {
    isPushed = !!bookingkitConnection.dateSyncSuccess && !!bookingkitConnection.orderSuccess;
  }

  if (isPushed) return BookingkitSyncStatus.PUSHED;
  if (bookingkitConnection.ignoreError) return BookingkitSyncStatus.IGNORED;
  if (bookingkitConnection.errorMessage || bookingkitConnection.errorName) return BookingkitSyncStatus.ERROR;
  return BookingkitSyncStatus.UNKNOWN;
};
