import PageTitle from 'components/common/PageTitle';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Automation from './tabs/Automation';
import DateTime from './tabs/DateTime';
import Tours from './tabs/Tours';

enum SystemConfigTabEnum {
  automation = 'automation',
  tours = 'tours',
  dateTime = 'dateTime',
}

const SystemConfigTabs: Record<SystemConfigTabEnum, React.ReactNode> = {
  [SystemConfigTabEnum.automation]: <Automation />,
  [SystemConfigTabEnum.tours]: <Tours />,
  [SystemConfigTabEnum.dateTime]: <DateTime />,
};

const SystemConfigPage = () => {
  const [key, setKey] = useState<SystemConfigTabEnum>(SystemConfigTabEnum.automation);
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t('systemConfig.title')} />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k as unknown as SystemConfigTabEnum)}
        className="mb-3 mw-100 w-max-content"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {Object.keys(SystemConfigTabEnum).map((tab) => (
          <Tab key={tab} eventKey={tab} className="mw-100" title={t(`systemConfig.${tab}`)}>
            {SystemConfigTabs[tab as SystemConfigTabEnum]}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default SystemConfigPage;
