import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { ProductForUpdatingAppointmentFragmentDoc } from '../../../fragments/product/generated/productForUpdatingAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductsForUpdatingAppointmentQueryVariables = SchemaTypes.Exact<{
  appointmentType?: SchemaTypes.InputMaybe<SchemaTypes.AppointmentType>;
}>;

export type ProductsForUpdatingAppointmentQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    name: string;
    language?: SchemaTypes.TourLanguage | null;
    meetingPointName?: string | null;
    tourTopic?: {
      __typename?: 'TourTopic';
      id: string;
      code: string;
      name: { __typename?: 'Localized'; en: string; de: string };
    } | null;
  }>;
};

export const ProductsForUpdatingAppointmentDocument = gql`
  query ProductsForUpdatingAppointment($appointmentType: AppointmentType) {
    products(appointmentType: $appointmentType) {
      ...ProductForUpdatingAppointment
    }
  }
  ${ProductForUpdatingAppointmentFragmentDoc}
`;

/**
 * __useProductsForUpdatingAppointmentQuery__
 *
 * To run a query within a React component, call `useProductsForUpdatingAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForUpdatingAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForUpdatingAppointmentQuery({
 *   variables: {
 *      appointmentType: // value for 'appointmentType'
 *   },
 * });
 */
export function useProductsForUpdatingAppointmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductsForUpdatingAppointmentQuery,
    ProductsForUpdatingAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsForUpdatingAppointmentQuery, ProductsForUpdatingAppointmentQueryVariables>(
    ProductsForUpdatingAppointmentDocument,
    options,
  );
}
export function useProductsForUpdatingAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsForUpdatingAppointmentQuery,
    ProductsForUpdatingAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsForUpdatingAppointmentQuery, ProductsForUpdatingAppointmentQueryVariables>(
    ProductsForUpdatingAppointmentDocument,
    options,
  );
}
export type ProductsForUpdatingAppointmentQueryHookResult = ReturnType<typeof useProductsForUpdatingAppointmentQuery>;
export type ProductsForUpdatingAppointmentLazyQueryHookResult = ReturnType<
  typeof useProductsForUpdatingAppointmentLazyQuery
>;
export type ProductsForUpdatingAppointmentQueryResult = Apollo.QueryResult<
  ProductsForUpdatingAppointmentQuery,
  ProductsForUpdatingAppointmentQueryVariables
>;
export function refetchProductsForUpdatingAppointmentQuery(variables?: ProductsForUpdatingAppointmentQueryVariables) {
  return { query: ProductsForUpdatingAppointmentDocument, variables: variables };
}
