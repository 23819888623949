import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type CurrentUserFragment = {
  __typename?: 'User';
  id: string;
  role: SchemaTypes.Roles;
  emailNotificationsEnabled: boolean;
  createdAt: string | Date;
  email: string;
  profile: { __typename?: 'UserProfile'; fullName: string };
};

export type CurrentUserFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    role
    profile {
      fullName
    }
    emailNotificationsEnabled
    createdAt
    email
  }
`;
