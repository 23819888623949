import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { AppointmentTypes, AppointmentType } from 'constants/appointment';
import appointmentModal from 'components/app/bookingRequestAndAppointment/modals/appointmentModal';
import { AppointmentsDocument, AppointmentsQueryVariables } from 'graphql/queries/appointment/generated/Appointments';
import createBookingRequestModal from 'components/app/bookingRequestAndAppointment/modals/createBookingRequestModal';
import { useNavigate } from 'react-router-dom';

const CreateAppointmentDropdown = ({ variables }: { variables: AppointmentsQueryVariables }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refetchQueries = useMemo(() => [{ query: AppointmentsDocument, variables }], [variables]);
  return (
    <Dropdown
      className="d-inline-block"
      onSelect={(type) => {
        if (type === AppointmentTypes.PRIVATE_TOUR) createBookingRequestModal({ navigate, refetchQueries });
        else appointmentModal({ type: type as AppointmentType, isEdit: false }, refetchQueries);
      }}
    >
      <Dropdown.Toggle className="dropdown-toggle d-flex align-items-center">
        <Plus size="1.5em" />
        {t('create')}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(AppointmentTypes).map((type) => (
          <Dropdown.Item key={type} eventKey={type}>
            {t(`productType.${type}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CreateAppointmentDropdown;
