import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRecurringEventMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.RecurringEventInput;
}>;

export type CreateRecurringEventMutation = { __typename?: 'Mutation'; createRecurringEvent: number };

export const CreateRecurringEventDocument = gql`
  mutation CreateRecurringEvent($data: RecurringEventInput!) {
    createRecurringEvent(data: $data)
  }
`;
export type CreateRecurringEventMutationFn = Apollo.MutationFunction<
  CreateRecurringEventMutation,
  CreateRecurringEventMutationVariables
>;

/**
 * __useCreateRecurringEventMutation__
 *
 * To run a mutation, you first call `useCreateRecurringEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringEventMutation, { data, loading, error }] = useCreateRecurringEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRecurringEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRecurringEventMutation, CreateRecurringEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRecurringEventMutation, CreateRecurringEventMutationVariables>(
    CreateRecurringEventDocument,
    options,
  );
}
export type CreateRecurringEventMutationHookResult = ReturnType<typeof useCreateRecurringEventMutation>;
export type CreateRecurringEventMutationResult = Apollo.MutationResult<CreateRecurringEventMutation>;
export type CreateRecurringEventMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringEventMutation,
  CreateRecurringEventMutationVariables
>;
