import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type GuideFeedbackForAppointmentFragment = {
  __typename?: 'GuideFeedback';
  totalParticipants: number;
  totalAccompanyingPersons?: number | null;
  comment: string;
  createdAt: string | Date;
};

export type GuideFeedbackForAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const GuideFeedbackForAppointmentFragmentDoc = gql`
  fragment GuideFeedbackForAppointment on GuideFeedback {
    totalParticipants
    totalAccompanyingPersons
    comment
    createdAt
  }
`;
