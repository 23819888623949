import React, { useCallback, useEffect } from 'react';
import s from './AppointmentControls.module.sass';
import ControlsLayout, {
  iControlsLayout,
  iControlsStates,
} from 'components/app/bookingRequestAndAppointment/ControlsLayout';
import { Col } from 'react-bootstrap';
import cn from 'classnames';
import { DatePicker } from 'components/inputs/DatePicker';
import { ReactDatePickerProps } from 'react-datepicker';
import { startOfWeek } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

export const initialValues = {
  startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
};

interface iAppointmentControls extends iControlsLayout, iControlsStates<typeof initialValues> {}

const AppointmentControls = ({ state, setState, extra }: iAppointmentControls) => {
  const [urlSearchParams] = useSearchParams();

  useEffect(() => {
    const initialStartDate = urlSearchParams.get('initialStartDate');
    if (initialStartDate) setState((s) => ({ ...s, startDate: new Date(initialStartDate) }));
  }, [setState, urlSearchParams]);

  const handleChange = useCallback<ReactDatePickerProps['onChange']>(
    (startDate) => {
      setState((s) => ({ ...s, startDate: startDate ? startDate : startOfWeek(new Date(), { weekStartsOn: 1 }) }));
    },
    [setState],
  );

  return (
    <ControlsLayout extra={extra}>
      <Col xs={12} sm={6} lg="auto" className={cn(s.date, 'mb-3')}>
        <DatePicker selected={state.startDate} onChange={handleChange} />
      </Col>
    </ControlsLayout>
  );
};

export default AppointmentControls;
