import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import s from './Modal.module.sass';
import PageTitle from '../PageTitle';
import CloseButton from 'react-bootstrap/CloseButton';
import cn from 'classnames';
import { screenSizeConfig } from '../Visibility';

ReactModal.setAppElement(document.getElementById('root') as HTMLElement);

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const showModal = useCallback((data = {}) => {
    setModalProps(data);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalProps({});
  }, []);

  return { showModal, hideModal, modalProps, isVisible };
};

export interface iModalProps {
  children?: React.ReactNode;
  isVisible: boolean;
  headerText?: string;
  onClose: () => void;
  width?: number | string;
  type?: 'modal' | 'drawer';
}

const Modal: FC<iModalProps> = ({ children, isVisible, onClose, headerText = '', width, type = 'modal' }) => {
  return (
    <ReactModal
      isOpen={isVisible}
      style={{ content: { maxWidth: typeof width === 'string' ? width : (width ?? screenSizeConfig.md) + 'px' } }}
      onRequestClose={onClose}
      bodyOpenClassName={s.bodyOpen}
      className={{
        base: s[type],
        afterOpen: s.modalAfterOpen,
        beforeClose: s.modalBeforeClose,
      }}
      overlayClassName={{
        base: s.overlay,
        afterOpen: s.overlayAfterOpen,
        beforeClose: s.overlayBeforeClose,
      }}
      closeTimeoutMS={300}
    >
      <PageTitle
        title={headerText}
        extra={<CloseButton onClick={onClose} className={s.modalCloseButton} />}
        className={cn(s.modalHeader, 'border-bottom pb-0 justify-content-between')}
      />
      {children}
    </ReactModal>
  );
};

export default Modal;
