import React, { forwardRef, memo, useCallback } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { RootElement, AllModifiers } from '../RichEditor.type';
import cn from 'classnames';
import BlockButton, { BlockComponent, iRenderButtonProps } from './BlockButton';
import {
  TypeBold,
  TypeItalic,
  TypeUnderline,
  Quote,
  ListUl,
  ListOl,
  TextLeft,
  TextCenter,
  TextRight,
  Link,
  Image,
} from 'react-bootstrap-icons';
import FontSizeSelect from './FontSizeSelect';
import ColorSelect from './ColorSelect';
import { alignFn } from '../plugins/withAlign';
import s from './SlateToolbar.module.sass';
import { linkFn } from '../plugins/withLink';
import { useFocused } from 'slate-react';
import { BLOCK_ELEMENT } from '../constants';
import { imageFn } from '../plugins/withImage';

export const ToolbarWithRef = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function Toolbar(
  { className, ...props },
  ref,
) {
  const isFocused = useFocused();
  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        className,
        s.toolbar,
        isFocused ? s.stickyToolbar : '',
        'd-flex flex-wrap gap-2 rich-editor-toolbar',
      )}
    />
  );
});

type ToolbarChildren = FC | React.ReactElement;

export interface iSlateToolbarProps {
  toolbarChildren?: ToolbarChildren;
  allowedModifiers?: AllModifiers[];
  rootElement: RootElement;
  className?: string;
  readOnly?: boolean;
}

const preventDefault = (e: React.MouseEvent) => {
  e.preventDefault();
};

const Divider = () => <div className="vr my-2" />;

const SlateToolbarMemo: FC<iSlateToolbarProps> = memo(function SlateToolbarMemo({
  toolbarChildren,
  allowedModifiers,
  rootElement,
  className,
  readOnly,
}) {
  const renderButton = useCallback(
    (format: AllModifiers, component: (props: iRenderButtonProps) => React.ReactElement) => {
      if (!allowedModifiers || allowedModifiers.includes(format)) return component({ format, rootElement });
      return null;
    },
    [allowedModifiers, rootElement],
  );
  if (readOnly) return null;
  return (
    <ToolbarWithRef onMouseDown={preventDefault} className={className}>
      {renderButton('font-size', (props) => (
        <BlockComponent disallowUnset {...props} component={FontSizeSelect} />
      ))}
      <Divider />
      {renderButton('bold', (props) => (
        <BlockButton icon={<TypeBold size="1.5em" />} {...props} />
      ))}
      {renderButton('italic', (props) => (
        <BlockButton icon={<TypeItalic size="1.5em" />} {...props} />
      ))}
      {renderButton('underline', (props) => (
        <BlockButton icon={<TypeUnderline size="1.5em" />} {...props} />
      ))}
      {renderButton('color', (props) => (
        <BlockComponent disallowUnset {...props} component={ColorSelect} />
      ))}
      {renderButton(BLOCK_ELEMENT.LINK, (props) => (
        <BlockButton customFn={linkFn} icon={<Link size="1.5em" />} {...props} />
      ))}
      {renderButton(BLOCK_ELEMENT.IMAGE, (props) => (
        <BlockButton customFn={imageFn} icon={<Image size="1.5em" />} {...props} />
      ))}
      {renderButton(BLOCK_ELEMENT.BLOCK_QUOTE, (props) => (
        <BlockButton icon={<Quote size="1.5em" />} {...props} />
      ))}
      <Divider />
      {renderButton('align-left', (props) => (
        <BlockButton icon={<TextLeft size="1.5em" />} customFn={alignFn()} {...props} />
      ))}
      {renderButton('align-center', (props) => (
        <BlockButton icon={<TextCenter size="1.5em" />} customFn={alignFn('center')} {...props} />
      ))}
      {renderButton('align-right', (props) => (
        <BlockButton icon={<TextRight size="1.5em" />} customFn={alignFn('right')} {...props} />
      ))}
      <Divider />
      {renderButton(BLOCK_ELEMENT.BULLETED_LIST, (props) => (
        <BlockButton icon={<ListUl size="1.5em" />} {...props} />
      ))}
      {renderButton(BLOCK_ELEMENT.NUMBERED_LIST, (props) => (
        <BlockButton icon={<ListOl size="1.5em" />} {...props} />
      ))}
      {toolbarChildren ? (
        <>
          <Divider />
          {typeof toolbarChildren === 'function' ? toolbarChildren({}) : toolbarChildren}
        </>
      ) : undefined}
    </ToolbarWithRef>
  );
},
equal);

export default SlateToolbarMemo;
