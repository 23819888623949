import React from 'react';
import CPagination from 'rc-pagination';
import constants from 'constants/constants';
import cn from 'classnames';
import 'rc-pagination/assets/index.css';
import s from './Pagination.module.sass';
import { ChevronRight, ChevronLeft, ThreeDots } from 'react-bootstrap-icons';

export interface iPaginationProps {
  changePage: (page: number) => void;
  currentPage: number;
  total?: number;
  className?: string;
  pageSize?: number;
}

const itemRender = (currentPage: number) =>
  function ItemRender(page: number, type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next') {
    return (
      <div
        className={cn(
          s.button,
          s[type],
          'btn',
          currentPage === page && type === 'page' ? 'btn-primary' : 'btn-outline-primary',
        )}
      >
        &#8203;
        {type === 'jump-prev' || type === 'jump-next' ? <ThreeDots className="color-inherit" /> : null}
        {type === 'prev' ? <ChevronLeft className="color-inherit" /> : null}
        {type === 'next' ? <ChevronRight className="color-inherit" /> : null}
        {type === 'page' ? page : null}
      </div>
    );
  };

const Pagination: FC<iPaginationProps> = ({
  changePage,
  currentPage,
  total = 0,
  className,
  pageSize = constants.countTableRowsPerPage,
}) => {
  if (total <= pageSize) return null;
  return (
    <CPagination
      itemRender={itemRender(currentPage)}
      className={cn(s.rcPagination, className)}
      pageSize={pageSize}
      onChange={(page) => {
        window.scrollTo(0, 0);
        changePage(page);
      }}
      current={currentPage}
      total={total}
      showTitle={false}
    />
  );
};

export default Pagination;
