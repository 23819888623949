import React from 'react';
import PageTitle from 'components/common/PageTitle';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import CustomerCard from './cards/CustomerCard';
import BookingRequestCard from './cards/BookingRequestCard';
import AppointmentTable from './tables/AppointmentTable';
import SentEmailTable from './tables/SentEmailTable';
import { useBookingRequestViewQuery } from 'graphql/queries/bookingRequest/generated/BookingRequestView';
import { useParams } from 'react-router-dom';
import CardPlaceholder from './components/CardPlaceholder';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { FormConfigProvider } from './context';
import Controls from './components/Controls';

const BookingRequestViewCards = ({ bookingRequestId }: { bookingRequestId: string }) => {
  const { data, loading, error } = useBookingRequestViewQuery({
    variables: { id: bookingRequestId },
  });

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <FormConfigProvider targetGroup={data?.bookingRequest.customer.organization.targetGroup}>
      <Row className="mb-3">
        <Col lg={6} className="mb-3">
          {!data && loading ? (
            <CardPlaceholder countFields={8} />
          ) : (
            <BookingRequestCard bookingRequest={data?.bookingRequest} />
          )}
        </Col>
        <Col>
          {!data && loading ? (
            <CardPlaceholder countFields={6} />
          ) : (
            <CustomerCard customer={data?.bookingRequest.customer} bookingRequestId={bookingRequestId} />
          )}
        </Col>
      </Row>
    </FormConfigProvider>
  );
};

const BookingRequestViewPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t('bookingRequestView.title')} extra={<Controls bookingRequestId={id || ''} />} />
      <BookingRequestViewCards bookingRequestId={id || ''} />

      <AppointmentTable bookingRequestId={id || ''} />
      <SentEmailTable bookingRequestId={id || ''} />
    </>
  );
};

export default BookingRequestViewPage;
