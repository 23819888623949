import React from 'react';
import * as yup from 'yup';
import StepLayout from '../components/StepLayout';
import { TargetGroupForm, targetGroupSchema } from 'components/bookingRequest/TargetGroupForm';

export const organizationTypeValidationSchema = () =>
  yup.object().shape({
    ...targetGroupSchema(),
  });

const TargetGroup = () => {
  return (
    <StepLayout validationSchema={organizationTypeValidationSchema}>
      <TargetGroupForm />
    </StepLayout>
  );
};

export default TargetGroup;
