import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';
import PageTitle from 'components/common/PageTitle';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <Row className="h-100">
      <Col md={8} lg={6} xl={5} xxl={4} className="m-auto">
        <PageTitle title={t('pageTitles.forgotPassword')} className="justify-content-center" />
        <ForgotPasswordForm />
      </Col>
    </Row>
  );
};

export default ForgotPasswordPage;
