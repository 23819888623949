import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type EmailTemplatesSendEmailFragment = {
  __typename?: 'EmailTemplate';
  id: string;
  name: string;
  language: SchemaTypes.TourLanguage;
  subject: string;
  body: string;
};

export type EmailTemplatesSendEmailFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const EmailTemplatesSendEmailFragmentDoc = gql`
  fragment EmailTemplatesSendEmail on EmailTemplate {
    id
    name
    language
    subject
    body
  }
`;
