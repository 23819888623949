import React, { useState, useCallback, createContext, useContext } from 'react';
import { isDesktop } from 'react-device-detect';

interface SidebarState {
  sidebarOpen: boolean;
  toggleSidebar: (v: boolean) => void;
}

const defaultSidebarState: SidebarState = { sidebarOpen: isDesktop, toggleSidebar: (v) => ({ sidebarOpen: v }) };

const useSidebarState = () => {
  const [sidebarOpen, setSidebar] = useState(defaultSidebarState.sidebarOpen);
  const toggleSidebar = useCallback((v) => {
    setSidebar(typeof v === 'boolean' ? v : (s) => !s);
  }, []);
  return { sidebarOpen, toggleSidebar };
};

export const AppContext = createContext({ ...defaultSidebarState });

interface iAppContextProviderProps {
  children: React.ReactChildren | React.ReactElement;
}

export const AppContextProvider: FC<iAppContextProviderProps> = ({ children }) => {
  const sidebarState = useSidebarState();
  return <AppContext.Provider value={{ ...sidebarState }}>{children}</AppContext.Provider>;
};

export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => useContext(AppContext);
