import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { ProductConfigFragmentDoc } from '../../../fragments/product/generated/productConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProductMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: 'Mutation';
  createProduct: {
    __typename?: 'Product';
    id: string;
    bookingkitEventId?: string | null;
    bookingkitPriceId?: string | null;
    bookingkitSyncEnabled: boolean;
    name: string;
    language?: SchemaTypes.TourLanguage | null;
    type: SchemaTypes.ProductType;
    meetingPointName?: string | null;
    startPointAddress?: string | null;
    endPointAddress?: string | null;
    tourTopic?: {
      __typename?: 'TourTopic';
      id: string;
      name: { __typename?: 'Localized'; en: string; de: string };
    } | null;
  };
};

export const CreateProductDocument = gql`
  mutation CreateProduct($data: CreateProductInput!) {
    createProduct(data: $data) {
      ...ProductConfig
    }
  }
  ${ProductConfigFragmentDoc}
`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
