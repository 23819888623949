import React from 'react';
import { MutationOptions } from '@apollo/client';
import ConfirmModal from 'components/common/Modal/ConfirmModal';
import client from 'graphql/apollo';
import {
  DeleteAppointmentDocument,
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables,
} from 'graphql/mutations/appointment/generated/DeleteAppointment';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getBookingkitDateUrl } from 'utils/bookingkit';

const deleteAppointmentModal = (props: {
  appointmentId: string;
  refetchQueries: MutationOptions['refetchQueries'];
  isCancel: boolean;
  bookingkitDateId?: string;
}) => {
  const { appointmentId, refetchQueries, isCancel, bookingkitDateId } = props;
  ConfirmModal({
    headerText: isCancel
      ? i18n.t('appointments.cancelConfirmation.title')
      : i18n.t('appointments.deleteConfirmation.title'),
    description: isCancel
      ? i18n.t('appointments.cancelConfirmation.description')
      : i18n.t('appointments.deleteConfirmation.description'),
    okText: isCancel ? i18n.t('cancel') : undefined,
    cancelText: isCancel ? i18n.t('no') : undefined,
    onOk: async () => {
      await client.mutate<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>({
        mutation: DeleteAppointmentDocument,
        variables: { id: appointmentId, force: !isCancel },
        refetchQueries,
      });
      if (isCancel) {
        toast.success(i18n.t('appointments.cancelApppointmentSuccess'));
      } else {
        toast.success(i18n.t('appointments.deleteAppointmentSuccess'));
      }

      if (bookingkitDateId) {
        ConfirmModal({
          headerText: i18n.t('appointments.bookingkitDateCancelConfirmation.title'),
          description: (
            <div>
              {i18n.t('appointments.bookingkitDateCancelConfirmation.description')}
              <br />
              <a href={getBookingkitDateUrl(bookingkitDateId)} target="_blank" rel="noreferrer">
                {i18n.t('appointments.bookingkitDateCancelConfirmation.openBookingkitLink')}
              </a>
            </div>
          ),
          okText: i18n.t('appointments.bookingkitDateCancelConfirmation.okText'),
          onOk: async () => {
            return;
          },
          hideCancel: true,
        });
      }
    },
  });
};

export default deleteAppointmentModal;
