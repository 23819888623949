import UrlPattern from 'url-pattern';

export const routePaths = {
  auth: '/auth/*',
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  secure: '/secure/*',
  logout: '/secure/logout',
  profile: '/secure/profile',
  emailTemplates: '/secure/email-templates',
  userManagement: '/secure/user-management',
  systemConfig: '/secure/system-config',
  appointments: '/secure/appointments',
  bookingRequests: '/secure/booking-requests',
  bookingRequest: '/secure/booking-request/:id',
  bookingRequestForm: '/',
};

type RoutePaths = typeof routePaths;

export const routePatterns = Object.entries(routePaths).reduce(
  (p, [key, pattern]) => ({ ...p, [key]: new UrlPattern(pattern) }),
  {},
) as Record<keyof RoutePaths, UrlPattern>;

export default routePaths;
