import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type GuideFragment = {
  __typename?: 'User';
  id: string;
  profile: { __typename?: 'UserProfile'; fullName: string };
};

export type GuideFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const GuideFragmentDoc = gql`
  fragment Guide on User {
    id
    profile {
      fullName
    }
  }
`;
