import {
  TotalAccompanyingPersonsInput,
  TotalParticipantsInput,
} from 'components/bookingRequest/PricingLevelForm/PricingLevelForm';
import FastField from 'components/common/FastField';
import TextInput from 'components/inputs/TextInput';
import React from 'react';

const GuideFeedbackForm = () => {
  return (
    <>
      <FastField name="comment" component={TextInput} label="appointments.fields.comment.label" />
      <TotalParticipantsInput />
      <TotalAccompanyingPersonsInput min={0} />
    </>
  );
};
export default GuideFeedbackForm;
