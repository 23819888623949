import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppointmentInfoForOfficeMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  infoForOffice?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
}>;

export type UpdateAppointmentInfoForOfficeMutation = {
  __typename?: 'Mutation';
  updateAppointmentInfoForOffice: {
    __typename?: 'Appointment';
    id: string;
    infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
  };
};

export const UpdateAppointmentInfoForOfficeDocument = gql`
  mutation UpdateAppointmentInfoForOffice($id: String!, $infoForOffice: String) {
    updateAppointmentInfoForOffice(id: $id, infoForOffice: $infoForOffice) {
      id
      infoForOffice
    }
  }
`;
export type UpdateAppointmentInfoForOfficeMutationFn = Apollo.MutationFunction<
  UpdateAppointmentInfoForOfficeMutation,
  UpdateAppointmentInfoForOfficeMutationVariables
>;

/**
 * __useUpdateAppointmentInfoForOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentInfoForOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentInfoForOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentInfoForOfficeMutation, { data, loading, error }] = useUpdateAppointmentInfoForOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      infoForOffice: // value for 'infoForOffice'
 *   },
 * });
 */
export function useUpdateAppointmentInfoForOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentInfoForOfficeMutation,
    UpdateAppointmentInfoForOfficeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppointmentInfoForOfficeMutation, UpdateAppointmentInfoForOfficeMutationVariables>(
    UpdateAppointmentInfoForOfficeDocument,
    options,
  );
}
export type UpdateAppointmentInfoForOfficeMutationHookResult = ReturnType<
  typeof useUpdateAppointmentInfoForOfficeMutation
>;
export type UpdateAppointmentInfoForOfficeMutationResult =
  Apollo.MutationResult<UpdateAppointmentInfoForOfficeMutation>;
export type UpdateAppointmentInfoForOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentInfoForOfficeMutation,
  UpdateAppointmentInfoForOfficeMutationVariables
>;
