import { AppointmentTypes, AppointmentType } from 'constants/appointment';
import { FastField, useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DatePickerFormik, DateTimeRangePicker } from 'components/inputs/DatePicker';
import WeekDaysCheckbox from './components/WeekDaysCheckbox';
import { CalendarCheck } from 'react-bootstrap-icons';
import { filterPassedTime, TIME_FORMAT } from 'constants/date';

const TimeRangePicker = () => (
  <Col sm={6}>
    <DateTimeRangePicker
      isRequired
      timeClassName={() => 'hide-disabled-times'}
      label="appointments.fields.timeRange.label"
      endName="endTime"
      startName="startTime"
      dateFormat={TIME_FORMAT}
      filterTime={filterPassedTime}
      showTimeSelect
      showTimeSelectOnly
    />
  </Col>
);

const DateSection: FC<{ type: AppointmentType }> = ({ type }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Col sm={6}>
        {type === AppointmentTypes.RECURRING_BOOKING_SLOT ? (
          <DateTimeRangePicker
            isRequired
            label="appointments.fields.dateRange.label"
            endName="endDate"
            startName="startDate"
          />
        ) : (
          <Row>
            <Col sm={6}>
              <FastField
                isRequired
                name="startDate"
                component={DatePickerFormik}
                // minDate={new Date()}
                onChange={(date: Date | null) => {
                  setFieldValue('endDate', date, false);
                }}
                label="appointments.fields.date.label"
                placeholder="appointments.fields.date.placeholder"
                description="appointments.fields.date.description"
                icon={CalendarCheck}
              />
            </Col>
            <TimeRangePicker />
          </Row>
        )}
      </Col>
      {type === AppointmentTypes.RECURRING_BOOKING_SLOT ? (
        <>
          <TimeRangePicker />
          <Col sm={6}>
            <WeekDaysCheckbox />
          </Col>
        </>
      ) : null}
    </>
  );
};

export default DateSection;
