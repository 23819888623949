import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormConfigContext } from '../context';

const CardField: FC<{ fieldConfigName?: string; label: string; right?: React.ReactNode }> = ({
  fieldConfigName,
  label,
  children,
  right,
}) => {
  const { t } = useTranslation();
  const formConfig = useFormConfigContext();
  if (formConfig && fieldConfigName && !formConfig[fieldConfigName]?.visible) return null;
  return (
    <div className="mb-2">
      <div className="text-muted fw-bold">
        {t(label)} {right}
      </div>
      <small>{children ? children : t('notApplicable')}</small>
    </div>
  );
};

export default CardField;
