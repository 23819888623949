import React from 'react';
import Loading from './Loading';
import s from './LoadingOverlay.module.sass';

const LoadingOverlay = () => (
  <div className={s.fullscreen}>
    <Loading size={60} />
  </div>
);

export default LoadingOverlay;
