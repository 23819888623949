import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAppointmentMutationVariables = SchemaTypes.Exact<{
  force?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Boolean']>;
  id: SchemaTypes.Scalars['String'];
}>;

export type DeleteAppointmentMutation = {
  __typename?: 'Mutation';
  deleteAppointment?: { __typename?: 'Appointment'; id: string; status: SchemaTypes.AppointmentStatus } | null;
};

export const DeleteAppointmentDocument = gql`
  mutation DeleteAppointment($force: Boolean, $id: String!) {
    deleteAppointment(force: $force, id: $id) {
      id
      status
    }
  }
`;
export type DeleteAppointmentMutationFn = Apollo.MutationFunction<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;

/**
 * __useDeleteAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentMutation, { data, loading, error }] = useDeleteAppointmentMutation({
 *   variables: {
 *      force: // value for 'force'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(
    DeleteAppointmentDocument,
    options,
  );
}
export type DeleteAppointmentMutationHookResult = ReturnType<typeof useDeleteAppointmentMutation>;
export type DeleteAppointmentMutationResult = Apollo.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;
