import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type AutomationConfigFragment = {
  __typename?: 'AutomationConfig';
  id: string;
  isMaxToursDuringDayByTourTopic: boolean;
  maxParticipants: number;
  maxParticipantsInGroup: number;
  maxToursDuringDay?: number | null;
  participantsManualReviewRangeEnd: number;
  participantsManualReviewRangeStart: number;
};

export type AutomationConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const AutomationConfigFragmentDoc = gql`
  fragment AutomationConfig on AutomationConfig {
    id
    isMaxToursDuringDayByTourTopic
    maxParticipants
    maxParticipantsInGroup
    maxToursDuringDay
    participantsManualReviewRangeEnd
    participantsManualReviewRangeStart
  }
`;
