import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type CustomerForUpdatingAppointmentFragment = {
  __typename?: 'Customer';
  id: string;
  salutation: SchemaTypes.Salutation;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  organization: {
    __typename?: 'CustomerOrganization';
    id: string;
    name?: string | null;
    address?: string | null;
    buildingNumber?: string | null;
    zip?: string | null;
    city?: string | null;
    uidNumber?: string | null;
  };
};

export type CustomerForUpdatingAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CustomerForUpdatingAppointmentFragmentDoc = gql`
  fragment CustomerForUpdatingAppointment on Customer {
    id
    salutation
    firstName
    lastName
    email
    phoneNumber
    organization {
      id
      name
      address
      buildingNumber
      zip
      city
      uidNumber
    }
  }
`;
