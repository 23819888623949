import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupForUpdatingAppointmentFragmentDoc } from '../../targetGroup/generated/targetGroupForUpdatingAppointmentFragment';
export type CustomerTargetGroupForUpdatingAppointmentFragment = {
  __typename?: 'Customer';
  id: string;
  organization: {
    __typename?: 'CustomerOrganization';
    id: string;
    targetGroup: {
      __typename?: 'TargetGroup';
      code: string;
      id: string;
      name: { __typename?: 'Localized'; en: string; de: string };
      bookingFormConfig?: {
        __typename?: 'TargetGroupBookingFormConfig';
        fieldConfig: Array<{
          __typename?: 'TargetGroupFieldConfig';
          visible: boolean;
          required: boolean;
          name: string;
        }>;
      } | null;
    };
  };
};

export type CustomerTargetGroupForUpdatingAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CustomerTargetGroupForUpdatingAppointmentFragmentDoc = gql`
  fragment CustomerTargetGroupForUpdatingAppointment on Customer {
    id
    organization {
      id
      targetGroup {
        ...TargetGroupForUpdatingAppointment
      }
    }
  }
  ${TargetGroupForUpdatingAppointmentFragmentDoc}
`;
