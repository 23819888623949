import ConfirmModal from 'components/common/Modal/ConfirmModal';
import client from 'graphql/apollo';
import {
  DeleteProductDocument,
  DeleteProductMutation,
  DeleteProductMutationVariables,
} from 'graphql/mutations/product/generated/DeleteProduct';
import { Product } from 'graphql/types.generated';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getRefetchQueries } from './utils';

const deleteProductModal = (product: Pick<Product, 'id' | 'type'>) => {
  ConfirmModal({
    headerText: i18n.t('systemConfig.deleteProductConfirmation.title'),
    description: i18n.t('systemConfig.deleteProductConfirmation.description'),
    onOk: async () => {
      await client.mutate<DeleteProductMutation, DeleteProductMutationVariables>({
        mutation: DeleteProductDocument,
        variables: { id: product.id },
        refetchQueries: getRefetchQueries(product.type),
      });
      toast.success(i18n.t('systemConfig.deleteProductConfirmation.success'));
    },
  });
};

export default deleteProductModal;
