import React from 'react';
import RButton from 'react-bootstrap/Button';
import { ButtonProps } from 'react-bootstrap';
import cn from 'classnames';
import Loading from '../loading/Loading';
import s from './Button.module.sass';

interface iButtonProps extends ButtonProps {
  loading?: boolean;
  endIcon?: React.ReactNode;
}

const Button: FC<iButtonProps> = ({
  children,
  className,
  onClick,
  disabled = false,
  loading = false,
  size,
  type = 'button',
  variant = 'primary',
  endIcon = null,
}) => {
  return (
    <RButton
      {...{
        variant,
        size,
        onClick,
        type,
        disabled: disabled || loading,
        className: cn(s.btn, className, { [s.endIcon]: !!endIcon }),
      }}
    >
      <div style={loading ? { visibility: 'hidden' } : {}}>{children}</div>
      {endIcon ?? null}
      {loading ? (
        <div className={s.btnLoading}>
          <Loading />
        </div>
      ) : null}
    </RButton>
  );
};

export default Button;
