import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type BookingkitEventPricesFragment = {
  __typename?: 'BookingkitEvent';
  id: string;
  prices: Array<{ __typename?: 'BookingkitEventPrice'; id: string; title: string; value: number; currency: string }>;
};

export type BookingkitEventPricesFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingkitEventPricesFragmentDoc = gql`
  fragment BookingkitEventPrices on BookingkitEvent {
    id
    prices {
      id
      title
      value
      currency
    }
  }
`;
