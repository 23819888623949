import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingRequestForUpdatingAppointmentFragmentDoc } from '../../../fragments/bookingRequest/generated/bookingRequestForUpdatingAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingRequestForUpdatingAppointmentQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type BookingRequestForUpdatingAppointmentQuery = {
  __typename?: 'Query';
  bookingRequest: {
    __typename?: 'BookingRequest';
    id: string;
    date: string | Date;
    altDate: string | Date;
    defaultProduct: { __typename?: 'Product'; id: string };
    customer: {
      __typename?: 'Customer';
      id: string;
      organization: {
        __typename?: 'CustomerOrganization';
        id: string;
        targetGroup: {
          __typename?: 'TargetGroup';
          code: string;
          id: string;
          name: { __typename?: 'Localized'; en: string; de: string };
          bookingFormConfig?: {
            __typename?: 'TargetGroupBookingFormConfig';
            fieldConfig: Array<{
              __typename?: 'TargetGroupFieldConfig';
              visible: boolean;
              required: boolean;
              name: string;
            }>;
          } | null;
        };
      };
    };
  };
};

export const BookingRequestForUpdatingAppointmentDocument = gql`
  query BookingRequestForUpdatingAppointment($id: String!) {
    bookingRequest(id: $id) {
      ...BookingRequestForUpdatingAppointment
    }
  }
  ${BookingRequestForUpdatingAppointmentFragmentDoc}
`;

/**
 * __useBookingRequestForUpdatingAppointmentQuery__
 *
 * To run a query within a React component, call `useBookingRequestForUpdatingAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestForUpdatingAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestForUpdatingAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestForUpdatingAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookingRequestForUpdatingAppointmentQuery,
    BookingRequestForUpdatingAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingRequestForUpdatingAppointmentQuery, BookingRequestForUpdatingAppointmentQueryVariables>(
    BookingRequestForUpdatingAppointmentDocument,
    options,
  );
}
export function useBookingRequestForUpdatingAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingRequestForUpdatingAppointmentQuery,
    BookingRequestForUpdatingAppointmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BookingRequestForUpdatingAppointmentQuery,
    BookingRequestForUpdatingAppointmentQueryVariables
  >(BookingRequestForUpdatingAppointmentDocument, options);
}
export type BookingRequestForUpdatingAppointmentQueryHookResult = ReturnType<
  typeof useBookingRequestForUpdatingAppointmentQuery
>;
export type BookingRequestForUpdatingAppointmentLazyQueryHookResult = ReturnType<
  typeof useBookingRequestForUpdatingAppointmentLazyQuery
>;
export type BookingRequestForUpdatingAppointmentQueryResult = Apollo.QueryResult<
  BookingRequestForUpdatingAppointmentQuery,
  BookingRequestForUpdatingAppointmentQueryVariables
>;
export function refetchBookingRequestForUpdatingAppointmentQuery(
  variables: BookingRequestForUpdatingAppointmentQueryVariables,
) {
  return { query: BookingRequestForUpdatingAppointmentDocument, variables: variables };
}
