import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingRequestForUpdatingFragmentDoc } from '../../../fragments/bookingRequest/generated/bookingRequestForUpdatingFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingRequestForUpdatingQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type BookingRequestForUpdatingQuery = {
  __typename?: 'Query';
  bookingRequest: {
    __typename?: 'BookingRequest';
    id: string;
    date: string | Date;
    altDate: string | Date;
    totalParticipants: number;
    totalAccompanyingPersons?: number | null;
    ageOfParticipants?: string | null;
    tourTopicId: string;
    language: SchemaTypes.TourLanguage;
    howDidYouHear?: string | null;
    customer: {
      __typename?: 'Customer';
      id: string;
      organization: {
        __typename?: 'CustomerOrganization';
        id: string;
        targetGroup: {
          __typename?: 'TargetGroup';
          id: string;
          bookingFormConfig?: {
            __typename?: 'TargetGroupBookingFormConfig';
            fieldConfig: Array<{
              __typename?: 'TargetGroupFieldConfig';
              visible: boolean;
              required: boolean;
              name: string;
            }>;
          } | null;
        };
      };
    };
  };
};

export const BookingRequestForUpdatingDocument = gql`
  query BookingRequestForUpdating($id: String!) {
    bookingRequest(id: $id) {
      ...BookingRequestForUpdating
    }
  }
  ${BookingRequestForUpdatingFragmentDoc}
`;

/**
 * __useBookingRequestForUpdatingQuery__
 *
 * To run a query within a React component, call `useBookingRequestForUpdatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestForUpdatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestForUpdatingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestForUpdatingQuery(
  baseOptions: Apollo.QueryHookOptions<BookingRequestForUpdatingQuery, BookingRequestForUpdatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingRequestForUpdatingQuery, BookingRequestForUpdatingQueryVariables>(
    BookingRequestForUpdatingDocument,
    options,
  );
}
export function useBookingRequestForUpdatingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestForUpdatingQuery, BookingRequestForUpdatingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingRequestForUpdatingQuery, BookingRequestForUpdatingQueryVariables>(
    BookingRequestForUpdatingDocument,
    options,
  );
}
export type BookingRequestForUpdatingQueryHookResult = ReturnType<typeof useBookingRequestForUpdatingQuery>;
export type BookingRequestForUpdatingLazyQueryHookResult = ReturnType<typeof useBookingRequestForUpdatingLazyQuery>;
export type BookingRequestForUpdatingQueryResult = Apollo.QueryResult<
  BookingRequestForUpdatingQuery,
  BookingRequestForUpdatingQueryVariables
>;
export function refetchBookingRequestForUpdatingQuery(variables: BookingRequestForUpdatingQueryVariables) {
  return { query: BookingRequestForUpdatingDocument, variables: variables };
}
