import React from 'react';
import { Trans } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import updateCustomerModal from 'components/app/bookingRequestAndAppointment/modals/updateCustomerModal';
import { BookingRequestViewDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestView';
import { InternalRefetchQueryDescriptor } from '@apollo/client';

const PrivateTourAlert = ({
  customerId,
  bookingRequestId,
  refetchQueries,
}: {
  customerId: string;
  bookingRequestId: string;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}) => {
  return (
    <Alert variant="info" className="mb-3">
      <Trans
        i18nKey="appointments.privateTourAlert"
        components={{
          a: (
            <span
              className="text-decoration-underline cursor-pointer user-select-auto"
              onClick={() =>
                updateCustomerModal({
                  customerId,
                  refetchQueries: [
                    { query: BookingRequestViewDocument, variables: { id: bookingRequestId } },
                    ...(refetchQueries ?? []),
                  ],
                })
              }
            />
          ),
        }}
      />
    </Alert>
  );
};

export default PrivateTourAlert;
