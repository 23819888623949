import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type UserManagementFragment = {
  __typename?: 'User';
  id: string;
  deactivated?: boolean | null;
  lastActivityDate?: string | Date | null;
  role: SchemaTypes.Roles;
  email: string;
  profile: { __typename?: 'UserProfile'; fullName: string; phoneNumber?: string | null };
};

export type UserManagementFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const UserManagementFragmentDoc = gql`
  fragment UserManagement on User {
    id
    deactivated
    lastActivityDate
    role
    profile {
      fullName
      phoneNumber
    }
    email
  }
`;
