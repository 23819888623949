import React from 'react';
import Lottie from 'lottie-react';
import completed from './completed.json';
import { useTranslation } from 'react-i18next';
import { House } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import s from './Completed.module.sass';
import config from 'config/config';

const Completed = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center">
      <Lottie animationData={completed} loop={false} className={s.email} />
      <h4>{t('bookingRequest.completed.title')}</h4>
      <div className="font-weight-normal">{t('bookingRequest.completed.description')}</div>
      <br />
      <Button className="d-flex gap-3 align-items-center" href={config.shadesToursLanding}>
        <House />
        {t('bookingRequest.completed.goBack')}
      </Button>
      <br />
    </div>
  );
};

export default Completed;
