import { unsetBookingRequestValues } from 'components/app/bookingRequest';
import { TargetGroupFieldConfig, TourLanguage } from 'graphql/types.generated';

export const formatInput = (
  values: BookingRequestFormValues,
  formConfig: TargetGroupFieldConfig[] | undefined,
  isEdit = false,
) => {
  const bookingRequestInput = unsetBookingRequestValues(
    {
      altDate: values.altDateTime,
      date: values.dateTime,
      language: values.language,
      totalParticipants: values.totalParticipants,
      tourTopicId: values.tourTopicId,
      howDidYouHear: values.howDidYouHear,
      totalAccompanyingPersons: values.totalAccompanyingPersons,
      ...(isEdit ? {} : { ageOfParticipants: values.ageOfParticipants }),
    },
    formConfig,
  );
  return bookingRequestInput;
};

export type BookingRequestFormValues = {
  tourTopicId?: string;
  dateTime?: Date;
  altDateTime?: Date;
  totalParticipants?: number;
  totalAccompanyingPersons?: number;
  ageOfParticipants: string;
  language?: TourLanguage;
  howDidYouHear: string;
};

export const defaultInitialValues: BookingRequestFormValues = {
  tourTopicId: undefined,
  dateTime: undefined,
  altDateTime: undefined,
  totalParticipants: undefined,
  totalAccompanyingPersons: undefined,
  language: undefined,
  ageOfParticipants: '',
  howDidYouHear: '',
};
