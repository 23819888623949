import { Editor } from 'slate';
import isUrl from 'is-url';
import { wrapLink } from './withLink';
import { BLOCK_ELEMENT } from '../constants';

export const withInlines = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    [BLOCK_ELEMENT.MENTION, BLOCK_ELEMENT.LINK].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
