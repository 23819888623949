import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupForBookingRequestFormFragmentDoc } from '../../../fragments/targetGroup/generated/targetGroupForBookingRequestFormFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TargetGroupsForBookingRequestFormQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TargetGroupsForBookingRequestFormQuery = {
  __typename?: 'Query';
  targetGroups: Array<{
    __typename?: 'TargetGroup';
    id: string;
    name: { __typename?: 'Localized'; en: string; de: string };
    bookingFormConfig?: {
      __typename?: 'TargetGroupBookingFormConfig';
      iconUrl: string;
      description: { __typename?: 'Localized'; en: string; de: string };
      offerDescription: { __typename?: 'Localized'; en: string; de: string };
      fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
    } | null;
  }>;
};

export const TargetGroupsForBookingRequestFormDocument = gql`
  query TargetGroupsForBookingRequestForm {
    targetGroups {
      ...TargetGroupForBookingRequestForm
    }
  }
  ${TargetGroupForBookingRequestFormFragmentDoc}
`;

/**
 * __useTargetGroupsForBookingRequestFormQuery__
 *
 * To run a query within a React component, call `useTargetGroupsForBookingRequestFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetGroupsForBookingRequestFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetGroupsForBookingRequestFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useTargetGroupsForBookingRequestFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TargetGroupsForBookingRequestFormQuery,
    TargetGroupsForBookingRequestFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TargetGroupsForBookingRequestFormQuery, TargetGroupsForBookingRequestFormQueryVariables>(
    TargetGroupsForBookingRequestFormDocument,
    options,
  );
}
export function useTargetGroupsForBookingRequestFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TargetGroupsForBookingRequestFormQuery,
    TargetGroupsForBookingRequestFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TargetGroupsForBookingRequestFormQuery, TargetGroupsForBookingRequestFormQueryVariables>(
    TargetGroupsForBookingRequestFormDocument,
    options,
  );
}
export type TargetGroupsForBookingRequestFormQueryHookResult = ReturnType<
  typeof useTargetGroupsForBookingRequestFormQuery
>;
export type TargetGroupsForBookingRequestFormLazyQueryHookResult = ReturnType<
  typeof useTargetGroupsForBookingRequestFormLazyQuery
>;
export type TargetGroupsForBookingRequestFormQueryResult = Apollo.QueryResult<
  TargetGroupsForBookingRequestFormQuery,
  TargetGroupsForBookingRequestFormQueryVariables
>;
export function refetchTargetGroupsForBookingRequestFormQuery(
  variables?: TargetGroupsForBookingRequestFormQueryVariables,
) {
  return { query: TargetGroupsForBookingRequestFormDocument, variables: variables };
}
