import React, { memo } from 'react';
import { Button } from 'react-bootstrap';
import { Transforms, Editor, Element as SlateElement, Node } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import {
  CustomTextMark,
  iComponentProps,
  RootElement,
  Value,
  MarkModifier,
  AllModifiers,
  CustomElement,
} from '../RichEditor.type';
import cn from 'classnames';
import equal from 'fast-deep-equal/es6/react';
import { BLOCK_ELEMENT, BLOCK_ELEMENT_ARRAY, LIST_TYPES } from '../constants';

export const toggleMark = (editor: Editor, format: MarkModifier, value?: Value, disallowUnset?: boolean) => {
  const isActive = isMarkActive(editor, format);

  if (isActive && !disallowUnset) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value ?? true);
  }
};

const isBlockActive = (editor: Editor, format: BLOCK_ELEMENT): boolean => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });
  return !!match;
};

const isMarkActive = (editor: Editor, format: MarkModifier): boolean => {
  const marks = Editor.marks(editor);
  return marks ? !!marks[format as CustomTextMark] : false;
};

const getMarkValue = (editor: Editor, format: MarkModifier): Value => {
  const marks = Editor.marks(editor);
  return marks ? marks[format as CustomTextMark] : undefined;
};

const toggleBlock = (editor: Editor, format: BLOCK_ELEMENT) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    // eslint-disable-next-line no-nested-ternary
    type: isActive ? 'div' : isList ? 'list-item' : format,
  } as Partial<Node>;
  Transforms.setNodes(editor, newProperties);
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block as CustomElement);
  }
};

interface iBlockButtonProps {
  icon?: React.ReactElement;
  customFn?: (editor: Editor, format: AllModifiers) => void;
}

export interface iRenderButtonProps {
  rootElement: RootElement;
  format: AllModifiers;
}

export interface iBlockComponentProps extends iBlockButtonProps, iRenderButtonProps {
  component: FC<iComponentProps>;
  disallowUnset?: boolean;
}

export const BlockComponent: FC<iBlockComponentProps> = ({
  format,
  component: Component,
  disallowUnset,
  customFn,
  ...props
}) => {
  const editor = useSlate() as Editor;
  const isBlock = BLOCK_ELEMENT_ARRAY.includes(format as BLOCK_ELEMENT) as boolean;
  return (
    <Component
      getValue={() => {
        if (isBlock) return isBlockActive(editor, format as BLOCK_ELEMENT);
        else return getMarkValue(editor, format as MarkModifier);
      }}
      isActive={() => {
        if (isBlock) return isBlockActive(editor, format as BLOCK_ELEMENT);
        else return isMarkActive(editor, format as MarkModifier);
      }}
      onChange={(event, value, resetFocus = true) => {
        event.preventDefault();
        if (customFn) customFn(editor, format);
        else {
          if (isBlock) toggleBlock(editor, format as BLOCK_ELEMENT);
          else toggleMark(editor, format as MarkModifier, value, disallowUnset);
        }
        resetFocus && ReactEditor.focus(editor);
      }}
      {...props}
    />
  );
};

interface iBlockButton extends iComponentProps, Omit<iBlockButtonProps, 'customFn'> {}

const BlockButtonMemo: FC<iBlockButton> = memo(function BlockButton({ icon, onChange, isActive }) {
  return (
    <Button
      className={cn('p-0', isActive() ? 'text-primary' : 'text-muted')}
      variant="white"
      onMouseDown={(event) => {
        onChange(event);
      }}
    >
      {icon}
    </Button>
  );
}, equal);

const BlockButtonContainerMemo: FC<Omit<iBlockComponentProps, 'component'>> = memo(function BlockButtonContainer(
  props,
) {
  return <BlockComponent component={BlockButtonMemo} {...props} />;
},
equal);

export default BlockButtonContainerMemo;
