import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type EmailTemplateSettingFragment = {
  __typename?: 'EmailTemplate';
  id: string;
  name: string;
  language: SchemaTypes.TourLanguage;
  subject: string;
  targetGroupId?: string | null;
  body: string;
  createdAt: string | Date;
};

export type EmailTemplateSettingFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const EmailTemplateSettingFragmentDoc = gql`
  fragment EmailTemplateSetting on EmailTemplate {
    id
    name
    language
    subject
    targetGroupId
    body
    createdAt
  }
`;
