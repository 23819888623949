import React, { useCallback, useMemo } from 'react';
import s from './BookingRequestControls.module.sass';
import ControlsLayout, {
  iControlsLayout,
  iControlsStates,
} from 'components/app/bookingRequestAndAppointment/ControlsLayout';
import Select from 'react-select';
import { selectStyles } from 'components/inputs/Select/Select';
import { useTranslation } from 'react-i18next';
import { SortUp } from 'react-bootstrap-icons';
import { InputGroup } from 'react-bootstrap';
import { BookingRequestSort } from 'graphql/types.generated';

export const initialValues = {
  sort: BookingRequestSort.NEWEST_REQUEST,
};

interface iBookingRequestControls extends iControlsLayout, iControlsStates<typeof initialValues> {}

const BookingRequestControls = ({ state, setState, extra }: iBookingRequestControls) => {
  const { t } = useTranslation();

  const sortOptions = useMemo(
    () =>
      Object.values(BookingRequestSort).map((sort) => ({
        value: sort,
        label: t(`bookingRequestSort.${sort}`),
      })),
    [t],
  );
  const optionValue = useMemo(() => {
    const option = sortOptions.find((o) => o.value === state.sort);
    return option || null;
  }, [sortOptions, state.sort]);

  const onInputChange = useCallback(({ value }) => setState((s) => ({ ...s, sort: value })), [setState]);

  return (
    <ControlsLayout extra={extra}>
      <div className={s.sort}>
        <InputGroup className="flex-nowrap">
          <InputGroup.Text>
            <SortUp />
          </InputGroup.Text>
          <Select
            options={sortOptions}
            value={optionValue}
            isSearchable={false}
            isClearable={false}
            styles={selectStyles}
            onChange={onInputChange}
            components={{ IndicatorSeparator: () => null }}
          />
        </InputGroup>
      </div>
    </ControlsLayout>
  );
};

export default BookingRequestControls;
