import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type DisabledCodeFragment = {
  __typename?: 'DisabledCode';
  tourTopic: { __typename?: 'TourTopic'; id: string; code: string };
  targetGroup: { __typename?: 'TargetGroup'; id: string; code: string };
};

export type DisabledCodeFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const DisabledCodeFragmentDoc = gql`
  fragment DisabledCode on DisabledCode {
    tourTopic {
      id
      code
    }
    targetGroup {
      id
      code
    }
  }
`;
