import React from 'react';
import Formik from 'components/common/Formik';
import { Form } from 'formik';
import { BookingContextResult, useBookingContext } from '../context';
import Controls from './Controls';

export type ValidationSchemaParams = {
  formConfig: BookingContextResult['formConfig'];
};

export type StepLayoutProps = {
  validationSchema?: (
    params: ValidationSchemaParams,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
};

const StepLayout: FC<StepLayoutProps> = ({ children, validationSchema }) => {
  const { onSubmit, values, formConfig } = useBookingContext();
  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      {...(typeof validationSchema === 'function' && {
        validationSchema: () => validationSchema({ formConfig }),
      })}
      validateOnMount
    >
      <Form>
        {children}
        <Controls />
      </Form>
    </Formik>
  );
};

export default StepLayout;
