import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
import { FieldConfigFragmentDoc } from './fieldConfigFragment';
export type TargetGroupForBookingRequestFormFragment = {
  __typename?: 'TargetGroup';
  id: string;
  name: { __typename?: 'Localized'; en: string; de: string };
  bookingFormConfig?: {
    __typename?: 'TargetGroupBookingFormConfig';
    iconUrl: string;
    description: { __typename?: 'Localized'; en: string; de: string };
    offerDescription: { __typename?: 'Localized'; en: string; de: string };
    fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
  } | null;
};

export type TargetGroupForBookingRequestFormFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TargetGroupForBookingRequestFormFragmentDoc = gql`
  fragment TargetGroupForBookingRequestForm on TargetGroup {
    id
    name {
      ...Localized
    }
    bookingFormConfig {
      iconUrl
      description {
        ...Localized
      }
      offerDescription {
        ...Localized
      }
      fieldConfig {
        ...FieldConfig
      }
    }
  }
  ${LocalizedFragmentDoc}
  ${FieldConfigFragmentDoc}
`;
