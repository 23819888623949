import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  withReact,
  Slate,
  useSlateStatic,
  Editable,
  RenderLeafProps,
  RenderElementProps,
  ReactEditor,
} from 'slate-react';
import { Text, createEditor, Descendant, Editor, Node, Range, Transforms, Element as SlateElement } from 'slate';
import { withHistory } from 'slate-history';
import s from './RichEditor.module.sass';
import cn from 'classnames';
import { getStyles, getValueForDebug, getValueForI18n, tryGetInitialValue } from './utils';
import { Mention, withMentions } from './plugins/withMentions';
import SlateToolbar, { iSlateToolbarProps } from './components/SlateToolbar';
import { cloneDeep, isEqual } from 'lodash';
import { toggleMark } from './components/BlockButton';
import { withInlines } from './plugins/withInlines';
import { isKeyHotkey } from 'is-hotkey';
import { ImageElement, RootElement } from './RichEditor.type';
import { BLOCK_ELEMENT } from './constants';
import { withImages } from './plugins/withImage';
import Image from './components/Image';
import { withCorrectVoidBehavior } from './plugins/withCorrectVoidBehavior';

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  const style: React.CSSProperties = {};
  if ('text-align' in element) style.textAlign = element['text-align'];
  const attr = { ...attributes, style };
  switch (element.type) {
    case BLOCK_ELEMENT.BLOCK_QUOTE:
      return (
        <blockquote className={cn('text-muted', s.blockquote)} {...attr}>
          {children}
        </blockquote>
      );
    case BLOCK_ELEMENT.BULLETED_LIST:
      return <ul {...attr}>{children}</ul>;
    case BLOCK_ELEMENT.NUMBERED_LIST:
      return <ol {...attr}>{children}</ol>;
    case BLOCK_ELEMENT.LIST_ITEM:
      return <li {...attr}>{children}</li>;
    case BLOCK_ELEMENT.MENTION:
      return <Mention {...props}>{children}</Mention>;
    case BLOCK_ELEMENT.IMAGE:
      return (
        <Image {...props} attributes={attr} element={element as ImageElement}>
          {children}
        </Image>
      );

    case BLOCK_ELEMENT.LINK:
      return (
        <a {...attr} href={'url' in element ? element.url : '#'} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    default:
      return <div {...attr}>{children}</div>;
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  const style = getStyles(leaf);

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

interface iSlateComponentProps extends iSlateToolbarProps {
  renderLeaf: (props: RenderLeafProps) => React.ReactElement;
  renderElement: (props: RenderElementProps) => React.ReactElement;
  readOnly?: boolean;
  disabled?: boolean;
  classNameContainer?: string;
}

const SlateComponentWithRef = forwardRef<ReactEditor, iSlateComponentProps>(function SlateComponent(
  {
    renderLeaf,
    renderElement,
    toolbarChildren,
    allowedModifiers,
    rootElement,
    className,
    readOnly,
    classNameContainer,
  },
  ref,
) {
  const editor = useSlateStatic() as Editor;
  useImperativeHandle(ref, () => editor);
  return (
    <div className={cn('form-control p-0', className, classNameContainer)}>
      <SlateToolbar
        readOnly={readOnly}
        className="p-1 border-bottom rich-editor-toolbar"
        allowedModifiers={allowedModifiers}
        rootElement={rootElement}
        toolbarChildren={toolbarChildren}
      />
      <div className="rich-editor-content">
        <Editable
          readOnly={readOnly}
          renderLeaf={renderLeaf}
          renderElement={renderElement}
          spellCheck
          onKeyDown={(event) => {
            // ignore modal submission
            if (isKeyHotkey('enter', event.nativeEvent)) {
              event.preventDefault();
              editor.insertBreak();
            }
            // soft break
            if (isKeyHotkey('shift+enter', event.nativeEvent)) {
              event.preventDefault();
              editor.insertText('\n');
            }
            // tabulator
            if (isKeyHotkey('tab', event.nativeEvent) && !isKeyHotkey('shift', event.nativeEvent)) {
              event.preventDefault();
              const { selection } = editor;
              if (selection && Range.isCollapsed(selection)) {
                editor.insertText('\t');
              }
            }
            // remove tabulator
            if (isKeyHotkey('shift+tab', event.nativeEvent)) {
              event.preventDefault();
              const { selection } = editor;
              if (selection && Range.isCollapsed(selection)) {
                const node = Node.get(editor, selection.anchor.path);
                if (Text.isText(node) && node.text[selection.anchor.offset - 1] === '\t')
                  Editor.deleteBackward(editor, { unit: 'character' });
              }
            }
            // highlighted text as bold
            if (isKeyHotkey('mod+b', event.nativeEvent)) {
              event.preventDefault();
              toggleMark(editor, 'bold');
            }
            // highlighted text as italic
            if (isKeyHotkey('mod+i', event.nativeEvent)) {
              event.preventDefault();
              toggleMark(editor, 'italic');
            }
            // highlighted text as underline
            if (isKeyHotkey('mod+u', event.nativeEvent)) {
              event.preventDefault();
              toggleMark(editor, 'underline');
            }
            // Here we modify the behavior to unit:'offset'.
            // This lets the user step into and out of the inline without stepping over characters.
            if (isKeyHotkey('left', event.nativeEvent)) {
              if (!editor.selection) return;
              event.preventDefault();
              if (Range.isCollapsed(editor.selection)) Transforms.move(editor, { unit: 'offset', reverse: true });
              else Transforms.collapse(editor, { edge: 'start' });
              return;
            }
            if (isKeyHotkey('right', event.nativeEvent)) {
              if (!editor.selection) return;
              event.preventDefault();
              const selection = cloneDeep(editor.selection);
              if (Range.isCollapsed(editor.selection)) {
                Transforms.move(editor, { unit: 'offset' });
                if (
                  isEqual(selection, editor.selection) &&
                  Editor.above(editor, {
                    match: (node) => SlateElement.isElement(node) && BLOCK_ELEMENT.IMAGE === node.type,
                  })
                )
                  editor.insertBreak();
              } else Transforms.collapse(editor, { edge: 'end' });
              return;
            }
          }}
        />
      </div>
    </div>
  );
});

// window.showRichTextRaw = true;
// window.showRichTextForI18n = true;

export interface iRichEditorProps extends Omit<iSlateComponentProps, 'renderLeaf' | 'renderElement' | 'rootElement'> {
  rootElement?: RootElement;
  initialValue?: string;
  onChange?: (value: string) => void;
}

const RichTextExampleWithRef = forwardRef<Editor, iRichEditorProps>(function RichTextExample(
  { initialValue, onChange, rootElement = BLOCK_ELEMENT.DIV, ...props },
  ref,
) {
  const [key, setKey] = useState(1);
  const onUpdateKey = useCallback(() => setKey((p) => p + 1), []);
  const editor = useMemo(
    () => withCorrectVoidBehavior(withImages(withInlines(withMentions(withHistory(withReact(createEditor())))))),
    [],
  );
  const [value, setValue] = useState<Descendant[]>(() => tryGetInitialValue(initialValue));
  useImperativeHandle(ref, () => editor);
  const slateComponentRef: React.MutableRefObject<ReactEditor | null> = useRef(null);
  useEffect(() => {
    if (slateComponentRef.current && !ReactEditor.isFocused(slateComponentRef.current)) {
      const initialValueObj = tryGetInitialValue(initialValue, { rootElement });
      if (!isEqual(initialValueObj, value)) {
        setValue(initialValueObj);
        onUpdateKey();
      }
    }
  }, [initialValue, value, rootElement, onUpdateKey]);

  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);

  const onChangeMemo = useCallback(
    (newValue) => {
      setValue(newValue);
      window.setTimeout(() => {
        onChange && onChange(JSON.stringify(newValue));
      }, 0);
    },
    [onChange],
  );

  return (
    <Slate key={key} editor={editor} value={value} onChange={onChangeMemo}>
      <SlateComponentWithRef
        ref={slateComponentRef}
        rootElement={rootElement}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        {...props}
      />
      {getValueForI18n(value)}
      {getValueForDebug(value)}
    </Slate>
  );
});

export default RichTextExampleWithRef;
