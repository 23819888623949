import { productModal, deleteProductModal } from 'components/app/product';
import Table from 'components/common/Table';
import i18n from 'i18n';
import { FlagEmojis } from 'pages/app/UserProfilePage/UserProfilePage';
import React, { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { TFunction, useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { ProductsProps, Product } from './index.types';

const columns = ({ t }: { t: TFunction }) => [
  {
    key: 'name',
    title: t('systemConfig.columns.name'),
    render: (value: Product) => value.name,
    width: '40%',
  },
  {
    key: 'integrations',
    title: t('systemConfig.columns.integrations'),
    render: (value: Product) => {
      if (!value.bookingkitSyncEnabled) return 'N/A';
      return <Badge pill>{t('systemConfig.bookingkit')}</Badge>;
    },
  },
  {
    key: 'type',
    title: t('systemConfig.columns.type'),
    render: (value: Product) => t(`productType.${value.type}`),
    width: '20%',
  },
  {
    key: 'place',
    title: t('systemConfig.columns.place'),
    render: (value: Product) => value.meetingPointName,
  },
  {
    key: 'startPointAddress',
    title: t('systemConfig.columns.startPointAddress'),
    render: (value: Product) => value.startPointAddress,
  },
  {
    key: 'endPointAddress',
    title: t('systemConfig.columns.endPointAddress'),
    render: (value: Product) => value.endPointAddress,
  },
  {
    key: 'language',
    title: t('systemConfig.columns.language'),
    render: (value: Product) => FlagEmojis[value.language as keyof typeof FlagEmojis],
  },
  {
    key: 'tourTopic',
    title: t('systemConfig.columns.tourTopic'),
    render: (value: Product) => value.tourTopic?.name[i18n.language as keyof typeof value.tourTopic.name],
  },
  {
    key: 'actions',
    title: '',
    render: (value: Product) => (
      <div className="d-flex justify-content-end gap-3 text-muted">
        <Pencil className="cursor-pointer" onClick={() => productModal({ product: value })} />
        <Trash className="cursor-pointer" onClick={() => deleteProductModal(value)} />
      </div>
    ),
    width: '20%',
  },
];

const Products: ProductsProps = ({ data, loading }) => {
  const { t } = useTranslation();
  const tourTopicColumns = useMemo(() => columns({ t }), [t]);

  return (
    <Layout
      title={
        <>
          {t('systemConfig.products')} <Plus className="text-primary cursor-pointer" onClick={() => productModal()} />
        </>
      }
    >
      <Table className="table-header-border border" columns={tourTopicColumns} dataSource={data} loading={loading} />
    </Layout>
  );
};

export default Products;
