import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupFieldConfigFragmentDoc } from '../../targetGroup/generated/targetGroupFieldConfigFragment';
export type BookingRequestForUpdatingFragment = {
  __typename?: 'BookingRequest';
  id: string;
  date: string | Date;
  altDate: string | Date;
  totalParticipants: number;
  totalAccompanyingPersons?: number | null;
  ageOfParticipants?: string | null;
  tourTopicId: string;
  language: SchemaTypes.TourLanguage;
  howDidYouHear?: string | null;
  customer: {
    __typename?: 'Customer';
    id: string;
    organization: {
      __typename?: 'CustomerOrganization';
      id: string;
      targetGroup: {
        __typename?: 'TargetGroup';
        id: string;
        bookingFormConfig?: {
          __typename?: 'TargetGroupBookingFormConfig';
          fieldConfig: Array<{
            __typename?: 'TargetGroupFieldConfig';
            visible: boolean;
            required: boolean;
            name: string;
          }>;
        } | null;
      };
    };
  };
};

export type BookingRequestForUpdatingFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingRequestForUpdatingFragmentDoc = gql`
  fragment BookingRequestForUpdating on BookingRequest {
    id
    date
    altDate
    totalParticipants
    totalAccompanyingPersons
    ageOfParticipants
    tourTopicId
    language
    howDidYouHear
    customer {
      id
      organization {
        id
        targetGroup {
          ...TargetGroupFieldConfig
        }
      }
    }
  }
  ${TargetGroupFieldConfigFragmentDoc}
`;
