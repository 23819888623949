import React, { useCallback, useMemo, useState } from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import get from 'lodash/get';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import MenuList from './MenuList';
import theme from 'config/theme';
import { useTranslation } from 'react-i18next';
import s from './Select.module.sass';
import cn from 'classnames';
import { castArray, map } from 'lodash';
import FormDescription from '../FormDescription';
import { iFormDescription } from '../FormDescription/FormDescription';
import { iRequired, Required } from 'components/common/Required';

export const selectStyles: StylesConfig = {
  container: (style) => ({
    ...style,
    outline: 'none',
    boxSizing: 'border-box',
    marginBottom: '1px',
  }),
  control: (style) => ({
    ...style,
    border: '1px solid var(--bs-gray-400)',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: theme.gray800,
  }),
  valueContainer: (style) => ({
    ...style,
    padding: 0,
    marginLeft: '0.5em',
  }),
  singleValue: (style) => ({
    ...style,
    marginLeft: '2px',
  }),
  option: (style) => ({
    ...style,
    textAlign: 'left',
  }),
  menuPortal: (style) => ({ ...style, zIndex: 50 }),
};

export interface SelectOption {
  label: string;
  value: string | number | null;
}

interface iSelectProps extends iFormDescription, iRequired {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  placeholder: string;
  options: SelectOption[];
  isSearchable?: boolean;
  virtualizedList?: boolean;
  isClearable?: boolean;
  sideEffectOnSelect?: (value: string) => void;
  noOptionsMessage?: string;
  isMulti?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isHideError?: boolean;
}

const Select: FC<iSelectProps> = ({
  field,
  form,
  label,
  placeholder = '',
  options = [],
  isSearchable = false,
  virtualizedList = false,
  isClearable = false,
  sideEffectOnSelect,
  noOptionsMessage,
  isMulti,
  isRequired,
  loading,
  disabled,
  isHideError,
  description,
}) => {
  const { name, onBlur, value } = field;
  const { touched, errors, setFieldValue, isSubmitting } = form;
  const { t } = useTranslation();

  const isDisabled = disabled || isSubmitting;

  const [inputValue, setInputValue] = useState();

  const handleInputChange = useCallback((v) => {
    setInputValue(v);
  }, []);

  const handleChange = useCallback(
    (selectedOption) => {
      const selectedValue = isMulti
        ? map(selectedOption, (option) => get(option, 'value', null))
        : get(selectedOption, 'value', null);
      setFieldValue(field.name, selectedValue);
      if (typeof sideEffectOnSelect === 'function') sideEffectOnSelect(selectedValue);
    },
    [isMulti, setFieldValue, field.name, sideEffectOnSelect],
  );

  const optionValue = useMemo(() => {
    const arrayValue = castArray(value);
    const option = options[isMulti ? 'filter' : 'find']((o) => arrayValue.includes(o.value as string));
    return option || null;
  }, [value, options, isMulti]);

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <div className={isDisabled ? 'disabled-input' : ''}>
        <ReactSelect
          isDisabled={isDisabled}
          isLoading={loading}
          className={cn(s.select)}
          onInputChange={handleInputChange}
          inputId={field.name}
          value={optionValue}
          onChange={handleChange}
          blurInputOnSelect={false}
          name={field.name}
          styles={selectStyles}
          placeholder={placeholder && t(placeholder)}
          components={{ IndicatorSeparator: () => null, ...(virtualizedList ? { MenuList } : {}) }}
          menuPortalTarget={document.body}
          {...{ isMulti, isSearchable, options, isClearable, inputValue, onBlur }}
          {...(noOptionsMessage ? { noOptionsMessage: () => <div>{t(noOptionsMessage)}</div> } : {})}
        />
      </div>
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {isHideError || t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;
