import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type EmailMessageFragment = {
  __typename?: 'EmailMessage';
  id: string;
  subject: string;
  body: string;
  delivered?: boolean | null;
  emailClientResponse?: string | null;
  to: string;
  from: string;
  createdAt: string | Date;
  webhookEvents?: Array<{
    __typename?: 'MailgunWebhookEvent';
    id: string;
    event: string;
    deliveryStatusCode?: number | null;
    deliveryStatusMessage?: string | null;
    deliveryStatusDescription?: string | null;
    reason?: string | null;
    severity?: string | null;
    date: string | Date;
  }> | null;
  emailTemplate?: { __typename?: 'EmailTemplate'; id: string; name: string } | null;
};

export type EmailMessageFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const EmailMessageFragmentDoc = gql`
  fragment EmailMessage on EmailMessage {
    id
    subject
    body
    delivered
    webhookEvents {
      id
      event
      deliveryStatusCode
      deliveryStatusMessage
      deliveryStatusDescription
      reason
      severity
      date
    }
    emailClientResponse
    to
    from
    createdAt
    emailTemplate {
      id
      name
    }
  }
`;
