import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { FieldConfigFragmentDoc } from './fieldConfigFragment';
export type TargetGroupFieldConfigFragment = {
  __typename?: 'TargetGroup';
  id: string;
  bookingFormConfig?: {
    __typename?: 'TargetGroupBookingFormConfig';
    fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
  } | null;
};

export type TargetGroupFieldConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TargetGroupFieldConfigFragmentDoc = gql`
  fragment TargetGroupFieldConfig on TargetGroup {
    id
    bookingFormConfig {
      fieldConfig {
        ...FieldConfig
      }
    }
  }
  ${FieldConfigFragmentDoc}
`;
