import { GraphQLError } from 'graphql';
import { format } from 'date-fns';
import { de, enUS as en } from 'date-fns/locale';
import { ApolloError } from '@apollo/client';
import i18n, { Locales } from 'i18n';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { toast } from 'react-toastify';
import { DATE_TIME_FORMAT } from 'constants/date';

const phoneUtil = PhoneNumberUtil.getInstance();

const locales = { de, en };

export const wait = (timeout = 3000) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export type ErrorResponse = Error | ApolloError;

// Type must be augmented because of https://github.com/apollographql/apollo-link/issues/536
type NetworkError = ApolloError & { result: { errors: Error[] } };

export const formatGraphqlErrors = (
  error: ApolloError,
  resolver: (e: GraphQLError) => string | undefined = () => undefined,
) => {
  const graphqlErrorMsg = Array.isArray(error.graphQLErrors)
    ? error.graphQLErrors.map((err: GraphQLError) => resolver(err) ?? err.message).join('\n')
    : null;

  const networkError = error.networkError as NetworkError;
  const networkErrorResultMsg = Array.isArray(networkError?.result?.errors)
    ? networkError?.result?.errors.map((err) => err.message).join('\n')
    : null;
  const networkErrorMsg = networkError ? i18n.t('errors.serverConnectionError') : null;

  const msg = graphqlErrorMsg || networkErrorResultMsg || networkErrorMsg;
  const e: ErrorResponse = msg ? new Error(msg) : error;

  return e;
};

export const toastErrorMessage = (error: ApolloError, resolver?: (e: GraphQLError) => string | undefined) =>
  toast.error(formatGraphqlErrors(error, resolver).message);

export const formatDate = (date: Date | string, dateFormat = DATE_TIME_FORMAT) => {
  return format(new Date(date), dateFormat, { locale: locales[i18n.language as Locales] });
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  let number = phoneNumber;
  try {
    number = phoneUtil.format(phoneUtil.parse(`+${phoneNumber}`), PhoneNumberFormat.INTERNATIONAL);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error.message);
  }
  return number;
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(formatPhoneNumber(phoneNumber));
    if (!(phoneUtil.isValidNumber(number) && phoneUtil.isPossibleNumber(number))) return false;
  } catch {
    return false;
  }
  return true;
};
