import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type FieldConfigFragment = {
  __typename?: 'TargetGroupFieldConfig';
  visible: boolean;
  required: boolean;
  name: string;
};

export type FieldConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const FieldConfigFragmentDoc = gql`
  fragment FieldConfig on TargetGroupFieldConfig {
    visible
    required
    name
  }
`;
