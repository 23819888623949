import React from 'react';
import { iInputModalConfirm, InputModalConfirm } from 'components/common/Modal/InputModal';
import { Editor, Transforms, Element as SlateElement, Range, Location, Descendant } from 'slate';
import { LinkElement } from '../RichEditor.type';
import i18n from 'i18n';
import * as yup from 'yup';
import { cloneDeep } from 'lodash';
import { ReactEditor } from 'slate-react';
import FastField from 'components/common/FastField';
import TextInput from 'components/inputs/TextInput';
import { Trans } from 'react-i18next';
import { BLOCK_ELEMENT } from '../constants';

const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === BLOCK_ELEMENT.LINK,
  });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === BLOCK_ELEMENT.LINK,
  });
};

export const wrapLink = (editor: Editor, url: string, text?: string) => {
  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: BLOCK_ELEMENT.LINK,
    url,
    children: (isCollapsed ? [{ text }] : []) as Descendant[],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor: Editor, url: string, text: string) => {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
};

type InitialValues = {
  name: string;
  value: string;
};

const linkValidationSchema =
  (hideName = true) =>
  () =>
    yup.object().shape({
      ...(hideName
        ? {}
        : {
            name: yup.string().required().label(i18n.t('RichEditor.link.fields.name.label')),
          }),
      value: yup
        .string()
        .required()
        .test('check-url', i18n.t('RichEditor.link.fields.value.invalid'), (value) => {
          try {
            new URL(value as string);
            return true;
          } catch {
            return false;
          }
        })
        .label(i18n.t('RichEditor.link.fields.value.label')),
    });

const FormikContent = ({ hideName }: { hideName: boolean }) => (
  <>
    {hideName ? null : <FastField name="name" component={TextInput} label="RichEditor.link.fields.name.label" />}
    <FastField name="value" component={TextInput} label="RichEditor.link.fields.value.label" />
    <Trans i18nKey="RichEditor.link.fields.value.description" components={{ div: <div /> }} />
  </>
);
const createLinkModal = ({ hideName, onSubmit }: { hideName: boolean; onSubmit: (values: InitialValues) => void }) =>
  InputModalConfirm({
    formikContent: () => <FormikContent hideName={hideName} />,
    initialValues: { name: '', value: '' } as InitialValues,
    onSubmit,
    headerText: i18n.t('RichEditor.link.modalTitle'),
    validationSchema: linkValidationSchema(hideName),
  } as iInputModalConfirm<InitialValues>);

export const linkFn = (editor: Editor) => {
  if (!editor.selection) return;
  const prevSelection: Location = cloneDeep(editor.selection);
  const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
  const linkActive = isLinkActive(editor);
  if (linkActive) {
    ReactEditor.focus(editor);
    editor.selection && Transforms.select(editor, editor.selection);
    unwrapLink(editor);
  } else
    createLinkModal({
      hideName: !isCollapsed,
      onSubmit: ({ value, name }: InitialValues) => {
        ReactEditor.focus(editor);
        editor.selection && Transforms.select(editor, prevSelection);
        insertLink(editor, value, name);
      },
    });
};
