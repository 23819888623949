import { afterLogout } from 'hooks/auth/useLogout';
import RefreshTokenService from 'services/RefreshTokenService';
import { ApolloClient, ApolloLink, HttpLink, NormalizedCacheObject, split, gql } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import get from 'lodash/get';
import { backendErrors as enBackendErrors } from 'i18n/translations/en';
import { backendErrors as deBackendErrors } from 'i18n/translations/de';
import settings from 'config/config';
import config from 'config/config';
import { GraphQLError, print } from 'graphql';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { Observable, Operation, FetchResult } from '@apollo/client/core';
import { createClient, Client } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { values } from 'lodash';
import { LogoutDocument } from './mutations/auth/generated/Logout';
import { wait } from 'utils/helpers';
import errors from 'constants/errors';

class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

// eslint-disable-next-line prefer-const
let client: ApolloClient<NormalizedCacheObject>;
let key: string;

const wssLink = async () => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation getWsLink {
        getWsLink {
          url
          key
        }
      }
    `,
  });
  key = data.getWsLink.key;
  return data.getWsLink.url;
};

const wsLink = new GraphQLWsLink(
  createClient({
    retryWait: async () => {
      await wait(20000);
    },
    url: wssLink,
    connectionParams: () => {
      return { jwt: key };
    },
  }),
);

const httpLink = new HttpLink({
  uri: config.graphqlServerUrl,
  credentials: 'same-origin',
});

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const link = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      return RefreshTokenService.isTokenValidOrUndefined();
    },
    fetchAccessToken: () => {
      console.log('fetching new access token');
      return RefreshTokenService.fetchNewAccessToken();
    },
    handleFetch: () => console.log('refreshed a token with apollo TokenRefreshLink'),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleResponse: () => (response: any) => {
      return response;
    },
    handleError: () => {
      window.setTimeout(() => {
        window.setTimeout(async () => {
          try {
            await client.mutate({
              mutation: LogoutDocument,
            });
          } catch (e) {
            console.log('Error while logout', e);
          }
          afterLogout(client);
        });
      });
    },
  }),
  setContext((_, { headers }) => {
    return { headers };
  }),
  onError(({ graphQLErrors, operation }) => {
    const logError = (error: GraphQLError, errorType: string) => {
      const { message, locations, path, extensions } = error;

      if (path?.includes('login')) return;

      const lVariables = JSON.stringify(operation.variables);
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lVariables && `Variables: ${lVariables}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };

    if (graphQLErrors) graphQLErrors.forEach((error: GraphQLError) => logError(error, 'GraphQL error'));
  }),
  onError(({ graphQLErrors }) => {
    const locale = window?.localStorage?.getItem('language') || settings.defaultLocale;
    const backendErrorsTranslations = { de: deBackendErrors, en: enBackendErrors };

    const translateMessage = (error: GraphQLError) => {
      const messageKey = `${locale}.${error.message}`;
      const translated = get(backendErrorsTranslations, messageKey, error.message);
      if (
        error.message === 'Validation failed' &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values((error.extensions.exception as any).errors).find((e: any) => e.message === errors.duplicated)
      )
        error.message = errors.duplicated;
      else if (translated !== messageKey) {
        error.message = translated;
      }
    };
    if (graphQLErrors) {
      graphQLErrors.forEach(translateMessage);
    }
  }),
  splitLink,
]);

client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
});

export default client;
