import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
export type CustomerForAppointmentFragment = {
  __typename?: 'Customer';
  id: string;
  salutation: SchemaTypes.Salutation;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  email: string;
  organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
};

export type CustomerForAppointmentFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CustomerForAppointmentFragmentDoc = gql`
  fragment CustomerForAppointment on Customer {
    id
    salutation
    firstName
    lastName
    phoneNumber
    email
    organization {
      id
      name
    }
  }
`;
