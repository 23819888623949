import React from 'react';
import s from './BookingLayout.module.sass';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoSrc from 'assets/icons/logo-white.png';

type iBookingLayoutProps = {
  children: React.ReactNode;
};

const BookingLayout: FC<iBookingLayoutProps> = ({ children }) => {
  return (
    <Container className={s.container} fluid>
      <div className={s.background} />
      <Row className={s.row}>
        <Col className="p-4">
          <img className="d-block m-auto" src={logoSrc} />
          <div className="pt-5">{children}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default BookingLayout;
