import { FormikHelpers } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { BookingInitialValuesProps, onSubmitBookingRequest } from './BookingRequestPage.type';
import { find } from 'lodash';
import { FormConfig, fieldConfigArrayToObject } from 'components/bookingRequest/utils';
import { TargetGroupFieldConfigFragment } from 'graphql/fragments/targetGroup/generated/targetGroupFieldConfigFragment';
import { defaultInitialValues } from 'components/app/bookingRequest';

type OnSubmitProps = (values: BookingInitialValuesProps, helpers: FormikHelpers<BookingInitialValuesProps>) => void;

type OnBackProps = (values: BookingInitialValuesProps) => void;

export type BookingContextResult = {
  currentIndex: number;
  stepNumber: number;
  currentStep: FC;
  values: BookingInitialValuesProps;
  onSubmit: OnSubmitProps;
  onBack: OnBackProps;
  formConfig: FormConfig | undefined;
};

const BookingContext = React.createContext<BookingContextResult>({
  currentIndex: 0,
  stepNumber: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBack: () => {},
  currentStep: () => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: () => {},
  values: defaultInitialValues,
  formConfig: undefined,
});
export default BookingContext;

type BookingContextProps = {
  children: React.ReactNode;
  steps: FC[];
  completed: FC;
  onComplete: onSubmitBookingRequest;
  targetGroups: TargetGroupFieldConfigFragment[] | undefined;
};

export const BookingContextProvider: FC<BookingContextProps> = ({
  children,
  steps,
  completed,
  onComplete,
  targetGroups,
}) => {
  const valuesRef = useRef<BookingInitialValuesProps>(defaultInitialValues);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isCompleted, setCompleted] = useState<boolean>(false);
  const onBack = useCallback(
    (values: BookingInitialValuesProps) => {
      if (currentIndex) setCurrentIndex(currentIndex - 1);
      valuesRef.current = { ...valuesRef.current, ...values };
    },
    [currentIndex],
  );
  useEffect(() => {
    currentIndex;
    window.scrollTo(0, 0);
  }, [currentIndex]);

  const foundFieldConfig = useMemo(() => {
    return (find(targetGroups, { id: valuesRef.current?.targetGroupId }) as TargetGroupFieldConfigFragment | undefined)
      ?.bookingFormConfig?.fieldConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetGroups, valuesRef.current?.targetGroupId]);

  const onSubmit = useCallback<OnSubmitProps>(
    async (values, helpers) => {
      helpers.setSubmitting(true);
      const isLast = currentIndex === steps.length - 1;
      valuesRef.current = { ...valuesRef.current, ...values };
      try {
        if (isLast) {
          await onComplete(valuesRef.current, foundFieldConfig);
          setCompleted(true);
        } else {
          setCurrentIndex(currentIndex + 1);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error.message as string);
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [currentIndex, foundFieldConfig, onComplete, steps.length],
  );

  const formConfig = useMemo(() => {
    return foundFieldConfig && fieldConfigArrayToObject(foundFieldConfig);
  }, [foundFieldConfig]);

  const value = useMemo(
    () => ({
      formConfig: formConfig,
      currentIndex,
      currentStep: isCompleted ? completed : steps[currentIndex],
      onBack,
      onSubmit,
      stepNumber: steps.length,
      values: valuesRef.current,
    }),
    [completed, currentIndex, formConfig, isCompleted, onBack, onSubmit, steps],
  );
  return <BookingContext.Provider value={value}>{children}</BookingContext.Provider>;
};

export const useBookingContext = () => useContext(BookingContext);
