import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingkitConnectionForAppointmentFragmentDoc } from '../../../fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleIgnoreBookingkitErrorForAppointmentMutationVariables = SchemaTypes.Exact<{
  appointmentId: SchemaTypes.Scalars['String'];
  ignore: SchemaTypes.Scalars['Boolean'];
}>;

export type ToggleIgnoreBookingkitErrorForAppointmentMutation = {
  __typename?: 'Mutation';
  toggleIgnoreBookingkitErrorForAppointment: {
    __typename?: 'Appointment';
    id: string;
    bookingkitConnection?: {
      __typename?: 'AppointmentBookingkitConnection';
      dateId?: string | null;
      dateSyncedAt?: string | Date | null;
      dateSyncSuccess?: boolean | null;
      orderSuccess?: boolean | null;
      orderSyncedAt?: string | Date | null;
      errorName?: string | null;
      errorMessage?: string | null;
      ignoreError?: boolean | null;
    } | null;
  };
};

export const ToggleIgnoreBookingkitErrorForAppointmentDocument = gql`
  mutation ToggleIgnoreBookingkitErrorForAppointment($appointmentId: String!, $ignore: Boolean!) {
    toggleIgnoreBookingkitErrorForAppointment(appointmentId: $appointmentId, ignore: $ignore) {
      id
      bookingkitConnection {
        ...BookingkitConnectionForAppointment
      }
    }
  }
  ${BookingkitConnectionForAppointmentFragmentDoc}
`;
export type ToggleIgnoreBookingkitErrorForAppointmentMutationFn = Apollo.MutationFunction<
  ToggleIgnoreBookingkitErrorForAppointmentMutation,
  ToggleIgnoreBookingkitErrorForAppointmentMutationVariables
>;

/**
 * __useToggleIgnoreBookingkitErrorForAppointmentMutation__
 *
 * To run a mutation, you first call `useToggleIgnoreBookingkitErrorForAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleIgnoreBookingkitErrorForAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleIgnoreBookingkitErrorForAppointmentMutation, { data, loading, error }] = useToggleIgnoreBookingkitErrorForAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      ignore: // value for 'ignore'
 *   },
 * });
 */
export function useToggleIgnoreBookingkitErrorForAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleIgnoreBookingkitErrorForAppointmentMutation,
    ToggleIgnoreBookingkitErrorForAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleIgnoreBookingkitErrorForAppointmentMutation,
    ToggleIgnoreBookingkitErrorForAppointmentMutationVariables
  >(ToggleIgnoreBookingkitErrorForAppointmentDocument, options);
}
export type ToggleIgnoreBookingkitErrorForAppointmentMutationHookResult = ReturnType<
  typeof useToggleIgnoreBookingkitErrorForAppointmentMutation
>;
export type ToggleIgnoreBookingkitErrorForAppointmentMutationResult =
  Apollo.MutationResult<ToggleIgnoreBookingkitErrorForAppointmentMutation>;
export type ToggleIgnoreBookingkitErrorForAppointmentMutationOptions = Apollo.BaseMutationOptions<
  ToggleIgnoreBookingkitErrorForAppointmentMutation,
  ToggleIgnoreBookingkitErrorForAppointmentMutationVariables
>;
