import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type Locale = 'en' | 'de';

export const locales: Locale[] = ['de', 'en'];

export type ChangeLocaleComponentProps = {
  onChangeLanguage: (locale: Locale) => void;
  locales: Locale[];
};

type ChangeLocaleProps = {
  render: FC<ChangeLocaleComponentProps>;
};

const ChangeLocale: FC<ChangeLocaleProps> = ({ render: Component }) => {
  const { i18n } = useTranslation();

  const onChangeLanguage = useCallback(
    (locale: Locale) => {
      i18n.changeLanguage(locale);
    },
    [i18n],
  );

  return <Component onChangeLanguage={onChangeLanguage} locales={locales} />;
};
export default ChangeLocale;
