import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { DisabledCodeFragmentDoc } from './disabledCodeFragment';
export type BlockedDateConfigFragment = {
  __typename?: 'BlockedDate';
  id: string;
  endDate: string | Date;
  startDate: string | Date;
  isAllCodes?: boolean | null;
  disabledCodes?: Array<{
    __typename?: 'DisabledCode';
    tourTopic: { __typename?: 'TourTopic'; id: string; code: string };
    targetGroup: { __typename?: 'TargetGroup'; id: string; code: string };
  }> | null;
  blockedBy: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } };
};

export type BlockedDateConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BlockedDateConfigFragmentDoc = gql`
  fragment BlockedDateConfig on BlockedDate {
    id
    endDate
    startDate
    isAllCodes
    disabledCodes {
      ...DisabledCode
    }
    blockedBy {
      id
      profile {
        fullName
      }
    }
  }
  ${DisabledCodeFragmentDoc}
`;
