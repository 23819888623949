import React, { forwardRef, MouseEvent, useCallback, useMemo, useRef } from 'react';
import RDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowRight, CalendarCheck } from 'react-bootstrap-icons';
import s from './DatePicker.module.sass';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { Form } from 'react-bootstrap';
import FormDescription from '../FormDescription';
import { iFormDescription } from '../FormDescription/FormDescription';
import { DEFAULT_APPOINTMENT_DURATION_HOURS } from 'constants/appointment';
import { addHours } from 'date-fns';
import DatePickerBase from './DatePickerBase';
import { iRequired, Required } from 'components/common/Required';

export interface iTimePicker extends Omit<ReactDatePickerProps, 'onChange'> {
  onChange: (date: Date | null) => void;
  onClick: (event: MouseEvent) => void;
}

type DateTimeRestrict = Pick<ReactDatePickerProps, 'minDate' | 'minTime' | 'maxDate' | 'maxTime'>;

interface iTimeRangePickerFormik
  extends Omit<iTimePicker, 'filterTime' | 'onChange' | 'onClick' | 'minDate' | 'minTime' | 'maxDate' | 'maxTime'>,
    iFormDescription,
    iRequired {
  endName: string;
  endPlaceholder?: string;
  endRestrict?: DateTimeRestrict;
  label: string;
  startName: string;
  startPlaceholder?: string;
  startRestrict?: DateTimeRestrict;
  filterTime?: (isStart?: boolean) => iTimePicker['filterTime'];
}

const TimePickerWithRef = forwardRef<RDatePicker<never, undefined>, iTimePicker>(function TimePicker(
  { onClick, ...props },
  ref,
) {
  return (
    <div onClick={onClick} className="flex-1">
      <DatePickerBase ref={ref} {...props} />
    </div>
  );
});

const DateTimeRangePicker: FC<iTimeRangePickerFormik> = ({
  startName,
  endName,
  description,
  label,
  endRestrict,
  startRestrict,
  startPlaceholder,
  endPlaceholder,
  isRequired,
  filterTime,
  ...props
}) => {
  const { touched, values, errors, setFieldTouched, setValues, setTouched } = useFormikContext();
  const { t } = useTranslation();
  const names = useMemo(() => [startName, endName], [endName, startName]);
  const [error] = useMemo(
    () => names.map((name): string => (get(touched, name) ? get(errors, name) : undefined)).filter(Boolean),
    [names, touched, errors],
  );

  const [startDate, endDate] = useMemo(() => names.map((name) => get(values, name)), [names, values]);
  const [startProps, endProps] = useMemo(
    () =>
      [startName, endName].map((name, isStart) => {
        return {
          id: name,
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
          onBlur: () => setFieldTouched(name, true, true),
          className: 'form-control border-0 shadow-none px-0',
          filterTime: filterTime?.(!isStart),
          onChange: (date: Date | null) => {
            const newValues = {
              [name]: date && new Date(date),
            };
            setValues((state: object) => {
              if (date && name === startName && (!get(state, endName) || date > get(state, endName)))
                newValues[endName] = addHours(new Date(date), DEFAULT_APPOINTMENT_DURATION_HOURS);
              setTouched({ ...touched, ...Object.keys(newValues).reduce((acc, key) => ({ ...acc, [key]: true }), {}) });
              return { ...state, ...newValues };
            });
          },
        };
      }),
    [endName, filterTime, setFieldTouched, setTouched, setValues, startName, touched],
  );

  const onClick = useCallback((reverse = true) => {
    return (event: MouseEvent) => {
      event.stopPropagation();
      (reverse ? endTimeRef : startTimeRef).current?.setBlur();
      (reverse ? startTimeRef : endTimeRef).current?.setFocus();
      (reverse ? startTimeRef : endTimeRef).current?.setOpen(true);
    };
  }, []);

  const startTimeRef = useRef<RDatePicker<never, undefined> | null>(null);
  const endTimeRef = useRef<RDatePicker<never, undefined> | null>(null);
  return (
    <Form.Group>
      <Form.Label htmlFor={startName}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <span
        className={cn(s.container, 'form-control text-muted d-flex align-items-center py-0 pr-0')}
        onClick={onClick()}
      >
        <TimePickerWithRef
          ref={startTimeRef}
          selected={startDate}
          placeholderText={startPlaceholder && t(startPlaceholder)}
          {...startRestrict}
          {...startProps}
          {...props}
        />
        <ArrowRight className={s.dateRangeIcon} onClick={onClick(false)} />
        <TimePickerWithRef
          ref={endTimeRef}
          selected={endDate}
          placeholderText={endPlaceholder && t(endPlaceholder)}
          {...endRestrict}
          {...endProps}
          {...props}
        />
        <CalendarCheck className={s.dateRangeIcon} onClick={onClick()} />
      </span>
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {error && t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DateTimeRangePicker;
