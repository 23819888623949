import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BlockedDateFragmentDoc } from '../../../fragments/blockedDates/generated/blockedDateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBlockedDateMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.BlockedDateInput;
  id: SchemaTypes.Scalars['ID'];
}>;

export type UpdateBlockedDateMutation = {
  __typename?: 'Mutation';
  updateBlockedDate: { __typename?: 'BlockedDate'; id: string; endDate: string | Date; startDate: string | Date };
};

export const UpdateBlockedDateDocument = gql`
  mutation UpdateBlockedDate($data: BlockedDateInput!, $id: ID!) {
    updateBlockedDate(data: $data, id: $id) {
      ...BlockedDate
    }
  }
  ${BlockedDateFragmentDoc}
`;
export type UpdateBlockedDateMutationFn = Apollo.MutationFunction<
  UpdateBlockedDateMutation,
  UpdateBlockedDateMutationVariables
>;

/**
 * __useUpdateBlockedDateMutation__
 *
 * To run a mutation, you first call `useUpdateBlockedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockedDateMutation, { data, loading, error }] = useUpdateBlockedDateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBlockedDateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBlockedDateMutation, UpdateBlockedDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBlockedDateMutation, UpdateBlockedDateMutationVariables>(
    UpdateBlockedDateDocument,
    options,
  );
}
export type UpdateBlockedDateMutationHookResult = ReturnType<typeof useUpdateBlockedDateMutation>;
export type UpdateBlockedDateMutationResult = Apollo.MutationResult<UpdateBlockedDateMutation>;
export type UpdateBlockedDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateBlockedDateMutation,
  UpdateBlockedDateMutationVariables
>;
