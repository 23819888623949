import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { UpdateEmailTemplateFragmentDoc } from '../../../fragments/emailTemplate/generated/updateEmailTemplateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmailTemplateMutationVariables = SchemaTypes.Exact<{
  emailTemplateId: SchemaTypes.Scalars['ID'];
  modifier: SchemaTypes.EmailTemplateInput;
}>;

export type UpdateEmailTemplateMutation = {
  __typename?: 'Mutation';
  updateEmailTemplate: {
    __typename?: 'EmailTemplate';
    id: string;
    name: string;
    language: SchemaTypes.TourLanguage;
    subject: string;
    body: string;
  };
};

export const UpdateEmailTemplateDocument = gql`
  mutation UpdateEmailTemplate($emailTemplateId: ID!, $modifier: EmailTemplateInput!) {
    updateEmailTemplate(emailTemplateId: $emailTemplateId, modifier: $modifier) {
      ...UpdateEmailTemplate
    }
  }
  ${UpdateEmailTemplateFragmentDoc}
`;
export type UpdateEmailTemplateMutationFn = Apollo.MutationFunction<
  UpdateEmailTemplateMutation,
  UpdateEmailTemplateMutationVariables
>;

/**
 * __useUpdateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailTemplateMutation, { data, loading, error }] = useUpdateEmailTemplateMutation({
 *   variables: {
 *      emailTemplateId: // value for 'emailTemplateId'
 *      modifier: // value for 'modifier'
 *   },
 * });
 */
export function useUpdateEmailTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>(
    UpdateEmailTemplateDocument,
    options,
  );
}
export type UpdateEmailTemplateMutationHookResult = ReturnType<typeof useUpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationResult = Apollo.MutationResult<UpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailTemplateMutation,
  UpdateEmailTemplateMutationVariables
>;
