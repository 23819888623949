import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingRequestViewMainInfoFragmentDoc } from './bookingRequestViewMainInfoFragment';
import { CustomerForBookingRequestViewFragmentDoc } from '../../customer/generated/customerForBookingRequestViewFragment';
export type BookingRequestViewFragment = {
  __typename?: 'BookingRequest';
  id: string;
  date: string | Date;
  altDate: string | Date;
  createdAt: string | Date;
  totalParticipants: number;
  totalAccompanyingPersons?: number | null;
  ageOfParticipants?: string | null;
  code?: string | null;
  language: SchemaTypes.TourLanguage;
  howDidYouHear?: string | null;
  status: SchemaTypes.BookingRequestStatus;
  customer: {
    __typename?: 'Customer';
    id: string;
    salutation: SchemaTypes.Salutation;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | null;
    organization: {
      __typename?: 'CustomerOrganization';
      id: string;
      name?: string | null;
      address?: string | null;
      buildingNumber?: string | null;
      zip?: string | null;
      city?: string | null;
      uidNumber?: string | null;
      targetGroup: {
        __typename?: 'TargetGroup';
        id: string;
        bookingFormConfig?: {
          __typename?: 'TargetGroupBookingFormConfig';
          fieldConfig: Array<{
            __typename?: 'TargetGroupFieldConfig';
            visible: boolean;
            required: boolean;
            name: string;
          }>;
        } | null;
      };
    };
  };
  automationDetails?: Array<{
    __typename?: 'BookingRequestAutomationDetails';
    name: SchemaTypes.CriteriaType;
    details?: string | null;
    passed: boolean;
  }> | null;
};

export type BookingRequestViewFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const BookingRequestViewFragmentDoc = gql`
  fragment BookingRequestView on BookingRequest {
    ...BookingRequestViewMainInfo
    customer {
      ...CustomerForBookingRequestView
    }
  }
  ${BookingRequestViewMainInfoFragmentDoc}
  ${CustomerForBookingRequestViewFragmentDoc}
`;
