import React from 'react';
import Sidebar from 'components/layout/Sidebar';
import cn from 'classnames';
import s from './Layout.module.sass';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'contexts/AppContext';

type iAppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout: FC<iAppLayoutProps> = ({ children }) => {
  const { sidebarOpen } = useAppContext();

  return (
    <Container fluid>
      <Sidebar />
      <Row className="justify-content-center">
        <Col className={cn(s.appContent, s.indentForSidebar, { [s.sidebarOpen]: sidebarOpen })}>{children}</Col>
      </Row>
    </Container>
  );
};

export default AppLayout;
