import React, { memo, useMemo } from 'react';
import {
  AgeOfParticipantsInput,
  TotalAccompanyingPersonsFormConfigInput,
  TotalParticipantsInput,
} from 'components/bookingRequest/PricingLevelForm/PricingLevelForm';
import * as yup from 'yup';
import i18n from 'i18n';
import { useGuidesQuery } from 'graphql/queries/user/generated/Guides';
import { Field } from 'formik';
import Select from 'components/inputs/Select';
import { Row, Col, InputGroup, ColProps } from 'react-bootstrap';
import ProductForm, { iProductForm } from './ProductForm';
import { omit, pick } from 'lodash';
import { pricingLevelSchema } from 'components/bookingRequest/PricingLevelForm';
import { FormConfig, FormConfigs, useFormConfig } from 'components/bookingRequest/utils';
import PrivateTourAlert from './components/PrivateTourAlert';
import { AppointmentTypes, AppointmentType, PARTICIPANTS_MAX_LIMIT } from 'constants/appointment';
import { CustomerForm } from '../bookingRequest';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import NumberInput from 'components/inputs/NumberInput';
import FastField from 'components/common/FastField';
import DateSection from './DateSection';
import { useTranslation } from 'react-i18next';
import { AppointmentInfoForOffice } from 'graphql/types.generated';
import equal from 'fast-deep-equal/es6/react';
import { InternalRefetchQueryDescriptor } from '@apollo/client';
import TextInput from 'components/inputs/TextInput';

export type AppointmentSchemaProps = {
  formConfig: FormConfig;
  type: AppointmentType;
};

export const infoForOfficeSchema = () => ({
  infoForOffice: yup
    .string()
    .oneOf([...Object.values(AppointmentInfoForOffice), null])
    .nullable()
    .label(i18n.t('appointments.fields.infoForOffice.label')),
});

export const schema = ({ formConfig, type }: AppointmentSchemaProps) =>
  omit(
    {
      weekDays: yup.array().of(yup.string()).nullable().min(1).label(i18n.t('appointments.fields.weekDays.label')),
      endDate: yup.string().nullable().required().label(i18n.t('appointments.fields.date.endDate')),
      startDate: yup.string().nullable().required().label(i18n.t('appointments.fields.date.startDate')),
      endTime: yup.string().nullable().required().label(i18n.t('appointments.fields.timeRange.endTime')),
      startTime: yup.string().nullable().required().label(i18n.t('appointments.fields.timeRange.startTime')),
      productId: yup.string().nullable().required().label(i18n.t('appointments.fields.productId.label')),
      totalSlots: yup
        .number()
        .nullable()
        .required()
        .min(1)
        .max(PARTICIPANTS_MAX_LIMIT)
        .label(i18n.t('bookingRequest.fields.totalSlots.label')),
      ...infoForOfficeSchema(),
      ...pick(
        pricingLevelSchema({
          formConfig,
          minTotalParticipants: type === AppointmentTypes.BOOKING_SLOT ? 0 : undefined,
        }),
        ['totalParticipants', 'totalAccompanyingPersons', 'ageOfParticipants'],
      ),
    },
    [
      type === AppointmentTypes.RECURRING_BOOKING_SLOT ? [] : ['weekDays'],
      [AppointmentTypes.RECURRING_BOOKING_SLOT, AppointmentTypes.BOOKING_SLOT].includes(type)
        ? ['totalParticipants', 'totalAccompanyingPersons']
        : ['totalSlots'],
    ].flat(),
  );

export const GuideSelect = () => {
  const { data, loading, error } = useGuidesQuery({ fetchPolicy: 'cache-and-network' });
  const guideOptions = useMemo(() => {
    if (!data) return [];
    return data.guides.map(({ id, profile: { fullName } }) => ({
      value: id,
      label: fullName,
    }));
  }, [data]);

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Field
      disabled={!data && loading}
      loading={loading}
      label="appointments.fields.guideId.label"
      description="appointments.fields.guideId.description"
      name="guideId"
      component={Select}
      options={guideOptions}
      isClearable
    />
  );
};

const TotalSlotsInput = () => (
  <FastField
    isRequired
    name="totalSlots"
    component={NumberInput}
    isInteger
    min={1}
    max={PARTICIPANTS_MAX_LIMIT}
    label="bookingRequest.fields.totalSlots.label"
    placeholder="bookingRequest.fields.totalSlots.placeholder"
  />
);

const TotalsSectionMemo = memo(function TotalsSection({
  type,
  formConfig,
}: {
  type: AppointmentType;
  formConfig: FormConfig;
}) {
  const Component: FC<{ children: React.ReactNode }> =
    type === AppointmentTypes.PRIVATE_TOUR ? (props) => <Row className="m-0 p-0" {...props} /> : React.Fragment;
  const showTotalSlots = useMemo(
    () => [AppointmentTypes.BOOKING_SLOT, AppointmentTypes.RECURRING_BOOKING_SLOT].includes(type),
    [type],
  );
  return (
    <Component>
      {showTotalSlots ? (
        <Col sm={12}>
          <TotalSlotsInput />
        </Col>
      ) : (
        <>
          <Col>
            <TotalParticipantsInput />
          </Col>
          <TotalAccompanyingPersonsFormConfigInput colProps={{ sm: 6 }} formConfig={formConfig} />
        </>
      )}
    </Component>
  );
},
equal);

export const AppointmentInfoForOfficeInput = () => {
  const { t } = useTranslation();
  const option = useMemo(
    () =>
      Object.values(AppointmentInfoForOffice).map((infoForOffice) => ({
        value: infoForOffice,
        label: t(`infoForOffice.${infoForOffice}`),
      })),
    [t],
  );

  return (
    <FastField
      label="appointments.fields.infoForOffice.label"
      placeholder="appointments.fields.infoForOffice.placeholder"
      name="infoForOffice"
      isClearable
      component={Select}
      options={option}
    />
  );
};

const TotalGuidesInput = ({ colProps }: { colProps?: ColProps }) => {
  const { t } = useTranslation();
  return (
    <Col {...colProps}>
      <FastField
        name="totalGuides"
        component={NumberInput}
        label="appointments.fields.totalGuides.label"
        addon={<InputGroup.Text>{t('appointments.guides_other')}</InputGroup.Text>}
      />
    </Col>
  );
};

const InfoForGuidesInput = ({ colProps }: { colProps?: ColProps }) => (
  <Col {...colProps}>
    <FastField
      name="infoForGuides"
      component={TextInput}
      label="bookingRequest.fields.infoForGuides.label"
      description="bookingRequest.fields.infoForGuides.description"
    />
  </Col>
);

export interface iAppointmentForm extends iProductForm {
  isPushed: boolean;
  formConfigs: FormConfigs;
  customerId?: string;
  bookingRequestId?: string;
  refetchQueries?: InternalRefetchQueryDescriptor[];
}

const AppointmentForm = ({
  formConfigs,
  targetGroup,
  customerId,
  type,
  bookingRequestId,
  refetchQueries,
  product,
  isPushed,
}: iAppointmentForm) => {
  const formConfig = useFormConfig(formConfigs);
  return (
    <>
      {type === AppointmentTypes.PRIVATE_TOUR && customerId && bookingRequestId ? (
        <PrivateTourAlert customerId={customerId} bookingRequestId={bookingRequestId} refetchQueries={refetchQueries} />
      ) : null}
      <Row>
        <DateSection type={type} />
        {type === AppointmentTypes.RECURRING_BOOKING_SLOT ? null : (
          <Col sm={6}>
            <GuideSelect />
          </Col>
        )}
        <TotalsSectionMemo {...{ type, formConfig }} />
        <ProductForm targetGroup={targetGroup} product={product} type={type} disabled={isPushed} />
        <Col>
          <AppointmentInfoForOfficeInput />
        </Col>
        <AgeOfParticipantsInput formConfig={formConfig} />
      </Row>
      <Row>
        <InfoForGuidesInput />
        {type === AppointmentTypes.PRIVATE_TOUR ? <TotalGuidesInput colProps={{ sm: 6 }} /> : null}
      </Row>
      {type === AppointmentTypes.EVENT ? <CustomerForm formConfig={formConfig} /> : null}
    </>
  );
};

export default AppointmentForm;
