import React, { SyntheticEvent, useMemo } from 'react';
import { TIME_FORMAT } from 'constants/date';
import { differenceInMinutes, format, startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import { Form } from 'react-bootstrap';

export type TimePickerBaseProps = {
  onChange: (date: Date | null, event: SyntheticEvent<HTMLSelectElement>) => void;
  value: Date;
  onBlur?: React.DOMAttributes<HTMLSelectElement>['onBlur'];
  dateFormat?: string;
  timeIntervals?: number;
  minTime?: Date;
  maxTime?: Date;
  nullable?: boolean;
  name: string;
  placeholder?: string;
};

const TimePickerBase: FC<TimePickerBaseProps> = (props) => {
  const {
    onBlur,
    onChange,
    dateFormat = TIME_FORMAT,
    timeIntervals = 15,
    minTime = startOfDay(new Date()),
    maxTime = endOfDay(new Date()),
    nullable = false,
    value,
    name,
    placeholder,
  } = props;

  const options = useMemo(() => {
    const minutes = differenceInMinutes(maxTime, minTime);
    // include max time option
    const optionsN = Math.ceil(minutes / timeIntervals) + 1;
    return [...new Array(optionsN)].map((_, i) => {
      const date = new Date(minTime);
      date.setMinutes(minTime.getMinutes() + i * timeIntervals);
      return date;
    });
  }, [timeIntervals, minTime, maxTime]);

  return (
    <Form.Select
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={(event) => {
        const stringValue = event.target.value;
        if (!stringValue) onChange(null, event);
        else {
          onChange(new Date(stringValue), event);
          // const date = new Date();
          // const [hours, minutes] = stringValue.split(':');
          // onChange(new Date(date.setHours(+hours, +minutes)), event);
        }
      }}
      value={value ? value.toISOString() : ''}
      className={!value ? 'text-muted' : undefined}
    >
      <option value="" hidden disabled={!nullable}>
        {placeholder}
      </option>
      {options.map((date) => (
        <option key={date.toISOString()} value={date.toISOString()}>
          {format(date, dateFormat)}
        </option>
      ))}
    </Form.Select>
  );
};

export default TimePickerBase;
