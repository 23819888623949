import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { FontSize, iComponentProps } from '../RichEditor.type';
import equal from 'fast-deep-equal/es6/react';

export const FontSizeToStyle: Record<FontSize, string> = {
  small: '0.8em',
  normal: '1em',
  large: '2em',
  huge: '4em',
};

const FontSizeSelectMemo: FC<iComponentProps> = memo(function FontSizeSelect({ getValue, onChange }) {
  const { t } = useTranslation();

  return (
    <Dropdown
      className="d-inline-block dropdown-remove-toggle"
      onSelect={(v, e) => {
        onChange(e as React.MouseEvent, v as FontSize);
      }}
    >
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-basic"
        className="text-muted bg-transparent border-color-gray-400"
      >
        {t('fontSize.' + (getValue() || 'normal'))} <ChevronDown size="0.7em" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="text-muted">
        {Object.keys(FontSizeToStyle).map((name) => (
          <Dropdown.Item key={name} eventKey={name}>
            {t(`fontSize.${name}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}, equal);

export default FontSizeSelectMemo;
