import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupFieldConfigFragmentDoc } from '../../../fragments/targetGroup/generated/targetGroupFieldConfigFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TargetGroupFieldConfigsQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TargetGroupFieldConfigsQuery = {
  __typename?: 'Query';
  targetGroups: Array<{
    __typename?: 'TargetGroup';
    id: string;
    bookingFormConfig?: {
      __typename?: 'TargetGroupBookingFormConfig';
      fieldConfig: Array<{ __typename?: 'TargetGroupFieldConfig'; visible: boolean; required: boolean; name: string }>;
    } | null;
  }>;
};

export const TargetGroupFieldConfigsDocument = gql`
  query TargetGroupFieldConfigs {
    targetGroups {
      ...TargetGroupFieldConfig
    }
  }
  ${TargetGroupFieldConfigFragmentDoc}
`;

/**
 * __useTargetGroupFieldConfigsQuery__
 *
 * To run a query within a React component, call `useTargetGroupFieldConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetGroupFieldConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetGroupFieldConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTargetGroupFieldConfigsQuery(
  baseOptions?: Apollo.QueryHookOptions<TargetGroupFieldConfigsQuery, TargetGroupFieldConfigsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TargetGroupFieldConfigsQuery, TargetGroupFieldConfigsQueryVariables>(
    TargetGroupFieldConfigsDocument,
    options,
  );
}
export function useTargetGroupFieldConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TargetGroupFieldConfigsQuery, TargetGroupFieldConfigsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TargetGroupFieldConfigsQuery, TargetGroupFieldConfigsQueryVariables>(
    TargetGroupFieldConfigsDocument,
    options,
  );
}
export type TargetGroupFieldConfigsQueryHookResult = ReturnType<typeof useTargetGroupFieldConfigsQuery>;
export type TargetGroupFieldConfigsLazyQueryHookResult = ReturnType<typeof useTargetGroupFieldConfigsLazyQuery>;
export type TargetGroupFieldConfigsQueryResult = Apollo.QueryResult<
  TargetGroupFieldConfigsQuery,
  TargetGroupFieldConfigsQueryVariables
>;
export function refetchTargetGroupFieldConfigsQuery(variables?: TargetGroupFieldConfigsQueryVariables) {
  return { query: TargetGroupFieldConfigsDocument, variables: variables };
}
