import React, { useRef, useState, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import NumberFormat from 'react-number-format';
import isNil from 'lodash/isNil';
import clamp from 'lodash/clamp';
import { FieldInputProps, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import i18n, { Locales } from 'i18n';
import { useTranslation } from 'react-i18next';
import FormDescription from '../FormDescription';
import { iFormDescription } from '../FormDescription/FormDescription';
import { iRequired, Required } from 'components/common/Required';
import { InputGroup } from 'react-bootstrap';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

interface iNumberInputProps extends iFormDescription, iRequired {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  type?: string;
  label: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  separators?: boolean;
  isInteger?: boolean;
  addon?: React.ReactElement;
}

const NumberInput: FC<iNumberInputProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  disabled,
  min = 0,
  max = 5000000,
  separators = true,
  isInteger,
  description,
  isRequired,
  addon,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [, forceRerender] = useState(false);
  const { value, name, onBlur } = field;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (values) => {
      let v;
      if (isNil(values.floatValue)) {
        v = null;
      } else {
        v = clamp(values.floatValue, min, max);
      }
      setFieldValue(name, v);
      forceRerender((b) => !b);
    },
    [max, min, name, setFieldValue],
  );

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <InputGroup>
        <NumberFormat
          allowNegative={false}
          allowLeadingZeros={false}
          allowEmptyFormatting={false}
          {...(separators ? config[i18n.language as Locales] : {})}
          {...(isInteger ? { decimalScale: 0 } : {})}
          onValueChange={handleChange}
          name={name}
          value={value}
          onBlur={onBlur}
          autoComplete="off"
          id={name}
          disabled={disabled}
          className="form-control"
          getInputRef={(el: HTMLInputElement) => {
            inputRef.current = el;
          }}
          type="text"
        />
        {addon ? addon : null}
      </InputGroup>
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default NumberInput;
