import { FieldInputProps, FormikProps } from 'formik';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import RPhoneInput from 'react-phone-input-2';
import Form from 'react-bootstrap/Form';
import 'react-phone-input-2/lib/style.css';
import s from './PhoneNumberInput.module.sass';
import { useTranslation } from 'react-i18next';
import { iRequired, Required } from 'components/common/Required';

interface iPhoneInputProps extends iRequired {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  disabled?: boolean;
}

const PhoneNumberInput: FC<iPhoneInputProps> = ({ field, form, label, disabled = false, isRequired }) => {
  const { value, name, onBlur } = field;
  const { errors, touched, setFieldTouched, setFieldValue, isSubmitting } = form;
  const { t } = useTranslation();

  const isDisabled = disabled || isSubmitting;

  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);

  const handleChange = useCallback(
    (v: string) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const handleOnBlur = React.useCallback(() => {
    onBlur(name);
    setFieldTouched(name, true);
  }, [name, onBlur, setFieldTouched]);

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <RPhoneInput
        {...{ value, name }}
        onChange={handleChange}
        onBlur={handleOnBlur}
        countryCodeEditable={false}
        autoFormat
        inputClass={s.phoneNumber}
        buttonClass={s.buttonCountryCode}
        disabled={isDisabled}
        containerClass={isDisabled ? 'disabled-input' : undefined}
      />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default PhoneNumberInput;
