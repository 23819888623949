import RefreshTokenService from 'services/RefreshTokenService';
import { MeDocument } from 'graphql/queries/user/generated/Me';
import { useLoginMutation } from 'graphql/mutations/auth/generated/Login';
import { User } from 'graphql/types.generated';

const useLogin = () => {
  const [authMutation, { client }] = useLoginMutation({
    onCompleted: ({ login: resultData }) => {
      const { refreshToken, user } = resultData as { refreshToken: string | null; user: User };
      RefreshTokenService.setRefreshToken(refreshToken);
      client.writeQuery({
        query: MeDocument,
        data: { me: user },
      });
      return resultData;
    },
  });
  return authMutation;
};

export default useLogin;
