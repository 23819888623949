import React, { useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { EnvelopePlus } from 'react-bootstrap-icons';
import Table from 'components/common/Table';
import { formatDate } from 'utils/helpers';
import Button from 'components/common/Button';
import s from './SentEmailTable.module.sass';
import sendEmailModal from './sendEmailModal';
import { FULL_DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import { EmailMessagesDocument, useEmailMessagesQuery } from 'graphql/queries/emailMessage/generated/EmailMessages';
import { EmailMessageFragment } from 'graphql/fragments/emailMessage/generated/EmailMessage';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import constants from 'constants/constants';
import usePagination from 'hooks/usePagination';
import Pagination from 'components/common/Pagination';
import { BookingRequestViewDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestView';
import { BookingRequestAppointmentsDocument } from 'graphql/queries/bookingRequest/generated/BookingRequestAppointments';
import { isNil } from 'lodash';
import { Badge } from 'react-bootstrap';

const BodyCell: FC<{ value: EmailMessageFragment }> = ({ value }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [iframeHeight, setIframeHeight] = useState<number>(150);
  return (
    <>
      <div ref={ref} className={s.body}>
        {/* <EyeFill
          className={cn(s.eye, 'text-primary cursor-pointer')}
          onClick={() => sendEmailModal({ isPreview: true, sentEmail: value })}
        /> */}
        <iframe
          ref={iframeRef}
          srcDoc={value.body}
          height={iframeHeight}
          style={{ width: '500px', minWidth: '100%' }}
          onLoad={() => {
            if (iframeRef.current) {
              const h = iframeRef.current.contentWindow?.document.body.scrollHeight;
              if (h) setIframeHeight(h);
            }
          }}
        />
      </div>
      {/* <Button variant="link" className="px-0" onClick={() => sendEmailModal({ isPreview: true, sentEmail: value })}>
        {t('bookingRequestView.viewFullBody')}
      </Button> */}
    </>
  );
};

const columns = ({ t }: { t: TFunction }) => [
  {
    key: 'date',
    title: t('bookingRequestView.columns.date'),
    render: (value: EmailMessageFragment) => (
      <span className="text-nowrap">{value.createdAt ? formatDate(value.createdAt, FULL_DATE_FORMAT) : null}</span>
    ),
    cellProps: () => ({ className: 'align-top' }),
  },
  {
    key: 'time',
    title: t('bookingRequestView.columns.time'),
    render: (value: EmailMessageFragment) => (
      <span className="text-nowrap">{value.createdAt ? formatDate(new Date(value.createdAt), TIME_FORMAT) : null}</span>
    ),
    cellProps: () => ({ className: 'align-top' }),
  },
  {
    key: 'delivery',
    title: t('bookingRequestView.columns.delivery'),
    render: (value: EmailMessageFragment) => (
      <small>
        <div>
          {t('bookingRequestView.emailMessage.deliveryDetails.status')}:{' '}
          {value.delivered === true ? (
            <Badge bg="success">{t('bookingRequestView.emailMessage.deliveryDetails.statusDelivered')}</Badge>
          ) : null}
          {value.delivered === false ? (
            <Badge bg="danger">{t('bookingRequestView.emailMessage.deliveryDetails.statusNotDelivered')}</Badge>
          ) : null}
          {isNil(value.delivered) ? (
            <Badge bg="white" text="dark">
              {t('bookingRequestView.emailMessage.deliveryDetails.statusUnknown')}
            </Badge>
          ) : null}
        </div>
        <details>
          <summary>{t('bookingRequestView.emailMessage.deliveryDetails.serverResponse')}</summary>
          <code>{value.emailClientResponse || 'N/A'}</code>
        </details>
        {value.webhookEvents?.length ? (
          <details>
            <summary>{t('bookingRequestView.emailMessage.deliveryDetails.events')}</summary>
            {value.webhookEvents.map((event) => (
              <>
                <pre className={s.code}>
                  <code key={event.id}>{JSON.stringify(event, null, 2)}</code>
                </pre>
                <br />
              </>
            ))}
          </details>
        ) : null}
        {/* <span className="text-nowrap">{value.delivered ? formatDate(new Date(value.createdAt), TIME_FORMAT) : null}</span> */}
      </small>
    ),
    cellProps: () => ({ className: 'align-top' }),
  },
  {
    key: 'template',
    title: t('bookingRequestView.columns.template'),
    render: (value: EmailMessageFragment) => value.emailTemplate?.name,
    cellProps: () => ({ className: 'align-top' }),
  },
  {
    key: 'subject',
    title: t('bookingRequestView.columns.subject'),
    render: (value: EmailMessageFragment) => value.subject,
    cellProps: () => ({ className: 'align-top' }),
  },
  {
    key: 'body',
    title: t('bookingRequestView.columns.body'),
    render: (value: EmailMessageFragment) => <BodyCell value={value} />,
    cellProps: () => ({ className: 'align-top' }),
  },
];

const SentEmailTable: FC<{ bookingRequestId: string }> = ({ bookingRequestId }) => {
  const { t } = useTranslation();

  const { data, loading, error, currentPage, setCurrentPage, variables } = usePagination({
    pageSize: constants.emailMessageCountTableRowsPerPage,
    query: useEmailMessagesQuery,
    params: {
      bookingRequestId,
    },
  });

  const refetchQueries = useMemo(
    () => [
      { query: EmailMessagesDocument, variables },
      { query: BookingRequestViewDocument, variables: { id: bookingRequestId } },
      { query: BookingRequestAppointmentsDocument, variables: { id: bookingRequestId } },
    ],
    [variables, bookingRequestId],
  );

  const sentEmailColumns = useMemo(() => columns({ t }), [t]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
        <h5>{t('bookingRequestView.sentEmails')}</h5>
        <Button
          variant="primary"
          endIcon={<EnvelopePlus />}
          onClick={() => sendEmailModal({ bookingRequestId, refetchQueries })}
        >
          {t('bookingRequestView.newEmail')}
        </Button>
      </div>
      {error ? (
        <AlertGraphQLError error={error} />
      ) : (
        <>
          <Table
            className="border bg-white m-0"
            bordered
            loading={loading}
            columns={sentEmailColumns}
            dataSource={data?.emailMessages.items}
          />
          <Pagination
            total={data?.emailMessages.total}
            currentPage={currentPage}
            changePage={setCurrentPage}
            pageSize={constants.emailMessageCountTableRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default SentEmailTable;
