import FastField from 'components/common/FastField';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import Select from 'components/inputs/Select';
import TextInput from 'components/inputs/TextInput';
import { Roles } from 'graphql/types.generated';
import i18n from 'i18n';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type UserFormProps = {
  isCreating?: boolean;
  isMe?: boolean;
};

export const validationSchema = ({ isCreating }: Pick<UserFormProps, 'isCreating'> = {}) =>
  yup.object().shape({
    email: yup.string().required().email().label(i18n.t('userManagement.fields.email.label')),
    role: yup.string().required().label(i18n.t('userManagement.fields.role.label')),
    fullName: yup.string().max(50).required().label(i18n.t('userManagement.fields.fullName.label')),
    phoneNumber: yup.string().max(15).label(i18n.t('userManagement.fields.phoneNumber.label')),
    ...(isCreating
      ? { password: yup.string().min(8).required().label(i18n.t('userManagement.fields.password.label')) }
      : {}),
  });

const RoleSelect = () => {
  const { t } = useTranslation();

  const roleOptions = useMemo(
    () =>
      Object.keys(Roles).map((role) => ({
        value: role,
        label: t('role.' + role),
      })),
    [t],
  );

  return (
    <FastField
      isRequired
      name="role"
      component={Select}
      options={roleOptions}
      label="userManagement.fields.role.label"
      placeholder="userManagement.fields.role.placeholder"
    />
  );
};

const UserForm: FC<UserFormProps> = ({ isCreating, isMe }) => (
  <>
    <FastField
      isRequired
      name="fullName"
      component={TextInput}
      label="userManagement.fields.fullName.label"
      placeholder="userManagement.fields.fullName.placeholder"
    />
    <FastField
      isRequired
      name="email"
      component={TextInput}
      label="userManagement.fields.email.label"
      placeholder="userManagement.fields.email.placeholder"
    />
    {isCreating ? (
      <FastField
        isRequired
        name="password"
        type="password"
        component={TextInput}
        label="userManagement.fields.password.label"
        placeholder="userManagement.fields.password.placeholder"
      />
    ) : null}
    {isMe ? null : <RoleSelect />}
    <FastField name="phoneNumber" component={PhoneNumberInput} label="userManagement.fields.phoneNumber.label" />
  </>
);

export default UserForm;
