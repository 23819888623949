import React, { forwardRef } from 'react';
import { MentionContextProps, MentionElement, RootElement } from './RichEditor.type';
import { MentionContextProvider } from './context';
import RichEditorFormik, { iRichEditorFormikProps } from './RichEditorFormik';
import { Editor } from 'slate';
import PlaceholderSelect from './PlaceholderSelect';

const defaultMentionContextValue = () =>
  // { placeholdersSchema }: { placeholdersSchema: PlaceholdersSchema }
  {
    // console.log('placeholdersSchema => ', placeholdersSchema);
    const renderMention = ({ element }: { element: MentionElement }) => {
      const { _id } = element;

      return _id ?? '?';
    };
    return { renderMention } as MentionContextProps;
  };

export interface iRichEditorFieldProps extends Omit<iRichEditorFormikProps, 'rootElement' | 'toolbarChildren'> {
  rootElement?: RootElement;
  hidePlaceholdersButton?: boolean;
}

const RichEditorFieldWithRef = forwardRef<Editor, iRichEditorFieldProps>(function RichEditorField(
  { hidePlaceholdersButton, ...props },
  ref,
) {
  const toolbarChildren: FC = () => {
    if (hidePlaceholdersButton) return null;
    return <PlaceholderSelect />;
  };
  return (
    <MentionContextProvider
      value={
        // { placeholdersSchema: {} }
        defaultMentionContextValue()
      }
    >
      <RichEditorFormik ref={ref} {...{ toolbarChildren, ...props }} />
    </MentionContextProvider>
  );
});

const RichEditorFieldContainer: FC<iRichEditorFieldProps> = (props) => {
  const { readOnly } = props;
  if (readOnly) return <RichEditorFormik {...props} />;
  return <RichEditorFieldWithRef {...props} />;
};

export default RichEditorFieldContainer;
