import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TargetGroupFieldConfigFragmentDoc } from '../../targetGroup/generated/targetGroupFieldConfigFragment';
export type CustomerForBookingRequestViewFragment = {
  __typename?: 'Customer';
  id: string;
  salutation: SchemaTypes.Salutation;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  organization: {
    __typename?: 'CustomerOrganization';
    id: string;
    name?: string | null;
    address?: string | null;
    buildingNumber?: string | null;
    zip?: string | null;
    city?: string | null;
    uidNumber?: string | null;
    targetGroup: {
      __typename?: 'TargetGroup';
      id: string;
      bookingFormConfig?: {
        __typename?: 'TargetGroupBookingFormConfig';
        fieldConfig: Array<{
          __typename?: 'TargetGroupFieldConfig';
          visible: boolean;
          required: boolean;
          name: string;
        }>;
      } | null;
    };
  };
};

export type CustomerForBookingRequestViewFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const CustomerForBookingRequestViewFragmentDoc = gql`
  fragment CustomerForBookingRequestView on Customer {
    id
    salutation
    firstName
    lastName
    email
    phoneNumber
    organization {
      id
      name
      address
      buildingNumber
      zip
      city
      uidNumber
      targetGroup {
        ...TargetGroupFieldConfig
      }
    }
  }
  ${TargetGroupFieldConfigFragmentDoc}
`;
