import React, { ReactNode } from 'react';
import { BookingkitConnectionForAppointmentFragment } from 'graphql/fragments/bookingkit/generated/BookingkitConnectionForAppointmentFragment';
import { AppointmentType } from 'graphql/types.generated';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BookingkitSyncDebugInfo from './BookingkitSyncDebugInfo';
import Button from 'components/common/Button';
import PushAppointmentButton from './PushAppointmentToBookingkitButton';
import SyncStatusBadge from './SyncStatusBadge';
import { BookingkitSyncStatus, getSyncStatus } from './utils';
import IgnoreAppointmentPushErrorButton from './IgnoreAppointmentPushErrorButton';
import { getBookingkitDateUrl } from 'utils/bookingkit';
import PushBookingRequestButton from './PushBookingRequestToBookingkitButton';
import RetryBookingkitSyncButton from './RetryBookingkitSyncButton';

interface BookingkitCellProps {
  appointmentId: string;
  appointmentType: AppointmentType;
  bookingkitConnection?: BookingkitConnectionForAppointmentFragment | null;
  productBookingkitSyncEnabled: boolean;
  productName?: string;
  bookingRequestId?: string;
}

const BookingkitCell: FC<BookingkitCellProps> = (props) => {
  const {
    bookingkitConnection,
    appointmentType,
    productBookingkitSyncEnabled,
    productName,
    appointmentId,
    bookingRequestId,
  } = props;

  if (!bookingkitConnection && !productBookingkitSyncEnabled) {
    return <SyncNotEnabledForProductBadge productName={productName} />;
  }

  const { t } = useTranslation();
  const syncStatus = getSyncStatus({ bookingkitConnection, appointmentType });

  const showPushToBookingkitButton = [
    BookingkitSyncStatus.ERROR,
    BookingkitSyncStatus.UNKNOWN,
    BookingkitSyncStatus.NOT_PUSHED,
  ].includes(syncStatus);

  const showIgnoreButton = syncStatus === BookingkitSyncStatus.ERROR;

  let bookingkitButton: ReactNode | null = null;
  if (bookingRequestId) {
    bookingkitButton = <PushBookingRequestButton bookingRequestId={bookingRequestId} />;
  } else {
    bookingkitButton = <PushAppointmentButton appointmentId={appointmentId} />;
  }
  if (bookingkitConnection?.errorName === 'UPDATE_DATE' || bookingkitConnection?.errorName == 'UPDATE_ORDER') {
    bookingkitButton = <RetryBookingkitSyncButton appointmentId={appointmentId} />;
  }

  if (!bookingkitConnection) {
    return (
      <>
        <div>
          <SyncStatusBadge bookingkitConnection={bookingkitConnection} appointmentType={appointmentType} />
        </div>
        {showPushToBookingkitButton ? <div>{bookingkitButton}</div> : null}
      </>
    );
  }

  const dateId = bookingkitConnection.dateId;

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 0, hide: 700 }}
        overlay={
          <Tooltip className="lh-1" id={appointmentId}>
            <small>
              <BookingkitSyncDebugInfo bookingkitConnection={bookingkitConnection} appointmentType={appointmentType} />
            </small>
          </Tooltip>
        }
      >
        <div>
          <SyncStatusBadge bookingkitConnection={bookingkitConnection} appointmentType={appointmentType} />
        </div>
      </OverlayTrigger>

      <div>
        {showPushToBookingkitButton ? bookingkitButton : null}
        {showIgnoreButton ? <IgnoreAppointmentPushErrorButton appointmentId={appointmentId} /> : null}
        {dateId ? (
          <Button variant="link" size="sm">
            <small>
              <a href={getBookingkitDateUrl(dateId)} target="_blank" rel="noreferrer">
                {t('appointments.bookingkitCell.openDateInBookingkitLink')} <BoxArrowUpRight />
              </a>
            </small>
          </Button>
        ) : null}
      </div>
    </>
  );
};

const SyncNotEnabledForProductBadge = ({ productName }: { productName?: string }) => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={productName} className="lh-1">
          <small>{t('appointments.bookingkitCell.syncNotEnabledForProductTooltip', { name: productName || '' })}</small>
        </Tooltip>
      }
    >
      <Badge bg="white" text="dark">
        {t('appointments.bookingkitCell.syncNotEnabledForProductBadge')}
      </Badge>
    </OverlayTrigger>
  );
};

export default BookingkitCell;
