import React from 'react';
import { Alert } from 'react-bootstrap';
import { ApolloError } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';

interface iAlertGraphQLError {
  error: ApolloError;
}

const AlertGraphQLError: FC<iAlertGraphQLError> = ({ error }) => {
  return <Alert variant="danger">{formatGraphqlErrors(error).message}</Alert>;
};

export default AlertGraphQLError;
