import { FieldInputProps, FormikProps } from 'formik';
import { get } from 'lodash';
import React, { forwardRef, useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import s from './DatePicker.module.sass';
import cn from 'classnames';
import { Clock } from 'react-bootstrap-icons';
import FormDescription from '../FormDescription';
import DatePickerBase from './DatePickerBase';
import { iRequired, Required } from 'components/common/Required';

export interface iDatePickerProps extends ReactDatePickerProps, iRequired {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  yearItemNumber?: number;
  placeholder?: string;
  icon?: React.ReactNode;
  description?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DatePickerInputWithRef = forwardRef<React.ReactElement, any>(function DatePickerInput({ icon, ...props }, ref) {
  const Icon = useMemo(() => icon ?? Clock, [icon]);
  return (
    <span className={s.customInput}>
      <Icon {...props} className={cn(s.dateRangeIcon, s.icon)} />
      <input ref={ref} {...props} />
    </span>
  );
});

const DatePicker: FC<iDatePickerProps> = ({
  field,
  form,
  label,
  yearItemNumber,
  icon,
  placeholder,
  description,
  onChange,
  isRequired,
  ...props
}) => {
  const { name, onBlur } = field;
  const { touched, errors } = form;
  const { t } = useTranslation();
  const error = useMemo(() => get(touched, name) && get(errors, name), [touched, errors, name]);
  const [value, setValue] = React.useState(field.value ? new Date(field.value) : null);

  const handleChange = React.useCallback(
    (date, event) => {
      setValue(date);
      form.setFieldValue(name, date);
      typeof onChange === 'function' && onChange(date, event);
    },
    [form, name, onChange],
  );
  return (
    <Form.Group>
      <Form.Label htmlFor={name}>
        <Required visible={isRequired} /> {t(label)}
      </Form.Label>
      <DatePickerBase
        {...{ name, onBlur, yearItemNumber, ...props }}
        placeholderText={placeholder && t(placeholder)}
        selected={value}
        onChange={handleChange}
        customInput={<DatePickerInputWithRef icon={icon} />}
      />
      <FormDescription description={description} />
      <Form.Control.Feedback type="invalid" className="d-block min-height-3">
        {t(error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DatePicker;
