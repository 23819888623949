import React from 'react';
import { Placeholder } from 'react-bootstrap';

const InputPlaceholders: FC<{ count?: number }> = ({ count = 3 }) => {
  return (
    <>
      {[...new Array(count)].map((e, index) => (
        <React.Fragment key={index}>
          <Placeholder animation="glow">
            <Placeholder xs={6} bg="secondary" />
          </Placeholder>
          <Placeholder as={'h1'} animation="glow" className="mb-3">
            <Placeholder xs={12} bg="secondary" />
          </Placeholder>
        </React.Fragment>
      ))}
    </>
  );
};

export default InputPlaceholders;
