import React from 'react';
import { Editor, Transforms } from 'slate';
import { BLOCK_ELEMENT } from '../constants';
import { ImageElement } from '../RichEditor.type';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import i18n from 'i18n';
import * as yup from 'yup';
import { iInputModalConfirm, InputModalConfirm } from 'components/common/Modal/InputModal';
import FastField from 'components/common/FastField';
import TextInput from 'components/inputs/TextInput';
import { ReactEditor } from 'slate-react';

const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext ?? '');
};

const insertImage = (editor: Editor, url: string) => {
  const image: ImageElement = { type: BLOCK_ELEMENT.IMAGE, url, children: [{ text: '' }] };
  Transforms.insertNodes(editor, image);
};

export const withImages = (editor: Editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === BLOCK_ELEMENT.IMAGE ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result;
            insertImage(editor, url?.toString() ?? '');
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

type InitialValues = {
  url: string;
};

const imageValidationSchema = () =>
  yup.object().shape({
    url: yup
      .string()
      .required()
      .test('check-image', i18n.t('RichEditor.image.fields.value.invalid'), (value) => isImageUrl(value ?? ''))
      .label(i18n.t('RichEditor.image.fields.value.label')),
  });

const FormikContent = () => (
  <>
    <FastField
      name="url"
      component={TextInput}
      label="RichEditor.image.fields.value.label"
      placeholder="RichEditor.image.fields.value.placeholder"
    />
  </>
);

const createImageModal = ({ onSubmit }: { onSubmit: (values: InitialValues) => void }) =>
  InputModalConfirm({
    formikContent: () => <FormikContent />,
    initialValues: { url: '' } as InitialValues,
    onSubmit,
    headerText: i18n.t('RichEditor.image.modalTitle'),
    validationSchema: imageValidationSchema,
  } as iInputModalConfirm<InitialValues>);

export const imageFn = (editor: Editor) => {
  createImageModal({
    onSubmit: ({ url }: InitialValues) => {
      ReactEditor.focus(editor);
      insertImage(editor, url);
    },
  });
};
