import React from 'react';
import { FastField as FF } from 'formik';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FastField: React.ComponentType<any> = (props) => {
  const {
    i18n: { language },
  } = useTranslation();
  return <FF key={language} {...props} />;
};

export default FastField;
