import Select from 'components/inputs/Select';
import { AppointmentTypes, AppointmentType } from 'constants/appointment';
import { Field } from 'formik';
import {
  useProductsForUpdatingAppointmentQuery,
  ProductsForUpdatingAppointmentQuery,
} from 'graphql/queries/product/generated/ProductsForUpdatingAppointment';
import i18n from 'i18n';
import { FlagEmojis } from 'pages/app/UserProfilePage/UserProfilePage';
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { TargetGroup, AppointmentType as AppointmentTypeGenerated } from 'graphql/types.generated';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { ProductForUpdatingAppointmentFragment } from 'graphql/fragments/product/generated/productForUpdatingAppointmentFragment';
import { uniqBy } from 'lodash';

type Product = ProductsForUpdatingAppointmentQuery['products'][0];
interface iProductSelect {
  disabled?: boolean;
  products: Product[] | undefined;
  loading: boolean;
  description?: string;
}

interface iDisabledSelect extends iProductSelect {
  getOptionValue?: (product: Product) => string | null | undefined;
  renderOptionLabel: (product: Product) => string | null | undefined;
  label: string;
  name?: string;
}

export interface iProductForm {
  disabled?: boolean;
  product?: ProductForUpdatingAppointmentFragment;
  targetGroup?: Pick<TargetGroup, 'name' | 'code' | 'id'>;
  type: AppointmentType;
}

const ProductSelect = ({ products, loading, description, disabled }: iProductSelect) => {
  const options = useMemo(() => {
    if (!products) return [];
    return products.map(({ id, name }: Product) => ({
      value: id,
      label: name,
    }));
  }, [products]);

  return (
    <Field
      isRequired
      disabled={loading || disabled}
      label="appointments.fields.productId.label"
      name="productId"
      component={Select}
      {...{ options, loading, description }}
    />
  );
};

const DisabledSelect = ({
  products,
  loading,
  description,
  renderOptionLabel,
  getOptionValue,
  label,
  name,
}: iDisabledSelect) => {
  const options = useMemo(() => {
    if (!products) return [];
    return products.map((product: Product) => ({
      value: typeof getOptionValue === 'function' ? getOptionValue(product) : product.id,
      label: renderOptionLabel(product),
    }));
  }, [getOptionValue, products, renderOptionLabel]);
  return (
    <Field
      disabled
      isHideError
      name={name ?? 'productId'}
      component={Select}
      {...{ loading, label, options, description }}
    />
  );
};

const renderMeetingPlaces = (product: Product) => product.meetingPointName;
const renderTourTopic = ({ tourTopic }: Product) => tourTopic?.name[i18n.language as keyof typeof tourTopic.name];
const renderLanguage = ({ language }: Product) =>
  language && `${FlagEmojis[language as keyof typeof FlagEmojis]} ${i18n.t(`locale.${language}`)}`;
const renderTargetGroup = (targetGroup: iProductForm['targetGroup']) => () =>
  targetGroup?.name[i18n.language as keyof typeof targetGroup.name];
const renderCode = (targetGroup: iProductForm['targetGroup']) => (product: Product) =>
  [product.tourTopic?.code, targetGroup?.code].join('');

const ProductForm: FC<iProductForm> = ({ product, targetGroup, type, disabled }) => {
  const { data, loading, error } = useProductsForUpdatingAppointmentQuery({
    variables: {
      appointmentType: (type === AppointmentTypes.RECURRING_BOOKING_SLOT
        ? AppointmentTypes.BOOKING_SLOT
        : type) as AppointmentTypeGenerated,
    },
  });

  const products = useMemo(() => {
    if (!data?.products) return undefined;
    return uniqBy([...data.products, product].filter(Boolean), (product) => product?.id) as typeof data.products;
  }, [data, product]);

  const sharedProps = useMemo(
    () => ({
      loading,
      products,
    }),
    [loading, products],
  );

  if (error)
    return (
      <Col sm={12}>
        <AlertGraphQLError error={error} />
      </Col>
    );
  return (
    <>
      <Col sm={type === AppointmentTypes.EVENT ? 12 : 6}>
        <ProductSelect
          disabled={disabled}
          description={type === AppointmentTypes.EVENT ? undefined : 'appointments.fields.productId.description'}
          {...sharedProps}
        />
      </Col>
      {type === AppointmentTypes.EVENT ? null : (
        <>
          <Col sm={6}>
            <DisabledSelect
              {...sharedProps}
              renderOptionLabel={renderMeetingPlaces}
              label="appointments.fields.meetingPlace.label"
              description="appointments.fields.meetingPlace.description"
            />
          </Col>
          <Col sm={6}>
            <DisabledSelect
              {...sharedProps}
              renderOptionLabel={renderTourTopic}
              label="appointments.fields.tourTopicId.label"
              description="appointments.fields.tourTopicId.description"
            />
          </Col>
          <Col sm={6}>
            <DisabledSelect
              {...sharedProps}
              renderOptionLabel={renderLanguage}
              label="appointments.fields.appointmentLanguage.label"
              description="appointments.fields.appointmentLanguage.description"
            />
          </Col>
          <Col sm={6}>
            <DisabledSelect
              {...sharedProps}
              renderOptionLabel={renderTargetGroup(targetGroup)}
              getOptionValue={() => targetGroup?.id}
              name="targetGroupId"
              label="appointments.fields.targetGroupId.label"
              description="appointments.fields.targetGroupId.description"
            />
          </Col>
          <Col sm={6}>
            <DisabledSelect
              {...sharedProps}
              renderOptionLabel={renderCode(targetGroup)}
              label="appointments.fields.code.label"
              description="appointments.fields.code.description"
            />
          </Col>
        </>
      )}
    </>
  );
};

export default ProductForm;
