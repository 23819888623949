import TextInput from 'components/inputs/TextInput';
import React, { useMemo } from 'react';
import { Col, ColProps, Row } from 'react-bootstrap';
import * as yup from 'yup';
import i18n from 'i18n';
import Select from 'components/inputs/Select';
import { useTranslation } from 'react-i18next';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import FastField from 'components/common/FastField';
import TextArea from 'components/inputs/TextArea';
import routePaths from 'router/route-paths';
import { useMatch } from 'react-router-dom';
import { Salutation } from 'graphql/types.generated';
import { FormConfig } from '../utils';
import { ValidationSchemaParams } from 'pages/BookingRequestPage/components/StepLayout';
import { isValidPhoneNumber } from 'utils/helpers';

export const howDidYouHearSchema = ({ formConfig }: ValidationSchemaParams) => {
  const fields: Record<string, any> = {};
  if (formConfig?.howDidYouHear?.visible) {
    fields.howDidYouHear = yup.string().label(i18n.t('bookingRequest.fields.howDidYouHear.label'));
    if (formConfig?.howDidYouHear.required) fields.howDidYouHear = fields.howDidYouHear.required();
  }
  return fields;
};

export const schema = () => ({
  salutation: yup.string().nullable().required().label(i18n.t('bookingRequest.fields.salutation.label')),
  firstName: yup.string().required().max(50).label(i18n.t('bookingRequest.fields.firstName.label')),
  lastName: yup.string().required().max(50).label(i18n.t('bookingRequest.fields.lastName.label')),
  email: yup.string().required().email().max(50).label(i18n.t('bookingRequest.fields.email.label')),
  phoneNumber: yup
    .string()
    .max(15)
    .required()
    .test(
      'check-phone-number',
      i18n.t('bookingRequest.fields.phoneNumber.invalid'),
      (value?: string) => !!value && isValidPhoneNumber(value),
    )
    .label(i18n.t('bookingRequest.fields.phoneNumber.label')),
});

const SalutationSelect = () => {
  const { t } = useTranslation();

  const salutationOptions = useMemo(
    () =>
      Object.keys(Salutation).map((type) => ({
        value: type,
        label: t('salutations.' + type),
      })),
    [t],
  );

  return (
    <FastField
      isRequired
      label="bookingRequest.fields.salutation.label"
      placeholder="bookingRequest.fields.salutation.placeholder"
      name="salutation"
      component={Select}
      options={salutationOptions}
    />
  );
};

type FormConfigProps = { formConfig?: FormConfig };

export const HowDidYouHearInput = ({
  formConfig,
  colProps,
  rows = 3,
}: FormConfigProps & { colProps?: ColProps; rows?: number }) =>
  formConfig?.howDidYouHear?.visible ? (
    <Col {...colProps}>
      <FastField
        isRequired={formConfig?.howDidYouHear.required}
        name="howDidYouHear"
        component={TextArea}
        rows={rows}
        label="bookingRequest.fields.howDidYouHear.label"
      />
    </Col>
  ) : null;

const CustomerPhoneNumberInput = ({ colProps }: { colProps?: ColProps }) => (
  <Col {...colProps}>
    <FastField
      isRequired
      name="phoneNumber"
      component={PhoneNumberInput}
      label="bookingRequest.fields.phoneNumber.label"
    />
  </Col>
);

export const ContactPersonFormFields = ({ formConfig }: FormConfigProps) => {
  const match = useMatch(routePaths.bookingRequestForm);
  return (
    <>
      <Row>
        <Col sm={4}>
          <SalutationSelect />
        </Col>
        <Col>
          <FastField isRequired name="firstName" component={TextInput} label="bookingRequest.fields.firstName.label" />
        </Col>
        <Col>
          <FastField isRequired name="lastName" component={TextInput} label="bookingRequest.fields.lastName.label" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FastField isRequired name="email" component={TextInput} label="bookingRequest.fields.email.label" />
        </Col>
        <CustomerPhoneNumberInput colProps={{ sm: match ? 6 : 12 }} />
      </Row>
      {match ? <HowDidYouHearInput formConfig={formConfig} /> : null}
    </>
  );
};

const ContactPersonForm = (props: FormConfigProps) => {
  const { t } = useTranslation();
  return (
    <>
      <h5>{t('bookingRequest.contactTitle')}</h5>
      <ContactPersonFormFields {...props} />
    </>
  );
};

export default ContactPersonForm;
