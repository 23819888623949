import React, { useCallback } from 'react';
import { FastField, Form, FormikHelpers, FormikProps } from 'formik';
import Button from 'components/common/Button';
import TextInput from 'components/inputs/TextInput/TextInput';
import * as yup from 'yup';
import s from './LoginForm.module.sass';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useForgotPasswordMutation } from 'graphql/mutations/auth/generated/ForgotPassword';
import { toast } from 'react-toastify';
import { toastErrorMessage } from 'utils/helpers';
import Formik from 'components/common/Formik';

const validationSchema = yup.object().shape({
  email: yup.string().required(i18n.t('errors.requiredField')).email(i18n.t('errors.emailInvalid')),
});

interface iForgotPasswordFormValues {
  email: string;
}

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const initialValues: iForgotPasswordFormValues = { email: '' };
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation({
    onCompleted: () => {
      toast.success(t('auth.passwordResetLinkSent'));
      navigate(routePaths.login);
    },
    onError: toastErrorMessage,
  });

  const onSubmit = useCallback(
    async (values: iForgotPasswordFormValues, helpers: FormikHelpers<iForgotPasswordFormValues>) => {
      try {
        await forgotPassword({ variables: { email: values.email } });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [forgotPassword],
  );

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {({ isSubmitting }: FormikProps<iForgotPasswordFormValues>) => (
        <Form>
          <FastField
            name="email"
            component={TextInput}
            label="auth.email"
            startIcon={<FontAwesomeIcon icon={faUser} />}
          />
          <p className="my-4 text-end" style={{ fontSize: '14px' }}>
            {t('forgotPassword.goToLogin')} <Link to={routePaths.login}>{t('pageTitles.login')}</Link>
          </p>

          <div className={s.submitBtnWrapper}>
            <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
              {t('send')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
