import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { EmailTemplateSettingFragmentDoc } from '../../../fragments/emailTemplate/generated/emailTemplateSettingFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailTemplatesQueryVariables = SchemaTypes.Exact<{
  limit: SchemaTypes.Scalars['Int'];
  skip: SchemaTypes.Scalars['Int'];
}>;

export type EmailTemplatesQuery = {
  __typename?: 'Query';
  emailTemplates: {
    __typename?: 'PaginatedEmailTemplateResponse';
    total: number;
    items: Array<{
      __typename?: 'EmailTemplate';
      id: string;
      name: string;
      language: SchemaTypes.TourLanguage;
      subject: string;
      targetGroupId?: string | null;
      body: string;
      createdAt: string | Date;
    }>;
  };
};

export const EmailTemplatesDocument = gql`
  query EmailTemplates($limit: Int!, $skip: Int!) {
    emailTemplates(limit: $limit, skip: $skip) {
      items {
        ...EmailTemplateSetting
      }
      total
    }
  }
  ${EmailTemplateSettingFragmentDoc}
`;

/**
 * __useEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEmailTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<EmailTemplatesQuery, EmailTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailTemplatesQuery, EmailTemplatesQueryVariables>(EmailTemplatesDocument, options);
}
export function useEmailTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailTemplatesQuery, EmailTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailTemplatesQuery, EmailTemplatesQueryVariables>(EmailTemplatesDocument, options);
}
export type EmailTemplatesQueryHookResult = ReturnType<typeof useEmailTemplatesQuery>;
export type EmailTemplatesLazyQueryHookResult = ReturnType<typeof useEmailTemplatesLazyQuery>;
export type EmailTemplatesQueryResult = Apollo.QueryResult<EmailTemplatesQuery, EmailTemplatesQueryVariables>;
export function refetchEmailTemplatesQuery(variables: EmailTemplatesQueryVariables) {
  return { query: EmailTemplatesDocument, variables: variables };
}
