import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { iInputModalConfirm, InputModalConfirm } from 'components/common/Modal/InputModal';
import FastField from 'components/common/FastField';
import TextInput from 'components/inputs/TextInput';
import * as yup from 'yup';
import i18n from 'i18n';
import client from 'graphql/apollo';
import { ChangePasswordDocument } from 'graphql/mutations/user/generated/ChangePassword';
import { toast } from 'react-toastify';

const changePasswordValidationSchema = () =>
  yup.object().shape({
    password: yup.string().required().min(8).label(i18n.t('userProfile.fields.password.label')),
    newPassword: yup.string().required().min(8).label(i18n.t('userProfile.fields.newPassword.label')),
    repeatedNewPassword: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref('newPassword'), null], i18n.t('userProfile.fields.repeatedNewPassword.noMatch'))
      .label(i18n.t('userProfile.fields.repeatedNewPassword.label')),
  });

type ChangePasswordProps = {
  password: string;
  newPassword: string;
  repeatedNewPassword: string;
};

const ChangePasswordFormikContent: FC = () => (
  <>
    <FastField
      isRequired
      label="userProfile.fields.password.label"
      type="password"
      name="password"
      component={TextInput}
    />
    <FastField
      isRequired
      label="userProfile.fields.newPassword.label"
      type="password"
      name="newPassword"
      component={TextInput}
    />
    <FastField
      isRequired
      label="userProfile.fields.repeatedNewPassword.label"
      type="password"
      name="repeatedNewPassword"
      component={TextInput}
    />
  </>
);

const changePasswordModal = () =>
  InputModalConfirm({
    initialValues: {
      password: '',
      newPassword: '',
      repeatedNewPassword: '',
    },
    formikContent: ChangePasswordFormikContent,
    onSubmit: async (values) => {
      await client.mutate({
        mutation: ChangePasswordDocument,
        variables: { currentPassword: values.password, newPassword: values.newPassword },
      });
      toast.success(i18n.t('userProfile.changePassword.success'));
    },
    headerText: i18n.t('userProfile.changePassword.modalTitle'),
    validationSchema: changePasswordValidationSchema,
  } as iInputModalConfirm<ChangePasswordProps>);

const ChangePassword = () => {
  const { t } = useTranslation();
  return (
    <Button className="px-0" variant="link" onClick={changePasswordModal}>
      {t('userProfile.changePassword.button')}
    </Button>
  );
};

export default ChangePassword;
