import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../../../fragments/appointments/generated/appointmentFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingRequestAppointmentsQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type BookingRequestAppointmentsQuery = {
  __typename?: 'Query';
  bookingRequest: {
    __typename?: 'BookingRequest';
    id: string;
    status: SchemaTypes.BookingRequestStatus;
    appointments: Array<{
      __typename?: 'Appointment';
      id: string;
      ageOfParticipants?: string | null;
      code?: string | null;
      endDate: string | Date;
      guideCheckInDate?: string | Date | null;
      infoForGuides?: string | null;
      infoForOffice?: SchemaTypes.AppointmentInfoForOffice | null;
      startDate: string | Date;
      status: SchemaTypes.AppointmentStatus;
      totalAccompanyingPersons?: number | null;
      totalGuides?: number | null;
      totalParticipants?: number | null;
      totalSlots: number;
      type: SchemaTypes.AppointmentType;
      bookingkitConnection?: {
        __typename?: 'AppointmentBookingkitConnection';
        dateId?: string | null;
        dateSyncedAt?: string | Date | null;
        dateSyncSuccess?: boolean | null;
        orderSuccess?: boolean | null;
        orderSyncedAt?: string | Date | null;
        errorName?: string | null;
        errorMessage?: string | null;
        ignoreError?: boolean | null;
      } | null;
      bookingRequest?: { __typename?: 'BookingRequest'; id: string } | null;
      customer?: {
        __typename?: 'Customer';
        id: string;
        salutation: SchemaTypes.Salutation;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        email: string;
        organization: { __typename?: 'CustomerOrganization'; id: string; name?: string | null };
      } | null;
      guide?: { __typename?: 'User'; id: string; profile: { __typename?: 'UserProfile'; fullName: string } } | null;
      guideFeedback?: {
        __typename?: 'GuideFeedback';
        totalParticipants: number;
        totalAccompanyingPersons?: number | null;
        comment: string;
        createdAt: string | Date;
      } | null;
      product?: {
        __typename?: 'Product';
        id: string;
        name: string;
        meetingPointName?: string | null;
        bookingkitSyncEnabled: boolean;
      } | null;
      tourTopic?: { __typename?: 'TourTopic'; id: string; backgroundColor: string } | null;
    }>;
  };
};

export const BookingRequestAppointmentsDocument = gql`
  query BookingRequestAppointments($id: String!) {
    bookingRequest(id: $id) {
      id
      status
      appointments {
        ...Appointment
      }
    }
  }
  ${AppointmentFragmentDoc}
`;

/**
 * __useBookingRequestAppointmentsQuery__
 *
 * To run a query within a React component, call `useBookingRequestAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestAppointmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingRequestAppointmentsQuery, BookingRequestAppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingRequestAppointmentsQuery, BookingRequestAppointmentsQueryVariables>(
    BookingRequestAppointmentsDocument,
    options,
  );
}
export function useBookingRequestAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestAppointmentsQuery, BookingRequestAppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingRequestAppointmentsQuery, BookingRequestAppointmentsQueryVariables>(
    BookingRequestAppointmentsDocument,
    options,
  );
}
export type BookingRequestAppointmentsQueryHookResult = ReturnType<typeof useBookingRequestAppointmentsQuery>;
export type BookingRequestAppointmentsLazyQueryHookResult = ReturnType<typeof useBookingRequestAppointmentsLazyQuery>;
export type BookingRequestAppointmentsQueryResult = Apollo.QueryResult<
  BookingRequestAppointmentsQuery,
  BookingRequestAppointmentsQueryVariables
>;
export function refetchBookingRequestAppointmentsQuery(variables: BookingRequestAppointmentsQueryVariables) {
  return { query: BookingRequestAppointmentsDocument, variables: variables };
}
