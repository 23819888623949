import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { TourTopicForSelectFragmentDoc } from '../../../fragments/tourTopic/generated/tourTopicForSelectFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TourTopicsForSelectQueryVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export type TourTopicsForSelectQuery = {
  __typename?: 'Query';
  tourTopics: Array<{
    __typename?: 'TourTopic';
    id: string;
    name: { __typename?: 'Localized'; en: string; de: string };
  }>;
};

export const TourTopicsForSelectDocument = gql`
  query TourTopicsForSelect {
    tourTopics {
      ...TourTopicForSelect
    }
  }
  ${TourTopicForSelectFragmentDoc}
`;

/**
 * __useTourTopicsForSelectQuery__
 *
 * To run a query within a React component, call `useTourTopicsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourTopicsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourTopicsForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useTourTopicsForSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<TourTopicsForSelectQuery, TourTopicsForSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TourTopicsForSelectQuery, TourTopicsForSelectQueryVariables>(
    TourTopicsForSelectDocument,
    options,
  );
}
export function useTourTopicsForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TourTopicsForSelectQuery, TourTopicsForSelectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TourTopicsForSelectQuery, TourTopicsForSelectQueryVariables>(
    TourTopicsForSelectDocument,
    options,
  );
}
export type TourTopicsForSelectQueryHookResult = ReturnType<typeof useTourTopicsForSelectQuery>;
export type TourTopicsForSelectLazyQueryHookResult = ReturnType<typeof useTourTopicsForSelectLazyQuery>;
export type TourTopicsForSelectQueryResult = Apollo.QueryResult<
  TourTopicsForSelectQuery,
  TourTopicsForSelectQueryVariables
>;
export function refetchTourTopicsForSelectQuery(variables?: TourTopicsForSelectQueryVariables) {
  return { query: TourTopicsForSelectDocument, variables: variables };
}
