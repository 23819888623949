import React from 'react';
import { AppointmentForm, tourTopicAndLanguageSchema } from 'components/bookingRequest/AppointmentForm';
import StepLayout from '../components/StepLayout';
import * as yup from 'yup';

const Appointment = () => {
  return (
    <StepLayout
      validationSchema={() =>
        yup.object().shape({
          ...tourTopicAndLanguageSchema(),
        })
      }
    >
      <AppointmentForm />
    </StepLayout>
  );
};

export default Appointment;
