import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { BookingRequestViewFragmentDoc } from '../../../fragments/bookingRequest/generated/bookingRequestViewFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingRequestViewQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;

export type BookingRequestViewQuery = {
  __typename?: 'Query';
  bookingRequest: {
    __typename?: 'BookingRequest';
    id: string;
    date: string | Date;
    altDate: string | Date;
    createdAt: string | Date;
    totalParticipants: number;
    totalAccompanyingPersons?: number | null;
    ageOfParticipants?: string | null;
    code?: string | null;
    language: SchemaTypes.TourLanguage;
    howDidYouHear?: string | null;
    status: SchemaTypes.BookingRequestStatus;
    customer: {
      __typename?: 'Customer';
      id: string;
      salutation: SchemaTypes.Salutation;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber?: string | null;
      organization: {
        __typename?: 'CustomerOrganization';
        id: string;
        name?: string | null;
        address?: string | null;
        buildingNumber?: string | null;
        zip?: string | null;
        city?: string | null;
        uidNumber?: string | null;
        targetGroup: {
          __typename?: 'TargetGroup';
          id: string;
          bookingFormConfig?: {
            __typename?: 'TargetGroupBookingFormConfig';
            fieldConfig: Array<{
              __typename?: 'TargetGroupFieldConfig';
              visible: boolean;
              required: boolean;
              name: string;
            }>;
          } | null;
        };
      };
    };
    automationDetails?: Array<{
      __typename?: 'BookingRequestAutomationDetails';
      name: SchemaTypes.CriteriaType;
      details?: string | null;
      passed: boolean;
    }> | null;
  };
};

export const BookingRequestViewDocument = gql`
  query BookingRequestView($id: String!) {
    bookingRequest(id: $id) {
      ...BookingRequestView
    }
  }
  ${BookingRequestViewFragmentDoc}
`;

/**
 * __useBookingRequestViewQuery__
 *
 * To run a query within a React component, call `useBookingRequestViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingRequestViewQuery(
  baseOptions: Apollo.QueryHookOptions<BookingRequestViewQuery, BookingRequestViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingRequestViewQuery, BookingRequestViewQueryVariables>(
    BookingRequestViewDocument,
    options,
  );
}
export function useBookingRequestViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestViewQuery, BookingRequestViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingRequestViewQuery, BookingRequestViewQueryVariables>(
    BookingRequestViewDocument,
    options,
  );
}
export type BookingRequestViewQueryHookResult = ReturnType<typeof useBookingRequestViewQuery>;
export type BookingRequestViewLazyQueryHookResult = ReturnType<typeof useBookingRequestViewLazyQuery>;
export type BookingRequestViewQueryResult = Apollo.QueryResult<
  BookingRequestViewQuery,
  BookingRequestViewQueryVariables
>;
export function refetchBookingRequestViewQuery(variables: BookingRequestViewQueryVariables) {
  return { query: BookingRequestViewDocument, variables: variables };
}
