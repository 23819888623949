import Button from 'components/common/Button';
import { useToggleIgnoreBookingkitErrorForAppointmentMutation } from 'graphql/mutations/appointment/generated/ToggleIgnoreBookingkitErrorForAppointment';
import React from 'react';
import { EyeSlash } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';

const IgnoreAppointmentPushErrorButton: FC<{ appointmentId: string }> = ({ appointmentId }) => {
  const { t } = useTranslation();

  const [toggleIgnoreBookingkitErrorForAppointmentMutation, { loading }] =
    useToggleIgnoreBookingkitErrorForAppointmentMutation({
      variables: { appointmentId, ignore: true },
      onError: (error) => {
        toast.error(formatGraphqlErrors(error).message);
      },
    });

  return (
    <Button
      size="sm"
      variant="link"
      loading={loading}
      disabled={loading}
      onClick={() => toggleIgnoreBookingkitErrorForAppointmentMutation()}
    >
      <small>
        {t('appointments.bookingkitCell.ignoreErrorButton')} <EyeSlash />
      </small>
    </Button>
  );
};

export default IgnoreAppointmentPushErrorButton;
