import TextInput from 'components/inputs/TextInput';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { FormConfig, useTargetGroupNameFromCache } from '../utils';
import { ValidationSchemaParams } from 'pages/BookingRequestPage/components/StepLayout';
import { Field } from 'formik';

export const schema = ({ formConfig }: ValidationSchemaParams) => {
  const fields = [
    { name: 'customer.organization.name', schema: yup.string().max(100) },
    { name: 'customer.organization.buildingNumber', schema: yup.string().max(20) },
    { name: 'customer.organization.zip', schema: yup.string().max(10) },
    { name: 'customer.organization.city', schema: yup.string().max(30) },
    { name: 'customer.organization.address', schema: yup.string().max(50) },
    { name: 'customer.organization.uidNumber', schema: yup.string() },
  ].reduce((acc, { name, schema }) => {
    if (formConfig?.[name].visible) {
      const formName: string = name.split('.').reverse()[0];
      acc[formName] = schema.label(i18n.t(`bookingRequest.fields.${formName}.label`));
      if (formConfig?.[name].required) acc[formName] = acc[formName].required();
    }
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<string, any>);
  return fields;
};

interface iOrganizationFormFields {
  formConfig?: FormConfig;
}

export const OrganizationFormFields = ({ formConfig }: iOrganizationFormFields) => {
  const { nameConfig, zipConfig, cityConfig, addressConfig, buildingNumberConfig, UIDNumberConfig } = useMemo(
    () => ({
      nameConfig: formConfig?.['customer.organization.name'],
      zipConfig: formConfig?.['customer.organization.zip'],
      cityConfig: formConfig?.['customer.organization.city'],
      addressConfig: formConfig?.['customer.organization.address'],
      buildingNumberConfig: formConfig?.['customer.organization.buildingNumber'],
      UIDNumberConfig: formConfig?.['customer.organization.uidNumber'],
    }),
    [formConfig],
  );
  return (
    <>
      {nameConfig?.visible ? (
        <Field
          isRequired={nameConfig?.required}
          name="name"
          component={TextInput}
          label="bookingRequest.fields.name.label"
        />
      ) : null}
      {addressConfig?.visible || buildingNumberConfig?.visible ? (
        <Row>
          {addressConfig?.visible ? (
            <Col>
              <Field
                isRequired={addressConfig?.required}
                name="address"
                component={TextInput}
                label="bookingRequest.fields.address.label"
              />
            </Col>
          ) : null}
          {buildingNumberConfig?.visible ? (
            <Col sm={4}>
              <Field
                isRequired={buildingNumberConfig?.required}
                name="buildingNumber"
                component={TextInput}
                label="bookingRequest.fields.buildingNumber.label"
              />
            </Col>
          ) : null}
        </Row>
      ) : null}
      {zipConfig?.visible || cityConfig?.visible ? (
        <Row>
          {zipConfig?.visible ? (
            <Col {...(zipConfig?.visible && cityConfig?.visible && { xs: 12, sm: 4 })}>
              <Field
                isRequired={zipConfig?.required}
                name="zip"
                component={TextInput}
                label="bookingRequest.fields.zip.label"
              />
            </Col>
          ) : null}
          {cityConfig?.visible ? (
            <Col>
              <Field
                isRequired={cityConfig?.required}
                name="city"
                component={TextInput}
                label="bookingRequest.fields.city.label"
              />
            </Col>
          ) : null}
        </Row>
      ) : null}
      {UIDNumberConfig?.visible ? (
        <Field
          isRequired={UIDNumberConfig?.required}
          name="uidNumber"
          component={TextInput}
          label="bookingRequest.fields.uidNumber.label"
        />
      ) : null}
    </>
  );
};

interface iOrganizationForm extends iOrganizationFormFields {
  targetGroupId?: string;
}

const OrganizationForm = (props: iOrganizationForm) => {
  const { targetGroupId } = props;
  const { t, i18n } = useTranslation();
  const targetGroup = useTargetGroupNameFromCache(targetGroupId);
  return (
    <>
      <h5>
        {t('bookingRequest.organizationTitle', {
          name: targetGroup ? targetGroup.name[i18n.language as keyof typeof targetGroup.name] : '',
        })}
      </h5>
      <OrganizationFormFields {...props} />
    </>
  );
};

export default OrganizationForm;
