import React, { useCallback, useMemo } from 'react';
import RichEditorFieldWithRef from 'components/inputs/RichEditor';
import { FastField, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import i18n from 'i18n';
import * as yup from 'yup';
import Select from 'components/inputs/Select';
import constants from 'constants/constants';
import { find } from 'lodash';
import { SelectedEmailTemplate } from './index.type';
import { iRichEditorFieldProps } from 'components/inputs/RichEditor/RichEditorField';
import { iTextInput } from 'components/inputs/TextInput/TextInput';
import { EmailTemplate, emailTemplatesToOptions } from './utils';
import { useEmailTemplatesSendEmailQuery } from 'graphql/queries/emailTemplate/generated/EmailTemplatesSendEmail';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { iRequired } from 'components/common/Required';

export const emailTemplateIdSchema = () => ({
  emailTemplateId: yup.string().nullable().required().label(i18n.t('emailTemplates.fields.emailTemplateId.label')),
});

export const schema = () => ({
  name: yup.string().required().label(i18n.t('emailTemplates.fields.name.label')),
  language: yup.string().nullable().required().label(i18n.t('emailTemplates.fields.language.label')),
  subject: yup.string().required().label(i18n.t('emailTemplates.fields.subject.label')),
  body: yup.string().required().label(i18n.t('emailTemplates.fields.body.label')),
});

export const NameInput = () => (
  <FastField isRequired label="emailTemplates.fields.name.label" name="name" component={TextInput} />
);

export const ToInput = (props: { label: string; name: string }) => (
  <FastField {...props} disabled component={TextInput} />
);

export const SubjectInput: FC<iTextInput> = ({
  label = 'emailTemplates.fields.subject.label',
  name = 'subject',
  ...props
}) => <FastField isRequired {...{ label, name, ...props }} component={TextInput} />;

export const BodyInput: FC<iRichEditorFieldProps> = ({
  label = 'emailTemplates.fields.body.label',
  name = 'body',
  ...props
}) => <RichEditorFieldWithRef {...{ label, name, ...props }} />;

interface iEmailTemplateSelectWithFetch extends iRequired {
  name: string;
  label: string;
  onChange: (emailTemplate: SelectedEmailTemplate) => void;
  disabled?: boolean;
}

const EmailTemplateSelectWithFetch: FC<iEmailTemplateSelectWithFetch> = ({
  name,
  label,
  onChange,
  disabled,
  isRequired,
}) => {
  const { data, loading, error } = useEmailTemplatesSendEmailQuery({
    variables: { limit: constants.emailTemplateSelectLimit, skip: 0 },
  });

  const [emailTemplates, emailTemplateOptions] = useMemo(() => {
    if (!data) return [[], []];
    const emailTemplates = data?.emailTemplates.items;
    return [emailTemplates, emailTemplatesToOptions(emailTemplates)];
  }, [data]);

  const handleChange = useCallback(
    (emailTemplateId) => {
      const selectedEmailTemplate = find(emailTemplates, { id: emailTemplateId });
      if (!selectedEmailTemplate) return;
      onChange(selectedEmailTemplate);
    },
    [emailTemplates, onChange],
  );

  if (error) return <AlertGraphQLError error={error} />;
  return (
    <Field
      disabled={(!data && loading) || disabled}
      loading={loading}
      component={Select}
      sideEffectOnSelect={handleChange}
      options={emailTemplateOptions}
      {...{ name, label, isRequired }}
    />
  );
};

interface iEmailTemplateSelect extends iEmailTemplateSelectWithFetch {
  emailTemplates: EmailTemplate[];
}

const EmailTemplateSelect: FC<iEmailTemplateSelect> = ({ emailTemplates, ...props }) => {
  const emailTemplateOptions = useMemo(() => {
    return emailTemplatesToOptions(emailTemplates);
  }, [emailTemplates]);
  return <FastField component={Select} options={emailTemplateOptions} {...props} />;
};

interface iEmailTemplateSelectContainer extends iEmailTemplateSelectWithFetch {
  emailTemplates?: EmailTemplate[];
}

export const EmailTemplateSelectContainer: FC<iEmailTemplateSelectContainer> = ({ emailTemplates, ...props }) => {
  if (emailTemplates) return <EmailTemplateSelect emailTemplates={emailTemplates} {...props} />;
  return <EmailTemplateSelectWithFetch {...props} />;
};
