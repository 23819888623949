import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TourTopicForConfigFragment = {
  __typename?: 'TourTopic';
  id: string;
  maxToursDuringDay?: number | null;
  name: { __typename?: 'Localized'; en: string; de: string };
};

export type TourTopicForConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TourTopicForConfigFragmentDoc = gql`
  fragment TourTopicForConfig on TourTopic {
    id
    maxToursDuringDay
    name {
      ...Localized
    }
  }
  ${LocalizedFragmentDoc}
`;
