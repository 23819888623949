import React, { useCallback, useRef } from 'react';
import s from '../Modal.module.sass';
import { Form, FormikHelpers, FormikProps, FormikValues } from 'formik';
import cn from 'classnames';
import Modal, { iModalProps } from '../Modal';
import { confirmAlert } from '@uplab/react-confirm-alert';
import * as yup from 'yup';
import InputModalFooter, { iInputModalFooter } from './Footer';
import { emptyFunction, useEnterDownDocument } from '../utils';
import { ApolloError } from '@apollo/client';
import { toastErrorMessage } from 'utils/helpers';
import { GraphQLError } from 'graphql';
import Lazy from 'yup/lib/Lazy';
import { isEqual } from 'lodash';
import ConfirmModal from '../ConfirmModal';
import i18n from 'i18n';
import Formik from 'components/common/Formik';
import { Button } from 'react-bootstrap';

interface iInputModalProps<T> extends Omit<iModalProps, 'isVisible'>, iInputModalFooter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikContent: FC<any>;
  initialValues?: T;
  onCloseAfter: () => void;
  onSubmit:
    | ((values: T, helpers?: FormikHelpers<T>) => Promise<boolean | undefined> | boolean | undefined)
    | ((values: T, helpers?: FormikHelpers<T>) => Promise<void> | void);
  onReset?: (values: T, helpers?: FormikHelpers<T>) => Promise<void> | void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema?: () => yup.ObjectSchema<any> | Lazy<any>;
  errorResolver?: (e: GraphQLError) => string | undefined;
}

const InputModal = <T,>({
  initialValues = {} as T,
  onSubmit,
  onReset,
  onClose = emptyFunction,
  okText,
  cancelText,
  formikContent: FormikContent,
  onCloseAfter,
  validationSchema,
  errorResolver,
  customFooter,
  ...props
}: iInputModalProps<T>): JSX.Element => {
  const formikRef = useRef<FormikProps<T> | null>(null);
  const onHandleSubmit = useCallback(
    async (values: T) => {
      try {
        const isHide = !(await Promise.resolve(onSubmit(values)));
        isHide && typeof onCloseAfter === 'function' && onCloseAfter();
      } catch (error) {
        toastErrorMessage(error as ApolloError, errorResolver);
      }
    },
    [errorResolver, onCloseAfter, onSubmit],
  );

  useEnterDownDocument(() => formikRef.current?.submitForm());

  const onHandleReset = useCallback(
    async (values: T) => {
      try {
        typeof onReset === 'function' && (await Promise.resolve(onReset(values)));
      } catch (error) {
        toastErrorMessage(error as ApolloError, errorResolver);
      }
    },
    [errorResolver, onReset],
  );

  const onHandleClose = useCallback(async () => {
    try {
      await Promise.resolve(onClose());
      if (
        !(
          formikRef.current &&
          !isEqual(formikRef.current.initialValues, formikRef.current.values) &&
          !(await new Promise((resolve) =>
            ConfirmModal({
              description: i18n.t('closeConfirmation'),
              onOk: async () => {
                resolve(true);
              },
              cancelText: i18n.t('no'),
              okText: i18n.t('yes'),
            }),
          ))
        )
      )
        onCloseAfter();
    } catch (error) {
      toastErrorMessage(error as ApolloError, errorResolver);
    }
  }, [errorResolver, onClose, onCloseAfter]);
  return (
    <Modal isVisible={true} onClose={onHandleClose} {...props}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={onHandleSubmit}
        onReset={onHandleReset}
        validationSchema={validationSchema}
      >
        <Form className="d-flex flex-1 flex-column">
          <div className={cn(s.modalBody, 'border-bottom flex-1')}>
            <FormikContent />
          </div>
          <InputModalFooter
            cancelText={cancelText}
            customFooter={customFooter}
            okText={okText}
            onClose={onHandleClose}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export interface iInputModalConfirm<T> extends Omit<iInputModalProps<T>, 'onClose' | 'onCloseAfter'> {
  onClose?: () => void;
}

export type CustomContainerProps<T> = FC<{
  Component: (props: iInputModalConfirm<T>) => JSX.Element;
  Modal: FC<Omit<iModalProps, 'onClose' | 'isVisible'> & { onClose?: () => void }>;
}>;

export const InputModalConfirm = <T extends FormikValues = FormikValues>({
  ...props
}: iInputModalConfirm<T> | { CustomContainer: CustomContainerProps<T> }) => {
  return confirmAlert({
    customUI: ({ onClose: onCloseAfter }) =>
      'CustomContainer' in props ? (
        <props.CustomContainer
          Component={(newProps: iInputModalConfirm<T>) => (
            <InputModal {...newProps} onClose={newProps.onClose ?? emptyFunction} onCloseAfter={onCloseAfter} />
          )}
          Modal={({ children, ...props }) => {
            const onClose = async () => {
              await Promise.resolve(props.onClose?.());
              onCloseAfter();
            };
            return (
              <Modal {...props} isVisible onClose={onClose}>
                <div className={s.customModalBody}>{children}</div>
                <div className="p-3 float-end">
                  <Button onClick={onClose} variant="outline-primary">
                    {i18n.t('close')}
                  </Button>{' '}
                  <Button onClick={onClose}>{i18n.t('ok')}</Button>
                </div>
              </Modal>
            );
          }}
        />
      ) : (
        <InputModal {...props} onClose={props.onClose ?? emptyFunction} onCloseAfter={onCloseAfter} />
      ),
  });
};
