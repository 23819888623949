import React from 'react';

const MultilineText: FC<{ text?: string | null }> = ({ text }) => {
  if (!text) return null;
  return (
    <>
      {text.split('\n').map((s, i) => (
        <React.Fragment key={i}>
          {s}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default MultilineText;
