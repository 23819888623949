import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEventAppointmentMutationVariables = SchemaTypes.Exact<{
  data: SchemaTypes.EventAppointmentInput;
}>;

export type CreateEventAppointmentMutation = {
  __typename?: 'Mutation';
  createEventAppointment: { __typename?: 'Appointment'; id: string };
};

export const CreateEventAppointmentDocument = gql`
  mutation CreateEventAppointment($data: EventAppointmentInput!) {
    createEventAppointment(data: $data) {
      id
    }
  }
`;
export type CreateEventAppointmentMutationFn = Apollo.MutationFunction<
  CreateEventAppointmentMutation,
  CreateEventAppointmentMutationVariables
>;

/**
 * __useCreateEventAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateEventAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventAppointmentMutation, { data, loading, error }] = useCreateEventAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventAppointmentMutation, CreateEventAppointmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventAppointmentMutation, CreateEventAppointmentMutationVariables>(
    CreateEventAppointmentDocument,
    options,
  );
}
export type CreateEventAppointmentMutationHookResult = ReturnType<typeof useCreateEventAppointmentMutation>;
export type CreateEventAppointmentMutationResult = Apollo.MutationResult<CreateEventAppointmentMutation>;
export type CreateEventAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateEventAppointmentMutation,
  CreateEventAppointmentMutationVariables
>;
