import classNames from 'classnames';
import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import c from './IconButton.module.sass';

interface iIconButtonProps extends ButtonProps {
  icon: React.ReactNode;
}

export default function IconButton({ icon, className, ...rest }: iIconButtonProps) {
  return (
    <Button {...rest} className={classNames([c.button, className])} variant="outline-primary" size="sm">
      {icon}
    </Button>
  );
}
