import React from 'react';
import { Formik as DefaultFormik, FormikConfig, FormikProps, FormikValues } from 'formik';
import RefreshI18nErrorsFormik from './RefreshI18nErrorsFormik';

// eslint-disable-next-line @typescript-eslint/ban-types
const I18nFormik = <Values extends FormikValues = FormikValues, ExtraProps = {}>(
  props: FormikConfig<Values> & ExtraProps,
) => {
  const { children } = props;
  return (
    <DefaultFormik {...props}>
      {(formikChildrenProps: FormikProps<Values>) => (
        <>
          <RefreshI18nErrorsFormik />
          {typeof children === 'function' ? children(formikChildrenProps) : children}
        </>
      )}
    </DefaultFormik>
  );
};

export default I18nFormik;
