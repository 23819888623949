import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBlockedDateMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;

export type DeleteBlockedDateMutation = { __typename?: 'Mutation'; deleteBlockedDate: boolean };

export const DeleteBlockedDateDocument = gql`
  mutation DeleteBlockedDate($id: ID!) {
    deleteBlockedDate(id: $id)
  }
`;
export type DeleteBlockedDateMutationFn = Apollo.MutationFunction<
  DeleteBlockedDateMutation,
  DeleteBlockedDateMutationVariables
>;

/**
 * __useDeleteBlockedDateMutation__
 *
 * To run a mutation, you first call `useDeleteBlockedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockedDateMutation, { data, loading, error }] = useDeleteBlockedDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlockedDateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBlockedDateMutation, DeleteBlockedDateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBlockedDateMutation, DeleteBlockedDateMutationVariables>(
    DeleteBlockedDateDocument,
    options,
  );
}
export type DeleteBlockedDateMutationHookResult = ReturnType<typeof useDeleteBlockedDateMutation>;
export type DeleteBlockedDateMutationResult = Apollo.MutationResult<DeleteBlockedDateMutation>;
export type DeleteBlockedDateMutationOptions = Apollo.BaseMutationOptions<
  DeleteBlockedDateMutation,
  DeleteBlockedDateMutationVariables
>;
