import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type ProductConfigFragment = {
  __typename?: 'Product';
  id: string;
  bookingkitEventId?: string | null;
  bookingkitPriceId?: string | null;
  bookingkitSyncEnabled: boolean;
  name: string;
  language?: SchemaTypes.TourLanguage | null;
  type: SchemaTypes.ProductType;
  meetingPointName?: string | null;
  startPointAddress?: string | null;
  endPointAddress?: string | null;
  tourTopic?: {
    __typename?: 'TourTopic';
    id: string;
    name: { __typename?: 'Localized'; en: string; de: string };
  } | null;
};

export type ProductConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const ProductConfigFragmentDoc = gql`
  fragment ProductConfig on Product {
    id
    bookingkitEventId
    bookingkitPriceId
    bookingkitSyncEnabled
    name
    language
    type
    meetingPointName
    startPointAddress
    endPointAddress
    tourTopic {
      id
      name {
        ...Localized
      }
    }
  }
  ${LocalizedFragmentDoc}
`;
