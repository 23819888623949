import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { LocalizedFragmentDoc } from '../../common/generated/localizedFragment';
export type TourTopicConfigFragment = {
  __typename?: 'TourTopic';
  id: string;
  code: string;
  name: { __typename?: 'Localized'; en: string; de: string };
};

export type TourTopicConfigFragmentVariables = SchemaTypes.Exact<{ [key: string]: never }>;

export const TourTopicConfigFragmentDoc = gql`
  fragment TourTopicConfig on TourTopic {
    id
    name {
      ...Localized
    }
    code
  }
  ${LocalizedFragmentDoc}
`;
